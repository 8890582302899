import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useClientLayoutRenderContext } from '@/context/client-layout-context'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import { responsiveGapV2 } from '@/pages/home-page/HomePage.styles'

const NotFoundPage = () => {
  const { t } = useTranslation()
  const { setIsContactFormRender, setIsHeaderRender, setIsFooterRender, resetDefaultLayout } =
    useClientLayoutRenderContext()

  useEffect(() => {
    setIsContactFormRender(false)
    setIsHeaderRender(false)
    setIsFooterRender(false)
    return () => {
      resetDefaultLayout()
    }
  }, [setIsContactFormRender, setIsHeaderRender, setIsFooterRender, resetDefaultLayout])

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '735px',
        margin: 'auto',
        px: responsiveGapV2
      }}
    >
      <Typography
        variant={'h1'}
        color={'primary'}
        sx={{
          '&.MuiTypography-root': {
            fontWeight: '700',
            fontSize: '80px',
            lineHeight: '97px',
            mb: '30px'
          }
        }}
      >
        404
      </Typography>
      <Typography
        variant={'h2'}
        sx={{ textAlign: 'center', mb: '16px' }}
      >
        {t('error.404')}
      </Typography>
      <Typography
        variant={'body1'}
        sx={{ fontSize: '16px', textAlign: 'center', mb: '30px' }}
      >
        {t('error.pageNotFound.subtitle')}
      </Typography>
      <Button
        variant={'contained'}
        component={NavLink}
        to={clientRoutes.home.route}
        sx={{ minWidth: { sm: '335px', xs: '100%' } }}
      >
        {t('error.pageNotFound.back')}
      </Button>
    </Box>
  )
}

export default NotFoundPage
