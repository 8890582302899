import AppSelect, {
  getAppMenuPropsMinWidth
} from '@/containers/invest-object-list/invest-object-list-filters/app-select/AppSelect'
import { Box, MenuItem, Paper, Zoom } from '@mui/material'
import { useEffect, useState } from 'react'
import AppSelectOptionLabel from '@/containers/invest-object-list/invest-object-list-filters/app-select-option-label/AppSelectOptionLabel'
import AppSmallTextField from '@/containers/invest-object-list/invest-object-list-filters/app-small-text-field/AppSmallTextField'
import AppSlider from '@/containers/invest-object-list/invest-object-list-filters/app-slider/AppSlider'
import useBreakpoints from '@/hooks/use-breakpoints'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@/hooks/use-debounce'
import useForm from '@/hooks/use-form'
import i18next from 'i18next'
import ListSubheader from '@mui/material/ListSubheader'
import FocusTrap from '@mui/base/FocusTrap'
import { ClickAwayListener } from '@mui/base'

const minMaxRangeField = (min, max) => (value) => {
  if (value < min) {
    return i18next.t('common.errorMessages.min', { min })
  }
  if (value > max) {
    return i18next.t('common.errorMessages.max', { max })
  }

  return undefined
}

const validRange = (value, form) => {
  if (!value) {
    return
  }
  if (form.from > form.to) {
    return i18next.t('common.errorMessages.range')
  }
}

const AppSelectRangeSlider = ({
  label,
  placeholder,
  min,
  max,
  step,
  range: initialRange,
  changeFrom,
  changeTo,
  mobileView
}) => {
  const [open, setOpen] = useState(false)

  const { data, formProps, errors, setMappedData, handleValidate } = useForm({
    validations: {
      from: [minMaxRangeField(min, max), validRange],
      to: [minMaxRangeField(min, max), validRange]
    },
    initialValues: initialRange
  })
  const { t } = useTranslation()
  useEffect(() => {
    if (!open) {
      setMappedData(initialRange)
    }
  }, [open, initialRange, setMappedData])
  const debouncedFrom = useDebounce(data.from, 500)
  const debouncedTo = useDebounce(data.to, 500)

  useEffect(() => {
    const isValid = handleValidate()
    if (debouncedFrom !== initialRange.from && isValid) {
      changeFrom(Number(debouncedFrom))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFrom])

  useEffect(() => {
    if (debouncedTo !== initialRange.to && handleValidate()) {
      changeTo(Number(debouncedTo))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTo])

  const { isLarge, isDesktop } = useBreakpoints()

  const selectContent = () => {
    return (
      <Box
        tabIndex={-1}
        sx={{
          '&:focus-visible': { outline: 'none' }
        }}
      >
        <ListSubheader>
          <AppSelectOptionLabel label={label} />
        </ListSubheader>

        <Box sx={{ px: '12px', py: '8px', display: 'flex', gap: '16px', justifyItems: 'space-between' }}>
          <AppSmallTextField
            sx={{ flex: '50%' }}
            label={t('forms.range.from')}
            value={data.from}
            error={Boolean(errors.from)}
            helperText={errors.from || ' '}
            onChange={(e) => {
              formProps.handleChange('from')(e)
            }}
            onBlur={(e) => {
              formProps.handleBlur('from')(e)
            }}
            inputProps={{
              step: step,
              min: min,
              max: Number(data.to),
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
          />
          <AppSmallTextField
            sx={{ flex: '50%' }}
            label={t('forms.range.to')}
            value={data.to}
            error={Boolean(errors.to)}
            helperText={errors.to || ' '}
            onChange={(e) => {
              formProps.handleChange('to')(e)
            }}
            onBlur={(e) => {
              formProps.handleBlur('to')(e)
            }}
            inputProps={{
              step: step,
              min: Number(data.from),
              max: max,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}
          />
        </Box>
        <Box sx={{ px: '22px', mb: '8px' }}>
          <AppSlider
            value={[Number(data.from), Number(data.to)]}
            min={min}
            max={max}
            step={step}
            onChange={(r) => {
              const [from, to] = r.target.value
              setMappedData({ from, to })
            }}
          />
        </Box>
      </Box>
    )
  }

  if (mobileView) {
    return <>{selectContent()}</>
  }

  if (isLarge || isDesktop) {
    return (
      <ClickAwayListener
        mouseEvent='onMouseDown'
        touchEvent='onTouchStart'
        onClickAway={() => setOpen(false)}
      >
        <Box
          sx={{ position: 'relative', width: '100%', flex: '1' }}
          onKeyDown={({ key }) => {
            if (key === 'Escape') {
              setOpen(false)
            }
          }}
        >
          <AppSelect
            fullWidth
            MenuProps={{ sx: { display: 'none' } }}
            value={data}
            open={open}
            onOpen={() => setOpen(true)}
            renderValue={(selected) => {
              if (selected.from === min && selected.to === max) {
                return isLarge ? placeholder : label
              }

              return t('forms.range.fromTo', { from: selected.from, to: selected.to, unit: '' })
            }}
          >
            {/*we keep menu item value to not break select */}
            <MenuItem
              value={data}
              sx={{ display: 'none' }}
            ></MenuItem>
          </AppSelect>

          {open ? (
            <Zoom in={open}>
              <Paper
                sx={{
                  position: 'absolute',
                  top: 40,
                  right: 0,
                  left: 0,
                  zIndex: 1,
                  minWidth: '280px',
                  '&:focus-visible': { outline: 'none' }
                }}
                elevation={3}
              >
                <FocusTrap open>{selectContent()}</FocusTrap>
              </Paper>
            </Zoom>
          ) : null}
        </Box>
      </ClickAwayListener>
    )
  }

  return (
    <AppSelect
      MenuProps={getAppMenuPropsMinWidth('280px')}
      value={data}
      open={open}
      onClose={(d) => {
        if (!d.target.className.includes('MuiSlider')) {
          setOpen(false)
        }
      }}
      onOpen={() => setOpen(true)}
      renderValue={(selected) => {
        if (selected.from === min && selected.to === max) {
          return isLarge ? placeholder : label
        }

        return t('forms.range.fromTo', { from: selected.from, to: selected.to, unit: '' })
      }}
    >
      {selectContent()}
      {/*we keep menu item value to not break select */}
      <MenuItem
        value={data}
        sx={{ display: 'none' }}
      ></MenuItem>
    </AppSelect>
  )
}

export default AppSelectRangeSlider
