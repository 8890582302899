import { useCallback } from 'react'
import AdminPageHeader from '@/containers/admin-page-header'
import AppForm from '@/components/app-form'
import AppInputs from '@/components/app-form/app-inputs'
import useAxios from '@/hooks/use-axios'
import useForm from '@/hooks/use-form'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { adminEditContainerSX } from '@/containers/constants'
import { UsersService } from '@/services/users-service'
import {
  changePasswordFormInitialValues,
  changePasswordInputs,
  changePasswordValidations,
  errorCodes
} from '@/pages/admin/admin-change-password-page/constants'
import { Button, FormGroup, useTheme } from '@mui/material'
import { Save } from '@mui/icons-material'
import Box from '@mui/material/Box'
import { useAuth } from '@/context/auth-context'

const AdminChangePasswordPage = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const {
    isDirty,
    data: passwordsData,
    formProps,
    handleValidate,
    setMappedData
  } = useForm({
    inputs: changePasswordInputs,
    initialValues: changePasswordFormInitialValues,
    validations: changePasswordValidations
  })

  const { logout } = useAuth()
  const { fetchData: sendData } = useAxios({ service: UsersService.postChangeUserPassword })
  const changeUserPassword = useCallback((data) => sendData(data), [sendData])

  const getErrorMessage = (error) => {
    switch (error?.response?.data?.errorCode) {
      case errorCodes.incorrectPassword:
        return [t(`error.${error?.response?.status}`), t(`profile.${Object.keys(errorCodes)[0]}`)].join('. ')
      case errorCodes.passwordsDoNotMatch:
        return [t(`error.${error?.response?.status}`), t(`profile.${Object.keys(errorCodes)[1]}`)].join('. ')
      default:
        return t(`error.${error?.response?.status}`).concat(`. ${error.response?.data?.message}`)
    }
  }

  const onSubmit = async () => {
    if (handleValidate()) {
      try {
        await changeUserPassword(passwordsData)
        enqueueSnackbar(
          'Ваш пароль був успішно змінено',
          {
            variant: 'success'
          }
        )
        logout()
      } catch (error) {
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error'
        })
      } finally {
        setMappedData(changePasswordFormInitialValues)
      }
    }
  }

  return (
    <Box sx={{ ...adminEditContainerSX }}>
      <AdminPageHeader
        title={t('changePassword.title')}
        styles={{ minHeight: 'auto' }}
      />
      <AppForm onFormSubmit={onSubmit}>
        <FormGroup sx={{ flexGrow: 1, marginTop: theme.spacing(2) }}>
          <AppInputs
            inputs={changePasswordInputs}
            {...formProps}
          />
        </FormGroup>
        <Button
          variant='contained'
          endIcon={<Save />}
          disabled={!isDirty}
          type='submit'
          sx={{ marginLeft: 'auto' }}
        >
          {t('common.buttons.saveChanges')}
        </Button>
      </AppForm>
    </Box>
  )
}

export default AdminChangePasswordPage
