import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'
import { buildURL } from '@/utils/url-builder'

export const UsersService = {
  grid: (params) => {
    return request.post(URLs.admin.users.getUsers, { ...params })
  },
  countPerStatus: () => {
    return request.get(URLs.admin.users.getUsersCounts)
  },
  postUser: (data) => {
    return request.post(URLs.admin.users.create, data)
  },
  patchUser: ({ id, active, role }) => {
    return request.patch(buildURL(URLs.admin.users.patch, { id }), { role, active })
  },
  bulkActivate: (params) => {
    return request.post(URLs.admin.users.bulk.activate, { ...params })
  },
  bulkInActivate: (params) => {
    return request.post(URLs.admin.users.bulk.inActivate, { ...params })
  },
  getUserProfile: () => {
    return request.get(URLs.admin.users.getUserInfo)
  },
  putUserProfile: (data) => {
    return request.put(URLs.admin.users.putUserInfo, data)
  },
  postChangeUserPassword: (data) => {
    return request.post(URLs.admin.users.postChangeUserPassword, data)
  },
  resetCode(data) {
    return request.post(URLs.admin.users.resetCode, data)
  },
  resetPassword(data) {
    return request.post(URLs.admin.users.resetEmail, data)
  }
}
