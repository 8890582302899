import AppChipWithTooltip from '@/components/app-chip-with-tooltip'

const AppDefaultTag = (item, chosenLanguage, tagTooltip) => (
  <AppChipWithTooltip
    key={item.id}
    chipLabel={item.title[chosenLanguage]}
    tooltipLabel={item.title[tagTooltip]}
  />
)

export default AppDefaultTag
