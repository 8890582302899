import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { style } from '@/pages/home-page/HomePage.styles'
import NewsShortList from '@/containers/news-short-list'
import { useCallback, useEffect, useState } from 'react'
import useAxios from '@/hooks/use-axios'
import { NewsService } from '@/services/news-service'

import SupportProgramBanner from '@/containers/support-program-banner'
import { SupportProgramsService } from '@/services/support-program-service'
import RecentEventsBanner from '@/containers/recent-events-banner'
import { EventsService } from '@/services/events-service'
import VideoBanner from '@/containers/video-banner/VideoBanner'
import SimilarObjectBanner from '@/containers/similar-object-banner/SimilarObjectBanner'
import InvestmentOpportunities from '@/containers/investment_opportunities'
import BusinessHelpSection from '@/containers/business-help/BusinessHelpSection'
import { InvestObjectService } from '@/services/invest-object-service'
import BusinessServicesBanner from '@/containers/business-services-banner'
import ProjectCounterBanner from '@/containers/project-counter-banner'

const HomePage = () => {
  const { i18n } = useTranslation()
  const [eventItems, setEventItems] = useState([])
  const [newsItems, setNewsItems] = useState([])
  const [supportProgramItems, setSupportProgramItems] = useState([])
  const [investItems, setInvestItems] = useState([])

  const { fetchData: fetchEvents, loading: eventsLoading } = useAxios({
    service: EventsService.getEvents,
    initialLoading: false
  })
  const { fetchData: fetchNews, loading: newsLoading } = useAxios({
    service: NewsService.getNews,
    initialLoading: false
  })
  const { fetchData: fetchSupportPrograms, loading: supportProgramsLoading } = useAxios({
    service: SupportProgramsService.getSupportPrograms,
    initialLoading: false
  })
  const { fetchData: fetchInvestObject, loading: investItemsLoading } = useAxios({
    service: InvestObjectService.mapListGrid,
    handleError: true,
    initialLoading: true
  })

  const getHomePageData = useCallback(async () => {
    const [
      { items: fetchedNews },
      { items: fetchedSupportPrograms },
      { items: fetchedEvents },
      { items: fetchedInvest }
    ] = await Promise.all([
      fetchNews({ take: 3, skip: 0, lang: i18n.language }),
      fetchSupportPrograms({ take: 3, skip: 0, lang: i18n.language }),
      fetchEvents({ take: 3, skip: 0, lang: i18n.language }),
      fetchInvestObject({
        take: 5,
        skip: 0,
        sorting: { field: 'publishedDate', sort: 'DESC' },
        filtering: [],
        lang: i18n.language
      })
    ])
    setNewsItems(fetchedNews)
    setSupportProgramItems(fetchedSupportPrograms)
    setEventItems(fetchedEvents)
    setInvestItems([...fetchedInvest, ...fetchedInvest, ...fetchedInvest, ...fetchedInvest, ...fetchedInvest])
  }, [fetchEvents, fetchInvestObject, fetchNews, fetchSupportPrograms, i18n.language])

  useEffect(() => {
    getHomePageData().catch()
  }, [getHomePageData])

  return (
    <Box sx={style.container}>
      <VideoBanner />
      <BusinessHelpSection />
      <SupportProgramBanner
        items={supportProgramItems}
        loading={supportProgramsLoading}
      />
      <InvestmentOpportunities
        items={investItems}
        loading={investItemsLoading}
      />
      <SimilarObjectBanner />
      <BusinessServicesBanner />
      <ProjectCounterBanner />
      <RecentEventsBanner
        items={eventItems}
        loading={eventsLoading}
      />
      <NewsShortList
        items={newsItems}
        loading={newsLoading}
      />
    </Box>
  )
}

export default HomePage
