import { useCallback, useMemo, useState } from 'react'

export const useAttachedTags = () => {
  const [tags, setTags] = useState([])
  const [initialTags, setInitialTags] = useState([])

  const isTagsDirty = useMemo(() => {
    if (tags.length === initialTags.length) {
      return !tags.every(({ id }) => initialTags.some(({ id: initialId }) => initialId === id))
    }
    return true
  }, [tags, initialTags])

  const unAttachTag = useCallback((id) => {
    setTags((prevState) => prevState.filter((item) => item.id !== id))
  }, [])

  const attachTag = useCallback((tag) => {
    setTags((prevState) => [...prevState, tag])
  }, [])

  const clearTags = useCallback(() => {
    setTags([])
  }, [])

  const setInitial = useCallback((data) => {
    setTags(data)
    setInitialTags(data)
  }, [])

  return {
    attachTag,
    unAttachTag,
    clearTags,
    setInitial,
    isTagsDirty,
    tags
  }
}
