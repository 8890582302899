import AppChipWithTooltip from '@/components/app-chip-with-tooltip'
import DeleteIcon from '@mui/icons-material/Delete'

const AppDeleteAbleTag = (handleDeleteTag, onClick, item, chosenLanguage, tagTooltip, disabled) => {
  return (
    <AppChipWithTooltip
      key={item.id}
      chipLabel={item.title[chosenLanguage]}
      tooltipLabel={item.title[tagTooltip]}
      icon={<DeleteIcon />}
      onClick={(e) => onClick(item, e)}
      onIconClick={(e) => handleDeleteTag(item, e)}
      disabled={disabled}
    />
  )
}

export default AppDeleteAbleTag
