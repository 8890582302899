import { useMemo, useState } from 'react'
import { Button } from '@mui/material'
import phoneIcon from '@/assets/icons/phone.svg'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const componentProps = {
  phone: PropTypes.string.isRequired
}
const AppPhone = ({ phone, sx }) => {
  const { t } = useTranslation()
  const [showPhone, setShowPhone] = useState(false)
  const getTelProp = useMemo(() => (showPhone ? { href: `tel:${phone}` } : {}), [phone, showPhone])

  return (
    <Button
      variant='outlined'
      component={showPhone ? 'a' : 'button'}
      {...getTelProp}
      startIcon={
        <img
          src={phoneIcon}
          height={18}
          width={18}
          alt={'c'}
        />
      }
      onClick={() => {
        if (!showPhone) {
          setShowPhone(true)
        }
      }}
      sx={{ ...sx }}
    >
      {showPhone ? phone : t('investObjectsDetails.showPhone')}
    </Button>
  )
}
AppPhone.propType = componentProps

export default AppPhone
