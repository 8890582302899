import { useCallback } from 'react'

export const useAppDatePickerForm = ({ handleChange, handleBlur }) => {
  const handleRangeChange = useCallback(
    (key) => (value) => {
      handleChange(key)({ customType: 'range', value })
    },
    [handleChange]
  )

  const handleRangeBlur = (key) => (range) => {
    handleBlur(key)({ customType: 'range', value: range, simplifiedValue: range })
  }

  return {
    formProps: {
      handleRangeBlur,
      handleRangeChange
    }
  }
}
