import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { buttonStyles } from '@/containers/business-help/constants'
import { businessList } from '@/containers/business-help/constants'
import { responsiveGap } from '@/pages/home-page/HomePage.styles'
import AppNavLink from '@/components/app-nav-link/AppNavLink'

const componentProps = {
  industryType: PropTypes.oneOf(businessList),
  updateIndustry: PropTypes.func,
  sx: PropTypes.object
}

const BusinessHelpItem = ({ sx }) => {
  const { t } = useTranslation()

  const industryListItems = useMemo(() => {
    return businessList.map((item) => (
      <Grid
        key={item.id}
        sx={{ width: { sm: 'calc(100%/3)', xs: '100%' } }}
      >
        <Button
          to={item.linkTo}
          component={AppNavLink}
          sx={{
            ...buttonStyles,
            width: '100%',
            height: '100%',
            px: 0,
            py: { lg: '2.75rem', md: '2rem', xs: '1.625rem' },
            fontSize: { md: '1.5rem', xs: '1.2rem' },
            lineHeight: '29px',
            borderRadius: 'inherit',
            backgroundColor: `${item['bgcolor']}`
          }}
        >
          {t(`helpBusiness.${item['title']}`)}
        </Button>
      </Grid>
    ))
  }, [t])

  return (
    <Grid
      container
      sx={{ mb: responsiveGap, ...sx }}
    >
      {industryListItems}
    </Grid>
  )
}

BusinessHelpItem.prototype = componentProps

export default BusinessHelpItem
