import { createTheme, ThemeProvider, useTheme } from '@mui/material'
import { theme as overrideThemeConfig } from './theme'

const InvestObjectSubmitPageTheme = ({ children }) => {
  const theme = useTheme()
  const overrideTheme = createTheme(theme, overrideThemeConfig)
  
  return (<ThemeProvider theme={overrideTheme}>
    { children }
  </ThemeProvider>)
}

export default InvestObjectSubmitPageTheme
