import PropTypes from 'prop-types'
import i18next from 'i18next'
import { useState } from 'react'

import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { generatePassword } from '@/utils/password-generator-helper'
import { useHideAndShow } from '@/hooks/use-hide-and-show'

const AppPassAutoGenerateInputProps = {
  input: PropTypes.object,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool
}

const AppPassAutoGenerateInput = ({ input, value, error, helperText, onChange, onBlur, disabled, sx = {} }) => {
  const [inputValue, setInputValue] = useState(value)

  const { hideInput, toggleHideInput } = useHideAndShow()

  const onClickSSS = () => {
    const autoGeneratedPassword = generatePassword()
    setInputValue(autoGeneratedPassword)
    onChange({ target: { value: autoGeneratedPassword } })
  }

  const onChangeHandler = (event) => {
    setInputValue(event.target.value)
    onChange(event)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexGrow: 0, ...sx }}>
      <TextField
        value={inputValue}
        error={error}
        helperText={helperText}
        onChange={onChangeHandler}
        onBlur={onBlur}
        disabled={disabled}
        InputProps={{
          ...input.options,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={toggleHideInput}
                edge='end'
              >
                {hideInput ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
        required={input.options.required}
        label={input.options.label}
        sx={{ marginRight: '20px', width: '100%' }}
        type={hideInput ? 'password' : 'text'}
      />
      <Button
        variant='outlined'
        onClick={onClickSSS}
        sx={{ maxHeight: '56px', lineHeight: 'normal' }}
      >
        {i18next.t('users.autoGeneratePassword')}
      </Button>
    </Box>
  )
}

AppPassAutoGenerateInput.propTypes = AppPassAutoGenerateInputProps

export default AppPassAutoGenerateInput
