import { DataGrid, ukUA } from '@mui/x-data-grid'
import { useAppGrid } from '@/components/app-grid/AppGrid.context'
import { useEffect } from 'react'
import { useAppGridColumns } from '@/components/app-grid/hooks/use-app-grid-columns'
import { useAppGridServices } from '@/components/app-grid/hooks/use-app-grid-services'

const AppGridTable = () => {
  const {
    items,
    totalRows,
    loading,
    pageSize,
    settingsModel,
    selectedRows,
    currPage,
    filterModel,
    sortModel,
    setSelectedRows,
    setCurrPage,
    setPageSize,
    setFilterModel,
    setSortModel
  } = useAppGrid()

  const { loadData } = useAppGridServices()
  const { columnsModel } = useAppGridColumns()

  useEffect(() => {
    loadData().catch()
  }, [loadData, sortModel])

  return (
    <DataGrid
      {...settingsModel}
      className='app-grid-table'
      columns={columnsModel}
      loading={loading}
      rows={items}
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onPageChange={setCurrPage}
      onPageSizeChange={setPageSize}
      onSelectionModelChange={setSelectedRows}
      onSortModelChange={setSortModel}
      page={currPage}
      pageSize={pageSize}
      rowCount={totalRows}
      selectionModel={selectedRows}
      sortModel={sortModel}
      disableSelectionOnClick
      localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
    />
  )
}

export default AppGridTable
