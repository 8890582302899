import { styles } from './styles'
import { email, minMaxLength, requiredField, ukrainianPhoneNumber, validRegExp } from '@/utils/validations/common'
import { areaPattern, latitudeLongtitudePattern, onlyDigits, postalIndexPattern } from '@/constants/default'
import { transportSectionValidations } from '@/containers/invest-object-form/sections/transport-connection-section'
import GeneralSection from '@/containers/invest-object-form/sections/general-section'
import AddressSection from '@/containers/invest-object-form/sections/address-section'
import RealEstateSection from '@/containers/invest-object-form/sections/real-estate-section'
import LandPlotSection from '@/containers/invest-object-form/sections/land-plot-section'
import CommunicationsSection from '@/containers/invest-object-form/sections/communications-section'
import TransportConnectionSection from '@/containers/invest-object-form/sections/transport-connection-section'
import ContactPersonSection from '@/containers/invest-object-form/sections/contact-person-section'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CharacteristicsSection, {
  encumbranceOptions,
  usageTypeOptions,
  characteristicType
} from '@/containers/invest-object-form/sections/characteristics-section'
import InvestObjectFormAddressSectionMap from '@/containers/invest-object-form/sections/address-section/partials/InvestObjectFormAddressSectionMap'
import i18next from 'i18next'

export const supportedLanguages = ['uk']

export const generalSectionGeneralInputs = [
  {
    type: 'multipleImageUploader',
    options: {
      name: 'imageIds',
      sx: styles.inputs.images,
      header: 'investObjects.labels.imagesRequired',
      maxFilesCount: 15,
      maxFileSize: '5MB'
    }
  },
  {
    type: 'multipleFileUploader',
    options: {
      name: 'documentIds',
      sx: styles.inputs.documents,
      header: 'investObjects.labels.documents',
      maxFilesCount: 10,
      maxFileSize: '10MB'
    }
  }
]
export const generalSectionMultiInputs = [
  {
    type: 'text',
    options: {
      name: 'name',
      label: 'investObjects.labels.objectName',
      placeholder: 'investObjects.placeholder.objectName',
      sx: styles.inputs.name
    }
  },
  {
    type: 'text',
    options: {
      name: 'description',
      multiline: true,
      minRows: 5,
      maxRows: 5,
      label: 'investObjects.labels.objectDescription',
      placeholder: 'investObjects.placeholder.objectDescription',
      sx: styles.inputs.description
    }
  }
]

export const characteristicsSectionGeneralInputs = (t) => [
  {
    type: 'select',
    options: {
      name: 'usageType',
      label: 'investObjects.labels.usageType',
      sx: styles.inputs.usageType,
      SelectProps: {
        options: usageTypeOptions(t),
        IconComponent: ExpandMoreIcon
      }
    }
  },
  {
    type: 'text',
    options: {
      name: 'workersCount',
      label: 'investObjects.labels.workersCount',
      sx: styles.inputs.workersCount
    }
  },
  {
    type: 'select',
    options: {
      name: 'encumbrance',
      label: 'investObjects.labels.encumbranceRequired',
      sx: styles.inputs.encumbrance,
      SelectProps: {
        options: encumbranceOptions(t)
      }
    }
  },
  {
    type: 'text',
    options: {
      name: 'url',
      label: 'investObjects.labels.virtualStorage',
      placeholder: 'investObjects.placeholder.virtualStorage',
      sx: styles.inputs.url
    }
  }
]
export const characteristicsSectionMultiInputs = [
  {
    type: 'text',
    options: {
      name: 'previousUsage',
      label: 'investObjects.labels.previousUsage',
      placeholder: 'investObjects.placeholder.previousUsage',
      multiline: true,
      maxRows: 5,
      minRows: 5,
      sx: styles.inputs.previousUsage
    }
  }
]

export const addressSectionGeneralInputs = [
  {
    type: 'text',
    options: {
      name: 'buildingNumber',
      label: 'investObjects.labels.buildingNumber',
      sx: styles.inputs.buildingNumber
    }
  },
  {
    type: 'text',
    options: {
      name: 'index',
      label: 'investObjects.labels.index',
      sx: styles.inputs.index
    }
  },
  {
    type: 'custom',
    component: InvestObjectFormAddressSectionMap,
    options: {
      name: 'map',
      sx: styles.inputs.map
    }
  }
]
export const addressSectionMultiInputs = [
  {
    type: 'text',
    options: {
      name: 'settlement',
      label: 'investObjects.labels.settlement',
      sx: styles.inputs.settlement
    }
  },
  {
    type: 'text',
    options: {
      name: 'community',
      label: 'investObjects.labels.community',
      sx: styles.inputs.community
    }
  },
  {
    type: 'text',
    options: {
      name: 'district',
      label: 'investObjects.labels.district',
      sx: styles.inputs.district
    }
  },
  {
    type: 'text',
    options: {
      name: 'street',
      label: 'investObjects.labels.street',
      sx: styles.inputs.street
    }
  }
]

export const contactPersonSectionGeneralInputs = [
  {
    type: 'text',
    options: {
      name: 'contactsEmail',
      label: 'investObjects.labels.email',
      sx: styles.inputs.contactsEmail
    }
  },
  {
    type: 'maskInput',
    options: {
      name: 'contactsPhone',
      label: 'investObjects.labels.phoneNumber',
      mask: '{+38}0000000000',
      sx: styles.inputs.contactsPhone
    }
  }
]
export const contactPersonSectionMultiInputs = [
  {
    type: 'text',
    options: {
      name: 'contactsFullName',
      label: 'investObjects.labels.fullName',
      sx: styles.inputs.contactsFullName
    }
  }
]

export const investObjectFormHeaders = [
  {
    text: 'investObjects.headers.generalInformation',
    variant: 'h4',
    sx: styles.headers.generalInformation
  },
  {
    text: 'investObjects.headers.characteristic',
    variant: 'h4',
    sx: styles.headers.characteristic
  },
  {
    text: 'investObjects.headers.address',
    variant: 'h4',
    sx: styles.headers.address
  },
  {
    text: 'investObjects.headers.realEstate',
    variant: 'h4',
    sx: styles.headers.realEstate
  },
  {
    text: 'investObjects.headers.landPlot',
    variant: 'h4',
    sx: styles.headers.landPlot
  },
  {
    text: 'investObjects.headers.communications',
    variant: 'h4',
    sx: styles.headers.communications
  },
  {
    text: 'investObjects.headers.transportConnections',
    variant: 'h4',
    sx: styles.headers.transportConnections
  },
  {
    text: 'investObjects.headers.contactPerson',
    variant: 'h4',
    sx: styles.headers.contactPerson
  }
]

export const investObjectValidations = {
  characteristicsType: [requiredField],
  usageType: [requiredField],
  workersCount: [validRegExp({ regexp: onlyDigits(0, 7), errorMsg: 'investObjects.errors.workerCountFormatClient' })],
  encumbrance: [requiredField],
  previousUsage: [minMaxLength(0, 1000)],
  buildingNumber: [minMaxLength(0, 10)],
  index: [validRegExp({ regexp: postalIndexPattern, errorMsg: 'common.errorMessages.postalIndexError' })],
  latitude: [
    validRegExp({
      regexp: latitudeLongtitudePattern,
      errorMsg: 'common.errorMessages.latitudeAndLongitudeError'
    })
  ],
  longitude: [
    validRegExp({
      regexp: latitudeLongtitudePattern,
      errorMsg: 'common.errorMessages.latitudeAndLongitudeError'
    })
  ],
  internationalAndStateRoads: [transportSectionValidations],
  borderCrossingPoints: [transportSectionValidations],
  contactsPhone: [requiredField, ukrainianPhoneNumber],
  contactsEmail: [email],
  name: [minMaxLength(1, 200)],
  description: [minMaxLength(0, 1000)],
  imageIds: [requiredField],
  settlement: [minMaxLength(1, 100)],
  community: [minMaxLength(1, 100)],
  district: [minMaxLength(1, 100)],
  street: [minMaxLength(0, 100)],
  roadSurfaceType: [minMaxLength(1, 50)],
  otherUsageType: [],
  otherEncumbrance: [],
  contactsFullName: [requiredField],
  plotArea: [validRegExp({ regexp: areaPattern, errorMsg: i18next.t('investObjects.errors.landPlotAreaFormat') })]
}

export const investObjectFormInitialValues = {
  state: '',
  domain: [],
  code: '',
  note: '',
  imageIds: [],
  documentIds: [],
  characteristicsType: characteristicType.land,
  usageType: '',
  workersCount: '',
  encumbrance: '',
  url: '',
  buildingNumber: '',
  index: '',
  latitude: '',
  longitude: '',
  cadastralNumberStatus: '',
  cadastralNumber: '',
  plotArea: '',
  ownershipType: '',
  landCategorySection: '',
  landCategoryCategory: '',
  landCategoryType: '',
  landPlan: false,
  landPlanFilesIds: [],
  realtyType: [],
  realtyState: '',
  realtyTotalAreaSqM: '',
  realtyHasLandPlot: false,
  powerSupply: false,
  powerSupplyKW: '0',
  nearestPowerSupplyKM: '0',
  gasSupply: false,
  nearestGasSupplyKM: '0',
  waterSupply: false,
  nearestWaterSupplyKM: '0',
  drainage: false,
  nearestDrainageKM: '0',
  railroad: false,
  internationalAndStateRoads: [],
  borderCrossingPoints: [],
  contactsEmail: '',
  contactsPhone: '',
  name: '',
  description: '',
  otherUsageType: '',
  otherEncumbrance: '',
  previousUsage: '',
  settlement: '',
  community: '',
  district: '',
  street: '',
  waterSupplyType: '',
  drainageType: '',
  roadSurfaceType: '',
  contactsFullName: ''
}

export const investObjectSectionMap = (t) => ({
  general: {
    component: GeneralSection,
    title: investObjectFormHeaders[0],
    generalInputs: generalSectionGeneralInputs,
    multiInputs: generalSectionMultiInputs,
    validationFields: ['name', 'imageIds'],
    countableProgressFields: () => ['name', 'description', 'imageIds', 'documentIds']
  },
  characteristic: {
    component: CharacteristicsSection,
    title: investObjectFormHeaders[1],
    generalInputs: characteristicsSectionGeneralInputs(t),
    multiInputs: characteristicsSectionMultiInputs,
    validationFields: ['characteristicsType', 'usageType', 'otherUsageType', 'encumbrance', 'otherEncumbrance'],
    countableProgressFields: (ctx) => [
      'usageType',
      ctx.data.usageType === 'OTHER' && 'otherUsageType',
      'previousUsage',
      'workersCount',
      'url',
      'encumbrance',
      ctx.data.encumbrance === 'OTHER' && 'otherEncumbrance'
    ]
  },
  address: {
    component: AddressSection,
    title: investObjectFormHeaders[2],
    generalInputs: addressSectionGeneralInputs,
    multiInputs: addressSectionMultiInputs,
    validationFields: ['settlement', 'community', 'district'],
    countableProgressFields: (ctx) => [
      'settlement',
      'community',
      'district',
      'street',
      'buildingNumber',
      'index',
      'latitude',
      'longitude'
    ]
  },
  realEstate: {
    component: RealEstateSection,
    title: investObjectFormHeaders[3],
    generalInputs: [],
    multiInputs: [],
    isVisible: (ctx) => ctx.formProps.data.characteristicsType === characteristicType.realEstate,
    validationFields: ['realtyType', 'realtyState', 'realtyTotalAreaSqM'],
    countableProgressFields: (ctx) => ['realtyType', 'realtyState', 'realtyTotalAreaSqM']
  },
  landPlot: {
    component: LandPlotSection,
    title: investObjectFormHeaders[4],
    generalInputs: [],
    multiInputs: [],
    isVisible: (ctx) =>
      !ctx.formProps.data.characteristicsType ||
      ctx.formProps.data.characteristicsType === characteristicType.land ||
      ctx.formProps.data.realtyHasLandPlot,
    validationFields: ['cadastralNumberStatus', 'cadastralNumber', 'plotArea', 'ownershipType'],
    countableProgressFields: (ctx) => [
      'cadastralNumberStatus',
      ctx.data.cadastralNumberStatus === 'EXISTS' && 'cadastralNumber',
      'ownershipType',
      'plotArea',
      'landCategorySection',
      ctx.data.landCategorySection !== '' && 'landCategoryCategory',
      ctx.data.landCategorySection !== '' && 'landCategoryType',
      ctx.data.landPlan && 'landPlanFilesIds'
    ]
  },
  communications: {
    component: CommunicationsSection,
    title: investObjectFormHeaders[5],
    generalInputs: [],
    multiInputs: [],
    validationFields: [
      'powerSupply',
      'powerSupplyKW',
      'nearestPowerSupplyKM',
      'gasSupply',
      'nearestGasSupplyKM',
      'waterSupply',
      'waterSupplyType',
      'nearestWaterSupplyKM',
      'drainage',
      'drainageType',
      'nearestDrainageKM'
    ],
    countableProgressFields: (ctx) => [
      ctx.data.powerSupply && 'powerSupplyKW',
      !ctx.data.powerSupply && 'nearestPowerSupplyKM',
      !ctx.data.gasSupply && 'nearestGasSupplyKM',
      ctx.data.waterSupply && 'waterSupplyType',
      !ctx.data.waterSupply && 'nearestWaterSupplyKM',
      ctx.data.drainage && 'drainageType',
      !ctx.data.drainage && 'nearestDrainageKM'
    ]
  },
  transportConnect: {
    component: TransportConnectionSection,
    title: investObjectFormHeaders[6],
    generalInputs: [],
    multiInputs: [],
    validationFields: ['roadSurfaceType', 'railroad', 'internationalAndStateRoads', 'borderCrossingPoints'],
    countableProgressFields: (ctx) => ['roadSurfaceType', 'internationalAndStateRoads', 'borderCrossingPoints']
  },
  contactPerson: {
    component: ContactPersonSection,
    title: investObjectFormHeaders[7],
    generalInputs: contactPersonSectionGeneralInputs,
    multiInputs: contactPersonSectionMultiInputs,
    validationFields: ['contactsFullName', 'contactsPhone'],
    countableProgressFields: (ctx) => ['contactsFullName', 'contactsPhone', 'contactsEmail']
  }
})
const multiInputFields = [
  ...generalSectionMultiInputs,
  ...characteristicsSectionMultiInputs,
  ...addressSectionMultiInputs,
  ...contactPersonSectionMultiInputs
]

export const multiInputFieldNames = [
  ...multiInputFields.map((field) => field.options.name),
  // Dynamic fields which are used in some form sections
  'otherEncumbrance',
  'otherUsageType',
  'drainageType',
  'roadSurfaceType',
  'waterSupplyType'
]
