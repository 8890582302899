import { Box, Skeleton, Typography } from '@mui/material'
import AppCardItem from '@/components/app-card-item'
import { truncateTextByLines } from '@/utils/common'
import useAxios from '@/hooks/use-axios'
import { InvestObjectService } from '@/services/invest-object-service'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeAddress } from '@/containers/investment_opportunities/invests-item/InvestItem'

const AppMapMarkerInfoBody = ({ isHovered, info, loading }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        sx={(theme) => ({ ...truncateTextByLines(2) })}
        variant={'h4'}
        title={info?.name}
        color={isHovered && 'primary'}
        mb={'4px'}
      >
        {loading ? (
          <Skeleton
            width={240}
            variant={'rounded'}
          />
        ) : (
          info.name
        )}
      </Typography>
      <Typography
        sx={(theme) => ({ ...truncateTextByLines(2) })}
        variant={'h6'}
        color={'textSecondary'}
      >
        {loading ? <Skeleton /> : makeAddress(info)}
      </Typography>
    </Box>
  )
}
const AppMapMarkerInfo = ({ id, url }) => {
  const [info, setInfo] = useState({})
  const { i18n } = useTranslation()

  const { fetchData: fetchMapGridObject, loading } = useAxios({
    service: InvestObjectService.mapGridObject,
    handleError: true,
    initialLoading: true
  })

  const setInfoById = useCallback(async () => {
    const res = await fetchMapGridObject({ id, query: { lang: i18n.language } })
    setInfo(res)
  }, [fetchMapGridObject, i18n.language, id])

  useEffect(() => {
    setInfoById().catch()
  }, [setInfoById])

  return (
    <AppCardItem
      sx={{ maxWidth: '240px' }}
      vertical
      loading={loading}
      image={{ src: info.featureImage, alt: info.settlement }}
      imageBox={{ width: '240px', height: '200px' }}
      linkUrl={url}
    >
      <AppMapMarkerInfoBody
        loading={loading}
        info={info}
      />
    </AppCardItem>
  )
}

export default AppMapMarkerInfo
