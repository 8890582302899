import { useState } from 'react'
import PropTypes from 'prop-types'
import palette from '@/plugins/material-ui/palette'
import { Box, Typography } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import AppDragAndDrop from '@/components/app-drag-and-drop'

import { FileService } from '@/services/file-service'

import { commonDocumentsFileType } from '@/constants/default'
import { cutString } from '@/utils/common'

const AppMultipleFileUploaderProps = {
  maxFilesCount: PropTypes.number,
  value: PropTypes.array.isRequired,
  onChangeFiles: PropTypes.func.isRequired,
  header: PropTypes.string,
  accept: PropTypes.array,
  sx: PropTypes.object
}

const AppMultipleFileUploader = ({
  maxFilesCount = 15,
  maxFileSize = '10MB',
  value,
  onChangeFiles,
  header,
  accept,
  sx = {}
}) => {
  const onValueChange = (file) => {
    onChangeFiles([...value, ...file])
  }

  const handleRemoveFile = async (fileId) => {
    onChangeFiles(value.filter(({ id }) => id !== fileId))
    try {
      await FileService.delete(fileId)
    } catch (e) {
      console.error(e)
    }
  }

  const [showDeleteIcon, setShowDeleteIcon] = useState('')

  return (
    <Box sx={{ ...sx }}>
      {header && <Typography sx={{ my: '12px' }}>{header}</Typography>}
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap' }}
        onMouseLeave={() => setShowDeleteIcon('')}
      >
        {value.map(({ id, name }) => (
          <Box
            key={id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px',
              mr: '16px',
              mb: '16px',
              position: 'relative',
              width: '150px',
              height: '100px',
              border: '1px solid',
              borderColor: palette.primary.main,
              borderRadius: '4px'
            }}
            onMouseEnter={() => setShowDeleteIcon(id)}
          >
            {id === showDeleteIcon && (
              <HighlightOffIcon
                sx={{ position: 'absolute', top: '-15px', right: '-15px', color: 'primary.main', cursor: 'pointer' }}
                onClick={() => handleRemoveFile(id)}
              />
            )}
            <Typography
              variant={'subtitle1'}
              color={'primary'}
              sx={{ textOverflow: 'ellipsis', whiteSpace: 'normal', overflow: 'hidden' }}
            >
              {cutString(name, 60)}
            </Typography>
          </Box>
        ))}
        <AppDragAndDrop
          onValueChange={onValueChange}
          value={value}
          accept={accept || commonDocumentsFileType}
          maxFilesCount={maxFilesCount}
          maxFileSize={maxFileSize}
        />
      </Box>
    </Box>
  )
}

AppMultipleFileUploader.propTypes = AppMultipleFileUploaderProps

export default AppMultipleFileUploader
