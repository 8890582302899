export const styles = {
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gap: '8px',
  },
  container: {
    margin: '24px 0',
    padding: {
      xl: '50px 115px 50px 79px',
      lg: '30px 50px 30px 50px',
      sm: '30px 50px 30px 50px',
      xs: '20px 15px'
    },
    '&:last-child': {
      margin: 0,
    },
    '&:first-of-type': {
      marginTop: 1,
    },
  },
  containerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12)',
  },
  statusIcon: {
    gridRow: '1',
    gridColumn: '1 / 2',
  },
  title: {
    gridRow: '1',
    gridColumn: '2 / -1',
    marginBottom: { sm: '30px', xs: '22px' },
    display: 'flex',
    alignItems: 'center',
  },
  sectionContainer: {
    gridRow: '2',
    gridColumn: '2 / -1',
    paddingLeft: { sm: '47px' },
  },
}
