import { useCallback } from 'react'

export const useAppFilesUploaderForm = ({ handleChange }) => {
  const handleFileChange = useCallback(
    (key) => (value) => {
      handleChange(key)({ customType: 'file', value })
    },
    [handleChange]
  )

  return {
    formProps: { handleFileChange }
  }
}
