import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { useAppGrid } from '@/components/app-grid/AppGrid.context'
import { useAppGridActions } from '@/components/app-grid/hooks/use-app-grid-actions'
import { Typography } from '@mui/material'
import { useMemo } from 'react'

const AppGridToolbar = () => {
  const { settingsModel, selectedRows, totalRows, bulkActions, isActionLoading } = useAppGrid()
  const { onActionClick } = useAppGridActions()

  const bulkActionButtons = useMemo(
    () =>
      Object.values(bulkActions).map((button) => {
        return (
          <Button
            variant={'contained'}
            sx={{ mr: '8px' }}
            key={button.label}
            disabled={isActionLoading}
            onClick={() => onActionClick(button, selectedRows)}
            startIcon={button.icon}
            {...button.options}
          >
            {button.label}
          </Button>
        )
      }),
    [bulkActions, onActionClick, selectedRows, isActionLoading]
  )

  return (
    <Box
      className='app-grid-toolbar'
      sx={{ display: 'flex', justifyContent: 'space-between', my: '8px', minHeight: '50.5px', alignItems: 'center' }}
    >
      {Boolean(settingsModel.checkboxSelection && selectedRows.length) && (
        <Box
          className='app-grid-toolbar-selection'
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            variant='h5'
            className='app-grid-toolbar-selection-total'
            sx={{ mr: '16px' }}
          >
            {`Обрані записи: ${selectedRows.length}`}
          </Typography>
          <Box className='app-grid-toolbar-selection-actions'>{bulkActionButtons}</Box>
        </Box>
      )}
      <Typography
        variant='h5'
        className='app-grid-toolbar-total'
      >
        {`Кількість записів: ${totalRows}`}
      </Typography>
    </Box>
  )
}

export default AppGridToolbar
