import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material'
import { theme } from '@/plugins/material-ui/theme'
import { useState } from 'react'

const AppCheckboxGroup = ({ options, label, value, error, helperText, onChange, onBlur, row, sx }) => {
  const valuesMap = ((value && value.length) || {}) && value.reduce((acc, option) => ({ ...acc, [option]: true }), {})
  const optionsMap = options.reduce((acc, option) => ({ ...acc, [option.value]: valuesMap[option.value] || false }), {})
  const [optionsData, setOptionsData] = useState(optionsMap)

  const handleChange = (field) => (e) => {
    const newState = {
      ...optionsData,
      [field]: e.target.checked,
    }
    setOptionsData(newState)

    const values = Object.keys(newState).filter(optionKey => newState[optionKey])
    onChange({ target: { value: values } })
  }

  const controlComponent = (option) => (
    <Checkbox
      onChange={handleChange(option.value)}
      checked={optionsData[option.value]}
      sx={option.sx}
    />
  )

  return (
    <FormControl
      error={error}
      sx={{ mb: theme.spacing(1), ...sx }}
    >
      <FormLabel id='check-box-group-label'>{label}</FormLabel>
      <FormGroup
        aria-labelledby='check-box-group-label'
        row={row}
        onBlur={onBlur}
        name='check-box-group'
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.text}
            value={option.value}
            control={controlComponent(option)}
            label={option.text}
          />
        ))}
      </FormGroup>
      <FormHelperText sx={{ height: '20px', ml: 0, mt: 0 }}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default AppCheckboxGroup
