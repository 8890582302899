import React from 'react'
import { Box, IconButton } from '@mui/material'
import useBreakpoints from '@/hooks/use-breakpoints'
import { useTranslation } from 'react-i18next'
import softServeLogoUA from '@/assets/images/softserve-logo.svg'
import softServeLogoEN from '@/assets/images/softserve-logo-en.svg'
import { SOCIAL_DETAILS } from '../constants'
import facebookSVG from '@/assets/icons/facebook.svg'
import twitterSVG from '@/assets/icons/twitter.svg'
import linkedinSVG from '@/assets/icons/linkedin.svg'
import youtubeSVG from '@/assets/icons/youtube.svg'
import instagramSVG from '@/assets/icons/instagram.svg'

const SOFT_SERVE_LOGO = {
  en: softServeLogoEN,
  uk: softServeLogoUA
}

const SOCIAL_MEDIA_ITEMS = {
  facebook: { name: SOCIAL_DETAILS.FACEBOOK, icon: facebookSVG, path: SOCIAL_DETAILS.FACEBOOK_URL },
  twitter: { name: SOCIAL_DETAILS.TWITTER, icon: twitterSVG, path: SOCIAL_DETAILS.TWITTER_URL },
  linkedin: { name: SOCIAL_DETAILS.LINKEDIN, icon: linkedinSVG, path: SOCIAL_DETAILS.LINKEDIN_URL },
  youtube: { name: SOCIAL_DETAILS.YOUTUBE, icon: youtubeSVG, path: SOCIAL_DETAILS.YOUTUBE_URL },
  instagram: { name: SOCIAL_DETAILS.INSTAGRAM, icon: instagramSVG, path: SOCIAL_DETAILS.INSTAGRAM_URL }
}

const ClientBackFooter = () => {
  const { isDesktop, isTablet } = useBreakpoints()
  const iconStyles = {
    mr: isDesktop ? '1.5rem' : isTablet ? '1rem' : 0,
    width: '40px',
    height: '40px',
    padding: 0,
    justifyContent: { sm: 'center', xs: 'flex-start' }
  }

  const { i18n } = useTranslation()

  return (
    <Box sx={{
      my: '25px',
      display: 'flex',
      rowGap: { sm: '30px', xs: '27px' },
      justifyContent: 'space-between',
      flexDirection: { sm: 'row', xs: 'column' }
    }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: { sm: 'auto', xs:'300px' } }}>
        {Object.values(SOCIAL_MEDIA_ITEMS).map((item) => (
          <IconButton
            key={item.name}
            href={item.path}
            target='_blank'
            rel='noopener noreferrer'
            title={item.name}
            type='button'
            sx={{ ...iconStyles }}
          >
            <img
              alt={item.name}
              src={item.icon}
              height={40}
            />
          </IconButton>
        ))}
      </Box>
      <Box sx={{ textAlign: { sm: 'right', xs:'left' } }}>
        <img
          alt={'logo'}
          src={SOFT_SERVE_LOGO[i18n.language]}
          height={45}
        />
      </Box>
    </Box>
  )
}

export default ClientBackFooter
