import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'
import { buildURL } from '@/utils/url-builder'

export const SupportProgramsAdminService = {
  grid: (params) => {
    return request.post(URLs.admin.supportPrograms.search, { ...params })
  },
  bulkDeletion: (params) => {
    return request.post(URLs.admin.supportPrograms.bulk.delete, { ...params })
  },
  countPerStatus: () => {
    return request.get(URLs.admin.supportPrograms.countPerStatus)
  },
  createProgram: (data) => {
    return request.post(URLs.admin.supportPrograms.createProgram, { ...data })
  },
  getProgramById: (id) => {
    return request.get(buildURL(URLs.admin.supportPrograms.getProgramById, { id }))
  },
  updateProgram: (id, data) => {
    return request.put(buildURL(URLs.admin.supportPrograms.putProgramById, { id }), data)
  },
  getSupportPrograms: (params) => {
    return request.post(URLs.news.getNews, { ...params })
  }
}
