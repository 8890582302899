import Box from '@mui/material/Box'
import { theme } from '@/plugins/material-ui/theme'
import { Skeleton, Typography } from '@mui/material'
import useBreakpoints from '@/hooks/use-breakpoints'

const InvestCharacteristicType = ({ type, characteristic, loading, wider = false }) => {
  const { isMobile } = useBreakpoints()
  const styles = !isMobile && wider ? { width: '300px' } : { flex: '0 1 36%' }
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      flex: 1, gap:
      theme.spacing(2),
      mb: { xs: '6px' } }
    }
    >
      {!!type && (
        <Typography
          variant={'subtitle1'}
          color='textSecondary'
          sx={{ ...styles, wordBreak: 'break-word' }}
        >
          {type}
        </Typography>
      )}

      <Typography
        variant={'subtitle2'}
        color='textSecondary'
        width={'100%'}
        sx={{
          flex: 1,
          flexBasis: { sm: 'unset', xs: '59%' },
          wordBreak: 'break-word',
          fontSize: '0.875rem',
          fontWeight: 500,
          color: '#373A36',
        }}
        title={characteristic}
      >
        {loading ? (
          <Skeleton
            width={'100%'}
            variant={'rectangular'}
          />
        ) : !characteristic ? (
          '-'
        ) : (
          characteristic
        )}
      </Typography>
    </Box>
  )
}
export default InvestCharacteristicType
