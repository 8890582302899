export const editorConfig = {
  menubar: false,
  statusbar: true,
  plugins: 'link lists',
  font_size_formats: '10px 12px 14px 16px 18px 20px',
  content_style: 'body {font-size: 14px}',
  color_map: ['009639', 'Green', '36A923', 'Mid Green', '373A36', 'Invest Black', '6D6C6C', 'Gray', 'F04438', 'Red'],
  toolbar:
    'fontsize blocks | undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify | numlist bullist | link'
}
