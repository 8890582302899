import AppInputs from '@/components/app-form/app-inputs'
import { FormGroup } from '@mui/material'
import { tagsForm } from '@/containers/admin-tags/admin-tags-form/constants'

export const AdminTagsForm = ({ formProps }) => {
  return (
    <FormGroup
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '6px',
        flexWrap: 'nowrap'
      }}
    >
      <AppInputs
        inputs={tagsForm}
        {...formProps}
      />
    </FormGroup>
  )
}

export default AdminTagsForm
