import AppFormHeader from '@/components/app-form-header'
import { useTranslation } from 'react-i18next'
import { MenuItem, TextField } from '@mui/material'
import {
  cadastralNumberStatusOptions,
  landCategoryCategoryOptions,
  landCategorySectionOptions,
  landCategoryTypeOptions,
  ownershipTypeOptions
} from '@/containers/admin-invest-object/admin-invest-object-form/land-plot-section/constants'
import { useEffect, useMemo } from 'react'
import { styles } from '@/containers/admin-invest-object/admin-invest-object-form/admin-invest-object-form-styles'
import AppMultipleFileUploader from '@/components/app-multiple-file-uploader'
import i18next from 'i18next'
import AppRadioGroup from '@/components/app-radio-group'
import { commonDocumentsFileType, yesNoOptions } from '@/constants/default'
import { useAppErrorContext } from '@/context/app-error-context'
import PropTypes from 'prop-types'

const LandPlotSectionProps = {
  formPropsGeneral: PropTypes.object
}

const LandPlotSection = ({ formPropsGeneral }) => {
  const { t } = useTranslation()
  const { setError: setSnackbarError } = useAppErrorContext()

  const landCategoryCategoryAvailableOptions = useMemo(() => {
    return landCategoryCategoryOptions()[formPropsGeneral.data.landCategorySection] || []
  }, [formPropsGeneral.data.landCategorySection])

  const landCategoryTypeAvailableOptions = useMemo(() => {
    return landCategoryTypeOptions()[formPropsGeneral.data.landCategoryCategory] || []
  }, [formPropsGeneral.data.landCategoryCategory])

  const handleLandCategorySectionChange = (e) => {
    formPropsGeneral.handleChange('landCategoryCategory')({ target: { value: '' } })
    formPropsGeneral.handleChange('landCategorySection')(e)
  }

  const handleCadastralNumberStatusChange = (e) => {
    formPropsGeneral.handleChange('cadastralNumberStatus')(e)
  }

  const handleRadioChange = (e) => {
    const value = e.target.value === 'true'
    formPropsGeneral.handleChange('landPlan')({ target: { value } })
  }

  useEffect(() => {
    if (formPropsGeneral.errors.landPlanFilesIds) {
      setSnackbarError(t('investObjects.errors.landPlanFilesError'))
    } else {
      setSnackbarError('')
    }
  }, [formPropsGeneral.errors.landPlanFilesIds, setSnackbarError, t])

  return (
    <>
      <AppFormHeader
        text={t('investObjects.headers.landPlot')}
        variant={'h4'}
        sx={styles.headers.landPlot}
      />
      <TextField
        select
        label={t('investObjects.labels.сadastralNumberOfPlot')}
        value={formPropsGeneral.data.cadastralNumberStatus}
        error={Boolean(formPropsGeneral.errors.cadastralNumberStatus)}
        helperText={formPropsGeneral.errors.cadastralNumberStatus || ' '}
        onChange={handleCadastralNumberStatusChange}
        onBlur={formPropsGeneral.handleBlur('cadastralNumberStatus')}
        sx={styles.inputs.cadastralNumberOfPlot}
      >
        {cadastralNumberStatusOptions.map((option) => (
          <MenuItem
            key={option.text}
            value={option.value}
          >
            {option.text}
          </MenuItem>
        ))}
      </TextField>
      {formPropsGeneral.data.cadastralNumberStatus === 'EXISTS' && (
        <TextField
          value={formPropsGeneral.data.cadastralNumber}
          error={Boolean(formPropsGeneral.errors.cadastralNumber)}
          helperText={formPropsGeneral.errors.cadastralNumber || ' '}
          onChange={formPropsGeneral.handleChange('cadastralNumber')}
          onBlur={formPropsGeneral.handleBlur('cadastralNumber')}
          sx={styles.inputs.cadastralNumber}
          placeholder={t('investObjects.labels.cadastralNumber')}
        />
      )}
      <TextField
        value={formPropsGeneral.data.plotArea}
        error={Boolean(formPropsGeneral.errors.plotArea)}
        helperText={formPropsGeneral.errors.plotArea || ' '}
        onChange={formPropsGeneral.handleChange('plotArea')}
        onBlur={formPropsGeneral.handleBlur('plotArea')}
        sx={styles.inputs.plotArea}
        label={t('investObjects.labels.plotArea')}
      />
      <TextField
        select
        label={t('investObjects.labels.ownershipType')}
        value={formPropsGeneral.data.ownershipType}
        error={Boolean(formPropsGeneral.errors.ownershipType)}
        helperText={formPropsGeneral.errors.ownershipType || ' '}
        onChange={formPropsGeneral.handleChange('ownershipType')}
        onBlur={formPropsGeneral.handleBlur.ownershipType}
        sx={styles.inputs.ownershipType}
      >
        {ownershipTypeOptions().map((option) => (
          <MenuItem
            key={option.text}
            value={option.value}
          >
            {option.text}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label={t('investObjects.labels.landCategorySection')}
        value={formPropsGeneral.data.landCategorySection}
        error={Boolean(formPropsGeneral.errors.landCategorySection)}
        helperText={formPropsGeneral.errors.landCategorySection || ' '}
        onChange={handleLandCategorySectionChange}
        onBlur={formPropsGeneral.handleBlur.landCategorySection}
        sx={styles.inputs.landCategorySection}
      >
        {landCategorySectionOptions().map((option) => (
          <MenuItem
            key={option.text}
            value={option.value}
          >
            {option.text}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label={t('investObjects.labels.landCategoryCategory')}
        value={formPropsGeneral.data.landCategoryCategory}
        error={Boolean(formPropsGeneral.errors.landCategoryCategory)}
        helperText={formPropsGeneral.errors.landCategoryCategory || ' '}
        onChange={formPropsGeneral.handleChange('landCategoryCategory')}
        onBlur={formPropsGeneral.handleBlur.landCategoryCategory}
        sx={styles.inputs.landCategoryCategory}
        disabled={!formPropsGeneral.data.landCategorySection}
      >
        {landCategoryCategoryAvailableOptions.map((option) => (
          <MenuItem
            key={option.text}
            value={option.value}
          >
            {option.text}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label={t('investObjects.labels.landCategoryType')}
        value={formPropsGeneral.data.landCategoryType}
        error={Boolean(formPropsGeneral.errors.landCategoryType)}
        helperText={formPropsGeneral.errors.landCategoryType || ' '}
        onChange={formPropsGeneral.handleChange('landCategoryType')}
        onBlur={formPropsGeneral.handleBlur.landCategoryType}
        sx={styles.inputs.landCategoryType}
        disabled={!formPropsGeneral.data.landCategoryCategory}
      >
        {landCategoryTypeAvailableOptions.map((option) => (
          <MenuItem
            key={option.text}
            value={option.value}
          >
            {option.text}
          </MenuItem>
        ))}
      </TextField>
      <AppRadioGroup
        value={formPropsGeneral.data.landPlan}
        onChange={handleRadioChange}
        error={Boolean(formPropsGeneral.errors.landPlan)}
        helperText={formPropsGeneral.errors.landPlan}
        sx={styles.inputs.landPlan}
        options={yesNoOptions}
        label={i18next.t('investObjects.labels.landPlan')}
        row
      />
      {formPropsGeneral.data.landPlan && (
        <AppMultipleFileUploader
          value={formPropsGeneral.data.landPlanFilesIds}
          onChangeFiles={formPropsGeneral.handleFileChange('landPlanFilesIds')}
          accept={[...commonDocumentsFileType]}
          sx={styles.inputs.landPlanFilesIds}
          maxFilesCount={10}
          maxFileSize={'10MB'}
        />
      )}
    </>
  )
}

LandPlotSection.propTypes = LandPlotSectionProps

export default LandPlotSection
