import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { minMaxLength, noSpacesInString, requiredField } from '@/utils/validations/common'
import useForm from '@/hooks/use-form'
import { tagsForm, tagsFormInitialValue } from '@/containers/admin-tags/admin-tags-form/constants'

const hasDuplicates = (arr) => {
  return new Set(arr).size !== arr.length
}
const tagsBaseValidation = {
  uk: [requiredField, minMaxLength(0, 20), noSpacesInString],
  en: [requiredField, minMaxLength(0, 20), noSpacesInString]
}

export const useAdminTagsForm = (tagsList) => {
  const [formValues, setFormValue] = useState(tagsFormInitialValue)

  const useTagForm = useForm({
    inputs: tagsForm,
    initialValues: formValues,
    validations: tagsBaseValidation
  })
  
  useEffect(() => {
    const tagUniqueValidation = (value, formData) => {
      if (!value){
        return
      }
      const tagTitles = tagsList.reduce((acc, { title }) => {
        acc.push(title.en.toLowerCase(), title.uk.toLowerCase())
        return acc
      }, [])
      const formDataHasDuplicates = hasDuplicates(Object.values(formData).map(e=>e.toLowerCase()))
      if (formDataHasDuplicates) {
        return i18next.t('tags.tagNotUniqueForm')
      }
      const tagTitlesIncludesValue = tagTitles.includes(value.toLowerCase())
      if (tagTitlesIncludesValue) {
        return i18next.t('tags.tagNotUnique')
      }
    }
    useTagForm.setValidations({
      uk: [tagUniqueValidation, ...tagsBaseValidation.uk],
      en: [tagUniqueValidation, ...tagsBaseValidation.en]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useTagForm.setValidations, tagsList])
  
  return {
    ...useTagForm,
    setFormValue
  }
}
