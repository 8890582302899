import React from 'react'
import Box from '@mui/material/Box'
import AppLogo from '@/components/app-logo/AppLogo'
import FooterMenuItems from '@/containers/layouts/client/client-footer/client-nav-footer/footer-menu-items'
import SubscribeBlock from '@/components/subscribe-block'
import useBreakpoints from '@/hooks/use-breakpoints'

const ClientNavFooter = () => {
  const { isDesktop, isTablet, isMobile } = useBreakpoints()

  const newsContainerSx = {
    gridTemplateColumns: isDesktop ? '1fr 0.9fr 0.9fr 1fr 1.3fr' : isTablet ? '1fr 1fr 1fr' : isMobile && '1fr'
  }

  const appLogoContainerStyles = {
    pr: isDesktop ? '1rem' : 0,
    pb: !isDesktop ? '1rem' : 0,
    borderBottom: !isDesktop && '1px solid #D9D9D9',
    gridColumnStart: isTablet ? 1 : 0,
    gridColumnEnd: isTablet ? 4 : 0
  }

  return (
    <Box sx={{ display: 'grid', rowGap: '20px', py: isDesktop ? '4.5rem' : '2rem', ...newsContainerSx }}>
      <Box sx={{ ...appLogoContainerStyles }}>
        <AppLogo height={isDesktop ? 61 : 51} />
      </Box>
      {!isMobile && <FooterMenuItems />}
      <SubscribeBlock />
    </Box>
  )
}

export default ClientNavFooter
