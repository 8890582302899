import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, Button, Grid, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { theme } from '@/plugins/material-ui/theme'
import DownloadIcon from '@mui/icons-material/Download'

import useAxios from '@/hooks/use-axios'
import { SupportProgramsService } from '@/services/support-program-service'
import defaultImage from '@/assets/images/support-program-default-image.jpg'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import { baseContainerSX } from '@/containers/constants'
import useBreakpoints from '@/hooks/use-breakpoints'
import AppButtonGoBackTo from '@/components/app-button-go-back-to/AppButtonGoBackTo'
import SupportProgramItem from '@/containers/support-program-banner/support-program-item'
import { responsiveGap } from '@/pages/home-page/HomePage.styles'
import SupportProgramsSimilarSection from '@/containers/support-program-similiar-section'
import { ellipsis } from '@/pages/invest-object-details-page/constant'

const SupportProgramDetailPage = () => {
  const [program, setProgram] = useState({})
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const { isDesktop } = useBreakpoints()
  const navigate = useNavigate()

  const backgroundImage = program.featureImage || defaultImage
  const { fetchData, loading } = useAxios({
    service: SupportProgramsService.getSupportProgramById,
    initialLoading: true
  })

  const getProgram = useCallback(async () => {
    const data = await fetchData({ id, lang: i18n.language })
    setProgram(data)
  }, [fetchData, i18n.language, id])

  useEffect(() => {
    getProgram().catch((e) => {
      if (e.response.status === 404) {
        navigate('/support-programs')
      }
    })
  }, [getProgram, navigate])

  const borderSx = `1px solid ${theme.palette.grey.main}`
  const itemSx = { padding: { md: '30px 45px', xs: '20px' } }
  const programInfoSX = {
    border: borderSx,
    width: 'auto',
    mx: { md: 0, sm: '23px', xs: '15px' },
    mt: { md: '23px', xs: '15px' }
  }
  const ProgramOuterContent = () => (
    <>
      <Grid
        container
        sx={{ ...programInfoSX }}
      >
        <Grid
          item
          lg={4}
          md={12}
        >
          <Grid
            container
            borderRight={isDesktop && borderSx}
          >
            <Grid
              item
              lg={12}
              md={6}
              sx={itemSx}
            >
              <Typography
                variant='body1'
                mb='15px'
              >
                {t('supportPrograms.donor')}
              </Typography>
              {program.donorLogo ? (
                <img
                  src={program.donorLogo}
                  style={{ maxWidth: isDesktop ? '40%' : '80%' }}
                  alt='Donor logo'
                />
              ) : (
                <Typography variant='h3'>{program.donorName}</Typography>
              )}
            </Grid>

            <Grid
              item
              lg={12}
              md={6}
              sx={itemSx}
            >
              <Typography
                variant='body1'
                mb='10px'
              >
                {t('supportPrograms.helpAmount')}
              </Typography>

              <Typography
                variant='h3'
                sx={{ ...ellipsis('100%') }}
              >
                {program.amount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={8}
          borderTop={!isDesktop && borderSx}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={itemSx}
            >
              <Typography
                variant='body1'
                mb='10px'
              >
                {t('supportPrograms.recipient')}
              </Typography>

              <Typography
                variant='body2'
                sx={{ fontWeight: 600 }}
              >
                {program.target}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={itemSx}
            >
              <Typography
                variant='body1'
                mb='10px'
              >
                {t('supportPrograms.deadlineReceiving')}
              </Typography>

              <Typography
                variant='body2'
                sx={{ fontWeight: 600 }}
              >
                {program.submissionTerm}
              </Typography>
            </Grid>

            <Grid
              item
              sm={12}
              md={12}
              sx={itemSx}
            >
              <Typography
                variant='body1'
                mb='10px'
              >
                {t('supportPrograms.deadlineApply')}
              </Typography>

              <Typography
                variant='body2'
                sx={{ fontWeight: 600 }}
              >
                {program.receiptTerm}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box
        sx={{
          ...baseContainerSX,
          padding: responsiveGap,
          marginTop: theme.spacing(5),
          ...(!isDesktop && { marginX: theme.spacing(2) })
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: program.text }} />
      </Box>
    </>
  )

  const ProgramContent = () => (
    <>
      <Typography
        variant='h1'
        sx={{ marginTop: theme.spacing(2) }}
      >
        {program.title}
      </Typography>

      <Box
        display='flex'
        sx={{ marginTop: { xl: theme.spacing(4), xs: theme.spacing(2) } }}
      >
        <Box flexGrow={1}>
          {program.tags?.length
            ? program.tags.map((tag) => (
                // eslint-disable-next-line
                <Button
                  variant='outlined'
                  color='success'
                  key={tag.id}
                  sx={{ height: '46px', marginRight: theme.spacing(1), marginTop: theme.spacing(1) }}
                >
                  {tag.title}
                </Button>
              ))
            : null}
        </Box>

        {program.document && (
          <a
            href={program.document}
            download
            style={{ textDecoration: 'none', marginTop: 'auto' }}
          >
            <Button
              sx={{ height: '46px', minWidth: '238px' }}
              color='success'
              variant='contained'
            >
              <DownloadIcon />
              {t('supportPrograms.downloadPdf')}
            </Button>
          </a>
        )}
      </Box>
    </>
  )

  const Image = () => (
    <Box
      display='flex'
      maxHeight='484px'
      margin='auto'
      width='100%'
    >
      {loading ? (
        <Skeleton
          variant={'rectangular'}
          width={'100%'}
          height={470}
        />
      ) : (
        <Box
          component='img'
          sx={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }}
          src={`${backgroundImage}?auto=format`}
          srcSet={`${backgroundImage}?auto=format&dpr=2 2x`}
          alt={program.title}
          loading='lazy'
        />
      )}
    </Box>
  )

  return (
    <>
      <Image />
      <Box
        display='flex'
        flexDirection='column'
        sx={{ ...baseContainerSX, px: responsiveGap, mb: responsiveGap, width: '100%' }}
      >
        <Box
          display='flex'
          flexDirection='column'
        >
          <AppButtonGoBackTo
            sx={{ marginTop: { md: theme.spacing(6), xs: theme.spacing(2) } }}
            to={clientRoutes.supportPrograms.route}
            label={t('supportPrograms.backToPrograms')}
          />

          {loading ? (
            <Box sx={{ mt: responsiveGap, width: '100%' }}>
              <SupportProgramItem
                vertical
                loading
              />
            </Box>
          ) : (
            <ProgramContent />
          )}
        </Box>
      </Box>
      <Box sx={baseContainerSX}>{loading ? null : <ProgramOuterContent />}</Box>
      <SupportProgramsSimilarSection />
    </>
  )
}

export default SupportProgramDetailPage
