import AdminPageHeader from '@/containers/admin-page-header'
import { Link } from 'react-router-dom'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import AppTabs from '@/components/app-tabs'
import Box from '@mui/material/Box'
import { useCallback, useMemo, useState } from 'react'
import { setInitialFilter } from '@/components/app-grid/utils/app-grid-utils'
import { documentStatus } from '@/containers/constants'
import { useTabs } from '@/hooks/use-tabs'
import { useTranslation } from 'react-i18next'
import useAxios from '@/hooks/use-axios'
import { InvestObjectService } from '@/services/invest-object-service'
import AdminInvestObjectTable from '@/containers/admin-invest-object/admin-invest-object-table'
import AdminInvestObjectControlPane from '@/containers/admin-invest-object/admin-invest-object-control-pane'

const AdminInvestObjectPage = () => {
  const [counts, setCounts] = useState({ allObjects: 0, posted: 0, draft: 0 })
  const { t } = useTranslation()
  const { fetchData } = useAxios({ service: InvestObjectService.countPerStatus })

  const setCountPerStatus = useCallback(async () => {
    const { total, PUBLISHED, DRAFT } = await fetchData()
    setCounts({ allObjects: total, posted: PUBLISHED, draft: DRAFT })
  }, [fetchData])

  const investObjectsListTabs = useMemo(
    () => ({
      allPosts: {
        value: 'allObjects',
        label: 'Усі інвестиційні обʼєкти',
        component: <AdminInvestObjectTable onTableRefresh={setCountPerStatus} />
      },
      posted: {
        value: 'posted',
        label: 'Опубліковані',
        component: (
          <AdminInvestObjectTable
            onTableRefresh={setCountPerStatus}
            filterBy={setInitialFilter('status', documentStatus.PUBLISHED)}
          />
        )
      },
      draft: {
        value: 'draft',
        label: 'Чорновик',
        component: (
          <AdminInvestObjectTable
            onTableRefresh={setCountPerStatus}
            filterBy={setInitialFilter('status', documentStatus.DRAFT)}
          />
        )
      }
    }),
    [setCountPerStatus]
  )

  const { tabIndex, handleTabChange } = useTabs({ tabs: investObjectsListTabs })

  return (
    <Box
      className='admin-invest-object-page'
      sx={{ mx: '32px', mb: '32px', display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <AdminPageHeader
        title={t('investObjects.allInvestObjects')}
        buttonLabel={t('investObjects.create')}
        onClick={setCountPerStatus}
        buttonProps={{
          component: Link,
          to: adminRoutes.createInvestObject.path,
          sx: { mr: '16px' }
        }}
        controlPane={<AdminInvestObjectControlPane />}
      />
      <AppTabs
        counts={counts}
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        tabs={investObjectsListTabs}
        stayAlive={false}
      />
    </Box>
  )
}

export default AdminInvestObjectPage
