import { getOptionByValue } from '@/containers/admin-invest-object/admin-invest-object-table/constants'
import {
  characteristicsTypeOptions,
  characteristicType,
  encumbranceOptions,
  usageTypeOptions
} from '@/pages/admin/admin-invest-object-edit/constants'
import { formatUnit } from '@/utils/unit-formaters'
import {
  landCategoryCategoryOptions,
  landCategorySectionOptions,
  landCategoryTypeOptions,
  ownershipTypeOptions
} from '@/containers/admin-invest-object/admin-invest-object-form/land-plot-section/constants'
import { Box, Link, Typography } from '@mui/material'
import documentIcon from '@/assets/icons/document.svg'
import linkIcon from '@/assets/icons/link-out.svg'
import {
  realtyStateOptions,
  realtyTypesOptions
} from '@/containers/admin-invest-object/admin-invest-object-form/real-estate-section/constants'
import { createOptionsMap } from '@/containers/invest-object-list/invest-object-list-filter-short/constant'
import useBreakpoints from '@/hooks/use-breakpoints'

export const ellipsis = (maxWidth = '280px') => ({
  display: 'inline-block',
  maxWidth: '100%',
  width: maxWidth,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  verticalAlign: 'top'
})

const getCommunicationCharacteristic = (has, how, nearestUnit, nearest, unit, t) => {
  if (has) {
    const power = how ? `, ${t('investObjects.labels.power')} ${how} ${unit}` : ''
    return `${t('investObjects.labels.connected')}${power}${!how && unit ? ', ' + unit : ''}`
  } else {
    const howFar = `, ${nearestUnit} ${nearest} ${t('investObjectMap.unit.km')}`
    return `${t('investObjects.labels.notConnected')}${howFar}`
  }
}
const LinkIcon = ({ linkSrc, title, href, maxMobileWidth = '320px' }) => {
  const { isMobile } = useBreakpoints()

  if (!title) {
    return null
  }

  const sxLink = isMobile ? ellipsis(maxMobileWidth) : {}

  return (
    <Link
      component='a'
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      sx={{
        color: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
        gap: '10px',
        px: 0
      }}
    >
      <img
        src={linkSrc}
        height={18}
        width={18}
        alt={'c'}
      />
      <Typography
        variant='subtitle1'
        title={title}
        sx={{ ...sxLink }}
      >
        {title}
      </Typography>
    </Link>
  )
}

const DocumentTypes = ({ documents, maxMobileWidth }) => (
  <Box
    display='flex'
    flexDirection='column'
    gap='8px'
  >
    {documents.map(({ path, name }) => {
      return (
        <LinkIcon
          key={path}
          linkSrc={documentIcon}
          title={name}
          href={path}
          maxMobileWidth={maxMobileWidth}
        />
      )
    })}
  </Box>
)

export const getCharacteristicTypes = ({ detail, t, i18n }) => {
  if (Object.keys(detail).length === 0) {
    return []
  }

  const characteristicTypes = []

  const characteristicTypeObj = {
    title: t('investObjects.headers.characteristic'),
    types: [
      {
        type: t('investObjects.listShortCharacteristics.objectType'),
        characteristic: getOptionByValue(characteristicsTypeOptions(), detail.characteristicsType)?.label
      },
      {
        type: t('investObjects.listShortCharacteristics.usageType'),
        characteristic: getOptionByValue(usageTypeOptions(), detail.usageType)?.text
      },
      {
        type: t('investObjects.labels.previousUsage'),
        characteristic: detail.previousUsage
      },
      {
        type: t('investObjects.labels.workersCount'),
        characteristic: detail.workersCount
      },
      {
        type: t('investObjects.labels.encumbrance'),
        characteristic: getOptionByValue(encumbranceOptions(), detail.encumbrance)?.text
      },
      {
        type: t('investObjects.labels.virtualStorage'),
        characteristic: (
          <LinkIcon
            linkSrc={linkIcon}
            title={detail.url}
            href={detail.url}
            maxMobileWidth='160px'
          />
        )
      },
      {
        type: t('investObjects.labels.landPlan'),
        characteristic: <DocumentTypes documents={detail.landPlanFiles} maxMobileWidth='160px' />
      }
    ]
  }
  const landPlotCharacteristic = {
    title: t('investObjects.headers.landPlot'),
    types: []
  }
  if (detail.cadastralNumberStatus === 'EXISTS') {
    landPlotCharacteristic.types.push({
      type: t('investObjects.labels.сadastralNumberOfPlotLabel'),
      characteristic: detail.cadastralNumber
    })
  }

  landPlotCharacteristic.types.push(
    {
      type: t('investObjects.labels.squarePlot'),
      characteristic: formatUnit(i18n.language, detail.plotArea, 'hectare')
    },
    {
      type: t('investObjects.listShortCharacteristics.ownershipType'),
      characteristic: getOptionByValue(ownershipTypeOptions(), detail.ownershipType)?.text
    }
  )

  const categoryCategory = getOptionByValue(landCategorySectionOptions(), detail.landCategorySection)
  const categoryOption = categoryCategory
    ? getOptionByValue(landCategoryCategoryOptions()[categoryCategory.value], detail.landCategoryCategory)
    : null
  const categoryView = categoryOption
    ? getOptionByValue(landCategoryTypeOptions()[categoryOption.value], detail.landCategoryType)
    : null

  const landCategoryType = {
    title: t('investObjects.labels.landCategoryTypeLabel'),
    types: [
      {
        type: t('investObjects.labels.landCategorySectionShort'),
        characteristic: categoryCategory?.text
      },
      {
        type: t('investObjects.labels.landCategoryCategoryShort'),
        characteristic: categoryOption?.text
      },
      {
        type: t('investObjects.labels.landCategoryTypeShort'),
        characteristic: categoryView?.text
      }
    ]
  }
  const realityTypeMap = createOptionsMap(realtyTypesOptions())
  const realtyType = detail.realtyType?.replace('{', '').replace('}', '').split(',') ?? []

  const realEstateType = {
    title: t('investObjects.headers.realEstate'),
    types: [
      {
        type: t('investObjects.labels.realtyTypesLabel'),
        characteristic: realtyType.map((e) => realityTypeMap[e]).join(', ')
      },
      {
        type: t('investObjects.listShortCharacteristics.realtyTotalAreaSqM'),
        characteristic: `${formatUnit(i18n.language, detail.realtyTotalAreaSqM, 'meter')}2`
      },
      {
        type: t('investObjects.labels.realtyState'),
        characteristic: getOptionByValue(realtyStateOptions(), detail.realtyState)?.text
      }
    ]
  }
  const transportConnectionsType = {
    title: t('investObjects.headers.transportConnections'),
    types: [
      {
        type: t('investObjects.labels.roads'),
        characteristic: detail.roadSurfaceType
      },
      {
        type: t('investObjects.labels.railroads'),
        characteristic: t(`investObjects.labels.${detail.railroad ? 'exist' : 'notExist'}`)
      },
      {
        type: t('investObjects.labels.internationalAndStateRoads.title'),
        characteristic: (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {detail.internationalAndStateRoads?.map(({ name, distanceKM }) => (
              <Box key={name}>
                {`${name}, ${t('investObjects.labels.distance')} ${distanceKM} ${t('investObjectMap.unit.km')}`}
              </Box>
            ))}
          </Box>
        )
      },
      {
        type: t('investObjects.labels.borderCrossingPoints.title'),
        characteristic: (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {detail.borderCrossingPoints?.map(({ name, distanceKM }) => (
              <Box key={name}>
                {`${name}, ${t('investObjects.labels.distance')} ${distanceKM} ${t('investObjectMap.unit.km')}`}
              </Box>
            ))}
          </Box>
        )
      }
    ]
  }

  const communicationType = {
    title: t('investObjects.headers.communications'),
    types: [
      {
        type: t('investObjects.labels.powerSupply'),
        characteristic: getCommunicationCharacteristic(
          detail.powerSupply,
          detail.powerSupplyKw,
          t('investObjects.labels.nearestPowerSupply'),
          detail.nearestPowerSupplyKm,
          t('investObjectMap.unit.kw'),
          t
        )
      },
      {
        type: t('investObjects.labels.gasSupply'),
        characteristic: getCommunicationCharacteristic(
          detail.gasSupply,
          null,
          t('investObjects.labels.nearestGasSupply'),
          detail.nearestGasSupplyKm,
          null,
          t
        )
      },
      {
        type: t('investObjects.labels.waterSupply'),
        characteristic: getCommunicationCharacteristic(
          detail.waterSupply,
          null,
          t('investObjects.labels.nearestWaterSupply'),
          detail.nearestWaterSupplyKm,
          detail.waterSupplyType,
          t
        )
      },
      {
        type: t('investObjects.labels.drainage'),
        characteristic: getCommunicationCharacteristic(
          detail.drainage,
          null,
          t('investObjects.labels.nearestDrainage'),
          detail.nearestDrainageKm,
          detail.drainageType,
          t
        )
      }
    ]
  }

  const documentsType = {
    title: t('investObjects.headers.documents'),
    types: [
      {
        type: null,
        characteristic: <DocumentTypes documents={detail.documents} />
      }
    ]
  }

  characteristicTypes.push(characteristicTypeObj)

  if (
    getOptionByValue(characteristicsTypeOptions(), detail.characteristicsType)?.value === characteristicType.land ||
    detail.realtyHasLandPlot
  ) {
    characteristicTypes.push(landPlotCharacteristic)
    characteristicTypes.push(landCategoryType)
  }

  if (
    getOptionByValue(characteristicsTypeOptions(), detail.characteristicsType)?.value === characteristicType.realEstate
  ) {
    characteristicTypes.push(realEstateType)
  }

  characteristicTypes.push(transportConnectionsType)
  characteristicTypes.push(communicationType)
  if (detail.documents.length > 0){
    characteristicTypes.push(documentsType)
  }

  return characteristicTypes
}
