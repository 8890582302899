import { Link } from 'react-router-dom'
import { Alert, Box, Button, FormGroup, Typography } from '@mui/material'
import AppForm from '@/components/app-form'
import AppInputs from '@/components/app-form/app-inputs'
import useForm from '@/hooks/use-form'
import useAxios from '@/hooks/use-axios'
import { UsersService } from '@/services/users-service'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import { useAppErrorContext } from '@/context/app-error-context'
import { codeForm, emailForm } from '@/pages/admin/admin-reset-page/constant'
import { ellipsis } from '@/pages/invest-object-details-page/constant'

const AdminResetPage = () => {
  const { t } = useTranslation()
  const [codeSent, setCodeSent] = useState(null)
  const [passwordSent, setPasswordSent] = useState(null)
  const [form, setForm] = useState(emailForm)
  const { data, formProps, handleValidate, setMappedData, setValidations } = useForm(form)
  const { setError: setSnackbarError, clearError } = useAppErrorContext()

  useEffect(() => {
    if (codeSent) {
      setValidations(codeForm.validations)
      setForm(codeForm)
    } else {
      setValidations(emailForm.validations)
      setForm(emailForm)
    }
  }, [codeSent, setValidations])

  const { fetchData: resetCode, loading: loadingCode } = useAxios({
    service: UsersService.resetCode,
    handleError: true
  })
  const { fetchData: resetPassword, loading: loadingPassword } = useAxios({
    service: UsersService.resetPassword,
    handleError: true
  })

  const onSubmit = async () => {
    if (handleValidate()) {
      setMappedData(data)
      clearError()
      if (codeSent) {
        try {
          await resetPassword({ email: codeSent.email, resetPasswordCode: Number(data.code) })
          setPasswordSent({ email: codeSent.email, resetPasswordCode: data.code })
        } catch (e) {
          setSnackbarError(t('login.errorPasswordReset'))
          setPasswordSent(null)
        }
      } else {
        try {
          await resetCode(data)
          setCodeSent({ email: data.email })
        } catch (e) {
          setSnackbarError(t('login.errorSentCode'))
          setCodeSent(null)
        }
      }
    }
  }

  const backButton = () => {
    if (!codeSent || passwordSent) {
      return (
        <Button
          startIcon={<ArrowBackIcon />}
          component={Link}
          to={adminRoutes.login.path}
        >
          {t('login.backToLogin')}
        </Button>
      )
    }
    if (codeSent) {
      return (
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => setCodeSent(null)}
        >
          {t('login.backToReset')}
        </Button>
      )
    }
    return null
  }

  const getAlertInfo = () => {
    if (codeSent) {
      return (
        <Alert
          severity='info'
          mb='8px'
        >
          <Typography
            title={t('login.codeSent', { email: codeSent.email })}
            sx={{ ...ellipsis('900px') }}
          >
            {t('login.codeSent', { email: codeSent.email })}
          </Typography>
        </Alert>
      )
    }
    if (passwordSent) {
      return (
        <Alert
          severity='info'
          mb='8px'
        >
          <Typography
            title={t('login.passwordSent', { email: codeSent.email })}
            sx={{ ...ellipsis('900px') }}
          >
            {t('login.passwordSent', { email: codeSent.email })}
          </Typography>
        </Alert>
      )
    }
    return (
      <Alert
        severity='info'
        mb='8px'
      >
        Введіть ваш email. Туди буде надіслано повідомлення з інструкціями по відновленню пароля
      </Alert>
    )
  }

  const getForm = () => {
    if (passwordSent) {
      return null
    }
    return (
      <AppForm onFormSubmit={onSubmit}>
        <FormGroup sx={{ flexGrow: 1, gap: '8px' }}>
          <AppInputs {...formProps}></AppInputs>
        </FormGroup>
        <Button
          variant={'contained'}
          disabled={loadingCode || loadingPassword}
          type='submit'
        >
          {t(`login.${codeSent ? 'getPassword' : 'resetPassword'}`)}
        </Button>
      </AppForm>
    )
  }

  const getForwardButton = () => {
    if (!codeSent) {
      return (
        <Button
          size='small'
          startIcon={<ArrowForwardIcon />}
          onClick={() => {
            if (handleValidate()) {
              setCodeSent({ email: data.email })
            }
          }}
        >
          {t('login.gotCode')}
        </Button>
      )
    }
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '36px',
        gap: '8px'
      }}
    >
      {backButton()}
      {getAlertInfo()}
      {getForm()}
      {getForwardButton()}
    </Box>
  )
}
export default AdminResetPage
