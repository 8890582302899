import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '@/context/auth-context'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import { useEffect } from 'react'
import { setupAuthBearer, setupRefresh } from '@/utils/request'

const REFRESH_POOL_TIMEOUT = 5000

const TokenGuard = () => {
  const { accessToken, refresh, logout } = useAuth()

  setupAuthBearer()

  useEffect(() => {
    setupRefresh(refresh, logout).catch()
    const refreshIntervalId = setInterval(() => {
      setupRefresh(refresh, logout).catch()
    }, REFRESH_POOL_TIMEOUT)
    return () => {
      clearInterval(refreshIntervalId)
    }
  }, [logout, refresh])

  if (!accessToken) {
    return (
      <Navigate
        to={adminRoutes.login.path}
        replace
      />
    )
  }
  return <Outlet />
}

export default TokenGuard
