import { Box, Button, Divider, Typography } from '@mui/material'
import { responsiveGap } from '@/pages/home-page/HomePage.styles'
import InvestObjectList from '@/containers/invest-object-list/investObjectList'
import InvestObjectMap from '@/containers/invest-object-map/InvestObjectMap'
import useBreakpoints from '@/hooks/use-breakpoints'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useClientLayoutRenderContext } from '@/context/client-layout-context'
import { useTranslation } from 'react-i18next'
import useAxios from '@/hooks/use-axios'
import { InvestObjectService } from '@/services/invest-object-service'
import InvestObjectListFilters from '@/containers/invest-object-list/invest-object-list-filters/InvestObjectListFilters'
import ListIcon from '@mui/icons-material/List'
import MapIcon from '@mui/icons-material/Map'
import { theme } from '@/plugins/material-ui/theme'
import InvestObjectListSorting from '@/containers/invest-object-list/invest-object-list-sorting/InvestObjectListSorting'
import { useLocalStorage } from '@/hooks/use-local-storage'
import { useSorting } from '@/containers/invest-object-list/invest-object-list-filters/hooks/useSorting'
import { useFilterBy } from '@/containers/invest-object-list/invest-object-list-filters/hooks/useFilterBy'
import { InvestObjectListFilterShort } from '@/containers/invest-object-list/invest-object-list-filter-short/InvestObjectListFilterShort'

const InvestObjectMapPage = () => {
  const { setIsFooterRender, setIsContactFormRender, setIsHeaderWiderRender, resetDefaultLayout } =
    useClientLayoutRenderContext()
  const [listViewToggle, setListViewToggle] = useLocalStorage('investMapPage:listView', true)
  const { t, i18n } = useTranslation()
  const { isLarge: isDesktop, isMobile } = useBreakpoints()
  const { sortingModel } = useSorting()
  const { filterBy } = useFilterBy()

  const [investObjectItemsList, setInvestObjectItemsList] = useState([])
  const [investObjectItemsMap, setInvestObjectItemsMap] = useState([])
  const [totalInvestObject, setTotalInvestObject] = useState(0)
  const { fetchData: fetchInvestObject, loading } = useAxios({
    service: InvestObjectService.mapListGrid,
    handleError: true,
    initialLoading: true
  })

  const { fetchData: fetchMapGrid } = useAxios({
    service: InvestObjectService.mapGrid,
    handleError: true,
    initialLoading: true
  })

  const getInvestObjectItems = useCallback(
    async ({ take, skip }) => {
      if (listViewToggle || isDesktop) {
        const refine = {
          sorting: sortingModel,
          filtering: filterBy
        }
        const { items, count } = await fetchInvestObject({ take, skip, lang: i18n.language, ...refine })
        setInvestObjectItemsList([...investObjectItemsList, ...items])
        setTotalInvestObject(count)

        return items
      }
    },
    [fetchInvestObject, i18n.language, investObjectItemsList, sortingModel, filterBy, listViewToggle, isDesktop]
  )

  const getInvestObjectMap = useCallback(async () => {
    if (!listViewToggle || isDesktop) {
      const refine = {
        filtering: filterBy
      }
      const { items } = await fetchMapGrid({ lang: i18n.language, ...refine })

      setInvestObjectItemsMap(items)

      return items
    }
  }, [filterBy, fetchMapGrid, i18n.language, listViewToggle, isDesktop])

  useEffect(() => {
    getInvestObjectMap().catch()
  }, [getInvestObjectMap])

  useEffect(() => {
    setIsFooterRender(false)
    setIsContactFormRender(false)
    setIsHeaderWiderRender(true)

    return () => {
      resetDefaultLayout()
    }
  }, [setIsFooterRender, setIsContactFormRender, setIsHeaderWiderRender, resetDefaultLayout])

  const InfiniteScroll = useRef(null)
  const clearItems = () => {
    setTotalInvestObject(0)
    setInvestObjectItemsList([])
    setInvestObjectItemsMap([])
  }

  useEffect(() => {
    if (listViewToggle || isDesktop) {
      clearItems()
      InfiniteScroll.current?.refreshData()
    }
  }, [i18n.language, sortingModel, filterBy, listViewToggle, isDesktop])

  // to not rerender filters after changes
  const memoFilters = useMemo(() => <InvestObjectListFilters />, [])
  const map = useMemo(
    () => (
      <InvestObjectMap
        cords={investObjectItemsMap
          .map((e) => ({
            id: e.id,
            lat: e.latitude,
            lng: e.longitude
          }))
          .filter((e) => e.lat !== null && e.lng !== null)}
      />
    ),
    [investObjectItemsMap]
  )

  return (
    <Box sx={{ height: '100%', flex: '1', display: 'flex', flexDirection: isDesktop ? 'row' : 'column' }}>
      {listViewToggle || isDesktop ? (
        <Box
          sx={{
            height: '100%',
            flex: !isDesktop ? 1 : '1 0 700px',
            px: responsiveGap,
            pr: { xl: theme.spacing(2) },
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant={'h3'}
            component={'h1'}
            sx={{ mt: responsiveGap, mb: '12px', display: isDesktop ? 'block' : 'none' }}
          >
            {t('investObjectMap.title')}
          </Typography>

          {memoFilters}
          <Divider sx={{ mt: '2px' }} />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: theme.spacing(2)
            }}
          >
            <Typography
              color='textSecondary'
              variant={'subtitle1'}
            >
              {t('investObjectMap.total', { count: totalInvestObject })}
            </Typography>
            <InvestObjectListSorting />
          </Box>
          {!isMobile ? <InvestObjectListFilterShort /> : null}

          <InvestObjectList
            InfiniteScroll={InfiniteScroll}
            totalInvestObject={totalInvestObject}
            getInvestObjectItems={getInvestObjectItems}
            investObjectItems={investObjectItemsList}
            clearItems={clearItems}
            loading={loading}
          />
        </Box>
      ) : null}
      {!listViewToggle || isDesktop ? (
        <Box sx={{ height: '100%', flex: !isDesktop ? 1 : '1 1 60%', display: 'flex', flexDirection: 'column' }}>
          {!isDesktop ? <Box sx={{ px: responsiveGap }}>{memoFilters}</Box> : null}
          {map}
        </Box>
      ) : null}
      {!isDesktop ? (
        <Button
          sx={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: isMobile ? '190px' : 'auto'
          }}
          startIcon={!listViewToggle ? <ListIcon /> : <MapIcon />}
          variant={'contained'}
          onClick={() => setListViewToggle(!listViewToggle)}
        >
          {t(`investObjectMap.${!listViewToggle ? 'listView' : 'mapView'}`)}
        </Button>
      ) : null}
    </Box>
  )
}

export default InvestObjectMapPage
