import PropTypes from 'prop-types'
import { styles } from '@/containers/admin-events/admin-events-form/admin-events-form-styles'
import { FormGroup } from '@mui/material'
import AppInputs from '@/components/app-form/app-inputs'

const AdminEventsFormProps = {
  multiInputs: PropTypes.array,
  formPropsMulti: PropTypes.object,
  generalInputs: PropTypes.array,
  formPropsGeneral: PropTypes.object
}
const AdminEventsForm = ({ multiInputs, formPropsMulti, generalInputs, formPropsGeneral }) => {
  return (
    <FormGroup sx={{ flexGrow: 1, mt: '6px', ...styles.form }}>
      <AppInputs
        inputs={multiInputs}
        {...formPropsMulti}
      />
      <AppInputs
        inputs={generalInputs}
        {...formPropsGeneral}
      />
    </FormGroup>
  )
}

AdminEventsForm.propTypes = AdminEventsFormProps

export default AdminEventsForm
