import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
const AppCarousel = (props) => {
    return (
      <Carousel {...props} />
    )
}

export default AppCarousel
