import { useAppPopupDialog } from '@/context/app-popup-dialog-context'
import AppConfirm from '@/components/app-confirm'
import { useCallback } from 'react'

export const useConfirm = () => {
  const { openModal, closeModal } = useAppPopupDialog()

  const confirm = useCallback((text, content) => {
    let resolveCallback
    const handleConfirm = (isConfirmed) => {
      closeModal()
      resolveCallback(isConfirmed)
    }
    openModal({
      content: (
        <AppConfirm
          text={text}
          content={content}
          handleConfirm={handleConfirm}
        ></AppConfirm>
      )
    })
    return new Promise((resolve) => {
      resolveCallback = resolve
    })
  }, [closeModal, openModal])
  return { confirm }
}
