// FIXME: Use it for admin invest object create/edit form
import { TextField } from '@mui/material'
import AppRadioGroup from '@/components/app-radio-group'
import { characteristicsTypeOptions, characteristicType } from './constants'
import i18next from 'i18next'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SectionBase from '@/containers/invest-object-form/sections/common/SectionBase'

const CharacteristicsSection = ({ formPropsGeneral, formPropsMulti, generalInputs, multiInputs, styles }) => {
  const { t } = useTranslation()

  const clearLandSection = () => {
    const landSectionFields = [
      'cadastralNumberStatus',
      'cadastralNumber',
      'plotArea',
      'ownershipType',
      'landCategorySection',
      'landCategoryCategory',
      'landCategoryType'
    ]
    landSectionFields.forEach((field) => formPropsGeneral.handleChange(field)({ target: { value: '' } }))
    formPropsGeneral.handleChange('landPlan')({ target: { value: false } })
    formPropsGeneral.handleFileChange('landPlanFilesIds')([])
  }

  const clearRealEstateSection = () => {
    const realEstateFields = ['realtyState', 'realtyTotalAreaSqM']
    realEstateFields.forEach((field) => formPropsGeneral.handleChange(field)({ target: { value: '' } }))
    formPropsGeneral.handleChange('realtyHasLandPlot')({ target: { value: false } })
    formPropsGeneral.handleChange('realtyType')({ target: { value: [] } })
  }
  
  const handleCharacteristicsTypeChange = (e) => {
    formPropsGeneral.handleChange('characteristicsType')(e)
    if (e.target.value === characteristicType.land) {
      clearRealEstateSection()
    } else {
      clearLandSection()
    }
  }
  
  useEffect(() => {
    if (formPropsGeneral.data.usageType !== 'OTHER') {
      formPropsMulti.handleChange('otherUsageType')({ target: { value: '' } })
    }

    if (formPropsGeneral.data.encumbrance !== 'OTHER') {
      formPropsMulti.handleChange('otherEncumbrance')({ target: { value: '' } })
    }
    // with formPropsMulti in dependency array we end up with infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPropsGeneral.data.usageType, formPropsGeneral.data.encumbrance])
  
  
  return (
    <>
      <AppRadioGroup
        value={formPropsGeneral.data.characteristicsType}
        onChange={handleCharacteristicsTypeChange}
        error={Boolean(formPropsGeneral.errors.characteristicsType)}
        helperText={formPropsGeneral.errors.characteristicsType}
        sx={styles.inputs.characteristicsType}
        options={characteristicsTypeOptions(t)}
        label={i18next.t('investObjects.labels.objectType')}
        row
      />
      
      <SectionBase 
        generalInputs={generalInputs} 
        multiInputs={multiInputs} 
        formPropsGeneral={formPropsGeneral}
        formPropsMulti={formPropsMulti}
      />
      
      {formPropsGeneral.data.usageType === 'OTHER' && (
        <TextField
          value={formPropsMulti.data.otherUsageType}
          error={Boolean(formPropsMulti.errors.otherUsageType)}
          helperText={formPropsMulti.errors.otherUsageType || ' '}
          onChange={formPropsMulti.handleChange('otherUsageType')}
          onBlur={formPropsMulti.handleBlur('otherUsageType')}
          sx={styles.inputs.otherUsageType}
          placeholder={t('investObjects.labels.defineUsageType')}
        />
      )}
      {formPropsGeneral.data.encumbrance === 'OTHER' && (
        <TextField
          value={formPropsMulti.data.otherEncumbrance}
          error={Boolean(formPropsMulti.errors.otherEncumbrance)}
          helperText={formPropsMulti.errors.otherEncumbrance || ' '}
          onChange={formPropsMulti.handleChange('otherEncumbrance')}
          onBlur={formPropsMulti.handleBlur('otherEncumbrance')}
          sx={styles.inputs.otherEncumbrance}
          placeholder={t('investObjects.labels.defineEncumbrance')}
        />
      )}
    </>
  )
}

CharacteristicsSection.propTypes = SectionBase.propTypes

export default CharacteristicsSection
