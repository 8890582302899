import React, { useState, useRef } from 'react'

import { Box, IconButton } from '@mui/material'

import VideoPlayerSVG from '@/assets/icons/player.svg'
import CloseButtonSVG from '@/assets/icons/close-button.svg'
import VideoBannerPreview from '@/assets/images/video-banner-preview.png'
import { URLs } from '@/constants/URLs'

import useBreakpoints from '@/hooks/use-breakpoints'

const VideoBanner = () => {
  const youtubeVideoSrc = URLs.videoBanner.videoSRC

  const { isMobile, isTablet } = useBreakpoints()

  const videoRef = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const toggleBanner = () => {
    setIsOpen((open) => !open)

    if (videoRef) {
      videoRef.current.src = youtubeVideoSrc // resets iframe
    }
  }

  return (
    <>
      {!isOpen && (
        <Box
          sx={{
            pb: { sm: '28px', xs: '0' },
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            position: 'relative',
            overflow: { sm: 'auto', xs: 'hidden' }
          }}
        >
          <Box
            component='img'
            src={VideoBannerPreview}
            data-testid='preview-image'
            sx={{
              width: { sm: '100%', xs: 'auto' },
              minHeight: { md: '360px', sm: '246px', xs: '204px' },
              maxHeight: { md: '360px', sm: '246px', xs: '204px' },
              objectFit: 'cover'
            }}
          />
          <IconButton
            onClick={toggleBanner}
            data-testid='open-video-banner-button'
            sx={{
              p: 0,
              position: 'absolute',
              right: { md: '83px', sm: 0, xs: 0 },
              left: { md: 'auto', sm: 0, xs: 0 },
              margin: 'auto',
              cursor: 'pointer'
            }}
          >
            <img
              alt='Video Play'
              src={VideoPlayerSVG}
              height={isTablet || isMobile ? '80px' : '117px'}
            />
          </IconButton>
        </Box>
      )}
      <Box
        data-testid='youtube-video'
        sx={{
          position: { md: 'fixed', sm: 'relative', xs: 'fixed' },
          pb: { md: 0, sm: '30px', xs: 0 },
          zIndex: { md: 10, xs: 10 },
          width: '100%',
          height: { md: '100%', sm: '375px', xs: '100%' },
          left: 0,
          top: 0,
          display: isOpen ? 'block' : 'none'
        }}
      >
        <iframe
          title='video'
          width='100%'
          height='100%'
          src={youtubeVideoSrc}
          type='video/mp4'
          ref={videoRef}
          controls
          allowFullScreen
        ></iframe>
        <IconButton
          onClick={toggleBanner}
          data-testid='close-video-banner-button'
          sx={{
            p: 0,
            right: { md: '240px', sm: '240px', xs: '25px' },
            top: { md: '20px', sm: '22px', xs: '70px' },
            position: 'absolute',
            cursor: 'pointer'
          }}
        >
          <img
            alt='Video Close'
            src={CloseButtonSVG}
            height={25}
          />
        </IconButton>
      </Box>
    </>
  )
}

export default VideoBanner
