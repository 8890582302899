import AppRadioGroup from '@/components/app-radio-group'
import { styles } from '@/containers/admin-invest-object/admin-invest-object-form/admin-invest-object-form-styles'
import { connectedNotConnectedOptions } from '@/containers/admin-invest-object/admin-invest-object-form/communications-section/constants'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const CommunicationsSectionProps = {
  formPropsGeneral: PropTypes.object,
  formPropsMulti: PropTypes.object
}

const CommunicationsSection = ({ formPropsGeneral, formPropsMulti }) => {
  const { t } = useTranslation()
  const handlePowerSupplyChange = (e) => {
    const value = e.target.value === 'true'
    formPropsGeneral.handleChange('powerSupply')({ target: { value } })
  }

  const handleGasSupplyChange = (e) => {
    const value = e.target.value === 'true'
    formPropsGeneral.handleChange('gasSupply')({ target: { value } })
  }

  const handleWaterSupplyChange = (e) => {
    const value = e.target.value === 'true'
    formPropsGeneral.handleChange('waterSupply')({ target: { value } })
  }

  const handleDrainageChange = (e) => {
    const value = e.target.value === 'true'
    formPropsGeneral.handleChange('drainage')({ target: { value } })
  }

  const powerTextField = () => {
    if (formPropsGeneral.data.powerSupply === '') {
      return
    }
    const fieldName = formPropsGeneral.data.powerSupply ? 'powerSupplyKW' : 'nearestPowerSupplyKM'
    return (
      <TextField
        value={formPropsGeneral.data[fieldName]}
        error={Boolean(formPropsGeneral.errors[fieldName])}
        helperText={formPropsGeneral.errors[fieldName] || ' '}
        onChange={formPropsGeneral.handleChange(fieldName)}
        onBlur={formPropsGeneral.handleBlur(fieldName)}
        sx={styles.inputs[fieldName]}
        label={t(`investObjects.labels.${fieldName}`)}
      />
    )
  }

  const waterTextField = () => {
    if (formPropsGeneral.data.waterSupply === '') {
      return
    }
    const fieldName = formPropsGeneral.data.waterSupply ? 'waterSupplyType' : 'nearestWaterSupplyKM'
    const formProps = fieldName === 'waterSupplyType' ? formPropsMulti : formPropsGeneral
    return (
      <TextField
        value={formProps.data[fieldName]}
        error={Boolean(formProps.errors[fieldName])}
        helperText={formProps.errors[fieldName] || ' '}
        onChange={formProps.handleChange(fieldName)}
        onBlur={formProps.handleBlur(fieldName)}
        sx={styles.inputs[fieldName]}
        label={t(`investObjects.labels.${fieldName}`)}
      />
    )
  }

  const drainageTextField = () => {
    if (formPropsGeneral.data.drainage === '') {
      return
    }
    const fieldName = formPropsGeneral.data.drainage ? 'drainageType' : 'nearestDrainageKM'
    const formProps = fieldName === 'drainageType' ? formPropsMulti : formPropsGeneral
    return (
      <TextField
        value={formProps.data[fieldName]}
        error={Boolean(formProps.errors[fieldName])}
        helperText={formProps.errors[fieldName] || ' '}
        onChange={formProps.handleChange(fieldName)}
        onBlur={formProps.handleBlur(fieldName)}
        sx={styles.inputs[fieldName]}
        label={t(`investObjects.labels.${fieldName}`)}
      />
    )
  }

  return (
    <>
      <AppRadioGroup
        value={formPropsGeneral.data.powerSupply}
        onChange={handlePowerSupplyChange}
        onBlur={formPropsGeneral.handleBlur('powerSupply')}
        error={Boolean(formPropsGeneral.errors.powerSupply)}
        helperText={formPropsGeneral.errors.powerSupply || ' '}
        sx={styles.inputs.powerSupply}
        options={connectedNotConnectedOptions}
        label={t('investObjects.labels.powerSupplyRequired')}
        row
      />
      {powerTextField()}
      <AppRadioGroup
        value={formPropsGeneral.data.gasSupply}
        onChange={handleGasSupplyChange}
        error={Boolean(formPropsGeneral.errors.gasSupply)}
        helperText={formPropsGeneral.errors.gasSupply || ' '}
        sx={styles.inputs.gasSupply}
        options={connectedNotConnectedOptions}
        label={t('investObjects.labels.gasSupplyRequired')}
        row
      />
      {formPropsGeneral.data.gasSupply !== '' && !formPropsGeneral.data.gasSupply && (
        <TextField
          value={formPropsGeneral.data.nearestGasSupplyKM}
          error={Boolean(formPropsGeneral.errors.nearestGasSupplyKM)}
          helperText={formPropsGeneral.errors.nearestGasSupplyKM || ' '}
          onChange={formPropsGeneral.handleChange('nearestGasSupplyKM')}
          onBlur={formPropsGeneral.handleBlur('nearestGasSupplyKM')}
          sx={styles.inputs.nearestGasSupplyKM}
          label={t('investObjects.labels.nearestGasSupplyKM')}
        />
      )}
      <AppRadioGroup
        value={formPropsGeneral.data.waterSupply}
        onChange={handleWaterSupplyChange}
        error={Boolean(formPropsGeneral.errors.waterSupply)}
        helperText={formPropsGeneral.errors.waterSupply || ' '}
        sx={styles.inputs.waterSupply}
        options={connectedNotConnectedOptions}
        label={t('investObjects.labels.waterSupplyRequired')}
        row
      />
      {waterTextField()}
      <AppRadioGroup
        value={formPropsGeneral.data.drainage}
        onChange={handleDrainageChange}
        error={Boolean(formPropsGeneral.errors.drainage)}
        helperText={formPropsGeneral.errors.drainage || ' '}
        sx={styles.inputs.drainage}
        options={connectedNotConnectedOptions}
        label={t('investObjects.labels.drainageRequired')}
        row
      />
      {drainageTextField()}
    </>
  )
}

CommunicationsSection.propTypes = CommunicationsSectionProps

export default CommunicationsSection
