import i18next from 'i18next'

export const requiredField = (value) => {
  const isInvalid = !value || (typeof value !== 'boolean' && !(value?.trim?.() ?? value).length)
  if (isInvalid) {
    return i18next.t('common.errorMessages.emptyField')
  }
}

export const requiredFile = (value) => {
  if (!value?.id) {
    return i18next.t('common.errorMessages.emptyField')
  }
}

export const minMaxLength = (min, max) => (value) => {
  if (value?.length < min) {
    return i18next.t('common.errorMessages.shortText', { min })
  }
  if (value?.length > max) {
    return i18next.t('common.errorMessages.longText', { max })
  }
}

export const maxLength = (max) => (value) => {
  if (!value) return
  if (value.length > max) {
    return i18next.t('common.errorMessages.longText', { max })
  }
}

const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const phonePattern = /^\+?3?8?(0[5-9][0-9]\d{7})$/

export const ukrainianPhoneNumber = (phone) => {
  if (phone === '') {
    return ''
  }
  // Remove (, ), -, symbols from string
  const filteredNumber = phone
    .split('')
    .filter((item) => !['(', ')', '-'].includes(item))
    .join('')
  // Validate
  const valid = RegExp(phonePattern).test(filteredNumber)

  return valid ? '' : i18next.t('common.errorMessages.phoneNumber')
}

export const email = (value) => {
  if (value === '') {
    return ''
  }

  const valid = RegExp(emailPattern).test(value)

  return valid ? '' : i18next.t('common.errorMessages.email')
}

export const validRegExp =
  ({ regexp, errorMsg }) =>
  (value) => {
    const valid = RegExp(regexp).test(value)
    return valid ? undefined : i18next.t(errorMsg)
  }

export const noSpacesInString = (value) => {
  const valid = RegExp(/^\S*$/).test(value)
  return valid ? '' : i18next.t('common.errorMessages.noSpaces')
}

export const validatePassword = (password) => {
  const isLowerCaseLetters = RegExp(/[a-z]/g).test(password)
  const isUpperCaseLetters = RegExp(/[A-Z]/g).test(password)
  const isNumbers = RegExp(/[0-9]/g).test(password)
  const isSpecialCharacters = RegExp(/[!@#$%^&*]/g).test(password)

  if (password.length < 12) {
    return i18next.t('common.errorMessages.shortText', { min: 12 })
  }

  if (password.length > 25) {
    return i18next.t('common.errorMessages.longText', { max: 25 })
  }

  if (!isLowerCaseLetters) {
    return i18next.t('common.errorMessages.lowerCase')
  }

  if (!isUpperCaseLetters) {
    return i18next.t('common.errorMessages.upperCase')
  }

  if (!isNumbers) {
    return i18next.t('common.errorMessages.numbers')
  }

  if (!isSpecialCharacters) {
    return i18next.t('common.errorMessages.specialCharacters')
  }
}
