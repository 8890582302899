import { createContext, useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'

export const AppAdminErrorContext = createContext({})

export const AppAdminErrorProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [error, setError] = useState(null)

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
    }
  }, [enqueueSnackbar, error])

  const clearError = () => {
    setError(null)
  }
  return <AppAdminErrorContext.Provider value={{ error, setError, clearError }}>{children}</AppAdminErrorContext.Provider>
}

export const useAppErrorContext = () => {
  return useContext(AppAdminErrorContext)
}
