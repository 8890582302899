import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'
import { buildURL } from '@/utils/url-builder'

export const TagsService = {
  getTags: () => {
    return request.get(URLs.admin.tags.index)
  },
  createTag: (tag) => {
    return request.post(URLs.admin.tags.index, { title: { ...tag } })
  },
  isUsedTag: (id) => {
    return request.get(buildURL(URLs.admin.tags.usedById, { id }))
  },
  editTag: (id, tag) => {
    return request.put(buildURL(URLs.admin.tags.putById, { id }), { title: { ...tag } })
  },
  deleteTag: (id) => {
    return request.delete(buildURL(URLs.admin.tags.deleteById, { id }))
  }
}
