import i18next from 'i18next'
import { adminRoutes } from '@/plugins/router/admin-router/routes'

const emptyValue = ({ value }) => {
  return value ? value : '-'
}

const linkMap = {
  newsId: adminRoutes.editNews.path,
  ioId: adminRoutes.editInvestObject.path,
  supportProgramId: adminRoutes.editSupportProgram.path,
  eventId: adminRoutes.editEvent.path,
  tagId: adminRoutes.tags.path
}

export const changelogTableColumns = [
  {
    headerName: i18next.t('changelog.table.userName'),
    field: 'email',
    valueFormatter: emptyValue,
    sortable: true
  },
  { headerName: i18next.t('changelog.table.date'), field: 'createdAt', type: 'dateTime' },
  { headerName: i18next.t('changelog.table.section'), field: 'section', valueFormatter: emptyValue, sortable: false },
  {
    headerName: i18next.t('changelog.table.type'),
    field: 'changeType',
    valueFormatter: ({ value }) => {
      return i18next.t(`changelog.changeType.${value}`)
    },
    sortable: true
  },
  { headerName: i18next.t('changelog.table.title'), field: 'title', valueFormatter: emptyValue, sortable: true },
  {
    headerName: i18next.t('changelog.table.webPage'),
    sortable: false,
    field: 'urlParams',
    renderCell: ({ value }) => {
      // value object has one of linkMap key, for example { newsId: 221 }
      const partitionKey = Object.keys(value)[0]
      const pageLink = `${window.location.origin}${linkMap[partitionKey]}/${value[partitionKey]}`

      return partitionKey ? (
        <a
          href={pageLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          {pageLink}
        </a>
      ) : (
        ''
      )
    }
  }
]
export const changelogTableSettings = { checkboxSelection: false }
