import PropTypes from 'prop-types'

import AppCardItem from '@/components/app-card-item'

import mockImage from '@/assets/images/news-mock-img.png'
import SupportProgramItemBody from '@/containers/support-program-banner/support-program-item-body'

const componentProps = {
  item: PropTypes.object,
  vertical: PropTypes.bool,
  loading: PropTypes.bool,
  url: PropTypes.string
}

const SupportProgramItem = ({ vertical, item = {}, url, loading, textColor }) => {
  return (
    <AppCardItem
      linkUrl={url}
      image={{ src: item.featureImage ? item.featureImage : mockImage, alt: item.title }}
      title={item.title}
      body={item.text}
      vertical={vertical}
      loading={loading}
    >
      <SupportProgramItemBody item={item} loading={loading} textColor={textColor} />
    </AppCardItem>
  )
}

SupportProgramItem.propTypes = componentProps

export default SupportProgramItem
