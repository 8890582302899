import PropTypes from 'prop-types'

const SubmitFormProps = {
  onFormSubmit: PropTypes.func,
}

const AppForm = (props) => {
  // iOS needs an "action" attribute for nice input: https://stackoverflow.com/a/39485162/406725
  // We default the action to "#" in case the preventDefault fails (just updates the URL hash)
  const { action, onFormSubmit, ...rest } = props
  const _action = action ?? '#'
  const handleSubmit = (e) => {
    e.preventDefault()
    onFormSubmit(e.nativeEvent?.submitter?.value)
  }
  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      action={_action}
      style={{ display: 'contents' }}
      {...rest}
    />
  )
}

AppForm.propTypes = SubmitFormProps


export default AppForm
