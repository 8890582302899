export const styles = {
  tab: {
    fontSize: '24px',
    lineHeight: '29px',
    textTransform: 'capitalize',
    fontWeight: 400,
    '&.Mui-selected.MuiTab-root': {
      fontWeight: 600,
      textDecorationLine: 'underline',
      color: (theme) => theme.palette.text.primary
    },
    '&.Mui-disabled': {
      color: (theme) => theme.palette.grey.lighter
    },
    color: (theme) => theme.palette.text.secondary
  },

  leftButton: {
    '&.left': {
      transition: 'all .3s',
      transform: 'rotate(180deg)',
      zIndex: 10,
      height: '100%',
      backgroundColor: '#FFF',
      '&:hover': {
        backgroundColor: '#F7F7F7'
      },
      '&.disabled': {
        marginLeft: '-50px',
        zIndex: 0,
        opacity: 0
      }
    }
  }
}
