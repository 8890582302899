import { TextField } from '@mui/material'
import { IMaskMixin } from 'react-imask'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const AppMaskInputProps = {
  mask: PropTypes.any
}

const IMaskPhoneInput = IMaskMixin(({ inputRef, ...props }) => {
  const { t } = useTranslation()
  
  return (
    <TextField
      {...props}
      label={t(props.label)}
      inputRef={inputRef}
    />
  )
})

const AppMaskInput = ({ mask, onChange, onBlur, ...props }) => {
  return (
    <IMaskPhoneInput
      mask={mask}
      onBlur={onBlur}
      onAccept={(value) => onChange({ customType: 'mask', value })}
      {...props}
    />
  )
}

AppMaskInput.propTypes = AppMaskInputProps

export default AppMaskInput
