import { useCallback, useState } from 'react'
import useBlocker from '@/hooks/use-blocker'
import { useTranslation } from 'react-i18next'
import { useConfirm } from '@/hooks/use-confirm'

const useLeaveConfirm = (message) => {
  const [needConfirm, setNeedConfirm] = useState(false)
  const { confirm } = useConfirm()
  const { t } = useTranslation()
  const leaveMessage = message || t('dialog.leave_message')

  const blocker = useCallback(
    async (tx) => {
      if (tx.location.state === skipConfirmationNavState){
        tx.retry()
        return
      }
      if (await confirm(leaveMessage)) {
        tx.retry()
      }
    },
    [confirm, leaveMessage]
  )

  useBlocker(blocker, needConfirm)

  return { setNeedConfirm }
}

export const skipConfirmationNavState = 'skipConfirmationNavState'

export default useLeaveConfirm
