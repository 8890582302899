import { createTheme, responsiveFontSizes } from '@mui/material'
import typography from '@/plugins/material-ui/typography'
import palette from '@/plugins/material-ui/palette'
import button from '@/plugins/material-ui/styles-override/button'
import { scrollbar } from '@/plugins/material-ui/styles-override/scrollbar'
import chip from '@/plugins/material-ui/styles-override/chip'
import link from '@/plugins/material-ui/styles-override/link'

export const theme = responsiveFontSizes(
  createTheme({
    typography,
    palette,
    components: {
      MuiButton: button,
      MuiCssBaseline: { ...scrollbar },
      MuiChip: chip,
      MuiLink: link
    }
  })
)
