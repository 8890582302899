import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, IconButton, TextField, Zoom } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { searchbarTransitionDuration } from './constants'
import { minMaxLength } from '@/utils/validations/common'
import { useSnackbar } from 'notistack'
import searchIcon from '@/assets/icons/search.svg'

const SearchbarProps = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool
}

const iconButtonStyles = {
  p: '4px',
  mx: '4px',
  mr: '8px'
}

const Searchbar = ({
  open,
  setOpen,
  autoFocus = false,
  fullWidth = false,
  bigSize = false,
  searchTerm: initialSearch = ''
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [value, setValue] = React.useState('')

  useEffect(() => {
    setValue(initialSearch)
  }, [initialSearch])

  const inputRef = React.useRef(null)
  const formRef = React.useRef(null)

  React.useEffect(() => {
    const target = formRef.current
    const handler = (ev) => {
      if (!formRef.current.contains(ev.target)) {
        setOpen(false)
      }
    }

    if (target) {
      if (open) {
        document.documentElement.addEventListener('click', handler)
      }
    }
    return () => {
      document.documentElement.removeEventListener('click', handler)
    }
  }, [open, setOpen])

  const isInputEmpty = !value.length

  const clearInput = () => {
    setTimeout(() => {
      setValue('')
      setOpen(false)
      inputRef.current.blur()
    }, 0)
  }
  const sx = fullWidth ? { width: '100%', flex: 1, justifyContent: 'stretch' } : {}
  return (
    <Box
      onClick={() => setOpen(true)}
      ref={formRef}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        alignItems: 'center',
        ...sx
      }}
      component='form'
      onSubmit={(ev) => {
        let searchTerm = ev.target[0].value
        ev.preventDefault()
        const error = minMaxLength(4, 100)(searchTerm)
        if (error) {
          return enqueueSnackbar(error, { variant: 'info' })
        }
        if (!bigSize) {
          clearInput()
        }
        navigate(`/search-results?q=${searchTerm}`)
      }}
    >
      <TextField
        inputRef={inputRef}
        fullWidth
        autoFocus={autoFocus}
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        placeholder={t('clientNavigation.searchbar.placeholder')}
        sx={{
          maxWidth: fullWidth ? '100%' : open ? '575px' : '150px',
          transition: `max-width ${searchbarTransitionDuration}ms ease`,
          '& .MuiOutlinedInput-root': {
            borderRadius: '30px',
            height: bigSize ? '56px' : '46px',
            pr: '65px',
            fontSize: bigSize ? '16px' : '14px',
            transition: 'width 1s ease'
          },
          '& .MuiOutlinedInput-input': {
            pl: '20px',
            textOverflow: 'ellipsis'
          }
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '4px',
          transform: 'translateY(-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Zoom in={!isInputEmpty}>
          <Box sx={{ borderRight: 'solid 1px', borderRightColor: 'grey.main', maxHeight: '30px' }}>
            <IconButton
              title={t('clientNavigation.searchbar.clearInput')}
              sx={iconButtonStyles}
              onClick={clearInput}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Zoom>
        <IconButton
          title={t('clientNavigation.searchbar.submitButtonTitle')}
          type='submit'
          sx={iconButtonStyles}
          disabled={isInputEmpty}
        >
          <img
            src={searchIcon}
            width={24}
            height={24}
            alt='search'
          />
        </IconButton>
      </Box>
    </Box>
  )
}

Searchbar.propTypes = SearchbarProps

export default Searchbar
