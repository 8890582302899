import { Link, Typography } from '@mui/material'
import { theme } from '@/plugins/material-ui/theme'
import { NavigateBefore } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const AppButtonGoBackToProps = {
  to: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.object
}

const AppButtonGoBackTo = ({ to, label, sx }) => {
  const navigate = useNavigate()

  return (
    <Link
      component='button'
      underline='none'
      onClick={() => navigate(to)}
      sx={{
        display: 'flex',
        gap: theme.spacing(0),
        alignItems: 'center',
        alignSelf: 'flex-start',
        px: 0,
        ...sx
      }}
    >
      <NavigateBefore />
      <Typography variant='subtitle1'>{label}</Typography>
    </Link>
  )
}
AppButtonGoBackTo.propTypes = AppButtonGoBackToProps

export default AppButtonGoBackTo
