import React from 'react'
import PropTypes from 'prop-types'
import { MenuItemWithoutSubItemsType, MenuItemWithSubItemsType } from './types'
import { Button, lighten, Collapse } from '@mui/material'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import { Link } from 'react-router-dom'
import { responsiveGapV2 } from '@/pages/home-page/HomePage.styles'

const mobileButtonStyles = {
  color: 'text.primary',
  '&:hover, &:focus': {
    backgroundColor: 'grey.light'
  },
  px: responsiveGapV2,
  display: 'flex',
  justifyContent: 'space-between',
  '& .MuiSvgIcon-root': {
    fontSize: '28px'
  }
}

const subItemStyles = {
  borderRadius: 0,
  lineHeight: '20px',
  padding: '0px',
  my: '15px',
  ml: responsiveGapV2,
  '&.MuiButton-text': {
    padding: '3px 5px'
  },
  '&:focus': {
    backgroundColor: 'transparent'
  },
  '&, &:hover, &:focus-visible': {
    backgroundColor: (theme) => lighten(theme.palette.primary.light, 0.7)
  }
}

const MobileNavMenuItemProps = {
  menuItem: PropTypes.oneOfType([
    PropTypes.shape(MenuItemWithSubItemsType).isRequired,
    PropTypes.shape(MenuItemWithoutSubItemsType).isRequired
  ]).isRequired
}

const MobileNavMenuItem = ({ menuItem: { label, subItems }, toggleMenu }) => {
  const { t } = useTranslation()

  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return subItems ? (
    <>
      <Button
        disableRipple
        onClick={handleClick}
        id={`${label}-button`}
        variant='text'
        sx={mobileButtonStyles}
        disableElevation
        endIcon={open ? <KeyboardArrowUpIcon color='primary' /> : <KeyboardArrowDownIcon color='primary' />}
      >
        {t(`clientNavigation.menuItems.${label}`)}
      </Button>

      <Collapse
        in={open}
        timeout='auto'
        unmountOnExit
        sx={{
          '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            px: 2
          }
        }}
      >
        {subItems.map((subItem) => {
          return (
            <Button
              key={subItem.label}
              to={clientRoutes[subItem.label].route}
              end={'true'}
              component={Link}
              onClick={() => {
                toggleMenu()
                handleClick()
              }}
              sx={{ ...mobileButtonStyles, ...subItemStyles }}
            >
              {t(`clientNavigation.menuItems.${subItem.label}`)}
            </Button>
          )
        })}
      </Collapse>
    </>
  ) : (
    <Button
      disableRipple
      to={clientRoutes[label].route}
      end={'true'}
      component={Link}
      onClick={toggleMenu}
      id={`${label}-button`}
      sx={mobileButtonStyles}
      variant='text'
      disableElevation
    >
      {t(`clientNavigation.menuItems.${label}`)}
    </Button>
  )
}

MobileNavMenuItem.propTypes = MobileNavMenuItemProps

export default MobileNavMenuItem
