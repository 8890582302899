import React from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'

import AppContainerHeader from '@/components/app-container-header'

import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@mui/material'

const SimilarObjectBanner = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onHeaderButtonClick = () => {
    navigate('/submit-object')
  }

  const goToSupportProgramsButtonProps = { onClick: onHeaderButtonClick, title: t('similarObject.similarObjectButton') }

  return (
    <Box sx={{
        backgroundColor: '#373A36',
        borderRadius: '0px 4px 4px 0px',
        color: '#FFFFFF',
        py: { md: '60px', sm: '67px', xs: '37.5px' },
        px: { md: '15px', xs: '10px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center' }}
    >
      <AppContainerHeader
        sx={{ mb: { md: '20px', xs: '15px',  } }}
        title={t('homePage.similarObject')}
      />
      <Typography
        variant="h6"
        sx={{
          mb: { md: '37px', xs: '30px' },
          fontSize: { md: '1.063rem', xs: '1rem' },
          lineHeight: '20px'
        }}
      >
        {t('similarObject.similarObjectDescription')}
      </Typography>
      <Button
        sx={{ padding: '20px 10px',
            fontSize: '1.063rem',
            lineHeight: '1.25rem',
            width: '345px',
            maxWidth: { md: '239px', sm: '345px', xs: '100%' } }}
        {...goToSupportProgramsButtonProps} 
        variant="contained"
      >
        {t('similarObject.similarObjectButton')}
      </Button>

    </Box>
  )
}

export default SimilarObjectBanner
