import { Dialog } from '@mui/material'
import PropTypes from 'prop-types'

const AppPopupDialogProp = {
  content: PropTypes.node,
  open: PropTypes.bool,
  externalProps: PropTypes.object
}

const AppPopupDialog = ({ open, content, externalProps }) => {
  return (
    <Dialog
      open={open}
      {...externalProps}
    >
      {content}
    </Dialog>
  )
}

AppPopupDialog.propTypes = AppPopupDialogProp

export default AppPopupDialog
