import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import useAxios from '@/hooks/use-axios'
import { TagsService } from '@/services/tags-service'
import { Box, Button, Typography } from '@mui/material'
import AppAddAbleTag from '@/components/app-tags-list/app-add-able-tag/AppAddAbleTag'
import AdminTagsForm from '@/containers/admin-tags/admin-tags-form/AdminTagsForm'
import AppTagsList from '@/components/app-tags-list'
import { useAdminTagsForm } from '@/containers/admin-tags/admin-tags-form/hooks/use-admin-tags-form'

export const AdminTagsSidebar = ({ tags, addTag, chosenLanguage = 'uk' }) => {
  const { t } = useTranslation()

  const [tagsList, setTagsList] = useState([])
  const { fetchData: getTagsRequest, loading: getTagsLoading } = useAxios({ service: TagsService.getTags })
  const { fetchData: createTagRequest, loading: createTagLoading } = useAxios({ service: TagsService.createTag })

  const { data, clearForm, handleValidate, formProps } = useAdminTagsForm(tagsList)

  const handleTagCreate = async () => {
    const isValid = handleValidate()
    if (isValid) {
      const response = await createTagRequest({
        uk: data.uk.trim(),
        en: data.en.trim()
      })

      if (response) {
        setTagsList((prevState) => [...prevState, response])
        handleAddTag(response)
        clearForm()
      }
    }
  }

  const handleAddTag = useCallback(
    (tag) => {
      if (tags.length < 20) {
        addTag(tag)
      }
    },
    [addTag, tags.length]
  )

  useEffect(() => {
    const setData = async () => {
      const response = await getTagsRequest()
      setTagsList(response)
    }
    setData().catch()
  }, [getTagsRequest])

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        flexBasis: '30%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        variant={'h5'}
        sx={{ marginBottom: '12px', px: '12px', pt: '12px' }}
      >
        {t('tags.tags')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '12px', px: '12px' }}>
        <AdminTagsForm formProps={formProps} />
        <Button
          variant={'contained'}
          disabled={createTagLoading}
          onClick={handleTagCreate}
        >
          {t('tags.createTag')}
        </Button>
      </Box>
      <AppTagsList
        tagsList={tagsList}
        loading={getTagsLoading}
        excludedTagsList={tags}
        chosenLanguage={chosenLanguage}
        getTagItemComponent={AppAddAbleTag.bind(undefined, handleAddTag)}
      />
    </Box>
  )
}

export default AdminTagsSidebar
