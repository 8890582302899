import goToArrows from '@/assets/icons/go-to-arrows.svg'

import { Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const componentProps = { title: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired }

const AppButtonGoTo = ({ title, onClick, hideTitle = false, sx, className, color }) => {
  return (
    <Button
      sx={{ textTransform: 'none', py: { sm: '2px', xs: 0 }, px: { sm: '7px', xs: 0 }, ...sx }}
      color={'accent'}
      variant={'text'}
      onClick={onClick}
      className={className}
    >
      {!hideTitle && (
        <Typography
          sx={{ mr: '16px' }}
          variant={'h4'}
          color={color}
        >
          {title}
        </Typography>
      )}
      <img
        alt={'Go to'}
        src={goToArrows}
        height={20}
      />
    </Button>
  )
}

AppButtonGoTo.propType = componentProps

export default AppButtonGoTo
