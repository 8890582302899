import common from './common.json'
import login from './login.json'
import adminNavigation from './adminNavigation.json'
import tags from './tags.json'
import news from './news.json'
import supportPrograms from './support-programs.json'
import forms from './forms.json'
import dialog from './dialog-popup.json'
import homePage from './home-page.json'
import events from './events.json'
import clientNavigation from './client-navigation'
import investObjects from './invest-object.json'
import investObjectsDetails from './invest-object-details.json'
import businessOffers from './business-offers.json'
import startBusiness from './start-business.json'
import similarObject from './similar-object.json'
import newsPage from './news-page.json'
import globalSearchPage from './global-search-page.json'
import users from './users.json'
import profile from './profile.json'
import changePassword from './change-password.json'
import contactForm from './contact-form.json'
import error from './error.json'
import footer from './footer.json'
import exportConsulting from './export-consulting.json'
import adminCommunicationForms from './admin-communication-forms.json'
import investObjectMap from './invest-object-map.json'
import sorting from './sorting.json'
import helpBusiness from './help-business.json'
import changelog from './changelog.json'
import modalForm from './modal-form.json'
import partners from './partners.json'
import aboutUsPage from './about-us-page.json'

const en = {
  translations: {
    aboutUsPage,
    common,
    login,
    adminNavigation,
    news,
    events,
    tags,
    dialog,
    supportPrograms,
    forms,
    investObjectMap,
    error,
    clientNavigation,
    homePage,
    investObjects,
    investObjectsDetails,
    businessOffers,
    startBusiness,
    similarObject,
    newsPage,
    globalSearchPage,
    users,
    profile,
    changePassword,
    contactForm,
    footer,
    exportConsulting,
    sorting,
    adminCommunicationForms,
    helpBusiness,
    changelog,
    modalForm,
    partners
  }
}

export default en
