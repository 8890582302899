import useForm from '@/hooks/use-form'
import { useCallback } from 'react'
import AppForm from '@/components/app-form'
import { Box } from '@mui/material'
import AppInputs from '@/components/app-form/app-inputs'
import AppConfirm from '@/components/app-confirm'

const AppFormConfirm = ({ inputs, text, initialData, submit, closeModal }) => {
  const { data, formProps, clearForm, isDirty } = useForm({ initialValues: initialData, validations: [], inputs })

  const handleConfirm = useCallback(
    async (confirm) => {
      if (!confirm) {
        closeModal()
        return
      }

      await submit(data)
      closeModal()
      clearForm()
    },
    [clearForm, closeModal, data, submit]
  )

  const content = (
    <AppForm onFormSubmit={() => handleConfirm(true)}>
      <Box sx={{ display: 'flex', pt: '16px', gap: '16px', flexGrow: 1, overflow: 'auto' }}>
        <AppInputs
          inputs={inputs}
          {...formProps}
        />
      </Box>
    </AppForm>
  )

  return (
    <AppConfirm
      text={text}
      content={content}
      handleConfirm={handleConfirm}
      confirmDisabled={!isDirty}
    />
  )
}

export default AppFormConfirm
