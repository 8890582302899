import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Box, Divider } from '@mui/material'

import AppContainerHeader from '@/components/app-container-header'

import useBreakpoints from '@/hooks/use-breakpoints'
import AppButtonGoTo from '@/components/app-button-go-to'
import { useNavigate } from 'react-router-dom'
import { baseContainerSX } from '@/containers/constants'
import EventItem from '@/containers/recent-events-banner/event-item'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import { responsiveGap, style } from '@/pages/home-page/HomePage.styles'
import { generateSkeletonItems } from '@/utils/skeleton'

const componentProps = {
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
}

const RecentEventsBanner = ({ items, loading }) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const { isDesktop, isMobile } = useBreakpoints()

  const onHeaderButtonClick = () => {
    navigate('/event-calendar')
  }

  const bannerItems = useMemo(() => {
    const responsiveItems = loading ? generateSkeletonItems(3) : isDesktop ? items : items.slice(0, 2)

    return responsiveItems.map((item, index) => (
      <Fragment key={item.id}>
        <EventItem
          vertical={isMobile}
          url={`${clientRoutes.event.path}${item.id}`}
          item={item}
          loading={loading}
        />
        {responsiveItems.length - 1 !== index && <Divider />}
      </Fragment>
    ))
  }, [loading, isDesktop, items, isMobile])

  const goToEventsPage = { onClick: onHeaderButtonClick, title: i18n.t('homePage.allEvents') }

  return (
    <Box
      sx={{
        ...style.fullWidthContainer
      }}
    >
      <Box
        sx={{
          ...baseContainerSX,
          ...style.section
        }}
        component='section'
      >
        <AppContainerHeader
          sx={{ my: responsiveGap }}
          title={i18n.t('homePage.recentEvents')}
          button={goToEventsPage}
          showButton={!isMobile}
        />
        <Box sx={{ display: 'grid', columnGap: '30px', rowGap: '30px', gridTemplateColumns: '100%' }}>
          {bannerItems}
        </Box>
        {isMobile && (
          <Box sx={{ ...style.appToGo }}>
            <AppButtonGoTo {...goToEventsPage} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

RecentEventsBanner.propTypes = componentProps

export default RecentEventsBanner
