import PropTypes from 'prop-types'
import AppGlobalSearchItem from '@/components/app-global-search-item'

import GlobalSearchItemBody from '@/containers/global-search/global-search-item-body'

const componentProps = {
  item: PropTypes.object,
  loading: PropTypes.bool,
  onClick: PropTypes.func
}

const GlobalSearchItem = ({ item = {}, onClick, loading }) => {
  return (
    <AppGlobalSearchItem
      onClick={onClick}
    >
      <GlobalSearchItemBody item={item} loading={loading} />
    </AppGlobalSearchItem>
  )
}

GlobalSearchItem.propTypes = componentProps

export default GlobalSearchItem
