import { Skeleton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { truncateTextByLines } from '@/utils/common'
import { toShortDate, toTime } from '@/utils/date'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import AppTextWithIcon from '@/components/app-text-with-icon'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import useBreakpoints from '@/hooks/use-breakpoints'
import AppDotDivider from '@/components/app-dot-divider'

const EventItemBody = ({ item, isHovered, loading }) => {
  const { isMobile, isTablet } = useBreakpoints()
  const getFullDate = () => {
    let eventStartDate = ''
    let eventEndDate = ''

    if (item.startDate) {
      eventStartDate = toShortDate(item.startDate)
    }
    if (item.endDate) {
      eventEndDate = toShortDate(item.endDate)
    }

    return eventStartDate.concat(eventEndDate ? ` - ${eventEndDate}` : '')
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        data-testid={'title'}
        sx={(theme) => ({ mb: theme.spacing(3), ...truncateTextByLines(isMobile ? 5 : 1) })}
        variant={'h4'}
        color={isHovered && 'primary'}
      >
        {loading ? <Skeleton /> : item.title}
      </Typography>
      <Box sx={{ display: 'flex', mb: '15px', flexWrap: { xs: 'wrap', sm: 'no-wrap' }, gap: '15px' }}>
        <AppTextWithIcon
          text={{ value: getFullDate(), variant: 'body2', color: '#373a36' }}
          icon={<CalendarTodayIcon sx={(theme) => ({ color: theme.palette.primary.main })} />}
          loading={loading}
        />
        {isMobile ? null : <AppDotDivider />}
        <AppTextWithIcon
          text={{ value: toTime(item.startDate), variant: 'body2', color: '#373a36' }}
          icon={<AccessTimeIcon sx={(theme) => ({ color: theme.palette.primary.main })} />}
          loading={loading}
        />
        {isMobile ? null : <AppDotDivider />}
        <AppTextWithIcon
          text={{ value: item.location, variant: 'body2', color: '#373a36' }}
          icon={<LocationOnIcon sx={(theme) => ({ color: theme.palette.primary.main })} />}
          loading={loading}
        />
      </Box>
      {!isMobile && (
        <Typography
          data-testid={'text'}
          component='div'
          sx={(theme) => ({
            mb: theme.spacing(3),
            mr: theme.spacing(15),
            ...truncateTextByLines(isTablet ? 3 : 6)
          })}
          color='textSecondary'
          variant={'body1'}
        >
          {loading ? <Skeleton /> : item.text}
        </Typography>
      )}
    </Box>
  )
}

export default EventItemBody
