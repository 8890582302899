export const defaultTagsNames = [
  { uk: 'Програми кредитування', en: 'Loan Programs' },
  { uk: 'Гранти', en: 'Grants' },
  { uk: 'Безповоротна підтримка', en: 'Irrevocable Support' },
  { uk: 'Державні програми', en: 'State Programs' }
]

export const getDefaultTags = (lang) => {
  return defaultTagsNames.map((tag) => tag[lang])
}

export const defaultTagsSet = new Set(defaultTagsNames.flatMap((t) => Object.values(t)))
