import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import PropTypes from 'prop-types'
import AppButtonGoTo from '@/components/app-button-go-to'

const componentProps = {
  title: PropTypes.string.isRequired,
  button: PropTypes.shape({ title: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired }).isRequired,
  sx: PropTypes.object,
  showButton: PropTypes.bool
}

const AppContainerHeader = ({ title, button, sx, showButton }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...sx }}>
      <Typography variant={'h1'}>{title}</Typography>
      {showButton && <AppButtonGoTo {...button} />}
    </Box>
  )
}

AppContainerHeader.propType = componentProps

export default AppContainerHeader
