export const styles = {
  container: {
    maxWidth: '245px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: { md: '158px', xs: '120px' },
    height: { md: '158px', xs: '120px' },
    borderRadius: '50%',
    objectFit: 'cover',
    objectPosition: 'center',
    mb: { md: '24px', sm: '15px', xs: '10px' },
    filter: 'grayscale(90%)'
  },
  iconButton: {
    position: 'absolute',
    left: { md: '110px', xs: '85px' },
    bottom: { md: '25px', xs: '10px' },
    p: 0
  }
}
