import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const AppFormHeaderProps = {
  text: PropTypes.string,
  variant: PropTypes.string,
  sx: PropTypes.object
}

const AppFormHeader = ({ text, variant, sx = {} }) => {
  return (
    <Typography
      variant={variant}
      sx={{ margin: '12px', ...sx }}
    >
      {text}
    </Typography>
  )
}

AppFormHeader.propTypes = AppFormHeaderProps

export default AppFormHeader
