import { styles } from '@/containers/admin-news/admin-add-news-form/admin-news-form-styles'
import { FormGroup } from '@mui/material'
import AppInputs from '@/components/app-form/app-inputs'
import AdminAttachedTags from '@/containers/admin-tags/admin-attached-tags/AdminAttachedTags'

const AddNewsForm = ({
  lang,
  tags,
  onTagDelete,
  multiInputs,
  formPropsMulti,
  generalInputs,
  formPropsGeneral
}) => {
  return (
    <>
      <AdminAttachedTags
        tags={tags}
        onTagDelete={onTagDelete}
        lang={lang}
      />
      <FormGroup sx={{ flexGrow: 1, ...styles.form }}>
        <AppInputs
          inputs={generalInputs}
          {...formPropsGeneral}
        />
        <AppInputs
          inputs={multiInputs}
          {...formPropsMulti}
        />
      </FormGroup>
    </>
  )
}

export default AddNewsForm
