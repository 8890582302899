import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

import { cloneElement, useState } from 'react'

const componentProps = {
  onClick: PropTypes.func,
  children: PropTypes.node
}

const AppGlobalSearchItem = ({ onClick, children }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Box
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        cursor: 'pointer',
        width: '100%',
        border: '1px solid #C4C4C2',
        borderRadius: '4px',
        padding: '20px',
        transition: 'all .5s ease',
        ':hover': { color: 'basic.white', backgroundColor: 'secondary.dark' }
      }}
    >
      {cloneElement(children, { isHovered })}
    </Box>
  )
}

AppGlobalSearchItem.propType = componentProps

export default AppGlobalSearchItem
