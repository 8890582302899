import { useCallback, useState } from 'react'

export const useTabs = ({ tabs, onTabChange = () => null }) => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = useCallback((_event, index) => {
    const tabValue = Object.values(tabs)[index].value

    onTabChange(tabValue)
    setTabIndex(index)
  }, [onTabChange, tabs])

  return { tabIndex, setTabIndex, handleTabChange }
}
