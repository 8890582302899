import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import messengerIcon from '@/assets/icons/messenger-icon.svg'
import CloseIcon from '@mui/icons-material/Close'

import telegramIcon from '@/assets/icons/telegram-colored.svg'
import messengerColored from '@/assets/icons/messenger-colored.svg'
import viberIcon from '@/assets/images/viber-icon.png'

const options = [
  { label: 'Telegram', icon: telegramIcon, link: 'https://t.me/+380688555064' },
  { label: 'Viber', icon: viberIcon, link: 'viber://chat?number=380688555064' },
  { label: 'Facebook', icon: messengerColored, link: 'https://www.facebook.com/messages/t/104449359035079' }
]

export const ContactMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ position: 'fixed', bottom: '25px', right: '21px', zIndex: 1 }}>
      <IconButton
        sx={{
          backgroundColor: open ? ({ palette }) => palette.primary.light : 'black',
          ':hover': { backgroundColor: ({ palette }) => palette.primary.light }
        }}
        style={{ boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)', transition: 'all 0.2s linear' }}
        aria-label='more'
        id='contact-menu-button'
        aria-controls={open ? 'contact-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        {open ? (
          <CloseIcon
            fontSize='large'
            sx={{ color: 'white', height: '40px', width: '40px' }}
          />
        ) : (
          <img
            alt={'Contact menu'}
            src={messengerIcon}
            height={40}
          />
        )}
      </IconButton>
      <Menu
        id='contact-menu'
        MenuListProps={{
          'aria-labelledby': 'contact-menu-button'
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { transform: 'translate(0px,-5px)' }, sx: { borderRadius: '6px' } }}
      >
        {options.map((option) => (
          <MenuItem
            component='a'
            href={option.link}
            target='_blank'
            key={option.label}
            onClick={handleClose}
            sx={{ paddingRight: '46px' }}
          >
            <img
              style={{ marginRight: '16px' }}
              height={32}
              width={32}
              src={option.icon}
              alt={option.label}
            />
            <Typography variant={'body2'}>{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
