import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

import AdminPageHeader from '@/containers/admin-page-header'
import AdminUsersTable from '@/containers/admin-users/admin-users-table'
import { pageWrapperSX } from '@/pages/admin/constants'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import AppTabs from '@/components/app-tabs'
import { useTabs } from '@/hooks/use-tabs'
import { useCallback, useMemo, useState } from 'react'
import { setInitialFilter } from '@/components/app-grid/utils/app-grid-utils'
import useAxios from '@/hooks/use-axios'
import { role } from '@/plugins/router/router-guards/RoleGuard'
import { UsersService } from '@/services/users-service'

const visibleRoles = Object.keys(role).filter((e) => e !== role.SUPER_ADMIN)
const initialCounters = { ...visibleRoles.reduce((acc, key) => ({ ...acc, [key]: 0 }), {}), total: 0 }

const AdminUsersPage = () => {
  const { t } = useTranslation()
  const { fetchData } = useAxios({ service: UsersService.countPerStatus })
  const [counts, setCounts] = useState(initialCounters)

  const setCountPerStatus = useCallback(async () => {
    const res = await fetchData()
    setCounts(res)
  }, [fetchData])

  const usersTabList = useMemo(
    () => ({
      total: {
        value: 'total',
        label: t('users.all'),
        component: <AdminUsersTable onTableRefresh={setCountPerStatus} />
      },
      ...visibleRoles.reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            value: key,
            label: t(`users.role.${key}`),
            component: (
              <AdminUsersTable
                filterBy={setInitialFilter('role', key)}
                onTableRefresh={setCountPerStatus}
              />
            )
          }
        }),
        {}
      )
    }),
    [setCountPerStatus, t]
  )

  const { tabIndex, handleTabChange } = useTabs({ tabs: usersTabList })

  return (
    <Box sx={{ ...pageWrapperSX }}>
      <AdminPageHeader
        title={t('users.usersList')}
        buttonLabel={t('users.createUser')}
        buttonProps={{ component: Link, to: adminRoutes.createUser.path }}
      />
      <AppTabs
        counts={counts}
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        tabs={usersTabList}
        stayAlive={false}
      />
    </Box>
  )
}

export default AdminUsersPage
