import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Box from '@mui/material/Box'

import AdminPageHeader from '@/containers/admin-page-header'
import AppTabs from '@/components/app-tabs'
import { useMultiLang } from '@/hooks/admin-forms/use-multiLang'
import { EventsService } from '@/services/events-service'
import {
  draftEventsValidations,
  eventsFormInitialValue,
  eventsFormInputsGeneral,
  eventsMultiLangFormInputs,
  generalValidationDraft,
  generalValidationPublish,
  publishEventsValidations
} from '@/containers/admin-events/constants'
import AdminEventsForm from '@/containers/admin-events/admin-events-form/AdminEventsForm'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import useForm from '@/hooks/use-form'
import AppForm from '@/components/app-form/AppForm'
import useLeaveConfirm, { skipConfirmationNavState } from '@/hooks/use-leave-confirm'
import { mapDataFromInputs } from '@/utils/form-helper'
import { useTabs } from '@/hooks/use-tabs'
import AppEditorSubmit from '@/containers/admin-editor-submit/AppEditorSubmit'
import { useStatusChange } from '@/hooks/admin-forms/use-status-change'
import { adminEditContainerSX } from '@/containers/constants'

const AdminEventsEdit = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const { setNeedConfirm } = useLeaveConfirm()

  const {
    isDirty: generalInputsDirty,
    formProps: generalFormProps,
    handleValidate: handleGeneralValidate,
    data: generalInputData,
    setMappedData: updateGlobalData,
    setValidations: setGlobalValidation
  } = useForm({
    inputs: eventsFormInputsGeneral,
    initialValues: eventsFormInitialValue.global,
    validations: generalValidationDraft
  })

  const getFormComponent = useCallback(
    ({ lang, formProps }) => {
      return (
        <AdminEventsForm
          lang={lang}
          multiInputs={eventsMultiLangFormInputs}
          formPropsMulti={formProps}
          generalInputs={eventsFormInputsGeneral}
          formPropsGeneral={generalFormProps}
        />
      )
    },
    [generalFormProps]
  )

  const createSubmitService = useCallback(
    (data) => {
      return EventsService.createEvent({
        ...data,
        ...generalInputData.eventsRange,
        backgroundImage: generalInputData?.backgroundImage?.id ?? null
      })
    },
    [generalInputData.backgroundImage, generalInputData.eventsRange]
  )

  const editSubmitService = useCallback(
    (data) => {
      return EventsService.putById(id, {
        ...mapDataFromInputs(eventsMultiLangFormInputs, data),
        ...generalInputData.eventsRange,
        backgroundImage: generalInputData.backgroundImage?.id ?? null
      })
    },
    [generalInputData?.backgroundImage?.id, generalInputData?.eventsRange, id]
  )

  const afterSubmit = useCallback(() => {
    navigate(adminRoutes.events.path, { state: skipConfirmationNavState })
  }, [navigate])

  const updateRestData = useCallback(
    (res) => {
      if (res) {
        updateGlobalData({
          eventsRange: {
            startDate: res?.startDate,
            endDate: res?.endDate ? new Date(res?.endDate).toISOString() : null
          },
          backgroundImage: res?.backgroundImage
        })
      } else {
        updateGlobalData(eventsFormInitialValue.global)
      }
    },
    [updateGlobalData]
  )

  const setActiveTabIndex = (index) => {
    setTabIndex(index)
  }

  const { multiform, multilangTabs, handleSubmit, loading, isSaving, onLanguageChange } = useMultiLang({
    inputs: eventsMultiLangFormInputs,
    globalInputs: eventsFormInputsGeneral,
    getFormComponent,
    afterSubmit,
    setInitialData: updateRestData,
    validations: draftEventsValidations,
    publishValidation: publishEventsValidations,
    submitService: id ? editSubmitService : createSubmitService,
    initialData: eventsFormInitialValue,
    getDataService: EventsService.getById,
    additionalValidate: handleGeneralValidate,
    setActiveTabIndex,
    fallbackUrl: adminRoutes.events.path
  })
  const { tabIndex, handleTabChange, setTabIndex } = useTabs({ tabs: multilangTabs, onTabChange: onLanguageChange })

  const isDirty = useMemo(() => multiform.isDirty || generalInputsDirty, [multiform.isDirty, generalInputsDirty])

  useStatusChange({
    setValidation: setGlobalValidation,
    draftValidation: generalValidationDraft,
    publishValidation: generalValidationPublish,
    status: multiform.data[Object.keys(multiform.data)[tabIndex]].status
  })

  useEffect(() => {
    setNeedConfirm(isDirty)
  }, [isDirty, setNeedConfirm])

  if (loading) {
    return <Box>Loading...</Box>
  }

  return (
    <Box sx={{ ...adminEditContainerSX }}>
      <AdminPageHeader
        title={id ? t('events.edit') : t('events.create')}
        styles={{ minHeight: 'auto' }}
      />
      <AppForm onFormSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', gap: '16px', flexGrow: 1, overflow: 'auto' }}>
          <AppTabs
            tabIndex={tabIndex}
            handleTabChange={handleTabChange}
            tabs={multilangTabs}
          />
        </Box>
        <AppEditorSubmit
          isSaving={isSaving}
          isDirty={isDirty}
          id={id}
          afterDelete={afterSubmit}
          deleteService={EventsService.bulkDeletion}
        />
      </AppForm>
    </Box>
  )
}

export default AdminEventsEdit
