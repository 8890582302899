import i18next from 'i18next'

export const fileSizes = ['Bytes', 'KB', 'MB', 'GB']
export const fileSizeRegExp = new RegExp(/^([.0-9]+)(KB|MB|GB)$/)
export const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
export const postalIndexPattern = /^(\d{5})?$/
export const latitudeLongtitudePattern = /^(\d{2}\.\d{6})?$/
export const cadastralNumberPattern = /^$|^\d{10}:\d{2}:\d{3}:\d{4}$/
export const areaPattern = /^[\d/.]{0,10}?$/
export const realtyTotalAreaSqMPattern = /^((?=.{0,9}$)\d{1,8}(\.\d{1,4})?)?$/
export const decimalPattern = (minCharacters, maxCharacters) =>
  new RegExp('^\\d{' + minCharacters + ',' + maxCharacters + '}(\\.\\d{0,2})?$')

export const onlyDigits = (minCharacters, maxCharacters) =>
  new RegExp('^\\d{' + minCharacters + ',' + maxCharacters + '}$')

export const backgroundImageUploadLabels = {
  addLabel: i18next.t('common.labels.addBackgroundImage'),
  changeLabel: i18next.t('common.labels.changeBackgroundImage'),
  helpLabel: i18next.t('common.labels.helpBackgroundImage')
}

export const commonDocumentsFileType = ['.doc', '.docx', '.txt', '.pdf', '.xls', '.xlsx', '.ppt']
export const commonImageFileType = ['.jpeg', '.jpg', '.png']

export const yesNoOptions = [
  { value: true, label: 'common.yes' },
  { value: false, label: 'common.no' }
]
