export const styles = {
  form: {
    display: 'grid',
    gap: '16px 6px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: 'auto auto auto 1fr'
  },
  inputs: {
    title: {
      gridColumn: '1 / 5',
      gridRow: '1 / span 1',
    },
    status: {
      gridColumn: '5 / 7',
      gridRow: '1 / span 1',
    },
    location: {
      gridColumn: '1 / span 2',
      gridRow: '2 / span 1'
    },
    backgroundImage: {
      gridColumn: '1 / 4',
      gridRow: '3 / span 1'
    },
    eventsRange: {
      gridColumn: '3 / span 4',
      gridRow: '2 / span 1'
    },
    textEditor: {
      gridColumn: '1 / span 6',
      gridRow: '4 / span 1'
    }
  }
}
