export const footerMenuItems = (t) => [
  {
    key: 'supportPrograms',
    label: t('clientNavigation.menuItems.supportPrograms'),
    subItems: [
      { key: 'grants', label: t('clientNavigation.menuItems.grants'), tag: 'grants' },
      { key: 'statePrograms', label: t('clientNavigation.menuItems.statePrograms'), tag: 'statePrograms' },
      {
        key: 'irrevocableSupport',
        label: t('clientNavigation.menuItems.irrevocableSupport'),
        tag: 'irrevocableSupport'
      },
      { key: 'loanPrograms', label: t('clientNavigation.menuItems.loanPrograms'), tag: 'loanPrograms' }
    ]
  },

  {
    key: 'investmentOpportunitiesShort',
    label: t('clientNavigation.menuItems.investmentOpportunitiesShort'),
    subItems: [
      { key: 'investmentOpportunitiesMap', label: t('clientNavigation.menuItems.investmentOpportunitiesMap') },
      { key: 'submitObject', label: t('clientNavigation.menuItems.submitObject') }
    ]
  },
  {
    key: 'businessOffers',
    label: t('clientNavigation.menuItems.businessOffers'),
    subItems: [
      {
        key: 'financialPrograms',
        label: t('clientNavigation.menuItems.financialPrograms'),
        tag: 'financialPrograms'
      },
      {
        key: 'investmentObjects',
        label: t('clientNavigation.menuItems.investmentObjects'),
        tag: 'investmentObjects'
      },
      {
        key: 'businessAdministration',
        label: t('clientNavigation.menuItems.businessAdministration'),
        tag: 'businessAdministration'
      },
      {
        key: 'exportConsultations',
        label: t('clientNavigation.menuItems.exportConsultations'),
        tag: 'exportConsultations'
      }
    ]
  }
]

export const FACEBOOK = 'facebook'
export const LINKEDIN = 'linkedin'
export const TWITTER = 'twitter'
export const YOUTUBE = 'youtube'
export const INSTAGRAM = 'instagram'

export const FACEBOOK_URL = 'https://www.facebook.com/investoffice.lviv.ua'
export const LINKEDIN_URL = 'https://www.linkedin.com/company/investofficelvivua'
export const TWITTER_URL = 'https://twitter.com/office_invest'
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UC6OGQPMhfzOzFGRQF4UKT2g'
export const INSTAGRAM_URL = 'https://www.instagram.com/investoffice.lviv.ua/'

export const SOCIAL_DETAILS = {
  FACEBOOK,
  LINKEDIN,
  TWITTER,
  YOUTUBE,
  INSTAGRAM,
  FACEBOOK_URL,
  LINKEDIN_URL,
  TWITTER_URL,
  YOUTUBE_URL,
  INSTAGRAM_URL
}
