import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import AppDefaultTag from '@/components/app-tags-list/app-default-tag/AppDefaultTag'
import { defaultTagsSet } from '@/containers/admin-tags/defaultTags'

const AppTagsListProps = {
  tagList: PropTypes.array,
  excludedTagsList: PropTypes.array,
  loading: PropTypes.bool,
  getTagItemComponent: PropTypes.func,
  chosenLanguage: PropTypes.string
}

const AppTagsList = ({
  tagsList = [],
  loading,
  excludedTagsList = [],
  getTagItemComponent = AppDefaultTag,
  chosenLanguage = 'uk'
}) => {
  const tagTooltip = useMemo(() => (chosenLanguage === 'uk' ? 'en' : 'uk'), [chosenLanguage])

  const tagList = useMemo(
    () =>
      tagsList
        .filter(({ id }) => !excludedTagsList.some(({ id: tagId }) => id === tagId))
        .map((item) => {
          const disabled = defaultTagsSet.has(item.title[chosenLanguage])

          return getTagItemComponent(item, chosenLanguage, tagTooltip, disabled)
        }),
    [chosenLanguage, excludedTagsList, getTagItemComponent, tagTooltip, tagsList]
  )

  if (loading) {
    return <Box>Loading...</Box>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'auto',
        overflowX: 'hidden',
        px: '8px',
        pb: '12px'
      }}
    >
      {tagList}
    </Box>
  )
}

AppTagsList.propTypes = AppTagsListProps

export default AppTagsList
