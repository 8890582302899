import { theme } from '@/plugins/material-ui/theme'

export const styles = {
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    ml: theme.spacing(-2.5)
  }
}
