import { useRefine } from '@/containers/invest-object-list/invest-object-list-filters/context/refine-context'
import { useCallback } from 'react'

export const useFilterBy = () => {
  const { filterBy, setFilterBy } = useRefine()

  const getFilter = useCallback((filter) => findFilter(filterBy, filter), [filterBy])
  const findFilter = (filters, filter) => {
    return filters.find((e) => e.field === filter.field && e.operation === filter.operation)
  }
  const removeFilter = (filters, filter) => {
    return filters.filter((e) => !(e.operation === filter.operation && e.field === filter.field))
  }

  const clearFilter = useCallback((filter) => setFilterBy((filters) => removeFilter(filters, filter)), [setFilterBy])

  const setFilter = useCallback(
    (filter, value) => {
      return setFilterBy((filters) => {
        const foundedFilter = findFilter(filters, filter)
        if (foundedFilter) {
          return [...removeFilter(filters, filter), { ...filter, value }]
        } else {
          return [...filters, { ...filter, value }]
        }
      })
    },
    [setFilterBy]
  )

  const clearAllFilters = useCallback(() => {
    setFilterBy([])
  }, [setFilterBy])

  return { filterBy, getFilter, setFilter, clearFilter, clearAllFilters }
}
