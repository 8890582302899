import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@mui/material'

import { styles } from './styles'

import { Check } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

const InvestObjectSubmitFormProgressBar = ({ sections, progress }) => {
  const { t } = useTranslation()

  const scrollIntoView = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
    })
  }

  const sectionsList = sections.map((section) => {
      return (<ListItem sx={styles.listItem} key={section.key}>
        <ListItemIcon sx={styles.listItemIcon}>
          <Check color={ section.isValid ? 'primary' : 'textPrimary' } />
        </ListItemIcon>
        <ListItemText>
          <Link
            sx={styles.listItemTextLink}
            variant='h5'
            underline='always'
            onClick={() => scrollIntoView(section.key)}
          >
            {t(section.title.text)}
          </Link>
        </ListItemText>
      </ListItem>)
  })
  
  const progressPercents = useMemo(() => {
    return progress * 100
  }, [progress])
  
  return (<Card variant="outlined" sx={styles.container}>
    <CardContent>
      <Grid sx={{ padding: { sm: 1, xs: 0 } }}>
        <Grid container justifyContent="space-between" sx={styles.progressInfoContainer}>
          <Grid item>
            <Typography variant="subtitle1">{ t('investObjects.labels.fillProgress') }</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">{ `${Math.round(progressPercents)}%` }</Typography>
          </Grid>
        </Grid>
        <LinearProgress variant="determinate" value={progressPercents} sx={styles.progressBar} />
        <List sx={styles.list}>
          { sectionsList }
        </List>
      </Grid>
    </CardContent>
  </Card>)
}

export default InvestObjectSubmitFormProgressBar
