import React from 'react'
import Box from '@mui/material/Box'
import investLogoUA from '@/assets/images/invest-office-logo.svg'
import investLogoEn from '@/assets/images/invest-office-logo-en.svg'
import logoFooterEn from '@/assets/images/logo-footer-en.svg'
import logoFooterUa from '@/assets/images/logo-footer.svg'
import { useTranslation } from 'react-i18next'
import useBreakpoints from '@/hooks/use-breakpoints'

const logoMap = {
  en: investLogoEn,
  uk: investLogoUA
}
const logoMapSecondary = {
  en: logoFooterEn,
  uk: logoFooterUa
}

const AppLogo = ({ height, secondaryLogo }) => {
  const { t, i18n } = useTranslation()
  const { isDesktop } = useBreakpoints()

  return (
    <Box>
      <Box>
        <img
          alt={t('clientNavigation.logo.alt')}
          src={logoMap[i18n.language]}
          height={height}
        />
      </Box>
      {secondaryLogo && isDesktop && (
        <Box sx={{ pt: '1rem', pl: '5rem' }}>
          <img
            src={logoMapSecondary[i18n.language]}
            alt={t('clientNavigation.logo.alt')}
          />
        </Box>
      )}
    </Box>
  )
}

export default AppLogo
