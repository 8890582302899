import { usePagination } from '@/hooks/use-pagination'

import InfiniteScroll from 'react-infinite-scroller'
import { forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, IconButton, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ReplayIcon from '@mui/icons-material/Replay'

const componentProps = {
  noItems: PropTypes.element,
  parentRef: PropTypes.object,
  totalCount: PropTypes.number,
  fetchData: PropTypes.func,
  items: PropTypes.array,
  children: PropTypes.node,
  loader: PropTypes.node
}

const AppInfiniteScroll = forwardRef(
  ({ noItems, parentRef, totalCount, fetchData, clearItems, loader, items, children, threshold = 250 }, ref) => {
    const { t } = useTranslation()

    const { getData, hasMore, currPage, fetching, refreshData, error } = usePagination({ fetchData, items, totalCount })
    if (!loader) {
      loader = (
        <Box sx={{ maxWidth: '500px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Skeleton
            variant={'rounded'}
            width={'100%'}
            height={160}
          />
          <Skeleton variant={'rounded'} />
          <Skeleton />
        </Box>
      )
    }
    useImperativeHandle(ref, () => ({
      refreshData
    }))
    if (error) {
      return (
        <Alert
          variant='outlined'
          severity='error'
          action={
            <IconButton
              color='primary'
              onClick={refreshData}
            >
              <ReplayIcon />
            </IconButton>
          }
        >
          {t(`error.${error?.response?.data.statusCode}`)}
        </Alert>
      )
    }

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={() => getData(currPage)}
        hasMore={hasMore}
        useWindow={false}
        loader={<Box key={'loading'}>{loader}</Box>}
        getScrollParent={() => parentRef.current}
        threshold={threshold}
      >
        {!items.length && !fetching ? noItems : children}
      </InfiniteScroll>
    )
  }
)

AppInfiniteScroll.displayName = 'AppInfiniteScroll'
AppInfiniteScroll.propTypes = componentProps

export default AppInfiniteScroll
