import { Box, Divider } from '@mui/material'
import useBreakpoints from '@/hooks/use-breakpoints'
import { generateSkeletonItems } from '@/utils/skeleton'
import EventItem from '@/containers/recent-events-banner/event-item'

const EvenPageSkeleton = () => {
  const { isTablet, isDesktop } = useBreakpoints()
  return (
    <Box
      key='items'
      sx={{
        display: 'grid',
        gridTemplateColumns: isTablet ? '1fr 1fr' : '1fr',
        gap: '40px',
        mt: '40px'
      }}
    >
      {generateSkeletonItems(2).map((item) => (
        <Box key={item.id}>
          <EventItem
            vertical={!isDesktop}
            loading
          />
          {isDesktop && (
            <Divider
              variant='inset'
              sx={{ mt: '40px', mx: 0 }}
            />
          )}
        </Box>
      ))}
    </Box>
  )
}

export default EvenPageSkeleton
