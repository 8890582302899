import { OutlinedInput, Select, styled } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const getAppMenuPropsMinWidth = (min = '160px') => ({
  PaperProps: {
    sx: {
      minWidth: min,
      width: { xl: '440px', md: '400px', sm: '360px', xs: '320px' },
      maxWidth: { xl: '440px', md: '400px', sm: '360px', xs: '320px' }
    }
  }
})

const AppSelect = styled((props) => (
  <Select
    size='small'
    displayEmpty
    IconComponent={ExpandMoreIcon}
    input={<OutlinedInput />}
    {...props}
  />
))(() => ({}))

export default AppSelect
