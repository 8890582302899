import { useCallback, useState } from 'react'

export const usePagination = ({ fetchData, items, take = 6, totalCount = Infinity }) => {
  const [hasMore, setHasMore] = useState(items.length < totalCount)
  const [currPage, setCurrPage] = useState(0)
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(null)

  const getData = useCallback(
    async (page) => {
      if (fetching) {
        return
      }

      try {
        setFetching(true)
        const res = await fetchData({ take, skip: page * take })
        setHasMore(res.length === take)
      } catch (e) {
        setError(e)
        setHasMore(false)
      } finally {
        setCurrPage(page + 1)
        setFetching(false)
      }
    },
    [fetchData, fetching, take]
  )

  const refreshData = () => {
    setCurrPage(0)
    setHasMore(true)
    setError(null)
  }

  return { refreshData, getData, currPage, hasMore, fetching, error, setHasMore }
}
