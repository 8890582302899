import i18next from 'i18next'
import { email, requiredField, minMaxLength, ukrainianPhoneNumber, maxLength } from '@/utils/validations/common'

export const contactFormInitialValues = {
  name: '',
  phone: '',
  email: '',
  description: ''
}

export const getFormStyles = {
  form: {
    flexGrow: 1,
    display: 'grid',
    gap: '15px 30px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: 'auto auto auto 1fr'
  },
  name: {
    gridColumn: '1 / -1',
    gridRow: '1 / span 1',
    border: 'none',
    placeholder: 'investObjects.placeholder.previousUsage',
    input: {
      backgroundColor: '#ffffff',
    },
    fieldset: {
      border: 'none',
    },
    '.MuiFormLabel-root.Mui-focused': {
      display: 'none',
    }
  },
  phone: {
    gridColumn: { xs: '1 / -1', sm: '1 / 4' },
    gridRow: '2 / span 1',
    input: {
      backgroundColor: '#ffffff',
    },
    fieldset: {
      border: 'none',
    },
    '.MuiFormLabel-root.Mui-focused': {
      display: 'none',
    }
  },
  email: {
    gridColumn: { xs: '1 / -1', sm: '4 / span 3' },
    gridRow: { xs: '3 / span 1', sm: '2 / span 1' },
    input: {
      backgroundColor: '#ffffff',
    },
    fieldset: {
      border: 'none',
    },
  },
  description: {
    gridColumn: { xs: '1 / -1', sm: '1 / span 6' },
    gridRow: { xs: '4 / span 1', sm: '3 / span 5' },
    fieldset: {
      border: 'none',
    },

    '.MuiInputBase-formControl': {
      backgroundColor: '#ffffff',
    },
    '.MuiFormLabel-root.Mui-focused': {
      display: 'none',
    }
  },
  send: {
    width: { xs: '100%', sm: '220px' },
    gridColumn: { xs: '1 / 7', sm: ' 6 / 7', md: '1 / -1' }
  }
}

export const contactFormInputs = () => [
  {
    type: 'text',
    options: {
      name: 'name',
      placeholder: i18next.t('forms.name'),
      sx: getFormStyles.name,
      required: true
    }
  },
  {
    type: 'maskInput',
    options: {
      name: 'phone',
      placeholder: '+38(000)000-00-00',
      mask: '{+38}(000)[aaa]`-000[aaa]`-00[aa]`-00[aa]`',
      sx: getFormStyles.phone,
      required: true
    }
  },
  {
    type: 'text',
    options: {
      name: 'email',
      sx: getFormStyles.email,
      placeholder: 'E-mail'
    }
  },
  {
    type: 'text',
    options: {
      name: 'description',
      sx: getFormStyles.description,
      multiline: true,
      maxRows: 5,
      minRows: 5,
      placeholder: i18next.t('forms.contactDescription')
    }
  }
]

export const contactFormValidations = {
  name: [requiredField, minMaxLength(3, 50)],
  email: [email],
  phone: [requiredField, ukrainianPhoneNumber],
  description: [maxLength(3000)]
}
