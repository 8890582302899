export const adminRoutes = {
  login: { label: 'login', route: '/login', path: '/admin/login' },
  reset: { label: 'reset', route: '/reset', path: '/admin/reset' },
  users: { label: 'users', route: '/users', path: '/admin/users' },
  notAvailable: { label: 'notAvailable', route: '/notAvailable', path: '/admin/notAvailable' },
  profile: { label: 'profile', route: '/profile', path: '/admin/profile' },
  changePassword: {
    label: 'changePassword',
    route: '/change-password',
    path: '/admin/change-password'
  },
  tags: { label: 'tags', route: '/tags', path: '/admin/tags' },
  tagsEdit: { label: 'tagsEdit', route: '/tags/:id', path: '/admin/tags/' },
  createNews: { label: 'createNews', route: '/news/add', path: '/admin/news/add' },
  createUser: { label: 'createUser', route: '/user/add', path: '/admin/user/add' },
  editUser: { label: 'editUser', route: '/user/edit/:id', path: '/admin/user/edit' },
  editNews: { label: 'editNews', route: '/news/edit/:id', path: '/admin/news/edit' },
  news: { label: 'news', route: '/news', path: '/admin/news' },
  events: { label: 'events', route: '/events', path: '/admin/events' },
  createEvent: { label: 'createEvent', route: '/events/add', path: '/admin/events/add' },
  editEvent: { label: 'editEvent', route: '/events/edit/:id', path: '/admin/events/edit' },
  supportPrograms: { label: 'supportPrograms', route: '/support-programs', path: '/admin/support-programs' },
  createSupportPrograms: {
    label: 'createSupportPrograms',
    route: '/support-programs/add',
    path: '/admin/support-programs/add'
  },
  editSupportProgram: {
    label: 'editProgram',
    route: '/support-program/edit/:id',
    path: '/admin/support-program/edit'
  },
  investObjects: {
    label: 'investObjects',
    route: '/invest-objects',
    path: '/admin/invest-objects'
  },
  createInvestObject: {
    label: 'createInvestObject',
    route: '/invest-objects/add',
    path: '/admin/invest-objects/add'
  },
  editInvestObject: {
    label: 'editInvestObject',
    route: '/invest-objects/edit/:id',
    path: '/admin/invest-objects/edit'
  },
  communicationForms: {
    label: 'communicationForms',
    route: '/admin-communication-page',
    path: '/admin/admin-communication-page'
  },
  changelog: {
    label: 'changelog',
    route: '/changelog',
    path: '/admin/changelog'
  }
}
