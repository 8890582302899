import React, { useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import languages from '@/constants/tranlations/languages'
import { useTranslation } from 'react-i18next'

const toggleButtonGroupStyles = {
  display: 'flex',
  gap: '5px',
  justifyContent: { lg: 'center', md: 'flex-start' },
  '& .MuiToggleButtonGroup-grouped': {
    position: 'relative',
    height: '24px',
    border: 'none',
    borderRadius: 0,
    borderBottom: '1px solid transparent',
    p: 0,
    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-selected': {
      color: 'primary.main',
      borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`
    },
    '&:not(:first-of-type)': {
      borderLeft: 'none',
      ml: '10px',
      '&:before': {
        content: '""',
        width: '1px',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: (theme) => theme.spacing(-1),
        bgcolor: 'grey.main'
      }
    }
  }
}

const LanguageToggle = ({ toggleMenu }) => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(i18n.language)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [i18n, lang])

  const changeLanguage = ({ target }) => {
    setLang(target.value)
  }

  return (
    <ToggleButtonGroup
      onChange={changeLanguage}
      value={lang}
      variant='text'
      exclusive
      sx={toggleButtonGroupStyles}
    >
      {languages.map((language, i) => (
        <ToggleButton
          key={language.value}
          value={language.value}
          onClick={toggleMenu}
        >
          {language.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default LanguageToggle
