import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'
import { buildURL } from '@/utils/url-builder'

export const EventsService = {
  grid: (params) => {
    return request.post(URLs.admin.events.search, { ...params })
  },
  bulkDeletion: (params) => {
    return request.post(URLs.admin.events.bulk.delete, { ...params })
  },
  countPerStatus: () => {
    return request.get(URLs.admin.events.countPerStatus)
  },
  createEvent: (data) => {
    return request.post(URLs.admin.events.create, { ...data })
  },
  getById: (id) => {
    return request.get(buildURL(URLs.admin.events.getById, { id }))
  },
  putById: (id, data) => {
    return request.put(buildURL(URLs.admin.events.putById, { id }), data)
  },
  getEvents: (params) => {
    return request.post(URLs.events.getEvents, { ...params })
  },
  getCountByMonths: (lang) => {
    return request.get(buildURL(URLs.events.getCountByMonths, {}, { lang }))
  },
  getEventById: ({ id, query }) => {
    return request.get(buildURL(URLs.events.getEventById, { id }, query))
  }
}
