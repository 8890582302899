import i18next from 'i18next'

export const getSquareAreaLabel = (selected, min, max, unit) => {
  if (selected.from === min && selected.to === max) {
    return null
  }

  return i18next.t('forms.range.fromTo', { from: selected.from, to: selected.to, unit })
}

export const getChipObj = (field, label, onClear) => ({ field, label, onClear })

export const getMultiOptions = ({ selected, map, clear, set, filter }) => {
  return selected.map((usageKey) =>
    getChipObj(filter.field + usageKey, map[usageKey], () => {
      const withoutIt = selected.filter((e) => e !== usageKey)
      withoutIt.length === 0 ? clear(filter) : set(filter, withoutIt)
    })
  )
}

export const createOptionsMap = (options) => options.reduce((acc, { value, text }) => ({ ...acc, [value]: text }), {})
