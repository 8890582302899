export const realtyTypesOptions = (t) => [
  { value: 'RESIDENTIAL', text: t('investObjects.labels.realtyTypesOptions.residential') },
  { value: 'HOTEL', text: t('investObjects.labels.realtyTypesOptions.hotel') },
  { value: 'OFFICE', text: t('investObjects.labels.realtyTypesOptions.office') },
  { value: 'TRADING', text: t('investObjects.labels.realtyTypesOptions.trading') },
  { value: 'CAR_PARK', text: t('investObjects.labels.realtyTypesOptions.carPark') },
  { value: 'INDUSTRIAL', text: t('investObjects.labels.realtyTypesOptions.industrial') },
  { value: 'WAREHOUSE', text: t('investObjects.labels.realtyTypesOptions.warehouse') },
  { value: 'OUTBUILDING', text: t('investObjects.labels.realtyTypesOptions.outbuilding') },
  { value: 'OTHER', text: t('investObjects.labels.realtyTypesOptions.other') }
]

export const realtyStateOptions = (t) => [
  { value: 'READY_TO_USE', text: t('investObjects.labels.realtyStateOptions.readyToUse') },
  { value: 'NEEDS_MINOR_REPAIRS', text: t('investObjects.labels.realtyStateOptions.needsMinorRepairs') },
  { value: 'NEED_MAJOR_REPAIRS', text: t('investObjects.labels.realtyStateOptions.needsMajorRepairs') },
  { value: 'UNUSABLE', text: t('investObjects.labels.realtyStateOptions.unusable') }
]
