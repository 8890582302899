import AppGrid from '@/components/app-grid'
import { ChangelogService } from '@/services/changelog-service'
import {
  changelogTableColumns,
  changelogTableSettings
} from '@/containers/admin-changelog/admin-changelog-table/constants'

const AdminChangeLogTable = () => {
  const loadChangelog = async (params) => {
    return await ChangelogService.grid(params)
  }

  const tableService = { load: loadChangelog }

  const bulkActions = {}

  const rowActions = {}
  const filterBy = null
  const onTableRefresh = () => {}

  return (
    <AppGrid
      columns={changelogTableColumns}
      service={tableService}
      settings={changelogTableSettings}
      rowActions={rowActions}
      bulkActions={bulkActions}
      externalFilters={filterBy}
      onTableRefresh={onTableRefresh}
    />
  )
}

export default AdminChangeLogTable
