import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { IconButton, TextField } from '@mui/material'
import { useMemo } from 'react'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import { Close } from '@mui/icons-material'

const AppDatepicker = ({
  fullDate = false,
  label,
  value = null,
  required = false,
  disabled = false,
  error = false,
  helperText = '',
  minValue,
  maxValue,
  onChange,
  onBlur,
  disableFuture,
  disablePast,
  sx
}) => {
  const handleChange = (datetime) => {
    if (datetime) {
      const newValue = datetime.toUTC().toISO()
      onChange(newValue)
    } else {
      onChange(datetime)
    }
  }
  const handleBlur = (blurValue) => {
    onBlur(blurValue.target.value)
  }

  const formattedValue = useMemo(() => {
    return value ? DateTime.fromISO(value) : null
  }, [value])

  const formattedMinValue = useMemo(() => {
    return DateTime.fromISO(minValue)
  }, [minValue])

  const formattedMaxValue = useMemo(() => {
    return DateTime.fromISO(maxValue)
  }, [maxValue])

  const props = {
    label: label,
    disabled: disabled,
    value: formattedValue,
    minDateTime: formattedMinValue,
    maxDateTime: formattedMaxValue,
    disableFuture: disableFuture,
    disablePast: disablePast
  }

  let clearValue = () => {
    handleChange(null)
  }
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={'uk'}
    >
      <Box sx={sx}>
        {fullDate ? (
          <DateTimePicker
            {...props}
            onChange={(newValue) => {
              handleChange(newValue)
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <TextField
                ref={inputRef}
                {...inputProps}
                fullWidth
                label={label}
                placeholder={label}
                required={required}
                onBlur={handleBlur}
                error={error}
                helperText={helperText}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <>
                      <IconButton
                        onClick={clearValue}
                        edge='end'
                      >
                        <Close />
                      </IconButton>
                      {InputProps?.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        ) : (
          <DesktopDatePicker
            {...props}
            onChange={(newValue) => {
              handleChange(newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...{ ...params, inputProps: { ...params.inputProps, readOnly: true } }}
                required={required}
                onBlur={onBlur}
                error={error}
                helperText={helperText}
              />
            )}
          />
        )}
      </Box>
    </LocalizationProvider>
  )
}

export default AppDatepicker
