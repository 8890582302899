import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAxios from '@/hooks/use-axios'

export const useAdminEdit = ({ service, initialData, setInitialData: setRestInitialData = () => {}, fallbackUrl }) => {
  const [initialValues, setInitialData] = useState(initialData)

  const { id } = useParams()
  const { fetchData, loading } = useAxios({ service, initialLoading: true })
  const navigate = useNavigate()

  const isLoading = useMemo(() => Boolean(id && loading), [id, loading])

  const getData = useCallback(
    async (byId) => {
      try {
        const data = await fetchData(byId)
        setInitialData(data)
        setRestInitialData(data)
      } catch (err) {
        if (err?.response?.status === 404 && fallbackUrl) {
          navigate(fallbackUrl)
        }

        throw err
      }
    },
    [fetchData, setRestInitialData, fallbackUrl, navigate]
  )

  useEffect(() => {
    if (id) {
      getData(id).catch()
    } else {
      setInitialData(initialData)
      setRestInitialData(null)
    }
  }, [getData, id, initialData, setRestInitialData])

  return {
    initialValues,
    loading: isLoading
  }
}
