import Box from '@mui/material/Box'
import useAxios from '@/hooks/use-axios'
import { TagsService } from '@/services/tags-service'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '@mui/material'
import i18next from 'i18next'
import useLeaveConfirm, { skipConfirmationNavState } from '@/hooks/use-leave-confirm'
import { useNavigate, useParams } from 'react-router-dom'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import AppForm from '@/components/app-form'
import AdminPageHeader from '@/containers/admin-page-header'
import AppTagsList from '@/components/app-tags-list/AppTagsList'
import AppDeleteAbleTag from '@/components/app-tags-list/app-delete-able-tag/AppDeleteAbleTag'
import AdminTagsForm from '@/containers/admin-tags/admin-tags-form/AdminTagsForm'
import { tagsFormInitialValue } from '@/containers/admin-tags/admin-tags-form/constants'
import { useAdminTagsForm } from '@/containers/admin-tags/admin-tags-form/hooks/use-admin-tags-form'
import { useConfirm } from '@/hooks/use-confirm'
import { Add, Delete, Update } from '@mui/icons-material'
import { adminEditContainerSX } from '@/containers/constants'
import { useSnackbar } from 'notistack'

const AdminTagsPage = () => {
  const [initialTags, setInitialTags] = useState([])
  const [tagsList, setTagsList] = useState([])

  const { id } = useParams()
  const numberedId = useMemo(() => Number(id), [id])
  const { confirm } = useConfirm()
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const { fetchData: getTagsRequest, loading: getTagsLoading } = useAxios({ service: TagsService.getTags })
  const { fetchData: isUsedTagRequest, loading: isUsedTagLoading } = useAxios({ service: TagsService.isUsedTag })
  const { fetchData: createTagRequest, loading: createTagLoading } = useAxios({ service: TagsService.createTag })
  const { fetchData: editTagRequest, loading: editTagLoading } = useAxios({
    service: TagsService.editTag.bind(undefined, id)
  })
  const { fetchData: deleteTagRequest, loading: deleteTagLoading } = useAxios({ service: TagsService.deleteTag })

  useEffect(() => {
    const setData = async () => {
      const data = await getTagsRequest()
      setInitialTags(data)
    }
    setData().catch()
  }, [getTagsRequest])

  const { isDirty, setFormValue, data, clearForm, handleValidate, formProps } = useAdminTagsForm(tagsList)

  const { setNeedConfirm } = useLeaveConfirm()

  useEffect(() => {
    setNeedConfirm(isDirty)
  }, [isDirty, setNeedConfirm])

  const goTagsPage = useCallback(
    (opts) => {
      navigate(adminRoutes.tags.path, opts)
    },
    [navigate]
  )

  useEffect(() => {
    if (numberedId && initialTags.length > 0) {
      const tag = initialTags.find((tagFromList) => tagFromList.id === numberedId)
      if (tag) {
        setFormValue({
          uk: tag.title.uk,
          en: tag.title.en
        })
        setTagsList(initialTags.filter((tagFromList) => tagFromList.id !== numberedId))
      } else {
        goTagsPage()
      }
    } else {
      setFormValue(tagsFormInitialValue)
      setTagsList(initialTags)
    }
  }, [goTagsPage, initialTags, numberedId, setFormValue])

  const createTag = async () => {
    const response = await createTagRequest({
      uk: data.uk.trim(),
      en: data.en.trim()
    })

    if (response) {
      enqueueSnackbar(i18next.t('tags.created'), { variant: 'success' })
      setInitialTags((prevState) => [...prevState, response])
      clearForm()
    }
  }

  const editTag = async () => {
    const sendPayload = {
      uk: data.uk.trim(),
      en: data.en.trim()
    }
    await editTagRequest(sendPayload)
    enqueueSnackbar(i18next.t('tags.edited'), { variant: 'success' })
    setInitialTags((prevState) => [
      ...prevState.filter((e) => e.id !== numberedId),
      { id: numberedId, title: sendPayload }
    ])
  }

  const deleteTag = async (item) => {
    const tagUsage = await isUsedTagRequest(item.id)
    const message = i18next.t('tags.tagUsage', { count: tagUsage.total })
    const confirmed = await confirm(message)
    if (confirmed) {
      await deleteTagRequest(item.id).catch()
      enqueueSnackbar(i18next.t('tags.deleted'), { variant: 'success' })
      setInitialTags((prevState) => prevState.filter((e) => e.id !== item.id))
    }
    return confirmed
  }
  const activeDelete = async () => {
    const confirmed = await deleteTag({ id: numberedId })
    if (confirmed) {
      goTagsPage({ state: skipConfirmationNavState })
    }
  }
  const goTagsEditPage = (item) => {
    navigate(adminRoutes.tagsEdit.path + item.id)
  }

  const submit = () => {
    const isValid = handleValidate()

    if (isValid) {
      if (id) {
        editTag().catch()
      } else {
        createTag().catch()
      }
    }
  }

  return (
    <Box sx={{ ...adminEditContainerSX }}>
      <AdminPageHeader
        title={i18next.t('tags.tagsManage')}
        styles={{ minHeight: 'auto', marginBottom: '12px' }}
      />
      <AppForm onFormSubmit={submit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '12px', px: '12px' }}>
          <AdminTagsForm formProps={formProps} />
          {id ? (
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Button
                variant={'contained'}
                disabled={deleteTagLoading}
                onClick={activeDelete}
                endIcon={<Delete />}
              >
                {i18next.t('tags.deleteTag')}
              </Button>
              <Button
                variant={'contained'}
                disabled={editTagLoading || !isDirty}
                endIcon={<Update />}
                type='submit'
              >
                {i18next.t('tags.editTag')}
              </Button>
            </Box>
          ) : null}
          {!id ? (
            <Button
              variant={'contained'}
              disabled={createTagLoading || !isDirty}
              type='submit'
              endIcon={<Add />}
            >
              {i18next.t('tags.createTag')}
            </Button>
          ) : null}
        </Box>
      </AppForm>
      <AppTagsList
        tagsList={tagsList}
        loading={getTagsLoading || deleteTagLoading || isUsedTagLoading}
        getTagItemComponent={AppDeleteAbleTag.bind(undefined, deleteTag, goTagsEditPage)}
      />
    </Box>
  )
}

export default AdminTagsPage
