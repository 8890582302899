import { useCallback, useEffect, useRef, useState } from 'react'
import { LvivLatLng, toggleInfoWindowOnMarker, toggleMarkerAnimation } from '@/utils/map'
import { Box } from '@mui/material'
import mapMarkerIcon from '@/assets/icons/map-marker.svg'
import mapClusterMarkerIcon from '@/assets/icons/map-cluster-marker.svg'
import AppMapMarkerInfo from '@/components/app-map/app-map-info-marker/AppMapMarkerInfo'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
/* eslint no-undef: 0 */ // --> OFF

const AppMap = ({
  cords,
  disableZoom = false,
  canSetNewCords = false,
  onCordsChange = () => {},
  disableDoubleClickZoom = false,
}) => {
  const ref = useRef(null)
  const [map, setMap] = useState()
  const [bounds, setBounds] = useState()
  const [info, setInfo] = useState()
  const [markers, setMarkers] = useState([])
  const [mapCords, setMapCords] = useState([])

  useEffect(() => {
    setMapCords(cords)
  }, [cords])

  useEffect(() => {
    if (map) {
      canSetNewCords && onCordsChange && map.addListener('dblclick', (e) => {
        const lat = e.latLng?.lat()
        const lng = e.latLng?.lng()

        if (lat && lng) {
          const newCords = [{ lat, lng }]

          setMapCords(newCords)
          onCordsChange(newCords)
        }
      })
    }
  }, [canSetNewCords, map, onCordsChange])

  useEffect(() => {
    if (ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {
        disableDoubleClickZoom,
      })
      setMap(newMap)
      if (disableZoom) {
        newMap.setOptions({ draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true })
      }
    }
  }, [ref, map, disableZoom, disableDoubleClickZoom])

  const focusToLviv = useCallback(() => {
    const initialBounds = new google.maps.LatLngBounds()
    LvivLatLng.forEach((point) => {
      initialBounds.extend(point)
    })
    setBounds(initialBounds)
  }, [])

  // focus to Lviv bounds
  useEffect(() => {
    focusToLviv()
  }, [focusToLviv])

  useEffect(() => {
    setInfo(new google.maps.InfoWindow())
  }, [])

  // auto close info widow on map click
  useEffect(() => {
    if (map && info) {
      map.addListener('click', () => {
        info.close()
      })
    }
  }, [map, info])

  // fit markers on the map
  useEffect(() => {
    if (bounds && markers && map) {
      focusToLviv()
      markers.forEach((marker) => {
        bounds.extend(marker.getPosition())
      })
      map.fitBounds(bounds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markers, focusToLviv])

  useEffect(() => {
    if (map) {
      setMarkers(
        mapCords.map((cord) => {
          const newMarker = new google.maps.Marker({
            icon: mapMarkerIcon,
            position: { lat: +cord.lat, lng: +cord.lng },
            map: map,
            optimized: false
          })
          newMarker.addListener('click', () => {
            toggleMarkerAnimation(newMarker)
            if (cord.id) {
              toggleInfoWindowOnMarker(
                info,
                newMarker,
                <AppMapMarkerInfo
                  id={cord.id}
                  url={`/investment-object/${cord.id}`}
                />
              )
            }
          })

          return newMarker
        })
      )
    }
  }, [mapCords, map, info])

  useEffect(() => {
    return () => {
      markers.forEach((marker) => {
        marker.setMap(null)
      })
    }
  }, [markers])

  // making clusters
  useEffect(() => {
    let newCluster
    if (map && markers?.length > 0) {
      newCluster = new MarkerClusterer({
        map,
        markers,
        renderer: {
          render: ({ count, position }) => {
            return new google.maps.Marker({
              icon: mapClusterMarkerIcon,
              label: { text: String(count), color: 'white', fontSize: '16px' },
              position,
              // adjust zIndex to be above other markers
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
            })
          }
        }
      })
    }

    return () => {
      newCluster?.clearMarkers()
    }
  }, [map, markers])

  return (
    <Box
      ref={ref}
      sx={{ flexGrow: '1', height: '100%' }}
    />
  )
}

export default AppMap
