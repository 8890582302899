import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { Skeleton, Typography } from '@mui/material'
import { characteristicsTypeOptions, usageTypeOptions } from '@/pages/admin/admin-invest-object-edit/constants'
import { getOptionByValue } from '@/containers/admin-invest-object/admin-invest-object-table/constants'
import { ownershipTypeOptions } from '@/containers/admin-invest-object/admin-invest-object-form/land-plot-section/constants'
import { toShortDate } from '@/utils/date'
import { formatUnit } from '@/utils/unit-formaters'
import { theme } from '@/plugins/material-ui/theme'
import InvestCharacteristicType from '@/containers/invest-object-list/invest-characteristics-type/InvestCharacteristicsType'
import { makeAddress } from '@/containers/investment_opportunities/invests-item/InvestItem'
import { truncateTextByLines } from '@/utils/common'

const InvestObjectListItemBody = ({ item, isHovered, loading }) => {
  const { t, i18n } = useTranslation()
  const address = makeAddress(item)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <Typography
        variant={'h5'}
        color={isHovered && 'primary'}
        title={item?.name}
        sx={{ ...truncateTextByLines(2) }}
        mb={'5px'}
      >
        {loading ? <Skeleton /> : item.name}
      </Typography>
      <Typography
        variant={'body2'}
        color={'textSecondary'}
        title={address}
        sx={{ ...truncateTextByLines(1) }}
        mb={theme.spacing(2)}
      >
        {loading ? <Skeleton /> : address}
      </Typography>
      <InvestCharacteristicType
        loading={loading}
        type={t('investObjects.listShortCharacteristics.objectType')}
        characteristic={getOptionByValue(characteristicsTypeOptions(), item.characteristicsType)?.label}
      />

      <InvestCharacteristicType
        loading={loading}
        type={t('investObjects.listShortCharacteristics.usageType')}
        characteristic={getOptionByValue(usageTypeOptions(), item.usageType)?.text}
      />

      <InvestCharacteristicType
        loading={loading}
        type={t('investObjects.listShortCharacteristics.ownershipType')}
        characteristic={getOptionByValue(ownershipTypeOptions(), item.ownershipType)?.text}
      />

      {Object.hasOwn(item, 'plotArea') ? (
        <InvestCharacteristicType
          loading={loading}
          type={t('investObjects.listShortCharacteristics.plotArea')}
          characteristic={item.plotArea ? formatUnit(i18n.language, item.plotArea, 'hectare') : '-'}
        />
      ) : null}
      {Object.hasOwn(item, 'realtyTotalAreaSqM') ? (
        <InvestCharacteristicType
          loading={loading}
          type={t('investObjects.listShortCharacteristics.realtyTotalAreaSqM')}
          characteristic={
            item.realtyTotalAreaSqM ? `${formatUnit(i18n.language, item.realtyTotalAreaSqM, 'meter')}2` : '-'
          }
        />
      ) : null}
      <InvestCharacteristicType
        loading={loading}
        type={t('investObjects.listShortCharacteristics.publishedDate')}
        characteristic={toShortDate(item.publishedDate)}
      />
    </Box>
  )
}

export default InvestObjectListItemBody
