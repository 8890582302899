import { useCallback, useEffect, useState } from 'react'
import palette from '@/plugins/material-ui/palette'
import { Box } from '@mui/material'
import { theme } from '@/plugins/material-ui/theme'
import { responsiveGap, style } from '@/pages/home-page/HomePage.styles'
import useBreakpoints from '@/hooks/use-breakpoints'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAxios from '@/hooks/use-axios'
import { SupportProgramsService } from '@/services/support-program-service'
import SupportProgramItem from '@/containers/support-program-banner/support-program-item'
import { baseContainerSX } from '@/containers/constants'
import AppContainerHeader from '@/components/app-container-header'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import AppButtonGoTo from '@/components/app-button-go-to'

const SupportProgramsSimilarSection = () => {
  const [programs, setPrograms] = useState([])
  const { fetchData: fetchRestNews } = useAxios({
    service: SupportProgramsService.getSupportPrograms,
    initialLoading: true
  })
  const { id } = useParams()
  const { isMobile } = useBreakpoints()
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const containerPaddingSX = { px: { md: theme.spacing(5), sm: theme.spacing(3), xs: theme.spacing(2) } }
  const textColor = {
    color: palette.basic.white
  }

  const getRestPrograms = useCallback(async () => {
    const data = await fetchRestNews({ take: 3, skip: 0, exclude_ids: [Number(id)], lang: i18n.language })
    setPrograms(data.items)
  }, [fetchRestNews, i18n.language, id])

  useEffect(() => {
    getRestPrograms().catch()
  }, [getRestPrograms])

  const goToProgramsPage = {
    onClick: () => {
      navigate(clientRoutes.supportPrograms.route)
    },
    title: i18n.t('homePage.allSupportPrograms'),
    sx: { color: 'basic.white' }
  }

  return (
    <Box sx={{ ...style.fullWidthContainer, backgroundColor: palette.text.primary }}>
      <Box sx={{ ...baseContainerSX, ...containerPaddingSX, my: responsiveGap }}>
        <AppContainerHeader
          sx={{ my: responsiveGap, color: 'basic.white' }}
          title={i18n.t('supportPrograms.similarPrograms')}
          button={{
            ...goToProgramsPage,
          }}
          showButton={!isMobile}
        />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1, mb: responsiveGap }}>
            <Box
              key='items'
              sx={{
                display: 'grid',
                gridTemplateColumns: { md: '1fr 1fr 1fr', sm: '1fr 1fr', xs: '1fr' },
                gap: '40px',
                color: palette.basic.white
              }}
            >
              {programs.map((item) => (
                <Box
                  key={item.id}
                  sx={{ color: palette.basic.white }}
                >
                  <SupportProgramItem
                    url={`/support-programs/${item.id}`}
                    vertical
                    item={item}
                    textColor={textColor}
                  />
                </Box>
              ))}
            </Box>
            {isMobile && (
              <Box sx={{ ...style.appToGo }}>
                <AppButtonGoTo {...goToProgramsPage} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SupportProgramsSimilarSection
