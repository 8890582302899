export const scrollbar = {
  styleOverrides: {
    html: {
      // overflowY: 'hidden'
    },
    '*': {
      scrollbarWidth: 'thin',
      scrollbarColor: '#AFB7C4 transparent'
    },

    /* width */
    '::-webkit-scrollbar': {
      width: '10px',
      height: '10px'
    },

    /* Track */
    '::-webkit-scrollbar-track': {
      background: '#F4F4F6',
      borderRadius: '12px'
    },

    /* Corner */
    '::-webkit-scrollbar-corner': {
      display: 'none'
    },

    /* Handle */
    '::-webkit-scrollbar-thumb': {
      borderRadius: '12px',
      background: '#AFB7C4',
      border: '2px solid transparent',
      backgroundClip: 'content-box'
    },

    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      borderRadius: '12px',
      background: '#566277',
      border: '2px solid transparent',
      backgroundClip: 'content-box'
    },
    /* Buttons */
    '::-webkit-scrollbar-button': {
      display: 'none'
    }
  }
}
