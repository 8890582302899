import { Box, Typography } from '@mui/material'
import InvestCharacteristicType from '@/containers/invest-object-list/invest-characteristics-type/InvestCharacteristicsType'

const InvestObjectCharacteristic = ({ title, types, isLarge, isDesktop, headerSX }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: isLarge || isDesktop ? '30px' : '8px' }}>
      <Typography
        variant={'h4'}
        sx={{ ...headerSX, width: isLarge || isDesktop ? '315px' : '100%', mb: 0 }}
      >
        {title}
      </Typography>
      <Box sx={{ display: 'flex', gap: '9px', flexDirection: 'column', flex: '1' }}>
        {types.map(({ type, characteristic }) => (
          <InvestCharacteristicType
            key={type}
            type={type}
            characteristic={characteristic}
            wider
          />
        ))}
      </Box>
    </Box>
  )
}

export default InvestObjectCharacteristic
