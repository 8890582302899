import PropTypes from 'prop-types'
import AppInputs from '@/components/app-form/app-inputs'

const SectionBase = (
  {
    formPropsGeneral,
    formPropsMulti,
    multiInputs,
    generalInputs,
  }) => {
  return (
    <>
      <AppInputs {...formPropsGeneral} inputs={generalInputs} />
      <AppInputs {...formPropsMulti} inputs={multiInputs}  />
    </>
  )
}

const SectionProps = {
  formPropsGeneral: PropTypes.object,
  formPropsMulti: PropTypes.object,
  multiInputs: PropTypes.array,
  generalInputs: PropTypes.array,
  styles: PropTypes.object,
}

SectionBase.propTypes = SectionProps

export default SectionBase
