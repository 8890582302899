import { useRefine } from '@/containers/invest-object-list/invest-object-list-filters/context/refine-context'
import { useCallback } from 'react'

export const useSorting = () => {
  const { sortingModel, setSortingModel } = useRefine()

  const setSorting = useCallback(
    (key) => {
      const [field, sort] = key.split(':')
      setSortingModel({
        field,
        sort
      })
    },
    [setSortingModel]
  )

  return { sorting: `${sortingModel.field}:${sortingModel.sort}`, sortingModel, setSorting }
}
