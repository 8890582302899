import { Route, Routes } from 'react-router-dom'
import { appRoutes } from '@/plugins/router/routes'

import ClientLayout from '@/containers/layouts/client/client-layout'
import AdminLayout from '@/containers/layouts/admin/admin-layout'
import { AuthProvider } from '@/context/auth-context'
import { AppAdminErrorProvider } from '@/context/app-error-context'
import { ClientLayoutElementsProvider } from '@/context/client-layout-context'

const AppRouter = () => {
  const adminLayout = AuthProvider({
    children: (
      <AppAdminErrorProvider>
        <AdminLayout />
      </AppAdminErrorProvider>
    )
  })

  const clientLayout = ClientLayoutElementsProvider({
    children: <ClientLayout />
  })

  return (
    <Routes>
      <Route
        exact
        path={appRoutes.client.route}
        element={clientLayout}
      />
      <Route
        exact
        path={appRoutes.admin.route + '/*'}
        element={adminLayout}
      />
    </Routes>
  )
}

export default AppRouter
