import { Box, FormGroup, Button, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import useForm from '@/hooks/use-form'
import useAxios from '@/hooks/use-axios'
import AdminPageHeader from '@/containers/admin-page-header'
import AppForm from '@/components/app-form/AppForm'
import AppInputs from '@/components/app-form/app-inputs'
import { userFormInitialValues, userInputs, userValidations } from '@/pages/admin/admin-users-edit/constants'
import { UsersService } from '@/services/users-service'
import { useSnackbar } from 'notistack'
import i18next from 'i18next'
import { adminEditContainerSX } from '@/containers/constants'
import { adminRoutes } from '@/plugins/router/admin-router/routes'

const AdminUsersEdit = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { fetchData: sendData } = useAxios({ service: UsersService.postUser })

  const {
    data: userData,
    formProps,
    handleValidate
  } = useForm({ inputs: userInputs, initialValues: userFormInitialValues, validations: userValidations })

  const onSubmit = async () => {
    if (handleValidate()) {
      try {
        const response = await sendData(userData)
        enqueueSnackbar(i18next.t('users.createSuccess', { fullName: `${response.firstName} ${response.lastName}` }), {
          variant: 'success'
        })
        navigate(adminRoutes.users.path)
      } catch (error) {
        enqueueSnackbar(i18next.t(`error.${error?.response?.data?.message}`), { variant: 'error' })
      }
    }
  }

  return (
    <Box sx={{ ...adminEditContainerSX }}>
      <AdminPageHeader
        title={id ? t('users.editUser') : t('users.createUser')}
        styles={{ minHeight: 'auto' }}
      />
      <AppForm onFormSubmit={onSubmit}>
        <FormGroup sx={{ flexGrow: 1, marginTop: theme.spacing(2), gap: '8px' }}>
          <AppInputs
            inputs={userInputs}
            {...formProps}
          />
        </FormGroup>
        <Button
          variant={'contained'}
          type='submit'
          sx={{ width: '300px', marginLeft: 'auto' }}
        >
          {id ? t('users.editUser') : t('users.addUser')}
        </Button>
      </AppForm>
    </Box>
  )
}

export default AdminUsersEdit
