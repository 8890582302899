import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'
import { buildURL } from '@/utils/url-builder'

export const SupportProgramsService = {
  getSupportPrograms: (params) => {
    return request.post(URLs.supportPrograms.getSupportPrograms, { ...params })
  },
  getSupportProgramTags: (lang) => {
    return request.get(buildURL(URLs.supportPrograms.getSupportProgramTags, {}, { lang }))
  },
  getSupportProgramById: ({ id, ...query }) => {
    return request.get(buildURL(URLs.supportPrograms.getSupportProgramById, { id }, query))
  }
}
