import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import AdminPageHeader from '@/containers/admin-page-header'
import AppTabs from '@/components/app-tabs'

import { SupportProgramsAdminService } from '@/services/support-program-admin-service'
import { adminRoutes } from '@/plugins/router/admin-router/routes'

import { useAttachedTags } from '@/hooks/admin-forms/use-attached-tags'
import { useMultiLang } from '@/hooks/admin-forms/use-multiLang'
import AppForm from '@/components/app-form/AppForm'
import useLeaveConfirm, { skipConfirmationNavState } from '@/hooks/use-leave-confirm'
import AdminTagsSidebar from '@/containers/admin-tags/admin-tags-sidebar/AdminTagsSidebar'
import AdminSupportProgramForm from '@/containers/admin-support-programs/admin-support-program-form/AdminSupportProgramForm'
import useForm from '@/hooks/use-form'
import {
  draftSupportProgramValidation,
  generalValidationDraft,
  generalValidationPublish,
  publishSupportProgramValidation,
  supportProgramsFormGeneralInputs,
  supportProgramsFormInitialValue,
  supportProgramsFormMultiLangInputs
} from '@/containers/admin-support-programs/constants'
import { mapDataFromInputs } from '@/utils/form-helper'
import { useTabs } from '@/hooks/use-tabs'
import AppEditorSubmit from '@/containers/admin-editor-submit/AppEditorSubmit'
import { useStatusChange } from '@/hooks/admin-forms/use-status-change'
import { adminEditContainerSX } from '@/containers/constants'

const AdminSupportProgramsEdit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()

  const { setNeedConfirm } = useLeaveConfirm()

  const {
    isDirty: isGeneralDirty,
    formProps: generalFormProps,
    data: generalInputData,
    setMappedData: updateGlobalData,
    handleValidate: generalInputValidate,
    setValidations: setGlobalValidation
  } = useForm({
    inputs: supportProgramsFormGeneralInputs,
    initialValues: supportProgramsFormInitialValue.global,
    validations: generalValidationDraft
  })
  const { isTagsDirty, clearTags, attachTag, unAttachTag, setInitial: setInitialTags, tags } = useAttachedTags()

  const getFormComponent = useCallback(
    ({ lang, formProps }) => {
      return (
        <AdminSupportProgramForm
          generalInputs={supportProgramsFormGeneralInputs}
          formPropsMulti={formProps}
          multiInputs={supportProgramsFormMultiLangInputs}
          formPropsGeneral={generalFormProps}
          tags={tags}
          onTagDelete={unAttachTag}
          lang={lang}
        />
      )
    },
    [generalFormProps, unAttachTag, tags]
  )

  const createSubmitService = useCallback(
    (data) => {
      return SupportProgramsAdminService.createProgram({
        ...data,
        donorLogoId: generalInputData.donorLogo?.id ?? null,
        featureImageId: generalInputData.featureImage?.id ?? null,
        documentId: generalInputData.document[0]?.id ?? null,
        tags: tags.map((tag) => tag.id)
      })
    },
    [generalInputData, tags]
  )

  const editSubmitService = useCallback(
    (data) => {
      return SupportProgramsAdminService.updateProgram(id, {
        ...mapDataFromInputs(supportProgramsFormMultiLangInputs, data),
        donorLogoId: generalInputData.donorLogo?.id ?? null,
        featureImageId: generalInputData.featureImage?.id ?? null,
        documentId: generalInputData.document[0]?.id ?? null,
        tags: tags.map((tag) => tag.id)
      })
    },
    [generalInputData.document, generalInputData.donorLogo, generalInputData.featureImage, id, tags]
  )

  const afterSubmit = useCallback(() => {
    clearTags()
    navigate(adminRoutes.supportPrograms.path, { state: skipConfirmationNavState })
  }, [clearTags, navigate])

  const updateRestData = useCallback(
    (res) => {
      if (res) {
        setInitialTags(res?.tags || [])
        updateGlobalData({
          donorLogo: res?.donorLogo,
          featureImage: res?.featureImage,
          document: res?.document ? [res?.document] : []
        })
      } else {
        setInitialTags([])
        updateGlobalData(supportProgramsFormInitialValue.global)
      }
    },
    [updateGlobalData, setInitialTags]
  )

  const setActiveTabIndex = (index) => {
    setTabIndex(index)
  }

  const { multiform, multilangTabs, tabLanguage, onLanguageChange, handleSubmit, loading, isSaving } = useMultiLang({
    inputs: supportProgramsFormMultiLangInputs,
    getFormComponent,
    afterSubmit,
    validations: draftSupportProgramValidation,
    publishValidation: publishSupportProgramValidation,
    submitService: id ? editSubmitService : createSubmitService,
    initialData: supportProgramsFormInitialValue,
    setInitialData: updateRestData,
    getDataService: SupportProgramsAdminService.getProgramById,
    additionalValidate: generalInputValidate,
    setActiveTabIndex,
    fallbackUrl: adminRoutes.supportPrograms.path
  })

  const { tabIndex, handleTabChange, setTabIndex } = useTabs({ tabs: multilangTabs, onTabChange: onLanguageChange })

  const isDirty = useMemo(
    () => isTagsDirty || multiform.isDirty || isGeneralDirty,
    [isGeneralDirty, isTagsDirty, multiform.isDirty]
  )

  useStatusChange({
    setValidation: setGlobalValidation,
    draftValidation: generalValidationDraft,
    publishValidation: generalValidationPublish,
    status: multiform.data[Object.keys(multiform.data)[tabIndex]].status
  })

  useEffect(() => {
    setNeedConfirm(isDirty)
  }, [isDirty, setNeedConfirm])

  if (loading) {
    return <Box>Loading...</Box>
  }

  return (
    <Box sx={{ ...adminEditContainerSX }}>
      <AdminPageHeader
        title={id ? t('supportPrograms.editProgram') : t('supportPrograms.createProgram')}
        styles={{ minHeight: 'auto' }}
      />
      <AppForm onFormSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'auto' }}>
          <AppTabs
            tabs={multilangTabs}
            tabIndex={tabIndex}
            handleTabChange={handleTabChange}
            styles={{ overflow: 'auto', pr: '16px', flexBasis: '70%' }}
          />
          <AdminTagsSidebar
            tags={tags}
            addTag={attachTag}
            chosenLanguage={tabLanguage}
          />
        </Box>
        <AppEditorSubmit
          isSaving={isSaving}
          isDirty={isDirty}
          id={id}
          afterDelete={afterSubmit}
          deleteService={SupportProgramsAdminService.bulkDeletion}
        />
      </AppForm>
    </Box>
  )
}

export default AdminSupportProgramsEdit
