import InvestObjectMap from '@/containers/invest-object-map/InvestObjectMap'
import { Typography, Grid, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContext, useMemo, useState } from 'react'
import { documentStatus } from '@/containers/constants'
import { FormStatusContext } from '@/containers/invest-object-form/context/formStatusContext'

const InvestObjectFormAddressSectionMap = ({ data, handleBulkChange, handleBulkBlur, sx, errors }) => {
  const status = useContext(FormStatusContext)
  const [fieldCords, setFieldCords] = useState((data.latitude || data.longitude)
    ? `${data.latitude},${data.longitude}`
    : '')
  const { t } = useTranslation()

  const parseCords = (cordsField) => {
    const [latitude, longitude] = cordsField.split(',')

    const trimmedLatitude = latitude && latitude.trim()
    const trimmedLongitude = longitude && longitude.trim()

    return {
      lat: trimmedLatitude || '',
      lng: trimmedLongitude || '',
    }
  }

  const [cords, setCords] = useState([parseCords(fieldCords)])

  const parseAndChangeFormCords = (e) => {
    const value = e.target.value
    setFieldCords(value)

    const { lat, lng } = parseCords(value)

    handleBulkChange([
      'latitude',
      'longitude'
    ])([
      { target: { value: lat } },
      { target: { value: lng } },
    ])
  }

  const setMapCords = (selectedCords) => {
    const newLatitude = parseFloat(selectedCords.lat).toFixed(6)
    const newLongitude = parseFloat(selectedCords.lng).toFixed(6)

    setCords([{
      lat: +newLatitude,
      lng: +newLongitude,
    }])

    return {
      lat: newLatitude,
      lng: newLongitude,
    }
  }

  const handleMapCordsChange = ([selectedCords]) => {
    const { lat, lng } = setMapCords(selectedCords)

    setFieldCords(`${lat},${lng}`)

    handleBulkChange([
      'latitude',
      'longitude'
    ])([
      { target: { value: lat } },
      { target: { value: lng } },
    ])
  }

  const handleTextFieldBlur = () => {
    const { lat, lng } = parseCords(fieldCords)

    if (lat && lng) {
      setMapCords({ lat, lng })
    }
    
    setFieldCords(lat || lng ? `${lat},${lng}` : '')
    handleBulkBlur(['latitude', 'longitude'])
  }

  const handleTextFieldPaste = (e) => {
    e.preventDefault()

    const value = e.clipboardData.getData('text')

    const { lat, lng } = parseCords(value)

    const newLatitude = parseFloat(lat).toFixed(6)
    const newLongitude = parseFloat(lng).toFixed(6)

    setFieldCords(`${newLatitude},${newLongitude}`)

    handleBulkChange([
      'latitude',
      'longitude'
      ])([
      { target: { value: newLatitude } },
      { target: { value: newLongitude } },
      ])
  }

  const labelText = useMemo(() => {
    return !status || status === documentStatus.DRAFT
      ? t('investObjects.labels.latitudeAndLongitude')
      : t('investObjects.labels.latitudeAndLongitudeRequired')
  }, [status, t])
  
  const map = useMemo(() => (
    <InvestObjectMap
      cords={cords}
      onCordsChange={handleMapCordsChange}
      canSetNewCords
      disableDoubleClickZoom
    />),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [cords])

  const errorMessages = useMemo(() => {
    return [errors.latitude || errors.longitude]
  }, [errors])

  const errorMessagesComponent = (<>
    {errorMessages.map((errorMessage, i) =>
      (<Typography key={`${errorMessage}${i}`} variant="text" sx={{ textOverflow: 'hidden', display: 'inline-block', maxWidth: 300 }}>
        {errorMessage}
      </Typography>))}
  </>)
  
  return (
    <Grid 
      container 
      flexDirection="column" 
      spacing="7"
      sx={{ ...sx }}
    >
      <Grid item>
        <Typography variant='h5'>{t('investObjects.placeholder.map')}</Typography>
      </Grid>
      <Grid item>
        <Typography variant='text'>{t('investObjects.placeholder.mapDescription')}</Typography>
      </Grid>
      <Grid item sx={{ height: 400 }}>
        { map }
      </Grid>
      <Grid item>
        <TextField
          label={labelText}
          value={fieldCords}
          sx={{ width: { sm: 300, xs: 'auto' }, maxWidth: { sm: 400, xs: '100%' }, marginTop: '20px' }}
          onChange={parseAndChangeFormCords}
          onPaste={handleTextFieldPaste}
          error={errorMessages.some(Boolean)}
          helperText={errorMessagesComponent}
          onBlur={handleTextFieldBlur}
        />
      </Grid>
    </Grid>)
}

export default InvestObjectFormAddressSectionMap
