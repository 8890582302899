import { Box, Divider, Typography } from '@mui/material'
import { newsDetails } from '@/pages/news-details-page/news-details-styles'
import AppButtonGoBackTo from '@/components/app-button-go-back-to/AppButtonGoBackTo'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import NewsItem from '@/containers/news-short-list/news-item'
import { useTranslation } from 'react-i18next'
import useAxios from '@/hooks/use-axios'
import { InvestObjectService } from '@/services/invest-object-service'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import InvestObjectListItem from '@/containers/invest-object-list/invest-object-list-item/InvestObjectListItem'
import useBreakpoints from '@/hooks/use-breakpoints'
import { generateSkeletonItems } from '@/utils/skeleton'
import InvestObjectContactInfo from '@/containers/invest-object-details/InvestObjectContactInfo'
import InvestObjectInvite from '@/containers/invest-object-details/invest-object-invite/InvestObjectInvite'
import AppMap from '@/components/app-map/AppMap'
import { MapWrapper } from '@/utils/map'
import { getCharacteristicTypes } from '@/pages/invest-object-details-page/constant'
import InvestObjectImageGrid from '@/containers/invest-object-details/InvestObjectImageGrid'
import InvestObjectCharacteristic from '@/containers/invest-object-details/InvestObjectCharacteristic'

const InvestObjectDetailsPage = () => {
  const { t, i18n } = useTranslation()
  const { id: URL_ID } = useParams()
  const id = Number(URL_ID)
  const navigate = useNavigate()
  const [recommendation, setRecommendation] = useState([])
  const [detail, setDetail] = useState({})
  const { isDesktop, isLarge, isMobile, isTablet } = useBreakpoints()
  const { fetchData, loading } = useAxios({
    service: InvestObjectService.getInvestObjectDetails,
    initialLoading: true
  })

  const { fetchData: fetchRecommendation, loading: restLoading } = useAxios({
    service: InvestObjectService.mapListGrid,
    initialLoading: true
  })
  const computeHowManyToTake = useCallback(() => {
    if (isLarge) {
      return 4
    }
    if (isDesktop) {
      return 3
    }
    if (isTablet) {
      return 2
    }
    if (isMobile) {
      return 2
    }
  }, [isDesktop, isLarge, isMobile, isTablet])

  const getRest = useCallback(async () => {
    const { items } = await fetchRecommendation({
      take: computeHowManyToTake(),
      skip: 0,
      sorting: {
        field: 'publishedDate',
        sort: 'DESC'
      },
      filtering: [],
      lang: i18n.language,
      exclude_ids: [id]
    })
    setRecommendation(items)
  }, [computeHowManyToTake, fetchRecommendation, i18n.language, id])
  const stubLoadingRest = useMemo(() => generateSkeletonItems(computeHowManyToTake()), [computeHowManyToTake])
  const fakeHeaderH4 = { fontWeight: 700, fontSize: '20px', mb: '12px' }

  useEffect(() => {
    getRest().catch()
  }, [getRest])

  const getDetails = useCallback(async () => {
    const data = await fetchData({ id, query: { lang: i18n.language } })
    setDetail(data)
  }, [fetchData, id, i18n.language])

  useEffect(() => {
    document.title = `Invest in Lviv - ${detail.name}`
  }, [detail.name])

  useEffect(() => {
    getDetails().catch((res) => {
      if (res.response.status === 404) {
        navigate(clientRoutes.investmentOpportunitiesMap.route)
      }
    })
  }, [getDetails, navigate])

  const InvestObjectHeader = () => (
    <Typography
      variant={'h2'}
      component={'h1'}
      mb={isMobile ? '0' : '8px'}
    >
      {detail.name}
    </Typography>
  )

  const characteristicTypes = useMemo(() => getCharacteristicTypes({ detail, t, i18n }), [detail, t, i18n])

  const InvestObjectContent = () => {
    return (
      <>
        {isLarge || isDesktop ? <InvestObjectHeader /> : null}
        <Box
          display='flex'
          gap={isTablet || isMobile ? '10px' : '60px'}
          flexWrap={isTablet || isMobile ? 'wrap' : 'nowrap'}
        >
          <Box
            flex={isTablet || isMobile ? '100%' : '1 1 100%'}
            order={isTablet || isMobile ? 2 : 1}
            display={'flex'}
            flexDirection={'column'}
          >
            <InvestObjectContactInfo detail={detail} />
          </Box>
          <Box
            flex={isTablet || isMobile ? '100%' : '1 0 660px'}
            flexBasis={isLarge ? '660px' : '400px'}
            order={isTablet || isMobile ? 1 : 2}
          >
            <InvestObjectImageGrid images={detail?.images} />
            {isTablet || isMobile ? <InvestObjectHeader /> : null}
          </Box>
        </Box>
        {characteristicTypes.map((props) => (
          <Fragment key={props.title}>
            <Divider sx={{ my: '24px' }} />
            <InvestObjectCharacteristic
              {...props}
              headerSX={fakeHeaderH4}
              isDesktop={isDesktop}
              isTablet={isTablet}
            />
          </Fragment>
        ))}
        <Divider sx={{ my: '24px' }} />
        <Typography
          variant={'h4'}
          sx={fakeHeaderH4}
        >
          {t('investObjects.headers.placeOnMap')}
        </Typography>
        <Box sx={{ height: isMobile ? '200px' : '420px' }}>
          <MapWrapper>
            <AppMap
              cords={[
                {
                  lat: detail.latitude,
                  lng: detail.longitude
                }
              ]}
            ></AppMap>
          </MapWrapper>
        </Box>
        <Divider sx={{ my: '24px' }} />
        {isMobile ? (
          <Box
            position={'sticky'}
            bottom={0}
            right={0}
            left={0}
            padding={'15px'}
            marginX={'-15px'}
            zIndex={(theme) => theme.zIndex.drawer + 1}
            sx={{ boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.1)', backgroundColor: 'basic.white', mb: '24px' }}
          >
            <InvestObjectInvite detail={detail} />
          </Box>
        ) : null}
      </>
    )
  }

  return (
    <Box sx={{ ...newsDetails.container, pb: { sm: '50px', xs: '16px' } }}>
      <AppButtonGoBackTo
        to={clientRoutes.investmentOpportunitiesMap.route}
        label={t('investObjectMap.back')}
        sx={newsDetails.back}
      />
      {loading ? (
        <NewsItem
          loading
          vertical
        />
      ) : (
        <InvestObjectContent />
      )}
      <Typography
        variant={'h4'}
        sx={fakeHeaderH4}
      >
        {t('investObjectsDetails.recommendation')}
      </Typography>
      <Box sx={{
        display: 'flex',
        gap: { sm: '30px', xs: '10px' },
        mb: '30px',
        flexWrap: isMobile ? 'wrap' : 'nowrap',
      }}
      >
        {(!restLoading ? recommendation : stubLoadingRest).map((item) => (
          <InvestObjectListItem
            loading={restLoading}
            sx={{ flex: { sm: 1, xs: 'auto' }, maxWidth: '365px', marginTop: { sm: 0, xs: '30px' } }}
            key={item.id}
            vertical
            item={item}
            url={`/investment-object/${item.id}`}
          />
        ))}
      </Box>
    </Box>
  )
}

export default InvestObjectDetailsPage
