import PropTypes from 'prop-types'
import { Box, List } from '@mui/material'
import EventDetailsInfoItem from '@/containers/event-details-info-list/event-details-info-item'
import calendarIcon from '@/assets/icons/calendar.svg'
import timeIcon from '@/assets/icons/time.svg'
import locationIcon from '@/assets/icons/location.svg'
import { toShortDate, toTime } from '@/utils/date'
import { styles } from '@/containers/event-details-info-list/EventDetailsInfoList.styles'

const componentProps = {
  stackStyles: PropTypes.object,
  alt: PropTypes.string,
  icon: PropTypes.string,
  height: PropTypes.number
}

const EventDetailsInfoList = ({ data }) => {
  const { location, startDate, endDate } = data

  const getStartTime = () => toTime(startDate)

  const getFullDate = () => {
    let eventStartDate = ''
    let eventEndDate = ''

    if (startDate) {
      eventStartDate = toShortDate(startDate)
    }
    if (endDate) {
      eventEndDate = toShortDate(endDate)
    }

    return eventStartDate.concat(eventEndDate ? ` - ${eventEndDate}` : '')
  }

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <List sx={styles.list}>
        <EventDetailsInfoItem
          alt={'Date'}
          src={calendarIcon}
          text={getFullDate()}
        />
        <EventDetailsInfoItem
          alt={'Time'}
          src={timeIcon}
          text={getStartTime()}
        />
        <EventDetailsInfoItem
          alt={'Location'}
          src={locationIcon}
          text={location}
        />
      </List>
    </Box>
  )
}

EventDetailsInfoList.propTypes = componentProps

export default EventDetailsInfoList
