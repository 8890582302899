import { useSnackbar } from 'notistack'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { InvestObjectService } from '@/services/invest-object-service'
import { toShortDate } from '@/utils/date'
import { useCallback, useEffect, useState } from 'react'
import useAxios from '@/hooks/use-axios'
import { styles } from '@/containers/admin-invest-object/admin-invest-object-control-pane/control-pane-styles'

export const AdminInvestObjectControlPane = () => {
  const [syncDate, setSyncDate] = useState('')
  const { fetchData } = useAxios({ service: InvestObjectService.getLastCompletedSync })
  const { fetchData: sync } = useAxios({ service: InvestObjectService.sync })
  const { enqueueSnackbar } = useSnackbar()

  const getLastCompletedSync = useCallback(async () => {
    const data = await fetchData(null)

    setSyncDate(data.completedOn)
  }, [fetchData])

  useEffect(() => {
    getLastCompletedSync()
  }, [getLastCompletedSync])

  const onClick = useCallback(async () => {
    await sync(null)

    enqueueSnackbar('Синхронізацію розпочато, це може зайняти якийсь час', { variant: 'info' })
  }, [sync, enqueueSnackbar])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto' }}>
      <Button
        variant='contained'
        onClick={onClick}
        sx={styles}
      >
        Cинхронізація з Prozorro
      </Button>
      <Typography
        variant='h6'
        sx={{ textAlign: 'right', mr: '8px' }}
      >
        {`Оновлено ${syncDate && toShortDate(syncDate)}`}
      </Typography>
    </Box>
  )
}
