import PropTypes from 'prop-types'

import AppCardItem from '@/components/app-card-item'

import mockImage from '@/assets/images/news-mock-img.png'
import EventItemBody from '@/containers/recent-events-banner/event-item-body'

const componentProps = {
  item: PropTypes.object,
  vertical: PropTypes.bool,
  loading: PropTypes.bool,
  url: PropTypes.string
}

const EventItem = ({ vertical, item = {}, url, loading }) => {
  return (
    <AppCardItem
      sx={{ width: !vertical ? '100%' : 'inherit' }}
      linkUrl={url}
      image={{ src: item.backgroundImage ? item.backgroundImage : mockImage, alt: item.title }}
      title={item.title}
      body={item.text}
      vertical={vertical}
      loading={loading}
    >
      <EventItemBody item={item} loading={loading} />
    </AppCardItem>
  )
}

EventItem.propTypes = componentProps

export default EventItem
