import { useRef } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import AppInfiniteScroll from '@/components/app-infinite-scroll'
import useBreakpoints from '@/hooks/use-breakpoints'
import InvestObjectListItem from '@/containers/invest-object-list/invest-object-list-item/InvestObjectListItem'
import { responsiveGap } from '@/pages/home-page/HomePage.styles'
import { theme } from '@/plugins/material-ui/theme'
import { hideScroll } from '@/containers/constants'
import InvestObjectSkeleton from '@/containers/invest-object-list/InvestObjectSkeleton'

const componentProps = {
  investObjectItems: PropTypes.array,
  totalInvestObject: PropTypes.number,
  getInvestObjectItems: PropTypes.func,
  clearItems: PropTypes.func
}

const InvestObjectList = ({
  InfiniteScroll,
  investObjectItems,
  totalInvestObject,
  getInvestObjectItems,
  clearItems
}) => {
  const layoutRef = useRef(null)
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()

  return (
    <Box
      ref={layoutRef}
      sx={{ ...hideScroll }}
    >
      <AppInfiniteScroll
        ref={InfiniteScroll}
        items={investObjectItems}
        parentRef={layoutRef}
        totalCount={totalInvestObject}
        fetchData={getInvestObjectItems}
        clearItems={clearItems}
        loader={<InvestObjectSkeleton />}
        // Increase the threshold to allow more elements to load
        // if the height of the parent is less than the height of the child.
        // https://github.com/danbovey/react-infinite-scroller/blob/v1.2.6/src/InfiniteScroll.js#L216
        threshold={500}
        noItems={<div key='no-items'>{t('investObjectMap.noItems')}</div>}
      >
        <Box
          key='items'
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: responsiveGap,
            py: theme.spacing(2)
          }}
        >
          {investObjectItems.map((item) => (
            <Box key={item.id}>
              <InvestObjectListItem
                vertical={isMobile}
                item={item}
                url={`/investment-object/${item.id}`}
              />
            </Box>
          ))}
        </Box>
      </AppInfiniteScroll>
    </Box>
  )
}
InvestObjectList.propTypes = componentProps

export default InvestObjectList
