import {
  ownershipType,
  ownershipTypeOptions
} from '@/containers/admin-invest-object/admin-invest-object-form/land-plot-section/constants'
import { portalUsageTypeOptions, usageType } from '@/pages/admin/admin-invest-object-edit/constants'

export const definedOwnershipTypeOptions = () =>
  ownershipTypeOptions().filter((e) => e.value !== ownershipType.notDefined)
export const usageTypes = () =>
  portalUsageTypeOptions()
    .filter((e) => e.value !== usageType.saleOrRent)
    .filter((e) => e.value !== usageType.other)

export const filterOperation = {
  equal: 'equal',
  in: 'in',
  overlaps: 'overlaps',
  greaterThanOrEqual: 'greaterThanOrEqual',
  lessThanOrEqual: 'lessThanOrEqual'
}

export const characteristicsTypeFilter = {
  field: 'characteristicsType',
  operation: filterOperation.equal
}

export const usageTypeFilter = {
  field: 'usageType',
  operation: filterOperation.in
}

export const landCategorySectionFilter = {
  field: 'landCategorySection',
  operation: filterOperation.in
}

export const realStateTypeCategorySectionFilter = {
  field: 'realtyType',
  operation: filterOperation.overlaps
}

export const ownershipTypeFilter = {
  field: 'ownershipType',
  operation: filterOperation.in
}

export const plotAreaFromFilter = {
  field: 'plotArea',
  operation: filterOperation.greaterThanOrEqual
}
export const plotAreaToFilter = {
  field: 'plotArea',
  operation: filterOperation.lessThanOrEqual
}

export const realtyTotalAreaSqMFromFilter = {
  field: 'realtyTotalAreaSqM',
  operation: filterOperation.greaterThanOrEqual
}
export const realtyTotalAreaSqMToFilter = {
  field: 'realtyTotalAreaSqM',
  operation: filterOperation.lessThanOrEqual
}

export const squareMinArea = 0
export const squareMaxArea = 10_000
