import { useInvestObjectSubmitForm } from '@/hooks/invest-object/use-invest-object-submit-form'
import {
  investObjectFormInitialValues,
  investObjectValidations,
  supportedLanguages,
} from '@/containers/invest-object-submit-form/constants'
import { useMemo } from 'react'
import InvestObjectSubmitFormSectionWrapper
  from '@/containers/invest-object-submit-form/sections/general/InvestObjectSubmitFormSectionWrapper'
import { styles } from '@/containers/invest-object-submit-form/styles'
import { Button, Grid } from '@mui/material'
import InvestObjectSubmitFormProgressBar
  from '@/containers/invest-object-submit-form/progress-bar/InvestObjectSubmitFormProgressBar'
import { useTranslation } from 'react-i18next'

const InvestObjectSubmitFormSection = (
  { 
    formPropsGeneral, 
    formPropsMulti, 
    section,
    index,
  }) => (<InvestObjectSubmitFormSectionWrapper
    title={section.title.text}
    status={section.isValid}
    index={index}
    id={section.key}
         >
    <section.component
      multiInputs={section.multiInputs}
      generalInputs={section.generalInputs}
      formPropsMulti={formPropsMulti}
      formPropsGeneral={formPropsGeneral}
      styles={styles}
    />
  </InvestObjectSubmitFormSectionWrapper>)

const InvestObjectSubmitForm = ({ onSubmit }) => {
  const {
    formProps,
    visibleSectionsData,
    isDirty,
    progress,
    handleSubmit: handleFormSubmit,
  } = useInvestObjectSubmitForm({
    activeForms: supportedLanguages,
    initialValues: investObjectFormInitialValues,
    initialValidations: investObjectValidations,
  })
  
  const { t } = useTranslation()

  const handleSubmitButtonClick = () => {
    const formData = handleFormSubmit()

    if (formData) {
      onSubmit(formData)
    }
  }

  const sectionComponents = useMemo(() => (
    visibleSectionsData.map((section, idx) => (
      <InvestObjectSubmitFormSection
        section={section}
        index={idx}
        formPropsGeneral={formProps}
        formPropsMulti={formProps}
        key={section.key}
      />))
  ), [formProps, visibleSectionsData])

  return (<>
    <Grid container justifyContent='space-between' sx={{ flexDirection: { lg: 'row', xs: 'column-reverse' } }}>
      <Grid
        item xs={12} xl={9}
        lg={8}
      >
        { sectionComponents }
      </Grid>
      <Grid
        item xs={12} xl={3}
        lg={4}
      >
        <InvestObjectSubmitFormProgressBar sections={visibleSectionsData} progress={progress} />
      </Grid>
    </Grid>
    <Grid>
      <Button
        sx={{ width: '239px', margin: { sm: '50px 0', xs: '35px 15px' } }}
        variant='contained'
        disabled={!isDirty}
        onClick={handleSubmitButtonClick}
      >
        { t('investObjects.submit') }
      </Button>
    </Grid>
  </>)
}

export default InvestObjectSubmitForm
