import i18next from 'i18next'

export const objectCodeFormat = (value) => {
  if (!value) {
    return undefined
  }

  if (!RegExp(/^ *((\d *- *\d)|(\d{2} *- *\d{3,5})) *$/gm).test(value)) {
    return i18next.t('investObjects.errors.objectCodeFormat')
  }

  return undefined
}
