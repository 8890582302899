import i18next from 'i18next'
import { noSpacesInString, requiredField, validatePassword } from '@/utils/validations/common'

export const errorCodes = {
  incorrectPassword: 'INCORRECT_PASSWORD',
  passwordsDoNotMatch: 'PASSWORDS_DO_NOT_MATCH'
}

export const changePasswordFormInitialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
}

export const changePasswordInputs = [
  {
    type: 'passwordInput',
    options: {
      name: 'currentPassword',
      required: true,
      label: i18next.t('changePassword.password')
    }
  },
  {
    type: 'passwordInput',
    options: {
      name: 'newPassword',
      required: true,
      label: i18next.t('changePassword.newPassword')
    }
  },
  {
    type: 'passwordInput',
    options: {
      name: 'confirmPassword',
      required: true,
      label: i18next.t('changePassword.confirmPassword')
    }
  }
]

export const changePasswordValidations = {
  currentPassword: [requiredField, noSpacesInString],
  newPassword: [requiredField, validatePassword, noSpacesInString],
  confirmPassword: [requiredField, validatePassword, noSpacesInString]
}
