import { useState, useCallback } from 'react'

const useAxios = ({ service, initialLoading = false, handleError = true }) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(initialLoading)

  const fetchData = useCallback(
    async (params, recaptchaToken) => {
      try {
        setLoading(true)
        const { data } = await service(params, recaptchaToken)
        setError(null)
        return data
      } catch (e) {
        setError(e)
        console.error(e)
        if (handleError) {
          throw e
        }
      } finally {
        setLoading(false)
      }
    },
    [handleError, service]
  )

  return { error, loading, fetchData }
}

export default useAxios
