import i18next from 'i18next'
import { email, requiredField, minMaxLength, validatePassword } from '@/utils/validations/common'
import { role } from '@/plugins/router/router-guards/RoleGuard'

export const userFormInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  role: role.ADMIN,
  notification: false
}
const rolesSelectOptions = () =>
  Object.keys(role)
    .filter((e) => e !== role.SUPER_ADMIN)
    .map((r) => ({
      value: r,
      text: i18next.t(`users.role.${r}`)
    }))

export const roleSelect = {
  type: 'select',
  options: {
    name: 'role',
    label: i18next.t('common.role'),
    required: true,
    SelectProps: {
      options: rolesSelectOptions()
    }
  }
}

export const userInputs = [
  {
    type: 'text',
    options: {
      name: 'email',
      required: true,
      label: i18next.t('common.email')
    }
  },
  {
    type: 'text',
    options: {
      name: 'firstName',
      required: true,
      label: i18next.t('common.name')
    }
  },
  {
    type: 'text',
    options: {
      name: 'lastName',
      required: true,
      label: i18next.t('common.surname')
    }
  },
  {
    type: 'passAutoGenerateInput',
    options: {
      name: 'password',
      required: true,
      label: i18next.t('common.password')
    }
  },
  roleSelect,
  {
    type: 'checkbox',
    options: {
      name: 'notification',
      label: i18next.t('users.userNotifications')
    }
  }
]

export const userValidations = {
  email: [requiredField, email],
  firstName: [requiredField, minMaxLength(3, 50)],
  lastName: [minMaxLength(3, 50)],
  password: [requiredField, validatePassword]
}
