import { useFilterBy } from '@/containers/invest-object-list/invest-object-list-filters/hooks/useFilterBy'
import {
  definedOwnershipTypeOptions,
  landCategorySectionFilter,
  ownershipTypeFilter,
  plotAreaFromFilter,
  plotAreaToFilter,
  realStateTypeCategorySectionFilter,
  realtyTotalAreaSqMFromFilter,
  realtyTotalAreaSqMToFilter,
  squareMaxArea,
  squareMinArea,
  usageTypeFilter,
  usageTypes
} from '@/containers/invest-object-list/invest-object-list-filters/constant'
import Chip from '@mui/material/Chip'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  createOptionsMap,
  getChipObj,
  getMultiOptions,
  getSquareAreaLabel
} from '@/containers/invest-object-list/invest-object-list-filter-short/constant'
import { useMemo } from 'react'
import { usageType } from '@/pages/admin/admin-invest-object-edit/constants'
import { landCategorySectionOptions } from '@/containers/admin-invest-object/admin-invest-object-form/land-plot-section/constants'
import { realtyTypesOptions } from '@/containers/admin-invest-object/admin-invest-object-form/real-estate-section/constants'
import { filterChipSX } from '@/containers/invest-object-list/invest-object-list-filters/ivest-object-list-filter-characteristic/constants'

const FilterByChip = ({ label, onClear, sx }) => {
  if (!label) {
    return null
  }

  return (
    <Tooltip
      title={label}
      arrow
    >
      <Chip
        label={label}
        variant='outlined'
        sx={{ ...filterChipSX, mr: '10px', mb: '10px', maxWidth: '180px', ...sx }}
        deleteIcon={<ClearIcon />}
        onClick={onClear}
        onDelete={onClear}
      />
    </Tooltip>
  )
}

export const InvestObjectListFilterShort = () => {
  const { getFilter, setFilter, clearFilter, clearAllFilters } = useFilterBy()
  const { t, i18n } = useTranslation()

  const landCategoryMap = useMemo(() => createOptionsMap(landCategorySectionOptions(i18n.language)), [i18n.language])
  const usageTypeMap = useMemo(() => createOptionsMap(usageTypes(i18n.language)), [i18n.language])
  const definedOwnershipMap = useMemo(
    () => createOptionsMap(definedOwnershipTypeOptions(i18n.language)),
    [i18n.language]
  )
  const selectedTypesMap = useMemo(() => createOptionsMap(realtyTypesOptions(i18n.language)), [i18n.language])

  const usageTypeSelected = getFilter(usageTypeFilter)?.value.filter((e) => e !== usageType.saleOrRent) ?? []
  const landCategorySectionSelected = getFilter(landCategorySectionFilter)?.value ?? []
  const ownershipTypeSelected = getFilter(ownershipTypeFilter)?.value ?? []
  const realStateTypeSelected = getFilter(realStateTypeCategorySectionFilter)?.value ?? []

  const plotAreaRangeFrom = getFilter(plotAreaFromFilter)?.value ?? squareMinArea
  const plotAreaRangeTo = getFilter(plotAreaToFilter)?.value ?? squareMaxArea

  const realtyTotalAreaSqMRangeFrom = getFilter(realtyTotalAreaSqMFromFilter)?.value ?? squareMinArea
  const realtyTotalAreaSqMRangeTo = getFilter(realtyTotalAreaSqMToFilter)?.value ?? squareMaxArea

  const plotRange = { from: plotAreaRangeFrom, to: plotAreaRangeTo }
  const realtyRange = { from: realtyTotalAreaSqMRangeFrom, to: realtyTotalAreaSqMRangeTo }

  const chips = [
    getChipObj(
      plotAreaFromFilter.field,
      getSquareAreaLabel(plotRange, squareMinArea, squareMaxArea, t('investObjectMap.unit.ha')),
      () => {
        clearFilter(plotAreaFromFilter)
        clearFilter(plotAreaToFilter)
      }
    ),
    getChipObj(
      realtyTotalAreaSqMFromFilter.field,
      getSquareAreaLabel(realtyRange, squareMinArea, squareMaxArea, t('investObjectMap.unit.m2')),
      () => {
        clearFilter(realtyTotalAreaSqMFromFilter)
        clearFilter(realtyTotalAreaSqMToFilter)
      }
    ),
    ...getMultiOptions({
      selected: landCategorySectionSelected,
      filter: landCategorySectionFilter,
      clear: clearFilter,
      set: setFilter,
      map: landCategoryMap
    }),
    ...getMultiOptions({
      selected: usageTypeSelected,
      filter: usageTypeFilter,
      clear: clearFilter,
      set: setFilter,
      map: usageTypeMap
    }),
    ...getMultiOptions({
      selected: ownershipTypeSelected,
      filter: ownershipTypeFilter,
      clear: clearFilter,
      set: setFilter,
      map: definedOwnershipMap
    }),
    ...getMultiOptions({
      selected: realStateTypeSelected,
      filter: realStateTypeCategorySectionFilter,
      clear: clearFilter,
      set: setFilter,
      map: selectedTypesMap
    })
  ].filter((e) => !!e.label)

  if (chips.length === 0) {
    return null
  }

  return (
    <Box sx={{ my: '12px', display: 'flex', flexWrap: 'wrap' }}>
      {chips.map(({ label, field, onClear }) => (
        <FilterByChip
          key={field}
          label={label}
          onClear={onClear}
        />
      ))}
      <FilterByChip
        onClear={clearAllFilters}
        key='all'
        label={t('investObjectMap.filter.clearAll')}
        sx={{ maxWidth: '220px' }}
      />
    </Box>
  )
}
