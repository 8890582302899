import { Link } from 'react-router-dom'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import React from 'react'
import i18next from 'i18next'
import { langTypeColumn } from '@/containers/constants'

export const supportsProgramsTableColumns = [
  {
    headerName: i18next.t('supportPrograms.table.title'),
    field: 'title',
    renderCell: ({ id, value }) => <Link to={adminRoutes.editSupportProgram.path + `/${id}`}>{value}</Link>
  },
  { headerName: i18next.t('supportPrograms.table.authorEmail'), field: 'authorEmail' },
  { headerName: i18next.t('supportPrograms.table.publishedDate'), field: 'publishedDate', type: 'dateTime' },
  { headerName: i18next.t('supportPrograms.table.publishedLang'), field: 'publishedTranslations', ...langTypeColumn }
]
export const supportProgramsTableSettings = { checkboxSelection: true }
