import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const StepNumber = ({ index, size, sx }) => {
  const stepNumberSX = {
    border: '2px solid #373A36',
    borderRadius: '50%',
    width: `${size}px`,
    height: `${size}px`,
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: 500,
    color: '#000000',
  }
  
  return <Typography sx={{ ...stepNumberSX, ...sx }}>{index + 1}</Typography>
}

StepNumber.propTypes = {
  size: PropTypes.number,
  index: PropTypes.number,
}

StepNumber.defaultProps = {
  size: 24,
}

export default StepNumber
