import { FormControl, InputLabel } from '@mui/material'
import PropTypes from 'prop-types'
import { cloneElement } from 'react'
import useBreakpoints from '@/hooks/use-breakpoints'
import { theme } from '@/plugins/material-ui/theme'

const componentProps = {
  label: PropTypes.string,
  children: PropTypes.node
}

const AppFormControl = ({ label, children, selected = false, minWidth = 'auto', mobileView = false }) => {
  const { isLarge } = useBreakpoints()
  const selectedControl =
    !isLarge && selected
      ? {
          '.MuiInputBase-formControl': {
            backgroundColor: 'rgba(89, 189, 47, 0.1)'
          },
          '.MuiInputBase-formControl,.MuiSelect-icon': {
            color: theme.palette.secondary.dark
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.dark
          }
        }
      : {}
  return (
    <FormControl
      sx={{
        ...selectedControl,
        minWidth: isLarge ? 'auto' : minWidth,
        maxWidth: isLarge ? 'auto' : mobileView ? '100%' : 'auto',
        width: isLarge ? '160px' : mobileView ? '100%' : 'auto',
        flexShrink: 0
      }}
    >
      {isLarge ? (
        <InputLabel
          shrink
          sx={{ position: 'relative', transform: 'none', fontSize: '10px', lineHeight: '12px', mb: '4px' }}
        >
          {label}
        </InputLabel>
      ) : null}
      {cloneElement(children, { label })}
    </FormControl>
  )
}

AppFormControl.propTypes = componentProps

export default AppFormControl
