import palette from '@/plugins/material-ui/palette'

const link = {
  styleOverrides: {
    root: {
      color: palette.text.primary,
      outlineColor: palette.primary.main,
      '&:hover': {
        color: palette.primary.main
      }
    },
    underlineAlways: {
      '&:hover': {
        color: palette.primary.light
      }
    },
    button: {
      color: palette.primary.main,
      '&:hover': {
        color: palette.primary.dark
      }
    }
  }
}

export default link
