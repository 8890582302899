import { useCallback, useEffect, useState } from 'react'
import AdminPageHeader from '@/containers/admin-page-header'
import AppForm from '@/components/app-form'
import AppInputs from '@/components/app-form/app-inputs'
import useAxios from '@/hooks/use-axios'
import useForm from '@/hooks/use-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { adminEditContainerSX } from '@/containers/constants'
import { UsersService } from '@/services/users-service'
import { profileFormInitialValues, profileInputs, profileValidations } from '@/pages/admin/admin-profile-page/constants'
import { Button, FormGroup } from '@mui/material'
import { Save } from '@mui/icons-material'
import Box from '@mui/material/Box'
import { styles } from '@/pages/admin/admin-profile-page/styles'
import { adminRoutes } from '@/plugins/router/admin-router/routes'

const AdminProfilePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [profileInfo, setProfileInfo] = useState({})
  const { email, firstName, lastName, notification, id, role } = profileInfo

  const {
    isDirty,
    data: profileData,
    formProps,
    handleValidate,
    setMappedData
  } = useForm({
    inputs: profileInputs,
    initialValues: profileFormInitialValues,
    validations: profileValidations
  })

  const { fetchData } = useAxios({ service: UsersService.getUserProfile })
  const { fetchData: updateData } = useAxios({ service: UsersService.putUserProfile })

  const getProfileInfo = useCallback(async () => {
    const data = await fetchData()
    setProfileInfo(data)
  }, [fetchData])

  const updateProfileInfo = useCallback(
    (data) => {
      return updateData({
        firstName: data?.firstName,
        lastName: data?.lastName,
        notification: data?.notification
      })
    },
    [updateData]
  )

  const onClick = useCallback(() => {
    navigate(adminRoutes.changePassword.path)
  }, [navigate])

  const onSubmit = async () => {
    if (handleValidate()) {
      try {
        const response = await updateProfileInfo(profileData)
        setProfileInfo({ ...profileInfo, ...profileData })
        enqueueSnackbar(
          t('common.successMessages.saveChangesSuccess', { fullName: `${response.firstName} ${response.lastName}` }),
          {
            variant: 'success'
          }
        )
      } catch (error) {
        enqueueSnackbar(t(`error.${error?.response?.status}`).concat(`. ${error?.response?.data?.message}`), {
          variant: 'error'
        })
      }
    }
  }

  useEffect(() => {
    const initialData = {
      ...profileFormInitialValues,
      email,
      firstName,
      lastName,
      notification,
      role: t(`users.role.${role}`)
    }

    if (id) {
      setMappedData(initialData)
    } else {
      getProfileInfo().catch()
    }
  }, [setMappedData, getProfileInfo, email, firstName, lastName, notification, id, role, t])

  return (
    <Box sx={{ ...adminEditContainerSX }}>
      <AdminPageHeader
        title={t('profile.profileDetails')}
        styles={{ minHeight: 'auto' }}
      />

      <AppForm onFormSubmit={onSubmit}>
        <FormGroup sx={{ flexGrow: 1, ...styles.form }}>
          <AppInputs
            inputs={profileInputs}
            {...formProps}
          />
          <Button
            onClick={onClick}
            sx={styles.inputs.changePassword}
          >
            {t('profile.changePassword')}
          </Button>
        </FormGroup>

        <Button
          variant='contained'
          endIcon={<Save />}
          disabled={!isDirty}
          type='submit'
          sx={{ marginLeft: 'auto' }}
        >
          {t('common.buttons.saveChanges')}
        </Button>
      </AppForm>
    </Box>
  )
}

export default AdminProfilePage
