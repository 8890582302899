import PropTypes from 'prop-types'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { theme } from '@/plugins/material-ui/theme'
import { styles } from '@/containers/event-details-info-list/event-details-info-item/EventDetailsInfoItem.styles'

const componentProps = {
  alt: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.number,
  text: PropTypes.string,
  children: PropTypes.node
}

const EventDetailsInfoItem = ({ alt, src, height = 20, text }) => {
  return (
    <ListItem sx={styles.item}>
      <ListItemIcon sx={{ minWidth: 'initial' }}>
        <img
          style={{
            paddingRight: theme.spacing(1.5)
          }}
          alt={alt}
          src={src}
          height={height}
        />
      </ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          fontWeight: '600'
        }}
      />
    </ListItem>
  )
}

EventDetailsInfoItem.propTypes = componentProps

export default EventDetailsInfoItem
