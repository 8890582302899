import React from 'react'
import PropTypes from 'prop-types'
import MainNavMenu from '@/components/main-nav-menu'
import LanguageToggle from '@/components/app-language-toggle'
import { Box, Slide } from '@mui/material'
import useBreakpoints from '@/hooks/use-breakpoints'
import MobileToolbar from './MobileToolbar'
import Searchbar from './Searchbar'
import { searchbarTransitionDuration } from './constants'

const HeaderToolbarProps = {
  toggleBackdrop: PropTypes.func.isRequired
}

const HeaderToolbar = ({ toggleBackdrop, fullWidthHeader }) => {
  const [searchOpen, setSearchOpen] = React.useState(false)

  const [barVisible, setBarVisible] = React.useState(true)

  React.useEffect(() => {
    if (!searchOpen) {
      setTimeout(() => {
        setBarVisible(true)
      }, searchbarTransitionDuration)
    } else {
      setBarVisible(false)
    }
  }, [searchOpen])

  const { isLarge } = useBreakpoints()

  return isLarge ? (
    <>
      {barVisible && (
        <>
          <MainNavMenu
            toggleBackdrop={toggleBackdrop}
            fullWidthHeader={fullWidthHeader}
          />

          <LanguageToggle />
        </>
      )}

      <Box sx={{ display: 'flex', width: !barVisible ? '100%' : 'auto' }}>
        <Searchbar
          open={searchOpen}
          setOpen={setSearchOpen}
        />
      </Box>
    </>
  ) : (
    <>
      <MobileToolbar
        searchOpen={searchOpen}
        setSearchOpen={setSearchOpen}
      />
      {searchOpen ? (
        <Box
          sx={{
            opacity: searchOpen ? '1' : '0',
            transition: 'opacity 300ms ease',
            position: 'absolute',
            left: 0,
            right: 0,
            top: '70px',
            bgcolor: 'basic.white',
            padding: '18px 23px',
            zIndex: (theme) => theme.zIndex.drawer - 1
          }}
        >
          <Slide
            in
            direction='right'
          >
            <Box>
              <Searchbar
                open
                fullWidth
                setOpen={setSearchOpen}
              />
            </Box>
          </Slide>
        </Box>
      ) : null}
    </>
  )
}

HeaderToolbar.propTypes = HeaderToolbarProps

export default HeaderToolbar
