import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import BusinessHelpItem from '@/containers/business-help/business-items/BusinessHelpItem'
import { baseContainerSX } from '@/containers/constants'
import { style } from '@/pages/home-page/HomePage.styles'

const BusinessHelp = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box
        sx={{
        ...baseContainerSX,
        ...style.section,
        px: { ...style.section.px, xs: 0 }
      }}
        data-testid={'helpBusiness'}
      >
        <Typography
          sx={{
            px: { xs: style.section.px.xs, sm: style.section.px.sm, md: 0 },
            maxWidth: '1005px',
            fontWeight: 600,
            my: 0,
          }}
          variant='h1'
        >
          {t('helpBusiness.title')}
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: '#00782E' }}>
        <BusinessHelpItem
          sx={{
            padding: 0,
            margin: { xs: '14px auto 0', sm: '28px auto 0' },
            maxWidth: '1630px',
            flexDirection: { sm: 'row', xs: 'column' },
          }}
        />
      </Box>
    </Box>
  )
}

export default BusinessHelp
