import { Box, Button } from '@mui/material'
import { truncateTextByLines } from '@/utils/common'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const AppShowMore = ({ children, lines = 5, sx = {}, contentSx = {} }) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const [buttonShow, setButtonShow] = useState(false)

  const ref = useRef()

  useEffect(() => {
    setButtonShow(
      ref.current.offsetHeight < ref.current.scrollHeight || ref.current.offsetWidth < ref.current.scrollWidth
    )
  }, [])

  return (
    <>
      <Box
        ref={ref}
        sx={{
          ...truncateTextByLines(!showMore ? lines : 'none'),
          ...contentSx
        }}
      >
        {children}
      </Box>
      {buttonShow ? (
        <Button
          sx={{ padding: 0, ...sx }}
          color='primary'
          variant='text'
          onClick={() => setShowMore((e) => !e)}
        >
          {showMore ? t('common.showLess') : t('common.showMore')}
        </Button>
      ) : null}
    </>
  )
}
export default AppShowMore
