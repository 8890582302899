import i18next from 'i18next'

export const tagsForm = [
  {
    type: 'text',
    options: {
      name: 'uk',
      placeholder: i18next.t('common.labels.ukr'),
      size: 'small'
    }
  },
  {
    type: 'text',
    options: {
      name: 'en',
      placeholder: i18next.t('common.labels.eng'),
      size: 'small'
    }
  }
]

export const tagsFormInitialValue = {
  uk: '',
  en: ''
}
