import { minMaxLength, validRegExp } from '@/utils/validations/common'
import { decimalPattern } from '@/constants/default'
import i18next from 'i18next'

export const roads = 'internationalAndStateRoads'
export const borders = 'borderCrossingPoints'

export const transportSectionValidations = (value = []) => {
  const errorsObject = {}
  value.forEach(({ id, name, distanceKM }) => {
    const nameError = minMaxLength(1, 50)(name)
    const distanceError = validRegExp({
      regexp: decimalPattern(1, 5),
      errorMsg: i18next.t('investObjects.errors.decimalFormat', { max: 5 })
    })(distanceKM)
    errorsObject['name' + id] = nameError
    errorsObject['distance' + id] = distanceError
  })
  return errorsObject
}
