import { Box, Grid, Typography } from '@mui/material'
import { baseContainerSX } from '@/containers/constants'
import { Trans, useTranslation } from 'react-i18next'
import Divider from '@mui/material/Divider'

import PartnerItem from '@/containers/partner-item/PartnerItem'
import { partners } from '@/pages/partners-page/constant'
import { responsiveGapV2 } from '@/pages/home-page/HomePage.styles'
import useBreakpoints from '@/hooks/use-breakpoints'

const PartnersPage = () => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()

  return (
    <Box sx={{ ...baseContainerSX, px: { md: '44px', sm: '24px', xs: '16px' }, width: '100%', py: responsiveGapV2 }}>
      <Typography
        variant='h1'
        sx={{ mb: isMobile ? '20px' : '30px' }}
      >
        {t('partners.title')}
      </Typography>

      <Typography
        variant='h5'
        sx={{
          mb: isMobile ? '20px' : '42px',
          fontSize: isMobile ? '17px' : '20px',
          lineHeight: isMobile ? '20px' : '24px',
          fontWeight: isMobile ? '500' : '600',
          letterSpacing: '-0.006em'
        }}
      >
        <Trans i18nKey='partners.subtitle'>
          title 1 default
          <br />
          title 2 default
        </Trans>
      </Typography>
      <Divider sx={{ mb: '34px' }} />
      <Grid
        container
        spacing={'30px'}
      >
        {partners(t).map((content) => (
          <Grid
            key={content.title}
            item
            md={3}
            sm={6}
            xs={12}
          >
            <PartnerItem content={content} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default PartnersPage
