import { useState } from 'react'

export const useHideAndShow = () => {
  const [hideInput, setHideInput] = useState(true)

  const toggleHideInput = () => {
    setHideInput((prevState) => !prevState)
  }

  return {
    hideInput,
    toggleHideInput
  }
}
