import AppChipWithTooltip from '@/components/app-chip-with-tooltip'
import AddIcon from '@mui/icons-material/Add'

const AppAddAbleTag = (handleAddTag, item, chosenLanguage, tagTooltip) => (
  <AppChipWithTooltip
    key={item.id}
    chipLabel={item.title[chosenLanguage]}
    tooltipLabel={item.title[tagTooltip]}
    icon={<AddIcon />}
    onIconClick={() => handleAddTag(item)}
  />
)
export default AppAddAbleTag
