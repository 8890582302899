export const styles = {
  clientLayout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    mt: { sm: '90px', xs: '70px' },
    width: '100%',
    overflowX: 'hidden'
  }
}
