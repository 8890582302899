import FeedIcon from '@mui/icons-material/Feed'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import EventIcon from '@mui/icons-material/Event'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import Person from '@mui/icons-material/Person'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import MapIcon from '@mui/icons-material/Map'
import StyleIcon from '@mui/icons-material/Style'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'
import FindReplaceIcon from '@mui/icons-material/FindReplace'
import { adminRoutes } from '@/plugins/router/admin-router/routes'

import { role } from '@/plugins/router/router-guards/RoleGuard'

export const getSideBarItems = (activeRole = role.ADMIN, t) => {
  const users = {
    label: t('adminNavigation.users.label'),
    icon: <SupervisedUserCircleIcon />,
    prefix: adminRoutes.users.path,
    subItems: [
      {
        label: t('adminNavigation.users.list'),
        icon: <FormatListBulletedIcon />,
        link: adminRoutes.users.path
      },
      {
        label: t('adminNavigation.users.create'),
        icon: <PlaylistAddIcon />,
        link: adminRoutes.createUser.path
      }
    ]
  }
  const news = {
    label: t('adminNavigation.news.label'),
    icon: <FeedIcon />,
    prefix: adminRoutes.news.path,
    subItems: [
      {
        label: t('adminNavigation.news.list'),
        icon: <FormatListBulletedIcon />,
        link: adminRoutes.news.path
      },
      {
        label: t('adminNavigation.news.createNews'),
        icon: <PlaylistAddIcon />,
        link: adminRoutes.createNews.path
      }
    ]
  }
  const supportPrograms = {
    label: t('adminNavigation.supportPrograms.label'),
    icon: <AccessibilityNewIcon />,
    prefix: adminRoutes.supportPrograms.path,
    subItems: [
      {
        label: t('adminNavigation.supportPrograms.list'),
        icon: <FormatListBulletedIcon />,
        link: adminRoutes.supportPrograms.path
      },
      {
        label: t('adminNavigation.supportPrograms.create'),
        icon: <PlaylistAddIcon />,
        link: adminRoutes.createSupportPrograms.path
      }
    ]
  }
  const events = {
    label: t('adminNavigation.events.label'),
    icon: <EventIcon />,
    prefix: adminRoutes.events.path,
    subItems: [
      {
        label: t('adminNavigation.events.list'),
        icon: <FormatListBulletedIcon />,
        link: adminRoutes.events.path
      },
      {
        label: t('adminNavigation.events.create'),
        icon: <PlaylistAddIcon />,
        link: adminRoutes.createEvent.path
      }
    ]
  }
  const invest = {
    label: t('adminNavigation.investObjects.label'),
    icon: <MapIcon />,
    prefix: adminRoutes.investObjects.path,
    subItems: [
      {
        label: t('adminNavigation.investObjects.list'),
        icon: <FormatListBulletedIcon />,
        link: adminRoutes.investObjects.path
      },
      {
        label: t('adminNavigation.investObjects.create'),
        icon: <PlaylistAddIcon />,
        link: adminRoutes.createInvestObject.path
      }
    ]
  }
  const communication = {
    label: t('adminNavigation.communicationForms.label'),
    icon: <PermPhoneMsgIcon />,
    link: adminRoutes.communicationForms.path,
    forAll: true
  }
  const tags = {
    label: t('adminNavigation.tags.label'),
    icon: <StyleIcon />,
    link: adminRoutes.tags.path,
    forAll: true
  }
  const changelog = {
    label: t('adminNavigation.changelog.label'),
    icon: <FindReplaceIcon />,
    link: adminRoutes.changelog.path,
    forAll: true
  }
  const sidebarAdminItems = () => [users, news, supportPrograms, events, invest, communication, tags, changelog]
  const sideBarMarketItems = () => [news, supportPrograms, events, communication, tags, changelog]
  const sideBarAnalyticsItems = () => [invest, communication, changelog]

  switch (activeRole) {
    case role.SUPER_ADMIN:
      return sidebarAdminItems(t)
    case role.ADMIN:
      return sidebarAdminItems(t)
    case role.ANALYTICS:
      return sideBarAnalyticsItems(t)
    case role.MARKETING:
      return sideBarMarketItems(t)
    default:
      return []
  }
}

export const profile = {
  icon: <Person />,
  link: adminRoutes.profile.path
}
