import PropTypes from 'prop-types'
import { mainMenuItems } from './constants'
import MainNavMenuItem from './MainNavMenuItem'
import { Box } from '@mui/material'
import MobileNavMenuItem from './MobileNavMenuItem'

const MainNavMenuProps = {
  toggleBackdrop: PropTypes.func,
  mobile: PropTypes.bool
}

const MainNavMenu = ({ toggleBackdrop, mobile = false, toggleMenu }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: mobile ? 'column' : 'row',
        py: mobile ? 1 : 0
      }}
      component='nav'
    >
      {mainMenuItems.map((menuItem) => {
        return mobile ? (
          <MobileNavMenuItem
            key={menuItem.label}
            menuItem={menuItem}
            toggleMenu={toggleMenu}
          />
        ) : (
          <MainNavMenuItem
            key={menuItem.label}
            menuItem={menuItem}
            toggleBackdrop={toggleBackdrop}
          />
        )
      })}
    </Box>
  )
}

MainNavMenu.propTypes = MainNavMenuProps

export default MainNavMenu
