import { useCallback, useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import AdminSidebar from '@/containers/layouts/admin/admin-sidebar'
import AdminHeader from '@/containers/layouts/admin/admin-header'
import AdminRouter from '@/plugins/router/admin-router/AdminRouter'

import useBreakpoints from '@/hooks/use-breakpoints'
import { useAuth } from '@/context/auth-context'
import { drawerWidth } from '@/containers/layouts/admin/admin-sidebar/AdminSidebar'
import { setupCatchGlobalError } from '@/utils/request'
import { useAppErrorContext } from '@/context/app-error-context'
import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

const AdminLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { i18n } = useTranslation()
  const { accessToken, tempPassword } = useAuth()
  const { isDesktop } = useBreakpoints()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const drawerVariant = isDesktop ? 'permanent' : 'temporary'

  const mainMarginLeft = accessToken && (isDesktop || mobileOpen) ? `${drawerWidth}px` : '0px'

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const { setError } = useAppErrorContext()

  setupCatchGlobalError(setError)

  useEffect(() => {
    setMobileOpen(false)
  }, [isDesktop])

  useEffect(() => {
    i18n.changeLanguage('uk').catch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeHandle = useCallback(
    (snackbarId) => (
      <IconButton
        sx={{ color: 'common.white' }}
        onClick={() => closeSnackbar(snackbarId)}
      >
        <CloseIcon />
      </IconButton>
    ),
    [closeSnackbar]
  )
  useEffect(() => {
    let snackbarId
    if (accessToken && tempPassword) {
      snackbarId = enqueueSnackbar(
        'Ваш обліковий запис використовує тимчасовий пароль. Будь-ласка змініть на постійний пароль',
        {
          variant: 'warning',
          persist: true,
          action: closeHandle
        }
      )
    }

    return () => {
      if (snackbarId) {
        closeSnackbar(snackbarId)
      }
    }
  }, [accessToken, closeHandle, closeSnackbar, enqueueSnackbar, tempPassword])

  return (
    <Box>
      <AdminHeader handleMenuClick={handleDrawerToggle} />
      {accessToken && (
        <AdminSidebar
          isMobileOpen={mobileOpen}
          drawerVariant={drawerVariant}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          marginLeft: mainMarginLeft
        }}
      >
        <Toolbar />
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <AdminRouter />
        </Box>
      </Box>
    </Box>
  )
}

export default AdminLayout
