import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'

export const AuthService = {
  login: (credentials) => {
    return request.post(URLs.login, { ...credentials })
  },
  refresh: (refreshToken) => {
    return request.post(URLs.refresh, { refreshToken })
  }
}
