import { createElement } from 'react'
import PropTypes from 'prop-types'

import { FormLabel, MenuItem, TextField } from '@mui/material'
import FileUploader from '@/components/file-uploader'
import AppCheckbox from '@/components/app-checkbox'
import AppPassAutoGenerateInput from '@/components/app-pass-auto-generate-input'
import AppEditor from '@/components/app-editor'

import './AppInput.css'

import { editorConfig } from '@/constants/tinyMCEConfig'
import AppRangeDatePicker from '@/components/app-range-date-picker'
import AppImageUploader from '@/components/app-image-uploader'
import AppMultipleImagesUploader from '@/components/app-multiple-images-uploader'
import AppMultipleFileUploader from '@/components/app-multiple-file-uploader'
import AppRadioGroup from '@/components/app-radio-group'
import AppDatepicker from '@/components/app-date-picker'
import AppMaskInput from '@/components/app-mask-input'
import AppCheckboxGroup from '@/components/app-checkbox-group'
import { useTranslation } from 'react-i18next'
import AppPassInput from '@/components/app-pass-input'

const AppInputsProps = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  inputs: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleBulkBlur: PropTypes.func,
  disabled: PropTypes.bool,
  handleFileChange: PropTypes.func,
  handleEditorChange: PropTypes.func,
  handleRangeChange: PropTypes.func,
  handleRangeBlur: PropTypes.func,
  handleEditorBlur: PropTypes.func,
  handleImageChange: PropTypes.func
}

const AppInputs = ({
  inputs,
  handleChange,
  handleBulkChange,
  handleFieldValidation,
  data,
  errors,
  handleBlur,
  handleBulkBlur,
  disabled = false,
  handleFileChange,
  handleEditorChange,
  handleEditorInitialValue,
  handleRangeChange,
  handleRangeBlur,
  handleEditorBlur,
  handleImageChange
}) => {
  const { t } = useTranslation()
  const text = (input) =>
    createElement(TextField, {
      key: input.options.name,
      value: data[input.options.name],
      error: Boolean(errors[input.options.name]),
      helperText: errors[input.options.name] || ' ',
      onChange: handleChange(input.options.name),
      onBlur: handleBlur(input.options.name),
      disabled: disabled || input.options.disabled,
      text: t(input.text),
      ...input.options,
      label: t(input.options.label),
      placeholder: t(input.options.placeholder),
      header: t(input.options.header)
    })

  const passAutoGenerateInput = (input) =>
    createElement(AppPassAutoGenerateInput, {
      key: input.options.name,
      input: input,
      value: data[input.options.name],
      error: Boolean(errors[input.options.name]),
      helperText: errors[input.options.name] || ' ',
      onChange: handleChange(input.options.name),
      onBlur: handleBlur(input.options.name),
      disabled: disabled || input.options.disabled,
      ...input.options
    })

  const passwordInput = (input) =>
    createElement(AppPassInput, {
      key: input.options.name,
      input: input,
      value: data[input.options.name],
      error: Boolean(errors[input.options.name]),
      helperText: errors[input.options.name] || ' ',
      onChange: handleChange(input.options.name),
      onBlur: handleBlur(input.options.name),
      disabled: disabled || input.options.disabled,
      ...input.options
    })

  const textEditor = (input) =>
    createElement(AppEditor, {
      key: input.options.name,
      input: input,
      value: data[input.options.name],
      disabled: disabled,
      handleEditorChange: (newValue, editor) => handleEditorChange(input.options.name)(newValue, editor),
      onBlur: (_e, editor) => handleEditorBlur(input.options.name)(editor),
      error: errors[input.options.name] || '',
      init: { ...editorConfig, ...input.options.editorConfig },
      changeInitialValue: (newValue) => handleEditorInitialValue(input.options.name)(newValue),
      ...input.options
    })

  const select = (input) =>
    createElement(
      TextField,
      {
        select: true,
        key: input.options.name,
        value: data[input.options.name],
        error: Boolean(errors[input.options.name]),
        helperText: errors[input.options.name] || ' ',
        onChange: handleChange(input.options.name),
        onBlur: handleBlur(input.options.name),
        disabled: disabled || input.options.disabled,
        ...input.options,
        label: t(input.options.label)
      },
      input.options.SelectProps.options.map((option) => (
        <MenuItem
          key={option.text}
          value={option.value}
          {...option}
        >
          {option.text}
        </MenuItem>
      ))
    )

  const file = (input) =>
    createElement(FileUploader, {
      key: input.options.name,
      value: data[input.options.name],
      showPreview: input.options.showPreview || true,
      onChangeFile: handleFileChange(input.options.name),
      disabled: disabled || input.options.disabled,
      ...input.options
    })

  const multipleFileUploader = (input) =>
    createElement(AppMultipleFileUploader, {
      key: input.options.name,
      value: data[input.options.name],
      onChangeFiles: handleFileChange(input.options.name),
      disabled: disabled || input.options.disabled,
      ...input.options,
      header: t(input.options.header)
    })

  const checkbox = (input) =>
    createElement(AppCheckbox, {
      key: input.options.name,
      input: input,
      checked: data[input.options.name],
      handleChange: handleChange(input.options.name),
      disabled: disabled || input.options.disabled,
      ...input.options
    })

  const dateRange = (input) =>
    createElement(AppRangeDatePicker, {
      key: input.options.name,
      value: data[input.options.name],
      errors: errors[input.options.name],
      helperTexts: errors[input.options.name] || ' ',
      onChange: handleRangeChange(input.options.name),
      onBlur: handleRangeBlur(input.options.name),
      ...input.options
    })

  const date = (input) => {
    if (!input.options.enabled) {
      return null
    }

    return createElement(AppDatepicker, {
      key: input.options.name,
      value: data[input.options.name],
      error: Boolean(errors[input.options.name]),
      helperText: errors[input.options.name] || ' ',
      onChange: handleRangeChange(input.options.name),
      onBlur: handleRangeBlur(input.options.name),
      ...input.options
    })
  }

  const imageUploader = (input) =>
    createElement(AppImageUploader, {
      key: input.options.name,
      value: data[input.options.name],
      onImageChange: handleImageChange(input.options.name),
      validationError: errors[input.options.name] || '',
      ...input.options
    })

  const multipleImageUploader = (input) =>
    createElement(AppMultipleImagesUploader, {
      key: input.options.name,
      value: data[input.options.name],
      error: Boolean(errors[input.options.name]),
      helperText: errors[input.options.name] || ' ',
      onImagesChange: handleImageChange(input.options.name),
      ...input.options,
      header: t(input.options.header)
    })

  const radioGroup = (input) =>
    createElement(AppRadioGroup, {
      key: input.options.name,
      value: data[input.options.name],
      onChange: handleChange(input.options.name),
      error: Boolean(errors[input.options.name]),
      helperText: errors[input.options.name],
      ...input.options
    })

  const maskInput = (input) =>
    createElement(AppMaskInput, {
      key: input.options.name,
      value: data[input.options.name],
      onChange: handleChange(input.options.name),
      onBlur: handleBlur(input.options.name),
      error: Boolean(errors[input.options.name]),
      helperText: errors[input.options.name],
      mask: input.options.mask,
      ...input.options
    })

  const label = (input) =>
    createElement(FormLabel, {
      key: input.options.name,
      ...input.options
    })

  const custom = (input) =>
    createElement(input.component, {
      key: input.options.name,
      handleChange,
      handleBulkChange,
      handleBulkBlur,
      handleFieldValidation,
      data,
      errors,
      ...input.options
    })

  const checkboxGroup = (input) =>
    createElement(AppCheckboxGroup, {
      key: input.options.name,
      value: data[input.options.name],
      onChange: handleChange(input.options.name),
      error: Boolean(errors[input.options.name]),
      helperText: errors[input.options.name],
      ...input.options
    })

  const fields = {
    text,
    textEditor,
    checkbox,
    passAutoGenerateInput,
    select,
    file,
    date,
    multipleFileUploader,
    dateRange,
    label,
    imageUploader,
    multipleImageUploader,
    radioGroup,
    maskInput,
    checkboxGroup,
    custom,
    passwordInput
  }

  return <>{inputs.map((input) => fields[input.type](input))}</>
}

AppInputs.propTypes = AppInputsProps

export default AppInputs
