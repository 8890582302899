import { createContext, useContext, useEffect, useState } from 'react'
import { LayoutRefContext } from '@/containers/layouts/client/client-layout/ClientLayout'
import { useLocation } from 'react-router-dom'

export const ScrollContext = createContext({})

export const ScrollProvider = ({ children }) => {
  const layoutRef = useContext(LayoutRefContext)
  const [ref, setRef] = useState(layoutRef)
  const { pathname } = useLocation()

  useEffect(() => {
    ref.current?.scrollTo(0, 0)

    return () => {
      setRef(layoutRef)
    }
  }, [ref, pathname, layoutRef])
  return <ScrollContext.Provider value={{ ref, setRef }}>{children}</ScrollContext.Provider>
}

export const useScrollContext = () => useContext(ScrollContext)
