import { createContext, useCallback, useContext, useState } from 'react'

const clientLayoutRenderDefaultValues = {
  isFooterRender: true,
  setIsFooterRender: () => {},
  isHeaderRender: true,
  setIsHeaderRender: () => {},
  isContactFormRender: true,
  setIsContactFormRender: () => {},
  isHeaderWiderRender: false,
  setIsHeaderWiderRender: () => {},
  resetDefaultLayout: () => {}
}

export const ClientLayoutRenderContext = createContext(clientLayoutRenderDefaultValues)

export const ClientLayoutElementsProvider = ({ children }) => {
  const [isFooterRender, setIsFooterRender] = useState(clientLayoutRenderDefaultValues.isFooterRender)
  const [isHeaderRender, setIsHeaderRender] = useState(clientLayoutRenderDefaultValues.isHeaderRender)
  const [isContactFormRender, setIsContactFormRender] = useState(clientLayoutRenderDefaultValues.isContactFormRender)
  const [isHeaderWiderRender, setIsHeaderWiderRender] = useState(clientLayoutRenderDefaultValues.isHeaderWiderRender)

  const resetDefaultLayout = useCallback(() => {
    setIsFooterRender(clientLayoutRenderDefaultValues.isFooterRender)
    setIsHeaderRender(clientLayoutRenderDefaultValues.isHeaderRender)
    setIsContactFormRender(clientLayoutRenderDefaultValues.isContactFormRender)
    setIsHeaderWiderRender(clientLayoutRenderDefaultValues.isHeaderWiderRender)
  }, [])

  return (
    <ClientLayoutRenderContext.Provider
      value={{
        isFooterRender,
        setIsFooterRender,
        isHeaderRender,
        setIsHeaderRender,
        isContactFormRender,
        setIsContactFormRender,
        isHeaderWiderRender,
        setIsHeaderWiderRender,
        resetDefaultLayout
      }}
    >
      {children}
    </ClientLayoutRenderContext.Provider>
  )
}

export const useClientLayoutRenderContext = () => {
  return useContext(ClientLayoutRenderContext)
}
