export const mainMenuItems = [
  {
    label: 'supportPrograms'
  },
  {
    label: 'investmentOpportunities',
    subItems: [{ label: 'investmentOpportunitiesMap' }, { label: 'submitObject' }]
  },
  { label: 'businessOffers' },
  { label: 'media', subItems: [{ label: 'news' }, { label: 'eventCalendar' }] },
  { label: 'about', subItems: [{ label: 'aboutUs' }, { label: 'businessEnvironment' }] }
]
