import { linearProgressClasses } from '@mui/material'

export const styles = {
  container: {
    position: 'sticky',
    top: '115px',
    width: { lg: 300, xs: '100%' },
    margin: '0 auto',
    borderWidth: { sm: '1px', xs: '1px 0 1px 0' },
    borderRadius: { sm: '4px', xs: 0 },
  },
  progressInfoContainer: {
    marginBottom: '10px',
  },
  progressBar: {
    borderRadius: 5,
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: (theme) => theme.palette.grey.main
    },
  },
  list: {
    marginTop: '20px',
    display: { lg: 'block', xs: 'none' },
  },
  listItem: {
    padding: '5px 0',
  },
  listItemIcon: {
    minWidth: '30px',
  },
  listItemTextLink: {
    textDecorationColor: (theme) => theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      color: (theme) => theme.palette.text.primary,
    },
  },
}
