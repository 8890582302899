import PropTypes from 'prop-types'
import { MapWrapper } from '@/utils/map'
import AppMap from '@/components/app-map/AppMap'

const componentProps = {
  cords: PropTypes.array,
  canSetNewCords: PropTypes.bool,
  disableZoom: PropTypes.bool,
  disableDoubleClickZoom: PropTypes.bool,
  onCordsChange: PropTypes.func,
}

const InvestObjectMap = ({ cords, canSetNewCords, disableZoom, onCordsChange, disableDoubleClickZoom }) => {
  return (
    <MapWrapper>
      <AppMap
        cords={cords.filter((e)=>e.lat && e.lng)}
        canSetNewCords={canSetNewCords}
        disableZoom={disableZoom}
        onCordsChange={onCordsChange}
        disableDoubleClickZoom={disableDoubleClickZoom}
      />
    </MapWrapper>
  )
}

InvestObjectMap.propTypes = componentProps

export default InvestObjectMap
