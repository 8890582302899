import i18next from 'i18next'
import { email, requiredField, minMaxLength, ukrainianPhoneNumber } from '@/utils/validations/common'

export const communicationList = ['email', 'chat']

export const modalFormInputs = () => [
  {
    type: 'text',
    options: {
      name: 'description',
      multiline: true,
      maxRows: 5,
      minRows: 5,
      label: i18next.t('modalForm.interest')
    }
  },
]

export const emailInputs = [
    {
    type: 'text',
    options: {
      name: 'email',
      label: i18next.t('modalForm.yourEmail'),
      required: true
    }
  },
]

export const phoneInputs = [
  {
    type: 'text',
    options: {
      name: 'phone',
      label: i18next.t('modalForm.yourPhoneNumber'),
      required: true,
    }
  },
]

export const contactFormValidations = {
  description: [requiredField, minMaxLength(10, 3000)],
  email: [requiredField, email],
}


export const contactFormValidations2 = {
  description: [requiredField, minMaxLength(10, 3000)],
  phone: [requiredField, ukrainianPhoneNumber]
}
