import AppGrid from '@/components/app-grid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { EventsService } from '@/services/events-service'
import { eventsTableColumns, eventsTableSettings } from './constants'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import { useConfirm } from '@/hooks/use-confirm'

const AdminEventsTableProps = {
  filterBy: PropTypes.object,
  onTableRefresh: PropTypes.func
}
const AdminEventsTable = ({ filterBy, onTableRefresh }) => {
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const loadEvents = async (params) => {
    return await EventsService.grid(params)
  }

  const editEvents = async (id) => {
    navigate(adminRoutes.editEvent.path + `/${id}`)
  }

  const deleteEvents = async (selected) => {
    await confirm(i18next.t('dialog.delete_message')) && await EventsService.bulkDeletion({ ids: selected })
  }

  const tableService = { load: loadEvents }

  const bulkActions = {
    deletion: {
      icon: <DeleteIcon />,
      label: 'Видалити',
      onClick: deleteEvents
    }
  }

  const rowActions = {
    edit: {
      icon: <EditIcon />,
      label: 'Редагувати',
      onClick: editEvents
    },
    deletion: {
      icon: <DeleteIcon />,
      label: 'Видалити',
      onClick: deleteEvents
    },
  }

  return (
    <AppGrid
      columns={eventsTableColumns}
      service={tableService}
      settings={eventsTableSettings}
      rowActions={rowActions}
      bulkActions={bulkActions}
      externalFilters={filterBy}
      onTableRefresh={onTableRefresh}
    />
  )
}

AdminEventsTable.propTypes = AdminEventsTableProps

export default AdminEventsTable
