export const useAppEditorForm = ({ handleBlur, handleChange, setInitialData }) => {
  const handleEditorChange = (key) => (value, editor) => {
    handleChange(key)({ customType: 'editor', value, simplifiedValue: editor.getContent({ format: 'text' }) })
  }

  const handleEditorBlur = (key) => (editor) => {
    const simplifiedValue = editor.getContent({ format: 'text' })
    const value = editor.getContent()
    handleBlur(key)({ customType: 'editor', value, simplifiedValue })
  }

  const handleEditorInitialValue = (key) => (newValue) => {
    setInitialData((prevState) => ({ ...prevState, ...{ [key]: newValue } }))
  }

  return {
    formProps: {
      handleEditorChange,
      handleEditorBlur,
      handleEditorInitialValue
    }
  }
}
