import { theme } from '@/plugins/material-ui/theme'
import { responsiveGap } from '@/pages/home-page/HomePage.styles'

export const newsDetails = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    mx: { md: 'auto' },
    maxWidth: '1440px',
    width: '100%',
    px: { md: '45px', sm: '25px', xs: '15px' },
    pb: { md: theme.spacing(6), sm: theme.spacing(8), xs: theme.spacing(8) },
    mb: responsiveGap
  },
  back: {
    my: { xl: theme.spacing(4), md: theme.spacing(3), xs: theme.spacing(2) }
  },
  header: {
    mb: theme.spacing(2.8),
    [theme.breakpoints.up('sm')]: { maxWidth: '985px' }
  },
  shortDate: {
    mb: { md: theme.spacing(4), sm: theme.spacing(2.5), xs: theme.spacing(2.5) }
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    gap: theme.spacing(5),
    flexDirection: { md: 'row', xs: 'column' },
    justifyContent: 'space-between',
    inner: { flex: '1' },
    otherNews: {
      flex: '1',
      minWidth: '300px',
      maxWidth: { md: '343px', sm: 'auto' },
      position: 'sticky',
      top: '10px'
    }
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: { sm: theme.spacing(2), xs: theme.spacing(5) },
    justifyContent: 'space-between'
  },
  tags: { flex: '1 0 48%', display: 'flex', flexWrap: 'wrap', gap: theme.spacing(2) },
  share: { flex: { sm: '1 0 48%', xs: '1 1 100%' } }
}
