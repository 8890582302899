import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const AppConfirmProps = {
  text: PropTypes.string,
  handleConfirm: PropTypes.func,
  content: PropTypes.element
}

const AppConfirm = ({ text, handleConfirm, content, confirmDisabled = false }) => {
  const { t } = useTranslation()

  return (
    <>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleConfirm(false)}>{t('dialog.no')}</Button>
        <Button onClick={() => handleConfirm(true)} disabled={confirmDisabled}>{t('dialog.yes')}</Button>
      </DialogActions>
    </>
  )
}

AppConfirm.propTypes = AppConfirmProps

export default AppConfirm
