export const styles = {
  container: {
    maxWidth: '1440px',
    display: 'flex',
    flexDirection: { md: 'row', sm: 'row ', xs: 'column' },
    justifyContent: { md: 'space-between', sm: 'center' },
    mx: { xl: 'auto' },
    alignItems: 'center',
    width: '100%',
    mt: { md: '60px', xs: '40px' },
    px: { md: '45px', sm: '25px', xs: '15px' }
  },
  bulletList: {
    listStyleType: 'disc',
    py: 0,
    pl: 2,
    '& .MuiListItem-root': {
      display: 'list-item'
    },
    mt: '22px'
  },
  mapImage: {
    maxWidth: { md: '500px', sm: '275px', xs: '330px' },
    height: 'auto',
    mb: '30px'
  },
  allTeamMembersContainer: {
    maxWidth: '1440px',
    display: 'flex',
    flexDirection: 'column',
    mx: { xl: 'auto' },
    width: '100%',
    px: { md: '45px', sm: '25px', xs: '15px' },
    my: { md: '80px', sm: '60px', xs: '40px' }
  },
  teamMemberContainer: {
    display: 'flex',
    flexDirection: { md: 'row', sm: 'row ', xs: 'column' },
    justifyContent: 'center',
    alignItems: 'center'
  },
  memberImage: {
    width: { md: '230px', sm: '158px', xs: '246px' },
    height: { md: '230px', sm: '158px', xs: '246px' },
    borderRadius: '50%',
    objectFit: 'cover',
    filter: 'grayscale(95%)'
  },
  iconButton: {
    position: 'absolute',
    left: { md: '165px', sm: '110px', xs: '175px' },
    bottom: '0px',
    p: 0
  },
  memberList: {
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: { md: '15% 15% 15% 15% 15%', sm: '20% 20% 20% 20%', xs: '45% 45%' },
    columnGap: { md: '6.25%', sm: '5%', xs: '10%' },
    rowGap: { md: '50px', sm: '40px', xs: '30px' },
    pt: '0px'
  },
  serviceResponsiveContainer: {
    width: '347px',
    height: '180px',
    background: (theme) => theme.palette.basic.white,
    borderRadius: '4px',
    p: '20px'
  },
  serviceResponsiveNumber: {
    fontSize: '50px',
    fontWeight: 600,
    mb: '10px',
    lineHeight: '60px',
    color: (theme) => theme.palette.primary.main
  },
  servicesResponsiveList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '30px',
    pt: '20px'
  },
  servicesResponsiveTitle: {
    mb: { md: '30px', xs: '20px' },
    fontWeight: 600,
    color: 'white',
    fontSize: '32px',
    lineHeight: '39px'
  }
}
