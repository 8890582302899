import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useReCaptcha } from '@/hooks/use-captcha'
import { useSnackbar } from 'notistack'

import { Typography, Box, Grid } from '@mui/material'
import InvestObjectSubmitForm from '@/containers/invest-object-submit-form/InvestObjectSubmitForm'

import { useClientLayoutRenderContext } from '@/context/client-layout-context'

import { baseContainerSX } from '@/containers/constants'
import { InvestObjectService } from '@/services/invest-object-service'
import InvestObjectSubmitPageTheme from '@/themes/InvestObjectSubmitPageTheme'

const useInvestObjectSubmitPageLayoutPreset = () => {
  const { setIsFooterRender, setIsContactFormRender, setIsHeaderWiderRender, resetDefaultLayout } =
    useClientLayoutRenderContext()

  useEffect(() => {
    setIsFooterRender(true)
    setIsContactFormRender(false)
    setIsHeaderWiderRender(true)

    return () => {
      resetDefaultLayout()
    }
  }, [setIsFooterRender, setIsContactFormRender, setIsHeaderWiderRender, resetDefaultLayout])
}

const InvestObjectSubmitPage = () => {
  useInvestObjectSubmitPageLayoutPreset()

  const { handleReCaptchaVerify } = useReCaptcha()

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const submitInvestObject = async (formData) => {
    try {
      const token = await handleReCaptchaVerify()
      token && (await InvestObjectService.submitInvestObject(formData, token))
      enqueueSnackbar(
        t('investObjects.publishNewObject.successMessage'),
        {
          variant: 'success'
        }
      )
      navigate('/investment-opportunities-map')
    } catch (error) {
      enqueueSnackbar(t(`error.${error?.response?.status}`).concat(`. ${error.response?.data?.message}`), {
        variant: 'error'
      })
    }
  }

  return (
    <InvestObjectSubmitPageTheme>
      <Box sx={{ ...baseContainerSX, width: { sm: '90%', xs: '100%' }, }}>
        <Grid sx={{ marginTop: 4, marginBottom: 2.5 }}>
          <Typography variant='h1' sx={{ paddingLeft: { sm: 0, xs: '15px' } }}>{t('clientNavigation.menuItems.submitObject')}</Typography>
        </Grid>
        <Grid>
          <InvestObjectSubmitForm onSubmit={submitInvestObject} sx={{ paddingLeft: { sm: '25px', xs: '15px' } }} />
        </Grid>
      </Box>
    </InvestObjectSubmitPageTheme>
  )
}

export default InvestObjectSubmitPage
