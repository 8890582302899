import { useTranslation } from 'react-i18next'
import { baseContainerSX } from '@/containers/constants'
import { Box, Grid, Typography, Zoom } from '@mui/material'
import { content } from '@/pages/buissnes-offers/constant'
import { randomIntFromInterval } from '@/utils/skeleton'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useRef } from 'react'

export const BusinessOffersPage = () => {
  const { t } = useTranslation()

  let [searchParam] = useSearchParams()
  const tag = searchParam.get('tag')
  const listRef = useRef(null)

  useEffect(() => {
    listRef.current.childNodes.forEach((item) => {
      if (item?.classList.contains(tag)) {
        // we wait until dom rerender with new view items
        setTimeout(() => {
          item.scrollIntoView()
        }, 0)
      }
    })
  }, [tag])

  return (
    <Box
      sx={{
        ...baseContainerSX,
        px: { md: '44px', sm: '24px', xs: '16px' },
        width: '100%',
        pt: { md: '60px', xs: '30px' },
        pb: { md: '80px', xs: '60px' }
      }}
    >
      <Typography
        variant='h1'
        sx={{ mb: '30px' }}
      >
        {t('businessOffers.title')}
      </Typography>
      <Grid
        sx={{ flexGrow: 1 }}
        container
        spacing='30px'
        ref={listRef}
      >
        {content(t).map(({ title, description, tag: tagKey, offers }) => (
          <Grid
            key={title}
            className={tagKey}
            item
            md={6}
            xs={12}
          >
            <Zoom in>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: '#C4C4C2',
                  borderRadius: '4px',
                  padding: { sm: '45px 40px 60px', xs: '15px 20px 40px' },
                  height: '100%'
                }}
              >
                <Typography
                  variant='h3'
                  sx={{ mb: '30px', maxWidth: '535px' }}
                >
                  {title}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    mb: '30px',
                    maxWidth: '545px',
                    letterSpacing: '-0.006em',
                    fontWeight: 400,
                    whiteSpace: 'pre-line'
                  }}
                >
                  {description}
                </Typography>
                <Typography
                  variant='h6'
                  sx={{ mb: '10px', fontWeight: '600', fontSize: '18px', lineHeight: '22px', letterSpacing: '-.006em' }}
                >
                  {t('businessOffers.offers')}
                </Typography>
                <Box
                  container
                  spacing='30px'
                >
                  {offers.map(({ title: offerTitle, icon }) => (
                    <Box
                      key={offerTitle}
                      item
                      sm={6}
                      xs={12}
                    >
                      <Zoom
                        in
                        style={{ transitionDelay: `${randomIntFromInterval(100, 200)}ms` }}
                      >
                        <Box
                          display='flex'
                          alignItems='flex-start'
                          padding='10px'
                          gap='10px'
                        >
                          {icon}
                          <Typography
                            variant='subtitle1'
                            sx={{ fontSize: '16px', color: 'accent.main' }}
                          >
                            {offerTitle}
                          </Typography>
                        </Box>
                      </Zoom>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Zoom>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
export default BusinessOffersPage
