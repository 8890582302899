import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { createRoot } from 'react-dom/client'
import { theme } from '@/plugins/material-ui/theme'
import { Box, CircularProgress, ThemeProvider } from '@mui/material'

const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return (
        <Box sx={{ flexGrow: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      )
    case Status.FAILURE:
      return 'Error'
    default:
      return null
  }
}

export const MapWrapper = ({ children }) => (
  <Wrapper
    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
    render={render}
  >
    {children}
  </Wrapper>
)

export const LvivLatLng = [
  {
    lat: 49.83862300674477,
    lng: 24.02763761586104
  },
  {
    lat: 49.842077089934385,
    lng: 24.13750089711104
  },
  {
    lat: 49.84296271252171,
    lng: 23.910907879532914
  },
  {
    lat: 49.91154912069716,
    lng: 24.023517742814164
  },
  {
    lat: 49.782260054661435,
    lng: 24.02763761586104
  }
]

const createDynamicComponent = (component) => {
  const container = document.createElement('div')
  document.getElementById('root').append(container)
  const root = createRoot(container)
  root.render(<ThemeProvider theme={theme}>{component}</ThemeProvider>)
  return { root, container }
}

export const toggleInfoWindowOnMarker = (info, clickedMarker, component) => {
  info.close()
  const { root, container } = createDynamicComponent(component)
  info.setContent(container)
  info.open(clickedMarker.getMap(), clickedMarker)
  info.addListener('content_changed', () => {
    // remove created temp div
    root.unmount()
  })
}

// eslint-disable-next-line no-undef
export const toggleMarkerAnimation = (marker, animation = google.maps.Animation.BOUNCE, timeoutAnimation = 1000) => {
  if (marker.getAnimation() !== null) {
    marker.setAnimation(null)
  } else {
    marker.setAnimation(animation)
    setTimeout(() => {
      marker.setAnimation(null)
    }, timeoutAnimation)
  }
}
