import { Box } from '@mui/material'

const AppDotDivider = () => {
  return (
    <Box
      sx={{
        content: '""',
        alignSelf: 'center',
        width: '6px',
        height: '6px',
        borderRadius: '6px',
        backgroundColor: (theme) => theme.palette.grey.main
      }}
    ></Box>
  )
}

export default AppDotDivider
