import PropTypes from 'prop-types'

export const MenuSubItemType = {
  label: PropTypes.string.isRequired
}

export const MenuItemWithSubItemsType = {
  label: PropTypes.string.isRequired,
  subItems: PropTypes.arrayOf(PropTypes.shape(MenuSubItemType)).isRequired
}

export const MenuItemWithoutSubItemsType = {
  label: PropTypes.string.isRequired
}
