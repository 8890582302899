import AppSelect, {
  getAppMenuPropsMinWidth
} from '@/containers/invest-object-list/invest-object-list-filters/app-select/AppSelect'
import { useMemo } from 'react'
import useBreakpoints from '@/hooks/use-breakpoints'
import AppSelectOptionLabel from '@/containers/invest-object-list/invest-object-list-filters/app-select-option-label/AppSelectOptionLabel'
import { Box, Checkbox, MenuItem } from '@mui/material'
import { createOptionsMap } from '@/containers/invest-object-list/invest-object-list-filter-short/constant'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import { ellipsis } from '@/pages/invest-object-details-page/constant'

const AppSelectMultiCheckbox = ({
  selectedOptions,
  options,
  label,
  placeholder,
  changeOptions,
  removeOptions,
  mobileView,
  minWidth = '160px'
}) => {
  const { isLarge } = useBreakpoints()
  let onChangeOption = ({ target: { value } }) => {
    if (value.length > 0) {
      changeOptions(value)
    } else {
      removeOptions()
    }
  }

  const map = useMemo(() => createOptionsMap(options), [options])

  const handleChange = (value) => {
    if (mobileView) {
      const newValue = selectedOptions.includes(value)
        ? selectedOptions.filter((e) => e !== value)
        : [...selectedOptions, value]
      onChangeOption({ target: { value: newValue } })
    }
  }

  const getMenuItems = () =>
    options.map(({ text, value }) => (
      <MenuItem
        key={value}
        disableGutters
        value={value}
        sx={{ py: '2px', '&.Mui-selected': { backgroundColor: 'transparent' } }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleChange(value)
          }
        }}
        onClick={() => handleChange(value)}
      >
        <Checkbox checked={selectedOptions.indexOf(value) > -1} />
        <ListItemText
          primary={text}
          sx={{ whiteSpace: 'initial' }}
        />
      </MenuItem>
    ))

  if (mobileView) {
    return (
      <>
        <ListSubheader>
          <AppSelectOptionLabel label={label} />
        </ListSubheader>
        {getMenuItems()}
      </>
    )
  }

  return (
    <AppSelect
      MenuProps={getAppMenuPropsMinWidth(minWidth)}
      multiple
      value={selectedOptions}
      onChange={onChangeOption}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return isLarge ? placeholder : label
        }
        const sx = { ...ellipsis(), maxWidth: isLarge ? '100px' : '300px', width: 'auto' }
        return (
          <Box sx={sx}>{selected.map((e) => map[e]).join(', ')}</Box>
        )
      }}
    >
      <ListSubheader>
        <AppSelectOptionLabel label={label} />
      </ListSubheader>
      {getMenuItems()}
    </AppSelect>
  )
}

export default AppSelectMultiCheckbox
