import { AppBar, Box, IconButton, Typography } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close'
import React, { cloneElement } from 'react'
import { useTranslation } from 'react-i18next'

const InvestObjectListFilterMobileBar = ({ handleClose, children }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
      <AppBar
        position='fixed'
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'basic.white',
          color: 'basic.black',
          boxShadow: 'none',
          borderBottom: '1px solid',
          borderBottomColor: (theme) => theme.palette.grey.main
        }}
      >
        <Toolbar variant='regular'>
          <Typography
            flex='1'
            variant='h5'
            textAlign='center'
          >
            {t('investObjectMap.filter.title')}
          </Typography>
          <IconButton
            edge='start'
            color='primary'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ pt: '75px', display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
        {cloneElement(children, { mobileView: true })}
      </Box>
    </Box>
  )
}

export default InvestObjectListFilterMobileBar
