export const cadastralNumberStatusOptions = (t) => [
  { value: 'EXISTS', text: t('investObjects.labels.cadastralNumberOptions.exists') },
  { value: 'UNKNOWN', text: t('investObjects.labels.cadastralNumberOptions.unknown') },
  { value: 'UNALLOCATED_SITE', text: t('investObjects.labels.cadastralNumberOptions.unallocatedSite') },
  { value: 'MISSING', text: t('investObjects.labels.cadastralNumberOptions.missing') }
]
export const ownershipType = {
  communal: 'COMMUNAL',
  state: 'STATE',
  private: 'PRIVATE',
  notDefined: 'NOT_DEFINED'
}
export const ownershipTypeOptions = (t) => [
  { value: ownershipType.communal, text: t('investObjects.labels.ownershipTypeOptions.communal') },
  { value: ownershipType.state, text: t('investObjects.labels.ownershipTypeOptions.state') },
  { value: ownershipType.private, text: t('investObjects.labels.ownershipTypeOptions.private') },
  { value: ownershipType.notDefined, text: t('investObjects.labels.ownershipTypeOptions.notDefined') }
]

export const landCategorySectionOptions = (t) => [
  { value: 'SECTION_A', text: t('investObjects.labels.landCategorySectionOptions.sectionA') },
  { value: 'SECTION_B', text: t('investObjects.labels.landCategorySectionOptions.sectionB') },
  { value: 'SECTION_C', text: t('investObjects.labels.landCategorySectionOptions.sectionC') },
  { value: 'SECTION_D', text: t('investObjects.labels.landCategorySectionOptions.sectionD') },
  { value: 'SECTION_E', text: t('investObjects.labels.landCategorySectionOptions.sectionE') },
  { value: 'SECTION_G', text: t('investObjects.labels.landCategorySectionOptions.sectionG') },
  { value: 'SECTION_H', text: t('investObjects.labels.landCategorySectionOptions.sectionH') },
  { value: 'SECTION_I', text: t('investObjects.labels.landCategorySectionOptions.sectionI') },
  { value: 'SECTION_J', text: t('investObjects.labels.landCategorySectionOptions.sectionJ') },
  { value: 'SECTION_K', text: t('investObjects.labels.landCategorySectionOptions.sectionK') }
]

export const landCategoryCategoryOptions = (t) => ({
  SECTION_A: [{ value: '01', text: t('investObjects.labels.landCategoryCategoryOptions.01') }],
  SECTION_B: [
    { value: '02', text: t('investObjects.labels.landCategoryCategoryOptions.02') },
    { value: '03', text: t('investObjects.labels.landCategoryCategoryOptions.03') }
  ],
  SECTION_C: [
    { value: '04', text: t('investObjects.labels.landCategoryCategoryOptions.04') },
    { value: '05', text: t('investObjects.labels.landCategoryCategoryOptions.05') }
  ],
  SECTION_D: [{ value: '06', text: t('investObjects.labels.landCategoryCategoryOptions.06') }],
  SECTION_E: [{ value: '07', text: t('investObjects.labels.landCategoryCategoryOptions.07') }],
  SECTION_G: [{ value: '08', text: t('investObjects.labels.landCategoryCategoryOptions.08') }],
  SECTION_H: [{ value: '09', text: t('investObjects.labels.landCategoryCategoryOptions.09') }],
  SECTION_I: [{ value: '10', text: t('investObjects.labels.landCategoryCategoryOptions.10') }],
  SECTION_J: [
    { value: '11', text: t('investObjects.labels.landCategoryCategoryOptions.11') },
    { value: '12', text: t('investObjects.labels.landCategoryCategoryOptions.12') },
    { value: '13', text: t('investObjects.labels.landCategoryCategoryOptions.13') },
    { value: '14', text: t('investObjects.labels.landCategoryCategoryOptions.14') },
    { value: '15', text: t('investObjects.labels.landCategoryCategoryOptions.15') }
  ],
  SECTION_K: [
    { value: '16', text: t('investObjects.labels.landCategoryCategoryOptions.16') },
    { value: '17', text: t('investObjects.labels.landCategoryCategoryOptions.17') },
    { value: '18', text: t('investObjects.labels.landCategoryCategoryOptions.18') },
    { value: '19', text: t('investObjects.labels.landCategoryCategoryOptions.19') }
  ]
})
export const landCategoryTypeOptions = (t) => ({
  '01': [
    { value: '01.01', text: t('investObjects.labels.landCategoryTypeOptions.01.01') },
    { value: '01.02', text: t('investObjects.labels.landCategoryTypeOptions.01.02') },
    { value: '01.03', text: t('investObjects.labels.landCategoryTypeOptions.01.03') },
    { value: '01.04', text: t('investObjects.labels.landCategoryTypeOptions.01.04') },
    { value: '01.05', text: t('investObjects.labels.landCategoryTypeOptions.01.05') },
    { value: '01.06', text: t('investObjects.labels.landCategoryTypeOptions.01.06') },
    { value: '01.07', text: t('investObjects.labels.landCategoryTypeOptions.01.07') },
    { value: '01.08', text: t('investObjects.labels.landCategoryTypeOptions.01.08') },
    { value: '01.09', text: t('investObjects.labels.landCategoryTypeOptions.01.09') },
    { value: '01.10', text: t('investObjects.labels.landCategoryTypeOptions.01.10') },
    { value: '01.11', text: t('investObjects.labels.landCategoryTypeOptions.01.11') },
    { value: '01.12', text: t('investObjects.labels.landCategoryTypeOptions.01.12') },
    { value: '01.13', text: t('investObjects.labels.landCategoryTypeOptions.01.13') },
    { value: '01.14', text: t('investObjects.labels.landCategoryTypeOptions.01.14') }
  ],
  '02': [
    { value: '02.01', text: t('investObjects.labels.landCategoryTypeOptions.02.01') },
    { value: '02.02', text: t('investObjects.labels.landCategoryTypeOptions.02.02') },
    { value: '02.03', text: t('investObjects.labels.landCategoryTypeOptions.02.03') },
    { value: '02.04', text: t('investObjects.labels.landCategoryTypeOptions.02.04') },
    { value: '02.05', text: t('investObjects.labels.landCategoryTypeOptions.02.05') },
    { value: '02.06', text: t('investObjects.labels.landCategoryTypeOptions.02.06') },
    { value: '02.07', text: t('investObjects.labels.landCategoryTypeOptions.02.07') },
    { value: '02.08', text: t('investObjects.labels.landCategoryTypeOptions.02.08') },
    { value: '02.09', text: t('investObjects.labels.landCategoryTypeOptions.02.09') },
    { value: '02.10', text: t('investObjects.labels.landCategoryTypeOptions.02.10') }
  ],
  '03': [
    { value: '03.01', text: t('investObjects.labels.landCategoryTypeOptions.03.01') },
    { value: '03.02', text: t('investObjects.labels.landCategoryTypeOptions.03.02') },
    { value: '03.03', text: t('investObjects.labels.landCategoryTypeOptions.03.03') },
    { value: '03.04', text: t('investObjects.labels.landCategoryTypeOptions.03.04') },
    { value: '03.05', text: t('investObjects.labels.landCategoryTypeOptions.03.05') },
    { value: '03.06', text: t('investObjects.labels.landCategoryTypeOptions.03.06') },
    { value: '03.07', text: t('investObjects.labels.landCategoryTypeOptions.03.07') },
    { value: '03.08', text: t('investObjects.labels.landCategoryTypeOptions.03.08') },
    { value: '03.09', text: t('investObjects.labels.landCategoryTypeOptions.03.09') },
    { value: '03.10', text: t('investObjects.labels.landCategoryTypeOptions.03.10') },
    { value: '03.11', text: t('investObjects.labels.landCategoryTypeOptions.03.11') },
    { value: '03.12', text: t('investObjects.labels.landCategoryTypeOptions.03.12') },
    { value: '03.13', text: t('investObjects.labels.landCategoryTypeOptions.03.13') },
    { value: '03.14', text: t('investObjects.labels.landCategoryTypeOptions.03.14') },
    { value: '03.15', text: t('investObjects.labels.landCategoryTypeOptions.03.15') },
    { value: '03.16', text: t('investObjects.labels.landCategoryTypeOptions.03.16') },
    { value: '03.17', text: t('investObjects.labels.landCategoryTypeOptions.03.17') }
  ],
  '04': [
    { value: '04.01', text: t('investObjects.labels.landCategoryTypeOptions.04.01') },
    { value: '04.02', text: t('investObjects.labels.landCategoryTypeOptions.04.02') },
    { value: '04.03', text: t('investObjects.labels.landCategoryTypeOptions.04.03') },
    { value: '04.04', text: t('investObjects.labels.landCategoryTypeOptions.04.04') },
    { value: '04.05', text: t('investObjects.labels.landCategoryTypeOptions.04.05') },
    { value: '04.06', text: t('investObjects.labels.landCategoryTypeOptions.04.06') },
    { value: '04.07', text: t('investObjects.labels.landCategoryTypeOptions.04.07') },
    { value: '04.08', text: t('investObjects.labels.landCategoryTypeOptions.04.08') },
    { value: '04.09', text: t('investObjects.labels.landCategoryTypeOptions.04.09') },
    { value: '04.10', text: t('investObjects.labels.landCategoryTypeOptions.04.10') },
    { value: '04.11', text: t('investObjects.labels.landCategoryTypeOptions.04.11') }
  ],
  '05': [{ value: '05.00', text: t('investObjects.labels.landCategoryTypeOptions.05.00') }],
  '06': [
    { value: '06.01', text: t('investObjects.labels.landCategoryTypeOptions.06.01') },
    { value: '06.02', text: t('investObjects.labels.landCategoryTypeOptions.06.02') },
    { value: '06.03', text: t('investObjects.labels.landCategoryTypeOptions.06.03') },
    { value: '06.04', text: t('investObjects.labels.landCategoryTypeOptions.06.04') }
  ],
  '07': [
    { value: '07.01', text: t('investObjects.labels.landCategoryTypeOptions.07.01') },
    { value: '07.02', text: t('investObjects.labels.landCategoryTypeOptions.07.02') },
    { value: '07.03', text: t('investObjects.labels.landCategoryTypeOptions.07.03') },
    { value: '07.04', text: t('investObjects.labels.landCategoryTypeOptions.07.04') },
    { value: '07.05', text: t('investObjects.labels.landCategoryTypeOptions.07.05') }
  ],
  '08': [
    { value: '08.01', text: t('investObjects.labels.landCategoryTypeOptions.08.01') },
    { value: '08.02', text: t('investObjects.labels.landCategoryTypeOptions.08.02') },
    { value: '08.03', text: t('investObjects.labels.landCategoryTypeOptions.08.03') },
    { value: '08.04', text: t('investObjects.labels.landCategoryTypeOptions.08.04') }
  ],
  '09': [
    { value: '09.01', text: t('investObjects.labels.landCategoryTypeOptions.09.01') },
    { value: '09.02', text: t('investObjects.labels.landCategoryTypeOptions.09.02') },
    { value: '09.03', text: t('investObjects.labels.landCategoryTypeOptions.09.03') }
  ],
  10: [
    { value: '10.01', text: t('investObjects.labels.landCategoryTypeOptions.10.01') },
    { value: '10.02', text: t('investObjects.labels.landCategoryTypeOptions.10.02') },
    { value: '10.03', text: t('investObjects.labels.landCategoryTypeOptions.10.03') },
    { value: '10.04', text: t('investObjects.labels.landCategoryTypeOptions.10.04') },
    { value: '10.05', text: t('investObjects.labels.landCategoryTypeOptions.10.05') },
    { value: '10.06', text: t('investObjects.labels.landCategoryTypeOptions.10.06') },
    { value: '10.07', text: t('investObjects.labels.landCategoryTypeOptions.10.07') },
    { value: '10.08', text: t('investObjects.labels.landCategoryTypeOptions.10.08') },
    { value: '10.09', text: t('investObjects.labels.landCategoryTypeOptions.10.09') },
    { value: '10.10', text: t('investObjects.labels.landCategoryTypeOptions.10.10') },
    { value: '10.11', text: t('investObjects.labels.landCategoryTypeOptions.10.11') },
    { value: '10.12', text: t('investObjects.labels.landCategoryTypeOptions.10.12') }
  ],
  11: [
    { value: '11.01', text: t('investObjects.labels.landCategoryTypeOptions.11.01') },
    { value: '11.02', text: t('investObjects.labels.landCategoryTypeOptions.11.02') },
    { value: '11.03', text: t('investObjects.labels.landCategoryTypeOptions.11.03') },
    { value: '11.04', text: t('investObjects.labels.landCategoryTypeOptions.11.04') },
    { value: '11.05', text: t('investObjects.labels.landCategoryTypeOptions.11.05') }
  ],
  12: [
    { value: '12.01', text: t('investObjects.labels.landCategoryTypeOptions.12.01') },
    { value: '12.02', text: t('investObjects.labels.landCategoryTypeOptions.12.02') },
    { value: '12.03', text: t('investObjects.labels.landCategoryTypeOptions.12.03') },
    { value: '12.04', text: t('investObjects.labels.landCategoryTypeOptions.12.04') },
    { value: '12.05', text: t('investObjects.labels.landCategoryTypeOptions.12.05') },
    { value: '12.06', text: t('investObjects.labels.landCategoryTypeOptions.12.06') },
    { value: '12.07', text: t('investObjects.labels.landCategoryTypeOptions.12.07') },
    { value: '12.08', text: t('investObjects.labels.landCategoryTypeOptions.12.08') },
    { value: '12.09', text: t('investObjects.labels.landCategoryTypeOptions.12.09') },
    { value: '12.10', text: t('investObjects.labels.landCategoryTypeOptions.12.10') },
    { value: '12.11', text: t('investObjects.labels.landCategoryTypeOptions.12.11') }
  ],
  13: [
    { value: '13.01', text: t('investObjects.labels.landCategoryTypeOptions.13.01') },
    { value: '13.02', text: t('investObjects.labels.landCategoryTypeOptions.13.02') },
    { value: '13.03', text: t('investObjects.labels.landCategoryTypeOptions.13.03') },
    { value: '13.04', text: t('investObjects.labels.landCategoryTypeOptions.13.04') }
  ],
  14: [
    { value: '14.01', text: t('investObjects.labels.landCategoryTypeOptions.14.01') },
    { value: '14.02', text: t('investObjects.labels.landCategoryTypeOptions.14.02') },
    { value: '14.03', text: t('investObjects.labels.landCategoryTypeOptions.14.03') }
  ],
  15: [
    { value: '15.01', text: t('investObjects.labels.landCategoryTypeOptions.15.01') },
    { value: '15.02', text: t('investObjects.labels.landCategoryTypeOptions.15.02') },
    { value: '15.03', text: t('investObjects.labels.landCategoryTypeOptions.15.03') },
    { value: '15.04', text: t('investObjects.labels.landCategoryTypeOptions.15.04') },
    { value: '15.05', text: t('investObjects.labels.landCategoryTypeOptions.15.05') },
    { value: '15.06', text: t('investObjects.labels.landCategoryTypeOptions.15.06') },
    { value: '15.07', text: t('investObjects.labels.landCategoryTypeOptions.15.07') },
    { value: '15.08', text: t('investObjects.labels.landCategoryTypeOptions.15.08') },
    { value: '15.09', text: t('investObjects.labels.landCategoryTypeOptions.15.09') },
    { value: '15.10', text: t('investObjects.labels.landCategoryTypeOptions.15.10') }
  ],
  16: [{ value: '16.00', text: t('investObjects.labels.landCategoryTypeOptions.16.00') }],
  17: [{ value: '17.00', text: t('investObjects.labels.landCategoryTypeOptions.17.00') }],
  18: [{ value: '18.00', text: t('investObjects.labels.landCategoryTypeOptions.18.00') }],
  19: [{ value: '19.00', text: t('investObjects.labels.landCategoryTypeOptions.19.00') }]
})
