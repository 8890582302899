import i18next from 'i18next'
import { minMaxLength, requiredField, requiredFile } from '@/utils/validations/common'
import { styles } from '@/containers/admin-support-programs/admin-support-program-form/admin-support-program-form-styles'
import { backgroundImageUploadLabels } from '@/constants/default'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { documentStatus, documentStatusField } from '@/containers/constants'

export const supportProgramsFormGeneralInputs = [
  {
    type: 'imageUploader',
    options: {
      name: 'donorLogo',
      labels: {
        addLabel: i18next.t('supportPrograms.logoLabels.addLabel'),
        changeLabel: i18next.t('supportPrograms.logoLabels.changeLabel')
      },
      sx: styles.inputs.donorLogo,
      dataTestId: 'DonorLogo',
      required: true,
      maxSize: '5MB'
    }
  },
  {
    type: 'imageUploader',
    options: {
      name: 'featureImage',
      labels: backgroundImageUploadLabels,
      sx: styles.inputs.featureImage,
      dataTestId: 'FeatureImage',
      previewSize: { width: '100%', height: 'auto', maxWidth: '500px' },
      maxSize: '5MB'
    }
  },
  {
    type: 'file',
    options: {
      name: 'document',
      btnIcon: <PostAddIcon />,
      returnFullFile: true,
      className: 'file-input',
      accept: ['.pdf'],
      maxSize: '10MB',
      btnLabels: {
        addLabel: 'Додати документ',
        editLabel: 'Змінити документ'
      },
      multiple: true,
      showPreview: true,
      sx: styles.inputs.file
    }
  }
]

export const supportProgramsFormMultiLangInputs = [
  documentStatusField({
    sx: styles.inputs.status
  }),
  {
    type: 'text',
    options: {
      name: 'title',
      label: i18next.t('forms.addHeader'),
      sx: styles.inputs.title,
      required: true
    }
  },
  {
    type: 'text',
    options: {
      name: 'target',
      label: i18next.t('supportPrograms.forWhom'),
      sx: styles.inputs.target,
      required: true
    }
  },
  {
    type: 'text',
    options: {
      name: 'amount',
      label: i18next.t('supportPrograms.amountOfSupport'),
      required: true,
      sx: styles.inputs.amount
    }
  },
  {
    type: 'text',
    options: {
      name: 'donorName',
      label: i18next.t('supportPrograms.donor'),
      required: true,
      sx: styles.inputs.donorName
    }
  },
  {
    type: 'text',
    options: {
      name: 'submissionTerm',
      label: i18next.t('supportPrograms.submissionTerm'),
      sx: styles.inputs.submissionTerm
    }
  },
  {
    type: 'text',
    options: {
      name: 'receiptTerm',
      label: i18next.t('supportPrograms.receiptTerm'),
      sx: styles.inputs.receiptTerm
    }
  },
  {
    type: 'textEditor',
    options: {
      name: 'text',
      sx: styles.inputs.textEditor,
      required: true,
      header: i18next.t('supportPrograms.editorLabel')
    }
  }
]

export const supportProgramsFormInitialValue = {
  global: {
    donorLogo: null,
    featureImage: null,
    document: []
  },
  en: {
    title: '',
    status: documentStatus.DRAFT,
    text: '',
    target: '',
    amount: '',
    submissionTerm: '',
    receiptTerm: '',
    donorName: ''
  },
  uk: {
    title: '',
    status: documentStatus.DRAFT,
    text: '',
    target: '',
    amount: '',
    submissionTerm: '',
    receiptTerm: '',
    donorName: ''
  }
}

export const generalValidationDraft = {
  donorLogo: [],
  featureImage: []
}

export const generalValidationPublish = {
  donorLogo: [requiredFile],
  featureImage: [requiredFile]
}

export const draftSupportProgramValidation = {
  title: [minMaxLength(0, 100)],
  donorName: [minMaxLength(0, 50)],
  target: [minMaxLength(0, 50)],
  amount: [minMaxLength(0, 50)],
  text: [minMaxLength(0, 5000)],
  submissionTerm: [minMaxLength(0, 50)],
  receiptTerm: [minMaxLength(0, 50)]
}

export const publishSupportProgramValidation = {
  title: [requiredField, ...draftSupportProgramValidation.title],
  donorName: [requiredField, ...draftSupportProgramValidation.donorName],
  target: [requiredField, ...draftSupportProgramValidation.target],
  amount: [requiredField, ...draftSupportProgramValidation.amount],
  text: [requiredField, ...draftSupportProgramValidation.text],
  submissionTerm: [...draftSupportProgramValidation.submissionTerm],
  receiptTerm: [...draftSupportProgramValidation.receiptTerm]
}
