import React, { useCallback, useEffect, useState } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import AppRestFeed from '@/components/app-rest-feed/AppRestFeed'
import EventDetailsInfoList from '@/containers/event-details-info-list'
import useAxios from '@/hooks/use-axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EventsService } from '@/services/events-service'
import { DateTime } from 'luxon'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import palette from '@/plugins/material-ui/palette'
import { styles } from '@/pages/event-details-page/EventDetailsPage.styles'
import EventItem from '@/containers/recent-events-banner/event-item'
import { theme } from '@/plugins/material-ui/theme'
import AppButtonGoBackTo from '@/components/app-button-go-back-to/AppButtonGoBackTo'

const EventDetailsPage = () => {
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [eventDetails, setEventDetails] = useState({})
  const { backgroundImage, text, title, startDate } = eventDetails
  const [restEvents, setRestEvents] = useState([])
  const { fetchData: fetchEvent, loading } = useAxios({
    service: EventsService.getEventById,
    initialLoading: true,
    handleError: true
  })
  const { fetchData: fetchRestEvents, loading: restEventsLoading } = useAxios({
    service: EventsService.getEvents,
    initialLoading: true,
    handleError: true
  })

  const getRestEvents = useCallback(async () => {
    const { items } = await fetchRestEvents({
      take: 3,
      skip: 0,
      excludeIds: [Number(id)],
      lang: i18n.language,
      from: startDate,
      to: DateTime.fromISO(startDate)
        .plus({ years: +10 })
        .toUTC()
        .toISO()
    })
    setRestEvents(
      items.map(({ id: itemId, startDate: date, title: itemTitle }) => ({ id: itemId, date, title: itemTitle }))
    )
  }, [fetchRestEvents, i18n.language, id, startDate])

  const getEventDetails = useCallback(async () => {
    const data = await fetchEvent({ id, query: { lang: i18n.language } })
    setEventDetails(data)
  }, [fetchEvent, id, i18n.language])

  useEffect(() => {
    getEventDetails()
      .catch((res) => {
        if (res.response.status === 404) {
          navigate(clientRoutes.eventCalendar.route)
        }
      })
      .then(() => getRestEvents())
  }, [getEventDetails, navigate, getRestEvents])

  useEffect(() => {
    document.title = `Invest in Lviv - ${title}`
  }, [title])

  return (
    <>
      <Box
        display='flex'
        maxHeight='484px'
        margin='auto'
        width='100%'
      >
        {loading ? (
          <Skeleton
            variant={'rectangular'}
            width={'100%'}
            height={470}
          />
        ) : (
          <Box
            component='img'
            sx={styles.image}
            src={`${backgroundImage}?auto=format`}
            srcSet={`${backgroundImage}?auto=format&dpr=2 2x`}
            alt={title}
            loading='lazy'
          />
        )}
      </Box>

      <Box sx={{ backgroundColor: palette.background.light }}>
        <Box sx={styles.container}>
          <AppButtonGoBackTo
            sx={{ marginTop: { md: theme.spacing(6), xs: theme.spacing(2) } }}
            to={clientRoutes.eventCalendar.route}
            label={t('events.beckToEvents')}
          />
          <Typography
            variant='h1'
            sx={styles.header}
          >
            {loading ? <Skeleton /> : title}
          </Typography>
          {loading ? (
            <EventItem
              vertical
              loading
            />
          ) : (
            <EventDetailsInfoList data={eventDetails} />
          )}
        </Box>
      </Box>

      <Box sx={{ ...styles.container, ...styles.content }}>
        {loading ? null : <div dangerouslySetInnerHTML={{ __html: text }} />}
        {restEvents.length > 0 && (
          <Box sx={styles.content.otherEvents}>
            <AppRestFeed
              feedTitle={t('events.restEvents')}
              feeds={restEvents}
              loading={restEventsLoading}
              toPrefix={clientRoutes.eventCalendar.route}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default EventDetailsPage
