import { styled } from '@mui/material'
import Slider from '@mui/material/Slider'
import { theme } from '@/plugins/material-ui/theme'

const AppSlider = styled((props) => (
  <Slider
    size='small'
    valueLabelDisplay='auto'
    {...props}
  />
))(() => ({
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.common.white,
    border: '1px solid currentColor'
  },
  '& .MuiSlider-track': {
    color: theme.palette.primary.light
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? theme.palette.grey.light : theme.palette.grey.main,
    opacity: theme.palette.mode === 'dark' ? undefined : 1
  }
}))

export default AppSlider
