import { Editor } from '@tinymce/tinymce-react'
import { Alert, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { editorConfig } from '@/constants/tinyMCEConfig'

const AppEditorProps = {
  input: PropTypes.object,
  value: PropTypes.string,
  handleEditorChange: PropTypes.func,
  changeInitialValue: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  header: PropTypes.string
}

export const AppEditor = ({
  input,
  value,
  handleEditorChange,
  changeInitialValue,
  onBlur,
  error,
  disabled = false,
  header,
  sx = {}
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 0, ...sx }}>
      {header && <Typography sx={{ mb: '12px' }}>{header}</Typography>}
      {error && <Box sx={{ minHeight: '52px' }}>{error && <Alert severity='error'>{error}</Alert>}</Box>}
      <Editor
        disabled={disabled || input.options.disabled}
        value={value}
        rollback={0}
        onEditorChange={handleEditorChange}
        init={{
          init_instance_callback: (editor) => {
            // we change initial value of the editor because BE convert value when sanitize the input
            changeInitialValue(editor.getContent())
          },
          ...editorConfig,
          ...input.options.editorConfig
        }}
        onBlur={onBlur}
      />
    </Box>
  )
}

AppEditor.propTypes = AppEditorProps

export default AppEditor
