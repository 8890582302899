import AppGrid from '@/components/app-grid'

import DeleteIcon from '@mui/icons-material/Delete'
import { SupportProgramsAdminService } from '@/services/support-program-admin-service'
import {
  supportProgramsTableSettings,
  supportsProgramsTableColumns
} from '@/containers/admin-support-programs/admin-support-programs-table/constants'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from '@/hooks/use-confirm'
import i18next from 'i18next'

const AdminSupportProgramsTableProps = {
  filterBy: PropTypes.object,
  onTableRefresh: PropTypes.func
}

const AdminSupportProgramsTable = ({ filterBy, onTableRefresh }) => {
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const loadSupportPrograms = async (params) => {
    return await SupportProgramsAdminService.grid(params)
  }

  const editProgram = async (id) => {
    navigate(adminRoutes.editSupportProgram.path + `/${id}`)
  }

  const deleteSupportPrograms = async (selected) => {
    await confirm(i18next.t('dialog.delete_message')) && await SupportProgramsAdminService.bulkDeletion({ ids: selected })
  }

  const tableService = { load: loadSupportPrograms }

  const bulkActions = {
    deletion: {
      icon: <DeleteIcon />,
      label: 'Видалити',
      onClick: deleteSupportPrograms
    }
  }

  const rowActions = {
    edit: {
      icon: <EditIcon />,
      label: 'Редагувати',
      onClick: editProgram
    },
    deletion: {
      icon: <DeleteIcon />,
      label: 'Видалити',
      onClick: deleteSupportPrograms
    }
  }

  return (
    <AppGrid
      columns={supportsProgramsTableColumns}
      service={tableService}
      settings={supportProgramsTableSettings}
      rowActions={rowActions}
      bulkActions={bulkActions}
      externalFilters={filterBy}
      onTableRefresh={onTableRefresh}
    />
  )
}

AdminSupportProgramsTable.propTypes = AdminSupportProgramsTableProps

export default AdminSupportProgramsTable
