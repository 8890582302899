import supLogo from '@/assets/partners/0.svg'
import ebaLogo from '@/assets/partners/1.svg'
import ukuLogo from '@/assets/partners/3.svg'
import zurcLogo from '@/assets/partners/2.svg'
import zdpyLogo from '@/assets/partners/11.svg'
import bambykLogo from '@/assets/partners/4.svg'
import osaLogo from '@/assets/partners/8.svg'
import lodaLogo from '@/assets/partners/9.svg'
import lmrLogo from '@/assets/partners/10.svg'
import vesternNisLogo from '@/assets/partners/7.svg'
import proohLogo from '@/assets/partners/12.svg'
import perfectPRLogo from '@/assets/partners/6.svg'
import SoftServeLogo from '@/assets/partners/5.svg'

export const partners = (t) => [
  {
    icon: (
      <img
        src={supLogo}
        alt={t('partners.list.sup')}
      />
    ),
    title: t('partners.list.sup'),
    link: 'https://sup.org.ua/uk'
  },
  {
    icon: (
      <img
        src={ebaLogo}
        alt={t('partners.list.eba')}
      />
    ),
    title: t('partners.list.eba'),
    link: 'https://eba.com.ua/'
  },
  {
    icon: (
      <img
        src={ukuLogo}
        alt={t('partners.list.uku')}
      />
    ),
    title: t('partners.list.uku'),
    link: 'https://lvbs.com.ua/'
  },
  {
    icon: (
      <img
        src={zurcLogo}
        alt={t('partners.list.zurc')}
      />
    ),
    title: t('partners.list.zurc'),
    link: 'https://zurc.org/'
  },
  {
    icon: (
      <img
        src={zdpyLogo}
        alt={t('partners.list.zdpy')}
      />
    ),
    title: t('partners.list.zdpy'),
    link: 'https://www.facebook.com/TheWomensBusinessChamberofUkraine'
  },
  {
    icon: (
      <img
        src={bambykLogo}
        alt={t('partners.list.bambyk')}
      />
    ),
    title: t('partners.list.bambyk'),
    link: 'https://bambukstudio.com/uk'
  },
  {
    icon: (
      <img
        src={perfectPRLogo}
        alt={t('partners.list.perfectPR')}
      />
    ),
    title: t('partners.list.perfectPR'),
    link: 'https://www.facebook.com/PerfectPRUkraine/'
  },
  {
    icon: (
      <img
        src={SoftServeLogo}
        alt={t('partners.list.SoftServe')}
      />
    ),
    title: t('partners.list.SoftServe'),
    link: 'https://www.softserveinc.com/uk-ua'
  },
  {
    icon: (
      <img
        src={osaLogo}
        alt={t('partners.list.osa')}
      />
    ),
    title: t('partners.list.osa'),
    link: 'https://www.facebook.com/ocaukraine'
  },
  {
    icon: (
      <img
        src={lodaLogo}
        alt={t('partners.list.loda')}
      />
    ),
    title: t('partners.list.loda'),
    link: 'https://loda.gov.ua/'
  },
  {
    icon: (
      <img
        src={lmrLogo}
        alt={t('partners.list.lmr')}
      />
    ),
    title: t('partners.list.lmr'),
    link: 'https://city-adm.lviv.ua/'
  },
  {
    icon: (
      <img
        src={vesternNisLogo}
        alt={t('partners.list.vesternNis')}
      />
    ),
    title: t('partners.list.vesternNis'),
    link: 'https://wnisef.org/uk'
  },
  {
    icon: (
      <img
        src={proohLogo}
        alt={t('partners.list.prooh')}
      />
    ),
    title: t('partners.list.prooh'),
    link: 'https://www.undp.org/uk/ukraine'
  }
]
