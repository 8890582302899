import { useCallback, useEffect, useState } from 'react'
import useAxios from '@/hooks/use-axios'
import { useTranslation } from 'react-i18next'
import { getDefaultTags } from '@/containers/admin-tags/defaultTags'

export const useTagsFilter = ({ toggleMode = false, service, tagTileFromUlr = null, useDefaultTags = false }) => {
  const { i18n } = useTranslation()

  const [filterBy, setFilterBy] = useState([])
  const [tagsItems, setTagsItems] = useState([])

  const { fetchData: fetchTags, loading } = useAxios({ service })

  const handleFilterChange = (item) => {
    if (item === null) {
      setFilterBy([])
      return
    }

    if (toggleMode) {
      return setFilterBy([item])
    }
    const filters = filterBy.filter((filter) => filter.id !== item.id)

    if (filters.length === filterBy.length) {
      setFilterBy([...filterBy, item])
    } else {
      setFilterBy(filters)
    }
  }

  const getNewsTags = useCallback(async () => {
    const items = await fetchTags(i18n.language)
    if (!useDefaultTags) {
      setFilterBy([])
      setTagsItems(items)
      return
    }
    const defaultLangTags = getDefaultTags(i18n.language)
    const tagsWithOutDefault = items.filter(({ title }) => !defaultLangTags.includes(title))
    const tagsWithDefault = items.filter(({ title }) => defaultLangTags.includes(title))
    const defaultTagWhichEmpty = defaultLangTags
      .filter((tagTitle) => !tagsWithDefault.map((e) => e.title).includes(tagTitle))
      .map((e) => ({ title: e, id: e, count: 0 }))

    setTagsItems([...tagsWithDefault, ...defaultTagWhichEmpty, ...tagsWithOutDefault])
  }, [fetchTags, i18n.language, useDefaultTags])

  useEffect(() => {
    getNewsTags().catch()
  }, [getNewsTags])

  useEffect(() => {
    if (tagTileFromUlr) {
      const itemFromTag = tagsItems.filter((item) => item.title === tagTileFromUlr)
      setFilterBy(itemFromTag)
    }
  }, [tagTileFromUlr, tagsItems])

  return { handleFilterChange, filterBy, tagsItems, fetchTags, loading }
}
