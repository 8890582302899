import i18next from 'i18next'
import { userStatus } from '@/pages/admin/constants'
import { roleSelect } from '@/pages/admin/admin-users-edit/constants'

export const usersTableColumns = [
  {
    headerName: i18next.t('common.email'),
    field: 'email'
  },
  { headerName: i18next.t('common.name'), field: 'firstName' },
  {
    headerName: i18next.t('common.role'),
    field: 'role',
    renderCell: ({ id, value }) => i18next.t(`users.role.${value}`)
  },
  {
    headerName: i18next.t('common.status'),
    field: 'active',
    renderCell: ({ id, value }) => i18next.t(`users.status.${value}`)
  }
]
export const usersTableSettings = { checkboxSelection: true }

export const statusSelectionInputs = [
  {
    type: 'select',
    options: {
      name: 'active',
      required: true,
      label: i18next.t('forms.status'),
      SelectProps: {
        options: Object.keys(userStatus).map((key) => ({
          value: userStatus[key],
          text: i18next.t(`users.status.${key}`)
        }))
      }
    }
  }
]
export const roleSelectionInputs = [roleSelect]
