import ClientHeader from '@/containers/layouts/client/client-header'
import { Box } from '@mui/material'
import ClientFooter from '@/containers/layouts/client/client-footer'
import ClientRouter from '@/plugins/router/clinet-router'
import { createContext, useRef } from 'react'
import { useClientLayoutRenderContext } from '@/context/client-layout-context'
import ContactForm from '@/containers/contact-form'
import { styles } from '@/containers/layouts/client/client-layout/ClientLayout.styles'
import { ScrollProvider } from '@/context/client-scroll-context'
import useBreakpoints from '@/hooks/use-breakpoints'
import { hideScroll } from '@/containers/constants'
import ContactMenu from '@/containers/contact-menu'

export const LayoutRefContext = createContext(null)

const ClientLayout = () => {
  const layoutRef = useRef(null)
  const { isHeaderRender, isFooterRender, isContactFormRender, isHeaderWiderRender } = useClientLayoutRenderContext()
  const { isMobile, isTablet } = useBreakpoints()

  const additionalSx = isMobile || isTablet ? hideScroll : {}
  return (
    <Box sx={styles.clientLayout}>
      {isHeaderRender && <ClientHeader fullWidthHeader={isHeaderWiderRender} />}
      <Box
        ref={layoutRef}
        sx={{ ...styles.container, ...additionalSx }}
      >
        <LayoutRefContext.Provider value={layoutRef}>
          <ScrollProvider>
            <ClientRouter />
            {isContactFormRender && <ContactForm />}
            {isFooterRender && <ClientFooter />}
          </ScrollProvider>
        </LayoutRefContext.Provider>
      </Box>
      <ContactMenu />
    </Box>
  )
}

export default ClientLayout
