import { Grid, Typography, useTheme } from '@mui/material'
import InvestObjectSubmitFormSectionStatusIcon from '../InvestObjectSubmitFormSectionStatusIcon'
import { styles } from './styles'
import { useTranslation } from 'react-i18next'

const InvestObjectSubmitFormSectionWrapper = ({ title, status, index, children, id }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (<Grid id={id} sx={{ backgroundColor: theme.palette.background.light, ...styles.container }}>
    <Grid sx={styles.containerGrid}>
      <Grid sx={styles.title}>
        <Grid sx={styles.statusIcon}>
          <InvestObjectSubmitFormSectionStatusIcon index={index} isValid={status} />
        </Grid>
        <Typography
          sx={{ fontSize: { sm: '1.5rem', xs: '1.375rem' },
              fontWeight: 600,
              marginTop: '8px',
          }}
        >
          { t(title) }
        </Typography>
      </Grid>

      <Grid sx={styles.sectionContainer}>
        <Grid sx={styles.form}>
          { children }
        </Grid>
      </Grid>
    </Grid>
  </Grid>)
}

export default InvestObjectSubmitFormSectionWrapper
