import { Skeleton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { truncateTextByLines } from '@/utils/common'
import useBreakpoints from '@/hooks/use-breakpoints'
import { useTranslation } from 'react-i18next'

const GlobalSearchItemBody = ({ item, isHovered, loading }) => {
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        sx={{ mb: '20px', letterSpacing: '-0.006em' }}
        variant={'body2'}
        color={isHovered ? 'common.white' : 'primary'}
      >
        {loading ? <Skeleton /> : t(`globalSearchPage.searchCategory.${item.category}`)}
      </Typography>
      <Typography
        sx={{ mb: '14px', ...truncateTextByLines(isMobile ? 4 : 2) }}

        variant={'h5'}
      >
        {loading ? <Skeleton /> : item.title}
      </Typography>
      <Typography
        component='div'
        sx={{
          ...truncateTextByLines(isMobile ? 6 : 4),
          color: isHovered ? 'common.white': 'accent.light',
          backgroundColor: 'transparent'
        }}
        color='textSecondary'
        variant={'body1'}
      >
        {loading ? <Skeleton /> : <div dangerouslySetInnerHTML={{ __html: item.description }}></div>}
      </Typography>
    </Box>
  )
}

export default GlobalSearchItemBody
