import Box from '@mui/material/Box'

import AppGridToolbar from '@/components/app-grid/partials/app-grid-toolbar'
import PropTypes from 'prop-types'
import { AppGridProvider } from '@/components/app-grid/AppGrid.context'
import AppGridTable from '@/components/app-grid/partials/app-grid-table'

const AppGridProps = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  settings: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  bulkActions: PropTypes.object,
  rowActions: PropTypes.object,
  externalFilters: PropTypes.object,
  onTableRefresh: PropTypes.func,
}

const AppGrid = (props) => {
  return (
    <AppGridProvider {...props}>
      <Box
        className='app-grid'
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      >
        <AppGridToolbar />
        <AppGridTable sx={{ flexGrow: 1 }} />
      </Box>
    </AppGridProvider>
  )
}

AppGrid.propTypes = AppGridProps

export default AppGrid
