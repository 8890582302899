import i18next from 'i18next'
import { minMaxLength, requiredField, requiredFile } from '@/utils/validations/common'
import { styles } from '@/containers/admin-events/admin-events-form/admin-events-form-styles'
import { requiredRange, validRange } from '@/utils/validations/date-range'
import { backgroundImageUploadLabels } from '@/constants/default'
import { documentStatus, documentStatusField } from '@/containers/constants'

export const eventsMultiLangFormInputs = [
  documentStatusField({ sx: styles.inputs.status }),
  {
    type: 'text',
    options: {
      name: 'title',
      label: i18next.t('forms.addHeader'),
      sx: styles.inputs.title,
      required: true
    }
  },
  {
    type: 'text',
    options: {
      name: 'location',
      label: i18next.t('events.location'),
      sx: styles.inputs.location,
      required: true
    }
  },
  {
    type: 'textEditor',
    options: {
      name: 'text',
      sx: styles.inputs.textEditor,
      header: i18next.t('events.addEventText'),
      required: true
    }
  }
]

export const eventsFormInputsGeneral = [
  {
    type: 'imageUploader',
    options: {
      name: 'backgroundImage',
      labels: backgroundImageUploadLabels,
      sx: styles.inputs.backgroundImage,
      previewSize: { width: '100%', height: 'auto', maxWidth: '500px' },
      required: true,
      maxSize: '5MB'
    }
  },
  {
    type: 'dateRange',
    options: {
      name: 'eventsRange',
      labels: {
        start: i18next.t('events.startDate'),
        end: i18next.t('events.endDate')
      },
      sx: styles.inputs.eventsRange,
      required: {
        start: true,
        end: false
      }
    }
  }
]

export const draftEventsValidations = {
  title: [minMaxLength(0, 100)],
  text: [minMaxLength(0, 5000)],
  location: [minMaxLength(0, 100)]
}

export const publishEventsValidations = {
  title: [requiredField, ...draftEventsValidations.title],
  text: [requiredField, ...draftEventsValidations.text],
  location: [requiredField, ...draftEventsValidations.location]
}

export const generalValidationDraft = {
  backgroundImage: [],
  eventsRange: [validRange]
}

export const generalValidationPublish = {
  backgroundImage: [requiredFile],
  eventsRange: [requiredRange, ...generalValidationDraft.eventsRange]
}

export const eventsFormInitialValue = {
  global: {
    eventsRange: {
      startDate: null,
      endDate: null
    },
    backgroundImage: null
  },
  en: {
    title: '',
    text: '',
    location: '',
    status: documentStatus.DRAFT
  },
  uk: {
    title: '',
    text: '',
    location: '',
    status: documentStatus.DRAFT
  }
}
