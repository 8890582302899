import React from 'react'
import PropTypes from 'prop-types'
import { MenuItemWithoutSubItemsType, MenuItemWithSubItemsType } from './types'
import { Button, Menu, MenuItem, lighten } from '@mui/material'
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import AppNavLink from '@/components/app-nav-link/AppNavLink'

const buttonStyles = {
  whiteSpace: 'nowrap',
  color: 'text.primary',
  '&:hover, &:focus': {
    backgroundColor: 'grey.light'
  },
  fontSize: '16px',
  px: { lg: 1.1, md: 1, xl: 3 },
  '& .MuiButton-endIcon': {
    ml: '2px'
  }
}

const subItemStyles = {
  borderRadius: 0,
  lineHeight: 1.5,
  padding: '3px 7px',
  fontWeight: 500,
  m: (theme) => theme.spacing(2),
  '&:focus': {
    backgroundColor: 'transparent'
  },
  '&:hover, &:focus-visible': {
    backgroundColor: (theme) => lighten(theme.palette.primary.light, 0.7)
  }
}

const MainNavMenuItemProps = {
  menuItem: PropTypes.oneOfType([
    PropTypes.shape(MenuItemWithSubItemsType).isRequired,
    PropTypes.shape(MenuItemWithoutSubItemsType).isRequired
  ]).isRequired,
  toggleBackdrop: PropTypes.func.isRequired
}

const MainNavMenuItem = ({ menuItem: { label, subItems }, toggleBackdrop }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    toggleBackdrop()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    toggleBackdrop()
    setAnchorEl(null)
  }

  return subItems ? (
    <>
      <Button
        disableRipple
        onClick={handleClick}
        id={`${label}-button`}
        variant='text'
        sx={buttonStyles}
        disableElevation
        endIcon={open ? <KeyboardArrowUpIcon color='primary' /> : <KeyboardArrowDownIcon color='primary' />}
      >
        {t(`clientNavigation.menuItems.${label}`)}
      </Button>

      <Menu
        elevation={0}
        id={`${label}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: (theme) => `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
            borderTop: (theme) => `1px solid ${theme.palette.grey.main}`,
            padding: 1
          },
          '& .MuiList-root': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }
        }}
        transformOrigin={{
          vertical: -19,
          horizontal: 'left'
        }}
      >
        {subItems.map((subItem) => {
          return (
            <MenuItem
              key={subItem.label}
              sx={{ ...buttonStyles, ...subItemStyles }}
              component={AppNavLink}
              to={clientRoutes[subItem.label].route}
              end
              onClick={handleClose}
            >
              {t(`clientNavigation.menuItems.${subItem.label}`)}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  ) : (
    <Button
      disableRipple
      id={`${label}-button`}
      sx={buttonStyles}
      variant='text'
      to={clientRoutes[label].route}
      end
      component={AppNavLink}
      disableElevation
    >
      {t(`clientNavigation.menuItems.${label}`)}
    </Button>
  )
}

MainNavMenuItem.propTypes = MainNavMenuItemProps

export default MainNavMenuItem
