const palette = {
  basic: {
    black: '#000000',
    white: '#FFFFFF'
  },
  primary: {
    dark: '#00782E',
    main: '#009639',
    light: '#59BD2F'
  },
  secondary: {
    dark: '#36A923',
    main: '#59BD2F',
    light: '#81c963'
  },
  info: {
    dark: '#01579b',
    main: '#0288d1',
    light: '#03a9f4'
  },
  text: {
    primary: '#373A36',
    secondary: '#6D6C6C',
    contrast: '#C4C4C2'
  },
  accent: {
    main: '#373A36',
    light: '#6D6C6C',
    contrastText: '#000000'
  },
  grey: {
    main: '#D9D9D9',
    light: '#F7F7F7',
    lighter: '#D9D9D9',
    contrastText: '#000000'
  },
  background: {
    light: '#F3F3F3'
  },
  error: { main: '#f44336' }
}

export default palette
