import { Fragment, useEffect, useMemo, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useMatch } from 'react-router-dom'
import { getSideBarItems } from '@/containers/layouts/admin/admin-sidebar/constants'
import PropTypes from 'prop-types'
import AdminSidebarListItemLink from '@/containers/layouts/admin/admin-sidebar/admin-sidebar-list-item-link/AdminSidebarListItemLink'
import { useAuth } from '@/context/auth-context'
import { useTranslation } from 'react-i18next'

export const drawerWidth = 240

const AdminSidebarProps = {
  isMobileOpen: PropTypes.bool,
  drawerVariant: PropTypes.string
}

const AdminSidebar = ({ isMobileOpen, drawerVariant }) => {
  const { pathname } = useMatch({
    path: '/*'
  })
  const [openLists, setOpenLists] = useState({})
  const { t, i18n } = useTranslation()
  const { activeUserRole } = useAuth()

  const sidebarItems = useMemo(
    () => getSideBarItems(activeUserRole, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUserRole, i18n.language]
  )

  useEffect(() => {
    sidebarItems.forEach(({ label, prefix }) => {
      if (pathname.startsWith(prefix)) {
        setOpenLists((prevState) => ({ ...prevState, [label]: true }))
      }
    })
  }, [pathname, sidebarItems])

  const toggleCollapse = (label) => {
    setOpenLists((prevState) => {
      const closeAll = Object.keys(prevState).reduce((res, active) => ({ ...res, [active]: false }), {})
      return { ...closeAll, [label]: !prevState[label] }
    })
  }

  const navItems = sidebarItems.map((item) => (
    <Fragment key={item.label}>
      {item.link ? (
        <AdminSidebarListItemLink item={item} />
      ) : (
        <ListItemButton onClick={() => toggleCollapse(item.label)}>
          <ListItemIcon sx={{ minWidth: 'max-content', marginRight: '8px' }}>{item.icon}</ListItemIcon>
          <ListItemText
            primary={item.label}
            sx={{ flexGrow: 1 }}
          />
          {item.subItems ? openLists[item.label] ? <ExpandLess /> : <ExpandMore /> : ''}
        </ListItemButton>
      )}
      <Collapse
        in={openLists[item.label]}
        timeout='auto'
        unmountOnExit
      >
        {item.subItems &&
          item.subItems.map((subItem) => (
            <AdminSidebarListItemLink
              key={subItem.label}
              item={subItem}
              subItem
            />
          ))}
      </Collapse>
    </Fragment>
  ))

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant={drawerVariant}
      open={isMobileOpen}
      anchor='left'
    >
      <Toolbar />
      <Divider />
      <List>{navItems}</List>
      <Divider />
    </Drawer>
  )
}

AdminSidebar.propType = AdminSidebarProps

export default AdminSidebar
