import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'
import { buildURL } from '@/utils/url-builder'

export const NewsSubscription = {
  subscribe: (params, recaptchaToken) => {
    return request.post(
      URLs.news.news_subscriptions,
      { ...params },
      {
        headers: { 'x-recaptcha-value': recaptchaToken }
      }
    )
  }
}

export const NewsService = {
  grid: (params) => {
    return request.post(URLs.admin.news.search, { ...params })
  },
  bulkDeletion: (params) => {
    return request.post(URLs.admin.news.bulk.delete, { ...params })
  },
  countPerStatus: () => {
    return request.get(URLs.admin.news.countPerStatus)
  },
  createNews: (data) => {
    return request.post(URLs.admin.news.createNews, { ...data })
  },
  getNewsById: (id) => {
    return request.get(buildURL(URLs.admin.news.getNewsById, { id }))
  },
  putNewsById: (id, data) => {
    return request.put(buildURL(URLs.admin.news.putNewsById, { id }), data)
  },
  getNews: (params) => {
    return request.post(URLs.news.getNews, { ...params })
  },
  getNewsTags: (lang) => {
    return request.get(buildURL(URLs.news.getNewsTags, {}, { lang }))
  },
  getNewsDetailsById: ({ id, query }) => {
    return request.get(buildURL(URLs.news.getNewsDetailsById, { id }, query))
  }
}
