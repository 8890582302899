import { styles } from '@/containers/admin-invest-object/admin-invest-object-form/admin-invest-object-form-styles'
import { useTranslation } from 'react-i18next'
import AppRadioGroup from '@/components/app-radio-group'
import { yesNoOptions } from '@/constants/default'
import { Box, Button, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  borders,
  roads
} from '@/containers/admin-invest-object/admin-invest-object-form/transport-connection-section/constants'
import palette from '@/plugins/material-ui/palette'
import { generateGuid } from '@/utils/guid'
import PropTypes from 'prop-types'

const TransportConnectionSectionProps = {
  formPropsGeneral: PropTypes.object
}

const TransportConnectionSection = ({ formPropsGeneral }) => {
  const { t } = useTranslation()

  const handleRadioChange = (e) => {
    const value = e.target.value === 'true'
    formPropsGeneral.handleChange('railroad')({ target: { value } })
  }

  const handleChange = ({ field, index, e, sectionName }) => {
    const value = e.target.value
    const newValue = formPropsGeneral.data[sectionName]
    newValue[index] = { ...newValue[index], [field]: value }
    formPropsGeneral.handleChange(sectionName)({ customType: true, value: newValue })
  }

  const handleBlur = ({ field, index, e, sectionName }) => {
    const value = e.target.value
    const newValue = formPropsGeneral.data[sectionName]
    newValue[index] = { ...newValue[index], [field]: value }
    formPropsGeneral.handleBlur(sectionName)({ customType: true, value: newValue })
  }

  const handleAdd = (sectionName) => {
    const currentValue = formPropsGeneral.data[sectionName]
    formPropsGeneral.handleChange(sectionName)(
      {
        customType: true,
        value: [...currentValue, { name: '', distanceKM: '', id: generateGuid() }]
      },
      { notValidate: true }
    )
  }

  const handleDelete = ({ index: indexToDelete, sectionName }) => {
    const value = formPropsGeneral.data.internationalAndStateRoads.filter((_item, index) => index !== indexToDelete)

    formPropsGeneral.handleChange(sectionName)(
      {
        customType: true,
        value
      },
      { notValidate: true }
    )
  }

  const section = (sectionName) => (
    <>
      {formPropsGeneral.data[sectionName].map(({ name, distanceKM, id }, index) => {
        return (
          <Box
            sx={{ display: 'flex', gap: '16px' }}
            key={id}
          >
            <TextField
              value={name}
              id={'name' + id}
              error={Boolean(formPropsGeneral.errors[sectionName]?.['name' + id])}
              helperText={formPropsGeneral.errors[sectionName]?.['name' + id] || ' '}
              onChange={(e) =>
                handleChange({
                  field: 'name',
                  fieldId: 'name' + id,
                  index,
                  e,
                  sectionName
                })
              }
              onBlur={(e) =>
                handleBlur({
                  field: 'name',
                  fieldId: 'name' + id,
                  index,
                  e,
                  sectionName
                })
              }
              label={t(`investObjects.labels.${sectionName}.name`)}
              sx={{ flexBasis: '60%' }}
            />
            <TextField
              value={distanceKM}
              id={'distance' + id}
              error={Boolean(formPropsGeneral.errors[sectionName]?.['distance' + id])}
              helperText={formPropsGeneral.errors[sectionName]?.['distance' + id] || ' '}
              onChange={(e) => handleChange({ field: 'distanceKM', fieldId: 'distance' + id, index, e, sectionName })}
              onBlur={(e) =>
                handleBlur({
                  field: 'distanceKM',
                  fieldId: 'distance' + id,
                  index,
                  e,
                  sectionName
                })
              }
              label={t('investObjects.labels.distanceKM')}
              sx={{ flexBasis: '30%' }}
            />
            <DeleteIcon
              onClick={() => handleDelete({ index, sectionName })}
              sx={{
                mb: '20px',
                alignSelf: 'center',
                color: palette.primary.main,
                cursor: 'pointer',
                '&:hover': { color: palette.primary.light }
              }}
            />
          </Box>
        )
      })}
      {formPropsGeneral.data[sectionName].length < 5 && (
        <Button
          sx={{ alignSelf: 'center' }}
          onClick={() => handleAdd(sectionName)}
        >
          {t(`investObjects.labels.${sectionName}.add`)}
        </Button>
      )}
    </>
  )

  return (
    <>
      <AppRadioGroup
        value={formPropsGeneral.data.railroad}
        onChange={handleRadioChange}
        error={Boolean(formPropsGeneral.errors.railroad)}
        helperText={formPropsGeneral.errors.railroad}
        sx={styles.inputs.railroad}
        options={yesNoOptions}
        label={t('investObjects.labels.railroad')}
        row
      />
      <Box sx={styles.inputs.internationalAndStateRoads}>{section(roads)}</Box>
      <Box sx={styles.inputs.borderCrossingPoints}>{section(borders)}</Box>
    </>
  )
}

TransportConnectionSection.propTypes = TransportConnectionSectionProps

export default TransportConnectionSection
