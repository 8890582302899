import React from 'react'
import { Box } from '@mui/material'
import AppPhone from '@/components/app-phone/AppPhone'
import useBreakpoints from '@/hooks/use-breakpoints'
import ModalContainer from '@/components/app-modal/ModalContainer'

const InvestObjectInvite = ({ detail }) => {
  const { isMobile } = useBreakpoints()
  return (
    <Box
      display={'flex'}
      gap={ isMobile ? '12px' : '30px'}
      flexWrap={'wrap'}
    >
      <ModalContainer isMobile={isMobile} />
      <AppPhone
        phone={detail?.contactsPhone}
        sx={{ maxWidth: '100%', width: isMobile ? '100%': '280px' }}
      />
    </Box>
  )
}


export default InvestObjectInvite

