import { fileSizes } from '@/constants/default'

export const bytesToText = (bytes) => {
  if (bytes === 0) {
    return '0 Byte'
  }
  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${Math.round(bytes / Math.pow(1024, sizeIndex))} ${fileSizes[sizeIndex]}`
}

export const textToBytes = (size, measure) => {
  const index = fileSizes.findIndex((item) => item === measure)
  return size * Math.pow(1024, index)
}

export const getImageSizes = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const image = new Image()
      image.src = event.target.result
      image.onload = () => {
        const { width, height } = image
        return resolve({ width, height })
      }
    }
  })
}
