import React, { useState } from 'react'
import TriggerButton from '@/components/app-modal/TrigerButton'
import AppModal from '@/components/app-modal/AppModal'

export const ModalContainer = ({ isMobile }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const [formInitialData, setFormInitialData] = useState({ description: '', phone: '',  email: '' })
  const [communicationType, setCommunicationType] = useState(null)
  const [activeSubmit, setActivesubmit] = useState(false)
  const [visible, setVisible] = useState('hidden')
  const handleClose = () => {
    setFormInitialData({ description: '', phone: '',  email: '' })
    setCommunicationType(null)
    setOpen(false)
    setActivesubmit(false)
    setVisible('hidden')
  }
  const onHandleType = (item) => {
    setCommunicationType(item)
    setActivesubmit(true)
    setVisible('visible')
  }
    return (
      <>
        <TriggerButton
          handleOpen={handleOpen}
          isMobile={isMobile}
        />
        <AppModal
          open={open}
          handleClose={handleClose}
          formInitialData={formInitialData}
          communicationType={communicationType}
          onHandleType={onHandleType}
          visible={visible}
          activeSubmit={activeSubmit}
        />
      </>
    )
}

export default ModalContainer
