import { Link } from 'react-router-dom'
import React from 'react'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import i18next from 'i18next'
import { langTypeColumn } from '@/containers/constants'

export const eventsTableColumns = [
  {
    headerName: i18next.t('events.table.title'),
    field: 'title',
    renderCell: ({ id, value }) => <Link to={adminRoutes.editEvent.path + `/${id}`}>{value}</Link>
  },
  { headerName: i18next.t('events.table.authorEmail'), field: 'authorEmail' },
  { headerName: i18next.t('events.table.startDate'), field: 'startDate', type: 'dateTime' },
  { headerName: i18next.t('events.table.endDate'), field: 'endDate', type: 'dateTime' },
  { headerName: i18next.t('events.table.location'), field: 'location' },
  { headerName: i18next.t('events.table.publishedLang'), field: 'publishedTranslations', ...langTypeColumn }
]
export const eventsTableSettings = { checkboxSelection: true }
