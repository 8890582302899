import { useEffect } from 'react'

export const useScriptLoader = ({ path, loadAfterDeps = false,  deps = [] }) => {
  useEffect(() => {
    if (loadAfterDeps && deps.length === 0) {
      return
    }

    // Now Attach All Third Party Scripts.
    let script = document.createElement('script')

    // Hook Sources.
    script.src = `${process.env.PUBLIC_URL}/${path}`

    // Persist order of Loading.
    script.async = false
    script.id = 'script-' + path
    script.className = path.replace('/','')

    // Append to index.html
    document.body.appendChild(script)

    // Do Clean ups
    return () => {
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps])
}
