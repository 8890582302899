import AppInfiniteScroll from '@/components/app-infinite-scroll'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import useAxios from '@/hooks/use-axios'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LayoutRefContext } from '@/containers/layouts/client/client-layout/ClientLayout'
import Chip from '@mui/material/Chip'
import { SupportProgramsService } from '@/services/support-program-service'
import { useTagsFilter } from '@/hooks/use-tags-filter'
import SupportProgramItem from '@/containers/support-program-banner/support-program-item'
import useBreakpoints from '@/hooks/use-breakpoints'
import { getTagFromUrl } from '@/utils/url-helper'
import { responsiveGap } from '@/pages/home-page/HomePage.styles'
import SupportProgramsSkeleton from './SupportProgramsSkeleton'

const SupportProgramPage = () => {
  const layoutRef = useContext(LayoutRefContext)
  const { i18n, t } = useTranslation()

  const { isMobile } = useBreakpoints()

  const tagFromPath = getTagFromUrl('tag')
  const tagTileFromUlr = i18n.t(`clientNavigation.menuItems.${tagFromPath}`)
  const {
    filterBy,
    tagsItems,
    handleFilterChange,
    loading: tagsLoading
  } = useTagsFilter({
    service: SupportProgramsService.getSupportProgramTags,
    tagTileFromUlr,
    useDefaultTags: true
  })

  const [programItems, setProgramItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)

  const { fetchData: fetchSupportPrograms } = useAxios({
    service: SupportProgramsService.getSupportPrograms,
    handleError: true
  })

  const InfiniteScroll = useRef(null)

  const getSupportPrograms = useCallback(
    async ({ take, skip }) => {
      if (tagsLoading) {
        return []
      }
      const meaningFullTags = filterBy.filter(({ id }) => typeof id !== 'string')
      const emptyTags = filterBy.filter(({ id }) => typeof id === 'string')

      if (filterBy.length > 0 && emptyTags.length === filterBy.length) {
        setProgramItems([])
        setTotalItems(0)
        return []
      }

      const { items, count } = await fetchSupportPrograms({
        take,
        skip,
        lang: i18n.language,
        tags: meaningFullTags.map(({ id }) => id)
      })

      setProgramItems([...programItems, ...items])
      setTotalItems(count)
      return items
    },
    [tagsLoading, filterBy, fetchSupportPrograms, i18n.language, programItems]
  )

  const clearItems = () => {
    setTotalItems(0)
    setProgramItems([])
  }

  useEffect(() => {
    clearItems()
    InfiniteScroll.current.refreshData()
  }, [i18n.language, filterBy])

  const pageHeader = useRef(null)

  useEffect(() => {
    pageHeader?.current.scrollIntoView()
  }, [tagFromPath])

  const mobileTags = {
    display: 'flex',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }

  return (
    <Box
      sx={{
        px: { md: '44px', sm: '24px', xs: '16px' },
        margin: 'auto',
        mt: '45px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: '1440px',
        width: '100%'
      }}
    >
      <Typography
        ref={pageHeader}
        variant='h1'
        sx={{ mb: '30px' }}
      >
        {t('supportPrograms.title')}
      </Typography>
      <Box sx={isMobile ? mobileTags : {}}>
        <Chip
          variant={'outlined'}
          className={!filterBy.length ? 'active-chip' : ''}
          color={'secondary'}
          onClick={() => handleFilterChange(null)}
          sx={{ mr: '14px', mb: '14px' }}
          key='all'
          label={t('supportPrograms.showAll')}
        />
        {tagsItems.map((item) => (
          <Chip
            variant={'outlined'}
            className={filterBy.includes(item) ? 'active-chip' : ''}
            color={'secondary'}
            onClick={() => handleFilterChange(item)}
            sx={{ mr: '14px', mb: '14px' }}
            key={item.id}
            label={item.title}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1, mb: responsiveGap }}>
          <AppInfiniteScroll
            ref={InfiniteScroll}
            items={programItems}
            parentRef={layoutRef}
            totalCount={totalItems}
            fetchData={getSupportPrograms}
            clearItems={clearItems}
            loader={<SupportProgramsSkeleton />}
            noItems={
              <div key='no-items'>{t(`supportPrograms.${filterBy.length === 0 ? 'noItems' : 'noItemsTag'}`)}</div>
            }
          >
            <Box
              key='items'
              sx={{
                display: 'grid',
                gridTemplateColumns: { md: '1fr 1fr 1fr', sm: '1fr 1fr', xs: '1fr' },
                gap: '40px'
              }}
            >
              {programItems.map((item) => (
                <Box key={item.id}>
                  <SupportProgramItem
                    url={`/support-programs/${item.id}`}
                    vertical
                    item={item}
                  />
                </Box>
              ))}
            </Box>
          </AppInfiniteScroll>
        </Box>
      </Box>
    </Box>
  )
}

export default SupportProgramPage
