import React from 'react'
import i18next from 'i18next'
import { Link } from 'react-router-dom'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import { documentStatusOptions, langTypeColumn } from '@/containers/constants'
import { characteristicsTypeOptions } from '@/pages/admin/admin-invest-object-edit/constants'

export const getOptionByValue = (options, value) => options.find(({ value: mapValue }) => value === mapValue)

export const sourceType = {
  ADMIN: 'ADMIN',
  PROZORRO_SALE: 'PROZORRO_SALE',
  EXTERNAL_USER: 'EXTERNAL_USER'
}

export const sourceTypeOptions = [
  { value: sourceType.ADMIN, label: i18next.t('investObjects.sourceType.admin') },
  { value: sourceType.PROZORRO_SALE, label: i18next.t('investObjects.sourceType.prozorroSale') },
  { value: sourceType.EXTERNAL_USER, label: i18next.t('investObjects.sourceType.externalUser') }
]

export const investObjectTableColumns = [
  {
    headerName: i18next.t('investObjects.table.title'),
    field: 'name',
    renderCell: ({ id, value }) => <Link to={adminRoutes.editInvestObject.path + `/${id}`}>{value}</Link>
  },
  {
    headerName: i18next.t('investObjects.table.sourceType'),
    field: 'sourceType',
    renderCell: ({ value }) => getOptionByValue(sourceTypeOptions, value)?.label
  },
  {
    headerName: i18next.t('investObjects.table.type'),
    field: 'characteristicsType',
    renderCell: ({ value }) =>  getOptionByValue(characteristicsTypeOptions(), value)?.label
  },
  {
    headerName: i18next.t('investObjects.table.status'),
    field: 'status',
    type: 'status',
    renderCell: ({ value }) => getOptionByValue(documentStatusOptions, value)?.text
  },
  { headerName: i18next.t('investObjects.table.createdAt'), field: 'createdAt', type: 'dateTime' },
  { headerName: i18next.t('investObjects.table.publishedLang'), field: 'publishedTranslations', ...langTypeColumn },
  { headerName: i18next.t('investObjects.table.viewsCount'), field: 'viewsCount' }
]
export const investObjectTableSettings = { checkboxSelection: true }
