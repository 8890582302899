import { CheckCircle } from '@mui/icons-material'
import StepNumber from '@/containers/step-number'

const styles = {
  status: {
    width: { sm: '34px', xs: '28px' },
    height: { sm: '34px', xs: '28px' },
    fontSize: { sm: '1.2rem', xs: '1rem' },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0',
    marginRight: '10px',
    marginTop: '8px',
  },
}

const InvestObjectSubmitFormSectionStatusIcon = ({ isValid, index }) => {

  const icon = isValid
    ? (<CheckCircle
        color="primary"
        sx={styles.status}
       />)
    : (<StepNumber sx={styles.status} index={index} size={34} />)
  
  return (<div>
    { icon }
  </div>)
}

export default InvestObjectSubmitFormSectionStatusIcon
