import { useEffect } from 'react'
import { statusValidation } from '@/utils/form-helper'

export const useStatusChange = ({ setValidation, draftValidation, publishValidation, status }) => {
  useEffect(() => {
    if (publishValidation) {
      setValidation(statusValidation(status, draftValidation, publishValidation))
    }
  }, [draftValidation, publishValidation, setValidation, status])
}
