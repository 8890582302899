export const styles = {
  form: {
    pt: '16px',
    display: 'grid',
    gap: '16px 6px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: 'repeat(6, auto)'
  },
  inputs: {
    email: {
      gridColumn: '1 / 7',
      gridRow: '1'
    },
    firstName: {
      gridColumn: '1 / 7',
      gridRow: '2'
    },
    lastName: {
      gridColumn: '1 / 7',
      gridRow: '3'
    },
    password: {
      gridColumn: '1 / 5',
      gridRow: '4'
    },
    changePassword: {
      gridColumn: '5 / 7',
      gridRow: '4',
      alignSelf: 'flex-start'
    },
    role: {
      gridColumn: '1 / 7',
      gridRow: '5'
    },
    notification: {
      gridColumn: '1 / 7',
      gridRow: '6'
    }
  }
}
