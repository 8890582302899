import { useTranslation } from 'react-i18next'

import { Link, Navigate } from 'react-router-dom'

import { Alert, Box, Button, FormGroup } from '@mui/material'

import { useAuth } from '@/context/auth-context'
import { AuthService } from '@/services/auth-service'

import { loginFormInitialValues, loginInputs, loginValidations } from '@/pages/admin/admin-login-page/constants'
import { appRoutes } from '@/plugins/router/routes'
import useForm from '@/hooks/use-form'
import AppForm from '@/components/app-form/AppForm'
import useAxios from '@/hooks/use-axios'
import { useMemo } from 'react'
import AppInputs from '@/components/app-form/app-inputs'
import { adminRoutes } from '@/plugins/router/admin-router/routes'

const AdminLoginPage = () => {
  const { accessToken, login } = useAuth()
  const { t } = useTranslation()

  const {
    data: loginData,
    formProps,
    handleValidate,
    isDirty,
    setMappedData
  } = useForm({ inputs: loginInputs, initialValues: loginFormInitialValues, validations: loginValidations })

  const { fetchData: loginRequest, loading, error } = useAxios({ service: AuthService.login, handleError: true })

  const onSubmit = async () => {
    if (handleValidate()) {
      setMappedData(loginData)
      const data = await loginRequest(loginData)
      login(data)
    }
  }

  const isAuthError = useMemo(() => error?.response?.status === 401, [error])

  const errorMessage = useMemo(
    () => (isAuthError ? t('login.error') : error?.message),
    [error?.message, t, isAuthError]
  )

  if (accessToken) {
    return (
      <Navigate
        to={appRoutes.admin.route}
        replace
      />
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '36px' }}>
      <AppForm onFormSubmit={onSubmit}>
        <FormGroup sx={{ flexGrow: 1, gap: '8px' }}>
          <AppInputs
            {...formProps}
          ></AppInputs>
        </FormGroup>
        <Button
          variant={'contained'}
          disabled={loading}
          type='submit'
        >
          {t('login.login')}
        </Button>
      </AppForm>
      {errorMessage && !isDirty && !loading && (
        <Box sx={{ padding: '10px' }}>
          <Alert severity='error'>{errorMessage}</Alert>
        </Box>
      )}
      <Box mt={'20px'}>
        <Button
          component={Link}
          to={adminRoutes.reset.path}
          end
          variant={'text'}
        >
          {t('login.resetPassword')}
        </Button>
      </Box>
    </Box>
  )
}

export default AdminLoginPage
