import AppCardItem from '@/components/app-card-item'
import InvestObjectListItemBody from '@/containers/invest-object-list/invest-object-list-item-body/InvestObjectListItemBody'

const InvestObjectListItem = ({ vertical, item = {}, url, sx, loading }) => {
  return (
    <AppCardItem
      sx={sx}
      loading={loading}
      linkUrl={url}
      image={{ src: item.featureImage, alt: item.title }}
      title={item.title}
      body={item.text}
      vertical={vertical}
      imageBox={{ width: '224px', height: '160px' }}
    >
      <InvestObjectListItemBody
        item={item}
        loading={loading}
      />
    </AppCardItem>
  )
}

export default InvestObjectListItem
