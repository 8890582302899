export const theme = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          marginTop: 5,
          backgroundColor: '#fff',
          border: 'none',
          input: {
            padding: '12.5px 14px',
          },
          '.MuiSelect-select': {
            padding: '12.5px 14px',
          },
          fieldset: {
            top: 0,
            border: 'none',
            legend: {
              display: 'none',
            }
          }
        },
      }
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: '#373A36',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '18px',
          marginBottom: { sm: '10px', xs: '3px' },
          maxWidth: '100%',
          whiteSpace: 'normal',
          overflow: 'visible',
          position: 'static',
        },
        shrink: {
          transform: 'unset',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          marginBottom: '20px',
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          marginBottom: '12px',
          marginRight: '8px',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#373A36',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '18px',
          whiteSpace: 'normal',
        },

      }
    },
    MuiBox: {
      styleOverrides: {
        root: {
          marginBottom: 20,
        }
      }
    },
  }
}
