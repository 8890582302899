import Box from '@mui/material/Box'
import investTransparentLogo from '@/assets/images/invest-transparent.png'
import { Typography } from '@mui/material'
import arrowIcon from '@/assets/icons/location-map.svg'
import { truncateTextByLines } from '@/utils/common'
import useBreakpoints from '@/hooks/use-breakpoints'

export const makeAddress = (item) =>
  [item.settlement, item.community, item.district, item.street, item.buildingNumber].filter(Boolean).join(', ')

const InvestItem = ({ item }) => {
  const { isTablet, isMobile } = useBreakpoints()

  const address = makeAddress(item)
  return (
    <Box
      sx={{
        borderRadius: '5px',
        backgroundColor: '#216916',
        position: 'relative',
        width: isTablet || isMobile ? '100%' : '920px',
        height: isTablet || isMobile ? '100%' : '576px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        '> img': {
          width: item.featureImage ? '100%' : isMobile ? '196px' : '294px',
          objectFit: item.featureImage ? 'cover' : 'contain',
          transition: 'transform .5s ease'
        },
        ':hover img': {
          transform: 'scale(1.06)'
        }
      }}
    >
      {
        <img
          src={item.featureImage ? item.featureImage : investTransparentLogo}
          alt={item.featureImage ? item.name : 'Invest Logo'}
        />
      }
      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          color: 'common.white',
          left: '0px',
          right: '0px',
          textAlign: 'left',
          px: '30px'
        }}
      >
        <Typography
          title={item.name}
          sx={{ ...truncateTextByLines(2), fontSize: '30px' }}
          variant='h3'
        >
          {item.name}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box
            my={'12px'}
            component={'img'}
            alt={'left'}
            src={arrowIcon}
            height={18}
            width={18}
          />
          <Typography
            title={address}
            sx={{ mt: '12px', mb: '10px', ml: '10px', ...truncateTextByLines(2) }}
            variant='subtitle2'
          >
            {address}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
export default InvestItem
