import { styled, TextField } from '@mui/material'
import { theme } from '@/plugins/material-ui/theme'

const AppSmallTextField = styled((props) => (
  <TextField
    InputProps={{ disableUnderline: true }}
    variant={'filled'}
    size='small'
    {...props}
  />
))(() => ({
  '& .MuiFormLabel-root': {
    fontSize: '11px'
  },
  '& input': {
    paddingTop: '12px'
  },
  '& .MuiFilledInput-root': {
    height: '40px',
    border: '1px solid ',
    borderColor: theme.palette.grey.main,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.primary.main
    }
  }
}))

export default AppSmallTextField
