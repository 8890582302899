import { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import useAxios from '@/hooks/use-axios'

const useAxiosWithErrorSnackbar = ({ service, initialLoading = false }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { error, loading, fetchData } = useAxios({ service, initialLoading, handleError: true })

  const fetchDataWithSnackbar = useCallback(
    async (params, recaptchaToken = null) => {
      try {
        const data = await fetchData(params, recaptchaToken)

        return data
      } catch (err) {
        const errorMessage = err?.response?.data?.message
        if (errorMessage) {
          const snackbarMessage = Array.isArray(errorMessage) ? errorMessage.join(', ') : errorMessage
          enqueueSnackbar(snackbarMessage, { variant: 'error' })
        }

        throw err
      }
    },
    [enqueueSnackbar, fetchData]
  )

  return { error, loading, fetchData: fetchDataWithSnackbar }
}

export default useAxiosWithErrorSnackbar
