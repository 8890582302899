import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import PropTypes from 'prop-types'

import { cloneElement, useState } from 'react'
import { Skeleton } from '@mui/material'
import AppLogo from '@/components/app-logo'

const componentProps = {
  image: PropTypes.shape({ src: PropTypes.string, alt: PropTypes.string }),
  subTitle: PropTypes.shape({
    icon: PropTypes.element,
    text: PropTypes.shape({ variant: PropTypes.string, value: PropTypes.string, color: PropTypes.string })
  }),
  title: PropTypes.string,
  body: PropTypes.string,
  linkUrl: PropTypes.string,
  orientation: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  sx: PropTypes.object
}

const horizontalSx = {
  margin: 'auto',
  display: 'flex'
}

const AppCardItem = ({
  image,
  linkUrl,
  vertical,
  loading,
  sx,
  children,
  imageBox = { width: '315px', height: '223px' }
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const containerSX = vertical ? {} : horizontalSx
  const imageBoxSX = vertical
    ? { mb: '20px', maxHeight: imageBox.height }
    : { mr: '30px', minWidth: imageBox.width, maxWidth: imageBox.width }
  const imageSX = vertical ? { height: imageBox.height, width: '100%' } : { width: imageBox.width, height: '100%' }

  return (
    <Link
      href={linkUrl}
      underline='none'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ cursor: 'pointer', ...containerSX, ...sx }}
      data-testid={'AppCardItem'}
    >
      <Box sx={{ ...imageBoxSX, overflow: 'hidden' }}>
        {loading ? (
          <Skeleton
            variant='rounded'
            width={'100%'}
            height={imageBox.height}
          />
        ) : image.src ? (
          <img
            style={{
              ...imageSX,
              maxHeight: imageBox.height,
              objectFit: 'cover',
              transform: isHovered ? 'scale(1.06)' : '',
              transition: 'transform .5s ease'
            }}
            src={image.src}
            alt={image.alt}
          />
        ) : (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100%'
            sx={{
              ...imageSX,
              transform: isHovered ? 'scale(1.06)' : '',
              transition: 'transform .5s ease',
              backgroundColor: 'background.light'
            }}
          >
            <AppLogo height='42'></AppLogo>
          </Box>
        )}
      </Box>
      {cloneElement(children, { isHovered })}
    </Link>
  )
}

AppCardItem.propType = componentProps

export default AppCardItem
