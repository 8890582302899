import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'

import './styles.css'
import InvestItem from '@/containers/investment_opportunities/invests-item/InvestItem'
import { Box } from '@mui/material'
import { EffectFade, Navigation } from 'swiper'
import { useNavigate } from 'react-router-dom'

const InvestMobileSwiper = ({ items }) => {
  const navigate = useNavigate()

  return (
    <Box sx={{ width: '100%', height: '400px' }}>
      <Swiper
        initialSlide={15}
        slidesPerView={'auto'}
        centeredSlides
        centeredSlidesBounds
        slidesOffsetBefore={15}
        slidesOffsetAfter={15}
        spaceBetween={15}
        navigation
        modules={[EffectFade, Navigation]}
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={index}
            onClick={() => navigate('/investment-object/' + item.id)}
          >
            <InvestItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
export default InvestMobileSwiper
