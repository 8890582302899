import { Box, Button, Chip, Typography } from '@mui/material'
import { toShortDate } from '@/utils/date'
import { getOptionByValue } from '@/containers/admin-invest-object/admin-invest-object-table/constants'
import { characteristicsTypeOptions, usageTypeOptions } from '@/pages/admin/admin-invest-object-edit/constants'
import downloadIcon from '@/assets/icons/download.svg'
import AppShowMore from '@/components/app-show-more/AppShowMore'
import { useTranslation } from 'react-i18next'
import InvestObjectInvite from '@/containers/invest-object-details/invest-object-invite/InvestObjectInvite'
import useBreakpoints from '@/hooks/use-breakpoints'
import { makeAddress } from '@/containers/investment_opportunities/invests-item/InvestItem'
import useAxios from '@/hooks/use-axios'
import { InvestObjectService } from '@/services/invest-object-service'
import { fileDownload } from '@/utils/file-download'

const InvestObjectContactInfo = ({ detail }) => {
  const { t, i18n } = useTranslation()
  const { isMobile } = useBreakpoints()
  const address = makeAddress(detail)

  const { loading: exporting, fetchData: exportPdf } = useAxios({
    service: InvestObjectService.exportInvestObjectDetails
  })

  const exportPdfHandler = async () => {
    const data = await exportPdf({ id: detail.id, query: { lang: i18n.language } }).catch()
    fileDownload(new Blob([data], { type: 'application/pdf' }), `invest-object-${detail.id}-${Date.now().toFixed()}`)
  }

  return (
    <>
      <Typography
        variant='subtitle1'
        color={'textSecondary'}
        mb={'20px'}
        display='flex'
        justifyContent='space-between'
        flexWrap={'wrap'}
      >
        <Box sx={{ maxWidth: '400px' }}>{address}</Box>
        <Box>{t('investObjectsDetails.added') + toShortDate(detail.publishedDate)}</Box>
      </Typography>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '16px' }}
        mb={'24px'}
      >
        <Box sx={{ display: 'flex', gap: '14px', flexWrap: 'wrap' }}>
          <Chip
            sx={{ height: '26px', padding: 0 }}
            label={getOptionByValue(characteristicsTypeOptions(), detail.characteristicsType)?.label}
          />
          <Chip
            sx={{ height: '26px', padding: 0 }}
            label={getOptionByValue(usageTypeOptions(), detail.usageType)?.text}
          />
        </Box>
        <Button
          onClick={exportPdfHandler}
          disabled={exporting}
          sx={{
            height: '34px',
            minWidth: '132px',
            textDecoration: 'none',
            marginTop: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
          color={'secondary'}
          variant='contained'
        >
          <img
            src={downloadIcon}
            height={12}
            width={12}
            alt={'d'}
          />
          <Typography
            sx={{ fontSize: '14px' }}
            variant='subtitle2'
          >
            {t('investObjectsDetails.downloadPdf')}
          </Typography>
        </Button>
      </Box>
      <AppShowMore sx={{ alignSelf: 'flex-start', mb: '30px' }}>
        <Box
          component={'div'}
          dangerouslySetInnerHTML={{ __html: detail.description }}
        />
      </AppShowMore>
      {!isMobile ? <InvestObjectInvite detail={detail} /> : null}
    </>
  )
}
export default InvestObjectContactInfo
