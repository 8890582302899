import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'

export const ChangelogService = {
  grid: (params) => {
    return request.post(URLs.admin.changelog.search, { ...params })
  },
  exportCsv: () => {
    return request.get(URLs.admin.changelog.export)
  }
}
