import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, useTheme } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import AppDragAndDrop from '@/components/app-drag-and-drop'

import { FileService } from '@/services/file-service'

import { commonImageFileType } from '@/constants/default'

const AppMultipleImagesUploaderProps = {
  maxFilesCount: PropTypes.number,
  value: PropTypes.array.isRequired,
  onImagesChange: PropTypes.func.isRequired,
  header: PropTypes.string,
  sx: PropTypes.object
}

const AppMultipleImagesUploader = ({
  maxFilesCount = 15,
  maxFileSize = '5MB',
  value,
  error,
  helperText,
  onImagesChange,
  header,
  sx = {}
}) => {
  const theme = useTheme()

  const onValueChange = (file) => {
    onImagesChange([...value, ...file])
  }

  const [showDeleteIcon, setShowDeleteIcon] = useState('')

  const handleRemoveImage = async (imageId) => {
    onImagesChange(value.filter(({ id }) => id !== imageId))
    try {
      await FileService.delete(imageId)
    } catch (e) {
      console.error(e)
    }
  }

  const headerColor = useMemo(() => {
    return error && theme.palette.error.main
  }, [error, theme.palette.error.main])

  return (
    <Box sx={{ ...sx }}>
      {header && <Typography sx={{ my: '12px', color: headerColor }}>{header}</Typography>}
      <Box
        sx={{ display: 'flex', flexWrap: 'wrap' }}
        onMouseLeave={() => setShowDeleteIcon('')}
      >
        {value.map(({ id, path }) => (
          <Box
            key={id}
            sx={{
              mr: '16px',
              mb: '16px',
              position: 'relative'
            }}
            onMouseEnter={() => setShowDeleteIcon(id)}
          >
            {id === showDeleteIcon && (
              <HighlightOffIcon
                sx={{ position: 'absolute', top: '-15px', right: '-15px', color: 'primary.main', cursor: 'pointer' }}
                onClick={() => handleRemoveImage(id)}
              />
            )}
            <img
              src={path}
              style={{
                width: '150px',
                height: '100px'
              }}
              alt={path}
            />
          </Box>
        ))}
        <AppDragAndDrop
          onValueChange={onValueChange}
          value={value}
          accept={commonImageFileType}
          maxFilesCount={maxFilesCount}
          maxFileSize={maxFileSize}
        />
      </Box>
      {helperText && <Typography variant="subtitle1" sx={{ color: headerColor }}>{helperText}</Typography>}
    </Box>
  )
}

AppMultipleImagesUploader.propTypes = AppMultipleImagesUploaderProps

export default AppMultipleImagesUploader
