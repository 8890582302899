import i18next from 'i18next'
import { email, requiredField } from '@/utils/validations/common'

export const resetInput = [
  {
    type: 'text',
    options: {
      name: 'email',
      label: i18next.t('common.email')
    }
  }
]

export const codeInput = [
  {
    type: 'text',
    options: {
      name: 'code',
      label: i18next.t('login.code')
    }
  }
]

export const resetValidations = {
  email: [requiredField, email]
}

export const codeValidations = {
  code: [requiredField]
}

const resetInitial = { email: '' }
const codeInitial = { code: '' }

export const emailForm = {
  inputs: resetInput,
  initialValues: resetInitial,
  validations: resetValidations
}
export const codeForm = {
  inputs: codeInput,
  initialValues: codeInitial,
  validations: codeValidations
}
