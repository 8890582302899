import { Box, Button, DialogContent, ImageList, IconButton, ImageListItem } from '@mui/material'
import useBreakpoints from '@/hooks/use-breakpoints'
import photoIcon from '@/assets/icons/photo.svg'
import { useTranslation } from 'react-i18next'
import { useAppPopupDialog } from '@/context/app-popup-dialog-context'
import { Transition } from '@/components/app-popup-dialog/constant'
import AppCarousel from '@/components/app-carousel'
import './style.carousel-ovveride.css'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close'
import AppLogo from '@/components/app-logo'
const srcset = (image, size, rows = 1, cols = 1) => ({
  src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
  srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`
})
const photoSlice = 4

const SliderArrow = ({ clickHandler, next }) => {
  const { isMobile } = useBreakpoints()
  if (isMobile) {
    return null
  }
  const position = !next ? { left: '16px' } : { right: '16px' }
  return (
    <IconButton
      sx={{ position: 'fixed', top: '50%', backgroundColor: 'accent.light', ...position }}
      onClick={clickHandler}
      size='small'
    >
      {next ? <ChevronRightIcon /> : <ChevronLeftIcon />}
    </IconButton>
  )
}

const SliderDialog = ({ images, closeModal, selectedItem }) => {
  const { isTablet, isLarge, isDesktop, isMobile } = useBreakpoints()

  const getMaxWidthCarousel = () => {
    if (isLarge) {
      return '1200px'
    }
    if (isDesktop) {
      return '900px'
    }
    if (isTablet) {
      return '600px'
    }
    if (isMobile) {
      return '100%'
    }
  }

  return (
    <DialogContent
      sx={{
        backgroundColor: 'accent.main',
        color: 'common.white',
        padding: { xs: 0, sm: '46px', md: '26px' },
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <IconButton
        sx={{
          position: 'fixed',
          right: '16px',
          top: { sm: '16px', xs: '68px' },
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: 'common.white'
        }}
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          maxWidth: getMaxWidthCarousel(),
          flex: '1',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          margin: 'auto'
        }}
      >
        <AppCarousel
          stopOnHover
          useKeyboardArrows
          swipeable={isTablet || isMobile}
          infiniteLoop
          selectedItem={selectedItem}
          dynamicHeight
          emulateTouch
          statusFormatter={(current, total) => `${current} / ${total}`}
          showIndicators={false}
          renderArrowPrev={(clickHandler, hasPrev, label) => <SliderArrow clickHandler={clickHandler} />}
          renderArrowNext={(clickHandler, hasPrev, label) => (
            <SliderArrow
              clickHandler={clickHandler}
              next
            />
          )}
        >
          {images.map(({ id: imageId, path }) => (
            <img
              key={imageId}
              src={path}
              alt={'alt'}
              style={{ borderRadius: '4px' }}
            />
          ))}
        </AppCarousel>
      </Box>
    </DialogContent>
  )
}

const InvestObjectImageGrid = ({ images, alt = 'img' }) => {
  const { isMobile, isTablet } = useBreakpoints()
  const { t } = useTranslation()
  const { openModal, closeModal } = useAppPopupDialog()

  if (!images || images?.length === 0) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        minHeight={'200px'}
        height={isTablet || isMobile ? '310px' : '100%'}
        sx={{
          backgroundColor: 'background.light',
          mb: { sm: '35px', xs: '16px' }
        }}
      >
        <AppLogo height='42'></AppLogo>
      </Box>
    )
  }

  const openSliderDialog = (selectedItem = 0) => {
    openModal({
      content: (
        <SliderDialog
          images={images}
          closeModal={closeModal}
          selectedItem={selectedItem}
        />
      ),
      props: { fullScreen: true, TransitionComponent: Transition }
    })
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <ImageList
        sx={{
          width: '100%',
          height: 410,
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: 'translateZ(0)'
        }}
        rowHeight={135}
        gap={2}
        variant={'quilted'}
        cols={3}
      >
        {images.slice(0, photoSlice).map(({ id: imageId, path }, index) => {
          const isFirst = index === 0
          const ss = isFirst ? (isMobile ? { cols: 3, rows: 2 } : { cols: 2, rows: 3 }) : { cols: 1, rows: 1 }

          const cols = ss.cols
          const rows = ss.rows
          return (
            <ImageListItem
              onClick={() => openSliderDialog(index)}
              key={imageId}
              cols={cols}
              rows={rows}
            >
              <img
                src={path}
                {...srcset(path, 134, rows, cols)}
                alt={alt}
                style={{ borderRadius: '4px' }}
                loading='lazy'
              />
            </ImageListItem>
          )
        })}
      </ImageList>
      {images.length > photoSlice ? (
        <Button
          onClick={() => openSliderDialog()}
          sx={{
            position: 'absolute',
            left: '16px',
            bottom: '16px',
            zIndex: 1,
            borderRadius: '4px',
            padding: '10px',
            backgroundColor: 'common.white',
            color: 'common.black',
            '&:active, &:focus': {
              color: 'common.black'
            },
            '&:hover': {
              color: 'common.white'
            }
          }}
          variant='contained'
          startIcon={
            <img
              src={photoIcon}
              height={18}
              width={18}
              alt={'c'}
            />
          }
        >
          {t('investObjectsDetails.photos', { count: images.length })}
        </Button>
      ) : null}
    </Box>
  )
}

export default InvestObjectImageGrid
