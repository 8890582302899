import { useEffect } from 'react'
import {
  landPlotSectionEmptyValidations,
  landPlotSectionValidations
} from '@/containers/admin-invest-object/admin-invest-object-form/land-plot-section/constants'
import { minMaxLength, requiredField, validRegExp } from '@/utils/validations/common'
import { decimalPattern, onlyDigits } from '@/constants/default'
import {
  realEstateSectionEmptyValidations,
  realEstateSectionValidations
} from '@/containers/admin-invest-object/admin-invest-object-form/real-estate-section/constants'
import i18next from 'i18next'
import { characteristicType } from '@/pages/admin/admin-invest-object-edit/constants'

export const useUpdateValidations = ({
  activeForms,
  generalInputData,
  setGeneralValidations,
  setMultiLangValidations
}) => {
  useEffect(() => {
    if (activeForms.length) {
      if (!generalInputData.characteristicsType || generalInputData.characteristicsType === characteristicType.land) {
        setGeneralValidations((prevState) => ({
          ...prevState,
          ...realEstateSectionEmptyValidations,
          ...landPlotSectionValidations
        }))
      } else if (generalInputData.characteristicsType === characteristicType.realEstate) {
        setGeneralValidations((prevState) => ({
          ...prevState,
          ...(generalInputData.realtyHasLandPlot ? landPlotSectionValidations : landPlotSectionEmptyValidations),
          ...realEstateSectionValidations,
        }))
      }
    }
  }, [activeForms.length, generalInputData.characteristicsType, generalInputData.realtyHasLandPlot, setGeneralValidations])

  useEffect(() => {
    if (activeForms.length) {
      if (generalInputData.cadastralNumberStatus === 'EXISTS') {
        setGeneralValidations((prevState) => ({
          ...prevState,
          cadastralNumber: [...landPlotSectionValidations.cadastralNumber, requiredField]
        }))
      } else {
        setGeneralValidations((prevState) => ({
          ...prevState,
          cadastralNumber: [...landPlotSectionValidations.cadastralNumber]
        }))
      }

      if (generalInputData.landPlan) {
        setGeneralValidations((prevState) => ({
          ...prevState,
          landPlanFilesIds: [requiredField]
        }))
      } else {
        setGeneralValidations((prevState) => ({
          ...prevState,
          landPlanFilesIds: []
        }))
      }

      if (generalInputData.powerSupply) {
        setGeneralValidations((prevState) => ({
          ...prevState,
          powerSupplyKW: [
            requiredField,
            validRegExp({
              regexp: onlyDigits(0, 5),
              errorMsg: i18next.t('investObjects.errors.digitFormat', { max: 5 })
            })
          ],
          nearestPowerSupplyKM: []
        }))
      } else {
        setGeneralValidations((prevState) => ({
          ...prevState,
          powerSupplyKW: [],
          nearestPowerSupplyKM: [
            requiredField,
            validRegExp({
              regexp: decimalPattern(0, 5),
              errorMsg: i18next.t('investObjects.errors.decimalFormat', { max: 5 })
            })
          ]
        }))
      }

      if (generalInputData.gasSupply) {
        setGeneralValidations((prevState) => ({
          ...prevState,
          nearestGasSupplyKM: []
        }))
      } else {
        setGeneralValidations((prevState) => ({
          ...prevState,
          nearestGasSupplyKM: [
            requiredField,
            validRegExp({
              regexp: decimalPattern(0, 5),
              errorMsg: i18next.t('investObjects.errors.digitFormat', { max: 5 })
            })
          ]
        }))
      }
    }
  }, [
    activeForms.length,
    generalInputData.cadastralNumberStatus,
    generalInputData.gasSupply,
    generalInputData.landPlan,
    generalInputData.powerSupply,
    setGeneralValidations
  ])

  //sets new validations for dynamic fields
  useEffect(() => {
    if (generalInputData.usageType === 'OTHER') {
      activeForms.forEach((item) => {
        setMultiLangValidations[item]((prevState) => ({
          ...prevState,
          otherUsageType: [requiredField, minMaxLength(0, 20)]
        }))
      })
    } else {
      activeForms.forEach((item) => {
        setMultiLangValidations[item]((prevState) => ({ ...prevState, otherUsageType: [minMaxLength(0, 20)] }))
      })
    }

    if (generalInputData.encumbrance === 'OTHER') {
      activeForms.forEach((item) => {
        setMultiLangValidations[item]((prevState) => ({
          ...prevState,
          otherEncumbrance: [requiredField, minMaxLength(0, 20)]
        }))
      })
    } else {
      activeForms.forEach((item) => {
        setMultiLangValidations[item]((prevState) => ({ ...prevState, otherEncumbrance: [minMaxLength(0, 20)] }))
      })
    }
  }, [activeForms, generalInputData.encumbrance, generalInputData.usageType, setMultiLangValidations])

  useEffect(() => {
    if (activeForms.length) {
      if (generalInputData.waterSupply) {
        activeForms.forEach((item) => {
          setMultiLangValidations[item]((prevState) => ({
            ...prevState,
            waterSupplyType: [requiredField, minMaxLength(0, 20)]
          }))
        })

        setGeneralValidations((prevState) => ({
          ...prevState,
          nearestWaterSupplyKM: []
        }))
      } else {
        activeForms.forEach((item) => {
          setMultiLangValidations[item]((prevState) => ({
            ...prevState,
            waterSupplyType: []
          }))
        })

        setGeneralValidations((prevState) => ({
          ...prevState,
          nearestWaterSupplyKM: [
            requiredField,
            validRegExp({
              regexp: decimalPattern(0, 5),
              errorMsg: i18next.t('investObjects.errors.digitFormat', { max: 5 })
            })
          ]
        }))
      }

      if (generalInputData.drainage) {
        activeForms.forEach((item) => {
          setMultiLangValidations[item]((prevState) => ({
            ...prevState,
            drainageType: [requiredField, minMaxLength(0, 20)]
          }))
        })

        setGeneralValidations((prevState) => ({
          ...prevState,
          nearestDrainageKM: []
        }))
      } else {
        activeForms.forEach((item) => {
          setMultiLangValidations[item]((prevState) => ({
            ...prevState,
            drainageType: []
          }))
        })

        setGeneralValidations((prevState) => ({
          ...prevState,
          nearestDrainageKM: [
            requiredField,
            validRegExp({
              regexp: decimalPattern(0, 5),
              errorMsg: i18next.t('investObjects.errors.digitFormat', { max: 5 })
            })
          ]
        }))
      }
    }
  }, [
    activeForms,
    generalInputData.drainage,
    generalInputData.waterSupply,
    setGeneralValidations,
    setMultiLangValidations
  ])
}
