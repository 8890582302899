import { Box, Typography } from '@mui/material'
import { baseContainerSX } from '@/containers/constants'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppButtonGoTo from '@/components/app-button-go-to'
import { useNavigate } from 'react-router-dom'
import useBreakpoints from '@/hooks/use-breakpoints'
import { tags } from '@/containers/business-services-banner/constants'

const BusinessServicesBanner = () => {
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useBreakpoints()
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(null)
  return (
    <Box
      sx={{
        backgroundColor: ({ palette }) => palette.background.light,
        pt: { md: '110px', sm: '40px', xs: '40px' },
        pb: { md: '142px', sm: '30px', xs: '40px' },
        whiteSpace: 'pre-line'
      }}
    >
      <Box
        sx={{
          ...baseContainerSX,
          alignItems: isMobile ? 'start' : 'center',
          flexDirection: isMobile ? 'column' : 'row',
          px: { md: '40px', sm: '23px', xs: '16px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            flex={1}
            variant='h1'
            sx={{ mb: '30px' }}
          >
            {t('helpBusiness.businessServicesTitle')}
          </Typography>
          {!isMobile && (
            <>
              <ul style={{ paddingInlineStart: '20px', marginRight: '30px', flexGrow: 1 }}>
                {Array(4)
                  .fill('')
                  .map((item, index) => (
                    <li key={index}>
                      <Typography
                        sx={{ mb: '12px' }}
                        variant={'body1'}
                      >
                        {t(`helpBusiness.description.${index}`)}
                      </Typography>
                    </li>
                  ))}
              </ul>
              <AppButtonGoTo
                sx={{ mt: '30px', alignSelf: 'start' }}
                onClick={() => navigate('/business-offers')}
                title={t('helpBusiness.goTo')}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { md: '1fr 1fr', sm: '1fr', xs: '1fr' },
            gap: { md: '30px', sm: '20px', xs: '20px' },
            mx: 'auto'
          }}
        >
          {Array(4)
            .fill('')
            .map((item, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: isHovered === index ? ({ palette }) => palette.primary.light : '#FFFFFF',
                  height: { md: '150px', sm: '90px', xs: '90px' },
                  minWidth: { md: '370px', sm: '280px', xs: 'unset' },
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'all .3s',
                  cursor: 'pointer'
                }}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)}
                onClick={() => navigate(`/business-offers?tag=${tags[index]}`)}
              >
                <Typography
                  sx={{ px: '30px', transition: 'all .3s' }}
                  variant={isDesktop ? 'h4' : 'body2'}
                  color={isHovered === index && '#FFFFFF'}
                >
                  {t(`helpBusiness.boxes.${index}`)}
                </Typography>
              </Box>
            ))}
        </Box>
        {isMobile && (
          <AppButtonGoTo
            sx={{ mt: '30px', alignSelf: 'start' }}
            onClick={() => navigate('/business-offers')}
            title={t('helpBusiness.goTo')}
          />
        )}
      </Box>
    </Box>
  )
}

export default BusinessServicesBanner
