import PropTypes from 'prop-types'

import { Checkbox, FormControlLabel } from '@mui/material'
import Box from '@mui/material/Box'

const AppCheckboxProps = {
  input: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

const AppCheckbox = ({ input, checked, handleChange, className, disabled, sx={} }) => {
  return (
    <Box
      sx={{ flexShrink: 1, ...sx }}
      className={className}
    >
      <FormControlLabel
        label={input.options.label}
        disabled={disabled || input.options.disabled}
        className={input.options.className}
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            {...input.options}
          />
        }
      />
    </Box>
  )
}

AppCheckbox.propTypes = AppCheckboxProps

export default AppCheckbox
