import FileUploader from '@/components/file-uploader'
import { Button, FormHelperText, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useRef, useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import PropTypes from 'prop-types'
import { FileService } from '@/services/file-service'
import { commonImageFileType } from '@/constants/default'

const AppImageUploaderProps = {
  value: PropTypes.shape({
    id: PropTypes.number,
    path: PropTypes.string
  }),
  labels: PropTypes.shape({
    changeLabel: PropTypes.string,
    addLabel: PropTypes.string,
    helpLabel: PropTypes.string
  }),
  icon: PropTypes.elementType,
  onImageChange: PropTypes.func,
  maxSize: PropTypes.string,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  previewSize: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string
  }),
  validationError: PropTypes.string,
  sx: PropTypes.object,
  dataTestId: PropTypes.string,
  error: PropTypes.string
}

const AppImageUploader = ({
  value = null,
  labels,
  icon,
  onImageChange,
  maxSize,
  minHeight,
  minWidth,
  previewSize = { width: '100%', height: '100px' },
  validationError,
  sx,
  dataTestId,
  error: initialError = ''
}) => {
  if (!value) {
    value = { id: null, path: null }
  }
  const inputRef = useRef(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (initialError) {
      setError(initialError)
    }
  }, [initialError])

  const handleImageUpload = (image) => {
    onImageChange(image[0])
  }

  const handleRemoveImage = async (event) => {
    event.stopPropagation()
    onImageChange(null)
    try {
      await FileService.delete(value.id)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setError(validationError)
  }, [validationError])

  return (
    <Box
      sx={sx}
      data-testid={dataTestId}
    >
      <FileUploader
        accept={commonImageFileType}
        onChangeFile={handleImageUpload}
        maxSize={maxSize}
        minHeight={minHeight}
        minWidth={minWidth}
        handleError={setError}
        setLoading={setLoading}
        ref={inputRef}
        returnFullFile
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          {loading ? (
            <Box>Loading...</Box>
          ) : (
            <>
              {value.path ? (
                <Box sx={{ position: 'relative' }}>
                  <HighlightOffIcon
                    sx={{
                      position: 'absolute',
                      top: '-20px',
                      right: '-20px',
                      color: 'primary.main',
                      cursor: 'pointer',
                      zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    onClick={handleRemoveImage}
                  />
                  <img
                    src={value.path}
                    alt={'Logo'}
                    style={{ ...previewSize }}
                  />
                </Box>
              ) : null}
              <Button
                variant='text'
                color={error ? 'error' : 'primary'}
                sx={{ display: 'flex', mb: '8px' }}
                onClick={() => inputRef.current.handleClick()}
              >
                {icon ? icon : <AddPhotoAlternateIcon />}
                <Typography sx={{ ml: '4px' }}>
                  {error ? error : value.path ? labels.changeLabel : labels.addLabel}
                </Typography>
              </Button>
            </>
          )}
        </Box>
      </FileUploader>
      <FormHelperText sx={{ mt: '4px', textAlign: 'center' }}>{labels.helpLabel}</FormHelperText>
    </Box>
  )
}

AppImageUploader.propTypes = AppImageUploaderProps

export default AppImageUploader
