import PropTypes from 'prop-types'

import AppCardItem from '@/components/app-card-item'

import mockImage from '@/assets/images/news-mock-img.png'
import NewsItemBody from '@/containers/news-short-list/news-item-body'

const componentProps = {
  item: PropTypes.object,
  vertical: PropTypes.bool,
  loading: PropTypes.bool,
  url: PropTypes.string
}

const NewsItem = ({ vertical, item = {}, url, loading, dark = false }) => {
  return (
    <AppCardItem
      linkUrl={url}
      image={{ src: item.featureImage ? item.featureImage : mockImage, alt: item.title }}
      title={item.title}
      body={item.text}
      vertical={vertical}
      loading={loading}
    >
      <NewsItemBody
        dark={dark}
        item={item}
        loading={loading}
      />
    </AppCardItem>
  )
}

NewsItem.propTypes = componentProps

export default NewsItem
