import { Box, Button } from '@mui/material'
import i18next from 'i18next'
import PropTypes from 'prop-types'
import { Delete, Save } from '@mui/icons-material'
import { useConfirm } from '@/hooks/use-confirm'
import useAxios from '@/hooks/use-axios'

const AppEditorSaveProps = {
  isDirty: PropTypes.bool,
  isSaving: PropTypes.bool,
  afterDelete: PropTypes.func,
  id: PropTypes.string,
  deleteService: PropTypes.func
}

const AppEditorSubmit = ({ isSaving, isDirty, id, afterDelete, deleteService }) => {
  const { confirm } = useConfirm()
  const { loading: isDeleting, fetchData: deleteRequest } = useAxios({ service: deleteService })
  const onDelete = async () => {
    const confirmed = await confirm(i18next.t('dialog.delete_message'))
    if (!confirmed) {
      return
    }
    await deleteRequest({ ids: [Number(id)] }).catch()
    afterDelete()
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px', mt: '12px' }}>
      {id ? (
        <Button
          variant='outlined'
          disabled={isDeleting}
          startIcon={<Delete />}
          onClick={onDelete}
        >
          {i18next.t('common.labels.delete')}
        </Button>
      ) : null}
      <Button
        variant='contained'
        endIcon={<Save />}
        disabled={isSaving || !isDirty}
        type='submit'
      >
        {i18next.t('common.labels.save')}
      </Button>
    </Box>
  )
}

AppEditorSubmit.propTypes = AppEditorSaveProps

export default AppEditorSubmit
