export const containerPaddingSX = {
  display: 'block',
  backgroundColor: 'white',
  width: '400px',
  margin: '0 auto',
  marginTop: '7%',
  borderRadius: '8px',
  px: '16px',
  paddingTop: '20px',
  paddingBottom: '16px',
  position: 'relative',
  minHeight: '400px',
  maxWidth: '98%',
}

export const styles = {
  icon: {
    fill: '#009639',
    cursor: 'pointer',
    position: 'absolute',
    right: '21px'
  },
  title: {
    fontWeight: '600',
    fontSize: '1rem',
    lineHeight: '20px'
  }
}
