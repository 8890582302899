import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from '@/constants/tranlations'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage'],
    },
    fallbackLng: 'uk',
    // have a common namespace used around the full app
    ns: ['translations'],
  })

i18n.languages = ['en', 'uk']

export default i18n
