import React from 'react'
import commentIcon from '@/assets/icons/comment.svg'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
const TriggerButton = ({ isMobile, handleOpen }) => {
  const { t } = useTranslation()
  return (
    <Button
      variant='contained'
      color='primary'
      sx={{ maxWidth: '100%', width: isMobile ? '100%': '310px' }}
      onClick={handleOpen}
      startIcon={
        <img
          src={commentIcon}
          height={18}
          width={18}
          alt={'c'}
        />
      }
    >
      {t('investObjectsDetails.leftComment')}
    </Button>
  )
}
export default TriggerButton
