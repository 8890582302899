import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import AppContainerHeader from '@/components/app-container-header'

import useBreakpoints from '@/hooks/use-breakpoints'
import AppButtonGoTo from '@/components/app-button-go-to'
import { useNavigate } from 'react-router-dom'
import { baseContainerSX } from '@/containers/constants'
import SupportProgramItem from '@/containers/support-program-banner/support-program-item'
import { responsiveGap, style } from '@/pages/home-page/HomePage.styles'
import { generateSkeletonItems } from '@/utils/skeleton'

const componentProps = {
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
}

const SupportProgramBanner = ({ items, loading }) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const { isDesktop, isTablet, isMobile } = useBreakpoints()

  const onHeaderButtonClick = () => {
    navigate('/support-programs')
  }

  const bannerItems = useMemo(() => {
    const responsiveItems = loading ? generateSkeletonItems(3) : isDesktop ? items : items.slice(0, 2)

    return responsiveItems.map((item) => (
      <SupportProgramItem
        vertical
        url={`/support-programs/${item.id}`}
        key={item.id}
        item={item}
        loading={loading}
      />
    ))
  }, [isDesktop, items, loading])

  const newsContainerSx = { gridTemplateColumns: isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr' : '1fr' }

  const goToSupportProgramsButtonProps = { onClick: onHeaderButtonClick, title: i18n.t('homePage.allSupportPrograms') }

  return (
    <Box
      id='supportProgram'
      sx={{
        ...baseContainerSX,
        ...style.section,
      }}
      component='section'
      data-testid={'supportProgramBanner'}
    >
      <AppContainerHeader
        sx={{ mb: '30px' }}
        title={i18n.t('homePage.supportPrograms')}
        button={goToSupportProgramsButtonProps}
        showButton={!isMobile}
      />
      <Box sx={{ display: 'grid', columnGap: '30px', rowGap: '30px', ...newsContainerSx, mb: responsiveGap }}>{bannerItems}</Box>
      {isMobile && (
        <Box
          sx={{ ...style.appToGo }}
        >
          <AppButtonGoTo {...goToSupportProgramsButtonProps} />
        </Box>
      )}
    </Box>
  )
}

SupportProgramBanner.propTypes = componentProps

export default SupportProgramBanner
