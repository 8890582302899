import { Chip, Skeleton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { truncateTextByLines } from '@/utils/common'
import { SkeletonTags } from '@/containers/shared/skeleton/skeleton-tags/SkeletonTags'

const SupportProgramItemBody = ({ item, isHovered, loading, textColor }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        data-testid={'title'}
        sx={{
          mb: '16px',
          ...truncateTextByLines(3)
        }}
        variant={'h4'}
        color={isHovered && 'primary'}
      >
        {loading ? <Skeleton /> : item.title}
      </Typography>
      <Typography
        data-testid={'text'}
        component='div'
        sx={{
          mb: '20px',
          ...truncateTextByLines(6)
        }}
        color={textColor ? textColor : 'textSecondary'}
        variant={'body1'}
      >
        {loading ? <Skeleton /> : item.text}
      </Typography>
      <Box>
        {(() => {
          if (loading) {
            return <SkeletonTags />
          }

          const tags = item.tags.slice(0, 2)

          return tags.map((tag) => (
            <Chip
              variant={'outlined'}
              data-testid={'chip'}
              key={tag.title}
              sx={{ mr: '8px', mb: '8px', height: '34px', px: '16px', color: textColor ? textColor : null }}
              label={tag.title}
            />
          ))
        })()}
      </Box>
    </Box>
  )
}

export default SupportProgramItemBody
