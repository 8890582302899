import i18next from 'i18next'
import AppFormPopOver from '@/components/app-form-popover'

export const adminCommunicationTableColumns = [
  {
    headerName: i18next.t('adminCommunicationForms.table.name'),
    field: 'name'
  },
  {
    headerName: i18next.t('adminCommunicationForms.table.essenceAppeal'),
    field: 'description',
    renderCell: ({ value }) => (
      <>
        {value?.trim().length > 18 ? <AppFormPopOver val={value} /> : value}
      </>
    )
  },
  { headerName: i18next.t('adminCommunicationForms.table.email'), field: 'email' },
  { headerName: i18next.t('adminCommunicationForms.table.phone'), field: 'phone' },
  { headerName: i18next.t('adminCommunicationForms.table.webPage'), field: 'pageUrl' }
]
export const adminCommunicationTableSettings = { checkboxSelection: true }
