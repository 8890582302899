import React, { forwardRef } from 'react'
import { Slide } from '@mui/material'

export const Transition = forwardRef((props, ref) => {
  return (
    <Slide
      direction='down'
      ref={ref}
      {...props}
    />
  )
})

Transition.displayName = 'AppInfiniteScroll'
