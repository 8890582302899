import PropTypes from 'prop-types'
import { FormGroup } from '@mui/material'
import AppInputs from '@/components/app-form/app-inputs'
import {
  styles
} from '@/containers/admin-support-programs/admin-support-program-form/admin-support-program-form-styles'
import AdminAttachedTags from '@/containers/admin-tags/admin-attached-tags/AdminAttachedTags'

const AdminSupportProgramFormProps = {
  multiInputs: PropTypes.array,
  formPropsMulti: PropTypes.object,
  generalInputs: PropTypes.array,
  formPropsGeneral: PropTypes.object,
}
const AdminSupportProgramForm = ({
                                   multiInputs,
                                   formPropsMulti,
                                   generalInputs,
                                   formPropsGeneral,
                                   tags,
                                   onTagDelete,
                                   lang
                                 }) => {
  return (
    <>
      <AdminAttachedTags
        tags={tags}
        onTagDelete={onTagDelete}
        lang={lang}
      />
      <FormGroup sx={{ flexGrow: 1, ...styles.form }}>
        <AppInputs
          inputs={multiInputs}
          {...formPropsMulti}
        />
        <AppInputs
          inputs={generalInputs}
          {...formPropsGeneral}
        />
      </FormGroup>
    </>
  )
}

AdminSupportProgramForm.propTypes = AdminSupportProgramFormProps

export default AdminSupportProgramForm
