import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@mui/material'

import { bytesToText, getImageSizes, textToBytes } from '@/utils/file-helper'
import { errorTypes } from '@/components/file-uploader/constants'
import { commonImageFileType, fileSizeRegExp } from '@/constants/default'
import { FileService } from '@/services/file-service'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useTranslation } from 'react-i18next'
import { cutString } from '@/utils/common'

const FileUploaderProps = {
  accept: PropTypes.array.isRequired,
  value: PropTypes.array,
  multiple: PropTypes.bool,
  maxFilesCount: PropTypes.number,
  maxSize: PropTypes.string,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  validations: PropTypes.arrayOf(PropTypes.func),
  disable: PropTypes.bool,
  onChangeFile: PropTypes.func.isRequired,
  handleError: PropTypes.func,
  btnIcon: PropTypes.element,
  btnLabels: PropTypes.shape({
    addLabel: PropTypes.string,
    editLabel: PropTypes.string
  }),
  showPreview: PropTypes.bool,
  returnFullFile: PropTypes.bool,
  sx: PropTypes.object,
  setLoading: PropTypes.func
}

const FileUploader = forwardRef(
  (
    {
      children,
      accept,
      value,
      multiple,
      maxFilesCount,
      maxSize = '5MB',
      minWidth = 0,
      minHeight = 0,
      validations = [],
      disable,
      onChangeFile,
      handleError = () => null,
      btnIcon,
      btnLabels = {},
      showPreview,
      sx,
      returnFullFile = false,
      setLoading = () => {}
    },
    ref
  ) => {
    const { t } = useTranslation()
    const inputRef = useRef(null)
    const [filesInfoState, setFilesInfoState] = useState([])
    const [fileUploading, setFileUploading] = useState(false)
    const [error, setError] = useState('')

    const calculateMaxFileSize = () => {
      if (!maxSize) {
        return 0
      }
      const [, size, measure] = maxSize.toUpperCase().match(fileSizeRegExp)
      return textToBytes(size, measure)
    }

    const handleFileChange = async (event) => {
      if (!event.target.files.length) {
        return
      }
      //clear errors before uploading new file
      setError('')
      handleError('')
      const files = Array.from(event.target.files)
      const filesInfo = files.map((file) => {
        const nameAndExt = file.name.match(/(.+)(\.[0-9a-z]+$)/i)
        const [, name, extension] = nameAndExt ? nameAndExt : [file.name, file.name, '']
        const size = bytesToText(file.size)
        return { name, extension, size, rawFile: file, type: file.type }
      })
      let currentFilesCount = filesInfoState.length
      const validatedFiles = []
      for (const [index, file] of files.entries()) {
        // check if current file is image
        const isImage = commonImageFileType.some((type) => accept.includes(type))
        // validate file
        if (
          accept.length > 0 &&
          (!filesInfo[index].extension ||
            (!accept.includes(file.type) &&
              !accept.map((x) => x.toLowerCase()).includes(filesInfo[index].extension.toLowerCase())))
        ) {
          console.error('format')
          setError(
            t(`common.errorMessages.${isImage ? 'image' : 'file'}.${errorTypes.format}`, { formats: accept.join(', ') })
          )
          handleError(
            t(`common.errorMessages.${isImage ? 'image' : 'file'}.${errorTypes.format}`, { formats: accept.join(', ') })
          )
          continue
        } else if (validations.some((func) => !func(file))) {
          // run user validation
          console.error('validation')
          setError(t(`common.errorMessages.${isImage ? 'image' : 'file'}.${errorTypes.validation}`))
          handleError(t(`common.errorMessages.${isImage ? 'image' : 'file'}.${errorTypes.validation}`))
          continue
        } else if (maxSize && file.size > calculateMaxFileSize()) {
          console.error('size')
          setError(t(`common.errorMessages.${isImage ? 'image' : 'file'}.${errorTypes.size}`, { maxSize }))
          handleError(t(`common.errorMessages.${isImage ? 'image' : 'file'}.${errorTypes.size}`, { maxSize }))
          continue
        } else if (maxFilesCount && currentFilesCount >= maxFilesCount) {
          console.error('max files count')
          setError(t(`common.errorMessages.${isImage ? 'image' : 'file'}.${errorTypes.count}`, { maxFilesCount }))
          handleError(t(`common.errorMessages.${isImage ? 'image' : 'file'}.${errorTypes.count}`, { maxFilesCount }))
          continue
        } else {
          validatedFiles.push(file)
          currentFilesCount += 1
        }

        // resize an image if needed
        if (minWidth || minHeight) {
          const { width, height } = await getImageSizes(file)
          if (width < minWidth || height < minHeight) {
            console.error('imageSize')
            setError(t(`common.errorMessages.image.${errorTypes.smallImage}`))
            handleError(t(`common.errorMessages.image.${errorTypes.smallImage}`))
          }
        }
      }
      const uploadedFiles = []
      if (validatedFiles.length) {
        setFileUploading(true)
        setLoading(true)

        for (const file of validatedFiles) {
          try {
            const requestData = new FormData()
            requestData.append('file', file)
            const { data } = await FileService.upload(requestData)
            uploadedFiles.push(data)
          } catch (e) {
            console.error(e)
          }
        }
        setFileUploading(false)
        setLoading(false)
      }
      if (uploadedFiles.length) {
        multiple ? setFilesInfoState((prevState) => [...prevState, ...uploadedFiles]) : setFilesInfoState(uploadedFiles)
        onChangeFile(
          uploadedFiles.map((file) => {
            return returnFullFile ? file : file.id
          })
        )
      }
      inputRef.current.value = null
    }

    const handleClick = () => inputRef.current.click()

    useImperativeHandle(ref, () => ({
      handleFileChange,
      handleClick
    }))

    const handleRemoveFile = async (file) => {
      setFilesInfoState((prevState) => prevState.filter(({ id }) => id !== file.id))
      onChangeFile(value.filter(({ id }) => id !== file.id))
      try {
        await FileService.delete(file.id)
      } catch (e) {
        console.error(e)
      }
    }

    useEffect(() => {
      const errorTimeout = setTimeout(() => {
        if (error) {
          setError('')
          handleError('')
        }
      }, 10000)
      return () => clearTimeout(errorTimeout)
    }, [error, handleError])

    useEffect(() => {
      if (value) {
        setFilesInfoState(value)
      } else {
        setFilesInfoState([])
      }
    }, [value])

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 0,
          ...sx
        }}
      >
        {children ? (
          children
        ) : (
          <React.Fragment>
            <Button
              onClick={() => inputRef.current.click()}
              disabled={disable || fileUploading}
              color={error ? 'error' : 'primary'}
              sx={{ display: 'flex', mb: '8px' }}
            >
              {btnIcon}
              <Typography>
                {error ? error : filesInfoState?.length > 0 ? btnLabels.editLabel : btnLabels.addLabel}
              </Typography>
            </Button>
            {showPreview &&
              filesInfoState.map((file) => {
                return (
                  <Box
                    key={file.id}
                    sx={{ position: 'relative' }}
                  >
                    <HighlightOffIcon
                      sx={{
                        position: 'absolute',
                        top: '-20px',
                        right: '-5px',
                        color: 'primary.main',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleRemoveFile(file)}
                    />
                    <Typography
                      sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100px' }}
                    >
                      {cutString(file.name, 65)}
                    </Typography>
                  </Box>
                )
              })}
          </React.Fragment>
        )}
        <input
          data-testid='file-uploader'
          type={'file'}
          onChange={handleFileChange}
          accept={accept.join(',')}
          ref={inputRef}
          multiple={multiple}
          hidden
        />
      </Box>
    )
  }
)

FileUploader.displayName = 'FileUploader'
FileUploader.propTypes = FileUploaderProps

export default FileUploader
