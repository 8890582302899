export const pageWrapperSX = {
  mx: '32px',
  mb: '32px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}

export const userStatus = {
  Active: 'Active',
  Inactive: 'Inactive'
}
