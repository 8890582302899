import { useState } from 'react'
import { useReCaptcha } from '@/hooks/use-captcha'
import { Box, Button, FormGroup, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { useSnackbar } from 'notistack'

import useBreakpoints from '@/hooks/use-breakpoints'
import useForm from '@/hooks/use-form'
import useAxios from '@/hooks/use-axios'
import { baseContainerSX } from '@/containers/constants'
import {
  contactFormInputs,
  contactFormValidations,
  contactFormInitialValues,
  getFormStyles
} from '@/containers/contact-form/constants'
import { ContactService } from '@/services/contact-service'

import AppForm from '@/components/app-form/AppForm'
import AppInputs from '@/components/app-form/app-inputs'

import CompleteIconSVG from '@/assets/icons/complete-icon.svg'

const PHONE_NUMBER = '+38 (068) 855-50-64'
const EMAIL = 'info@investoffice.lviv.ua'

const ContactForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { handleReCaptchaVerify } = useReCaptcha()

  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { isMobile, isTablet } = useBreakpoints()
  const { t } = useTranslation()

  const { data, formProps, handleValidate } = useForm({
    inputs: contactFormInputs(),
    initialValues: contactFormInitialValues,
    validations: contactFormValidations
  })
  const { fetchData: contactRequest, loading } = useAxios({ service: ContactService.contact })

  const contactWrapper = {
    paddingRight: { lg: '145px', md: '80px', sm: '20px', xs: '15px' },
    paddingLeft:  { md: theme.spacing(5), sm: '20px', xs: '15px' },
    paddingY: { md: theme.spacing(10), xs: '40px' }
  }

  const onSubmit = async () => {
    if (handleValidate()) {
      try {
        const { email, ...rest } = data

        const newData = {
          ...(email !== '' && { email }),
          ...rest,
          pageUrl: window.location.href
        }
        const token = await handleReCaptchaVerify()
        token && (await contactRequest(newData, token))
        enqueueSnackbar(t('contactForm.successMessage'), { variant: 'success' })
        setIsSubmitted(true)
      } catch (error) {
        enqueueSnackbar(t(`error.${error?.response?.status}`), { variant: 'error' })
        setIsSubmitted(false)
      }
    }
  }

  const AbsoluteColoredBox = ({ color, sx }) => {
    return (
      <Box
        sx={{
          backgroundColor: color,
          position: 'absolute',
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          zIndex: -1,
          ...sx
        }}
      ></Box>
    )
  }

  return (
    <Box
      sx={{
        minWidth: '100%',
        position: 'relative',
        whiteSpace: 'pre-line'
      }}
    >
      <Box sx={{ ...baseContainerSX, width: '100%', flexDirection: isMobile || isTablet ? 'column' : 'row' }}>
        {isSubmitted ? (
          <Box
            sx={{
              backgroundColor: theme.palette.background.light,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              ...contactWrapper
            }}
            width={isMobile || isTablet ? '100%' : '60%'}
          >
            <img
              alt='Complete Icon'
              src={CompleteIconSVG}
              height={72}
            />
            <Typography
              variant='h2'
              mt='30px'
              mb='16px'
              textAlign='center'
            >
              {t('contactForm.thankYou')}
            </Typography>
            <Typography
              variant='h6'
              textAlign='center'
            >
              {t('contactForm.willContactYou')}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{ backgroundColor: theme.palette.background.light, ...contactWrapper }}
            width={isMobile || isTablet ? '100%' : '60%'}
          >
            <Typography variant='h1' sx={{ fontSize: { lg: '2.7849rem', xs: '2rem' } }}>{t('contactForm.contactUs')}</Typography>
            <AppForm onFormSubmit={onSubmit}>
              <FormGroup sx={{ ...getFormStyles.form, marginTop: theme.spacing(4) }}>
                <AppInputs
                  inputs={contactFormInputs()}
                  {...formProps}
                ></AppInputs>
                <Button
                  variant={'contained'}
                  type='submit'
                  disabled={loading}
                  sx={{ ...getFormStyles.send }}
                >
                  {t('forms.send')}
                </Button>
              </FormGroup>
            </AppForm>
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            ...contactWrapper,
            color: theme.palette.background.paper
          }}
          width={isMobile || isTablet ? '100%' : '40%'}
        >
          <Typography
            variant='h1'
            mb='24px'
            sx={{ fontSize: { lg: '2.7849rem', xs: '2rem' } }}
          >
            {t('contactForm.ourContacts')}
          </Typography>
          <Link
            underline='none'
            sx={{ '&:hover': { color: theme.palette.grey.lighter } }}
            href={`tel:${PHONE_NUMBER}`}
            color={'common.white'}
          >
            <Typography
              variant='h6'
              mt={theme.spacing(3)}
              sx={{ fontSize: '1.063rem' }}
            >
              {t('contactForm.phone')}
            </Typography>
            <Typography
              variant='h5'
              sx={{ marginTop: theme.spacing(1), fontSize: { sm: '1.5rem', xs: '1.375rem' } }}
            >
              {PHONE_NUMBER}
            </Typography>
          </Link>
          <Link
            underline='none'
            sx={{ '&:hover': { color: theme.palette.grey.lighter } }}
            href={`mailto:${EMAIL}`}
            color={'common.white'}
          >
            <Typography
              variant='h6'
              mt={theme.spacing(3)}
              sx={{ fontSize: '1.063rem' }}
            >
              Email
            </Typography>
            <Typography
              variant='h5'
              sx={{ marginTop: theme.spacing(1), fontSize: { sm: '1.5rem', xs: '1.375rem' } }}
            >
              {EMAIL}
            </Typography>
          </Link>
          <Link
            underline='none'
            target='_blank'
            rel='noopener noreferrer'
            sx={{ '&:hover': { color: theme.palette.grey.lighter } }}
            href={`https://www.google.com/maps/search/?api=1&query=${t('contactForm.address')}`}
            color={'common.white'}
          >
            <Typography
              variant='h6'
              mt={theme.spacing(5)}
              sx={{ fontSize: '1.063rem' }}
            >
              {t('contactForm.addressLabel')}
            </Typography>
            <Typography
              variant='h5'
              sx={{ marginTop: theme.spacing(1), fontSize: { sm: '1.5rem', xs: '1.375rem' } }}
            >
              {t('contactForm.address')}
            </Typography>
          </Link>
          <Box sx={{ marginTop: theme.spacing(5) }}>
            <Typography variant='h6' sx={{ fontSize: '1.063rem' }}>{t('contactForm.workScheduleLabel')}</Typography>
            <Typography
              variant='h5'
              sx={{ marginTop: theme.spacing(1), fontSize: { sm: '1.5rem', xs: '1.375rem' } }}
            >
              {t('contactForm.workingHours')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <AbsoluteColoredBox
        color={theme.palette.background.light}
        sx={{ right: '40%' }}
      />
      <AbsoluteColoredBox
        color={theme.palette.primary.main}
        sx={{ left: '60%' }}
      />
    </Box>
  )
}

export default ContactForm
