// FIXME: Use it for admin invest object create/edit form
import AppRadioGroup from '@/components/app-radio-group'
import { connectedNotConnectedOptions } from './constants'
import { Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SectionBase from '@/containers/invest-object-form/sections/common/SectionBase'

const CommunicationsSection = ({ formPropsGeneral, formPropsMulti, styles }) => {
  const { t } = useTranslation()

  const handleRadioButtonValueChange = (name) => ({ target: { value } }) => {
    formPropsGeneral.handleChange(name)({ target: { value: value === 'true' } })
  }

  const powerTextField = () => {
    if (formPropsGeneral.data.powerSupply === '') {
      return
    }
    const fieldName = formPropsGeneral.data.powerSupply ? 'powerSupplyKW' : 'nearestPowerSupplyKM'
    return (
      <TextField
        value={formPropsGeneral.data[fieldName]}
        error={Boolean(formPropsGeneral.errors[fieldName])}
        helperText={formPropsGeneral.errors[fieldName] || ' '}
        onChange={formPropsGeneral.handleChange(fieldName)}
        onBlur={formPropsGeneral.handleBlur(fieldName)}
        sx={styles.inputs[fieldName]}
        label={t(`investObjects.labels.${fieldName}`)}
      />
    )
  }

  const waterTextField = () => {
    if (formPropsGeneral.data.waterSupply === '') {
      return
    }
    const fieldName = formPropsGeneral.data.waterSupply ? 'waterSupplyType' : 'nearestWaterSupplyKM'
    const formProps = fieldName === 'waterSupplyType' ? formPropsMulti : formPropsGeneral
    return (
      <TextField
        value={formProps.data[fieldName]}
        error={Boolean(formProps.errors[fieldName])}
        helperText={formProps.errors[fieldName] || ' '}
        onChange={formProps.handleChange(fieldName)}
        onBlur={formProps.handleBlur(fieldName)}
        sx={styles.inputs[fieldName]}
        label={t(`investObjects.labels.${fieldName}`)}
      />
    )
  }

  const drainageTextField = () => {
    if (formPropsGeneral.data.drainage === '') {
      return
    }
    const fieldName = formPropsGeneral.data.drainage ? 'drainageType' : 'nearestDrainageKM'
    const formProps = fieldName === 'drainageType' ? formPropsMulti : formPropsGeneral
    return (
      <TextField
        value={formProps.data[fieldName]}
        error={Boolean(formProps.errors[fieldName])}
        helperText={formProps.errors[fieldName] || ' '}
        onChange={formProps.handleChange(fieldName)}
        onBlur={formProps.handleBlur(fieldName)}
        sx={styles.inputs[fieldName]}
        label={t(`investObjects.labels.${fieldName}`)}
      />
    )
  }

  return (
    <>
      <Grid sx={styles.inputs.powerSupply}>
        <Typography variant="h4" sx={{ marginBottom: '8px', fontWeight: { sm: 600, xs: 400 } }}>
          { t('investObjects.labels.powerSupply') }
        </Typography>
        <AppRadioGroup
          value={formPropsGeneral.data.powerSupply}
          onChange={handleRadioButtonValueChange('powerSupply')}
          onBlur={formPropsGeneral.handleBlur('powerSupply')}
          error={Boolean(formPropsGeneral.errors.powerSupply)}
          helperText={formPropsGeneral.errors.powerSupply || ' '}
          options={connectedNotConnectedOptions}
          label={t('investObjects.labels.isConnectedElectricity')}
          row
        />
      </Grid>
      {powerTextField()}
      <Grid sx={styles.inputs.gasSupply}>
        <Typography variant="h4" sx={{ marginBottom: '8px', fontWeight: { sm: 600, xs: 400 } }}>
          { t('investObjects.labels.gasSupply') }
        </Typography>
        <AppRadioGroup
          value={formPropsGeneral.data.gasSupply}
          onChange={handleRadioButtonValueChange('gasSupply')}
          error={Boolean(formPropsGeneral.errors.gasSupply)}
          helperText={formPropsGeneral.errors.gasSupply || ' '}
          options={connectedNotConnectedOptions}
          label={t('investObjects.labels.isConnectedGas')}
          row
        />
      </Grid>
      {formPropsGeneral.data.gasSupply !== '' && !formPropsGeneral.data.gasSupply && (
        <TextField
          value={formPropsGeneral.data.nearestGasSupplyKM}
          error={Boolean(formPropsGeneral.errors.nearestGasSupplyKM)}
          helperText={formPropsGeneral.errors.nearestGasSupplyKM || ' '}
          onChange={formPropsGeneral.handleChange('nearestGasSupplyKM')}
          onBlur={formPropsGeneral.handleBlur('nearestGasSupplyKM')}
          sx={styles.inputs.nearestGasSupplyKM}
          label={t('investObjects.labels.nearestGasSupplyKM')}
        />
      )}
      <Grid sx={styles.inputs.waterSupply}>
        <Typography variant="h4" sx={{ marginBottom: '8px', fontWeight: { sm: 600, xs: 400 } }}>
          { t('investObjects.labels.waterSupply') }
        </Typography>
        <AppRadioGroup
          value={formPropsGeneral.data.waterSupply}
          onChange={handleRadioButtonValueChange('waterSupply')}
          error={Boolean(formPropsGeneral.errors.waterSupply)}
          helperText={formPropsGeneral.errors.waterSupply || ' '}
          options={connectedNotConnectedOptions}
          label={t('investObjects.labels.isConnectedWaterSupply')}
          row
        />
      </Grid>
      {waterTextField()}
      <Grid sx={styles.inputs.drainage}>
        <Typography variant="h4" sx={{ marginBottom: '8px', fontWeight: { sm: 600, xs: 400 } }}>
          { t('investObjects.labels.drainage') }
        </Typography>
        <AppRadioGroup
          value={formPropsGeneral.data.drainage}
          onChange={handleRadioButtonValueChange('drainage')}
          error={Boolean(formPropsGeneral.errors.drainage)}
          helperText={formPropsGeneral.errors.drainage || ' '}
          options={connectedNotConnectedOptions}
          label={t('investObjects.labels.isConnectedWaterDrainage')}
          row
        />
      </Grid>
      {drainageTextField()}
    </>
  )
}

CommunicationsSection.propTypes = SectionBase.propTypes

export default CommunicationsSection
