import { useAuth } from '@/context/auth-context'
import { Navigate } from 'react-router-dom'
import { adminRoutes } from '@/plugins/router/admin-router/routes'

export const role = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  MARKETING: 'MARKETING',
  ANALYTICS: 'ANALYTICS'
}
const RoleGuard = ({
  roles = [],
  redirect = (
    <Navigate
      to={adminRoutes.notAvailable.path}
      replace
    />
  ),
  children
}) => {
  const { activeUserRole } = useAuth()

  if (activeUserRole === role.SUPER_ADMIN) {
    return children
  }

  if (!roles.includes(activeUserRole)) {
    return redirect
  }

  return children
}

export default RoleGuard
