import i18next from 'i18next'
import { requiredField, email } from '@/utils/validations/common'

export const loginFormInitialValues = {
  email: '',
  password: ''
}
export const loginInputs = [
  {
    type: 'text',
    options: {
      name: 'email',
      label: i18next.t('common.email')
    }
  },
  {
    type: 'passwordInput',
    options: {
      name: 'password',
      label: i18next.t('common.password')
    }
  }
]

export const loginValidations = {
  email: [requiredField, email]
}
