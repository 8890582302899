import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import AppChipWithTooltip from '@/components/app-chip-with-tooltip'

const AdminAttachedTagsProps = {
  tags: PropTypes.array,
  onTagDelete: PropTypes.func,
  lang: PropTypes.string
}

const AdminAttachedTags = ({ tags, onTagDelete, lang }) => {
  const [tagTooltip, setTagTooltip] = useState('en')

  useEffect(() => {
    if (lang === 'uk') {
      setTagTooltip('en')
    } else {
      setTagTooltip('uk')
    }
  }, [lang])

  return (
    <Box sx={{ mb: '12px' }}>
      {tags.map((item) => (
        <AppChipWithTooltip
          key={item.id}
          chipLabel={item.title[lang]}
          tooltipLabel={item.title[tagTooltip]}
          onIconClick={() => onTagDelete(item.id)}
        />
      ))}
    </Box>
  )
}

AdminAttachedTags.propTypes = AdminAttachedTagsProps

export default AdminAttachedTags
