import { Box, Skeleton } from '@mui/material'
import { generateSkeletonItems } from '@/utils/skeleton'

const SupportProgramsSkeleton = () => {
  return (
    <Box
      key='items'
      sx={{
        display: 'grid',
        gridTemplateColumns: { md: '1fr 1fr 1fr', sm: '1fr 1fr', xs: '1fr' },
        gap: '40px'
      }}
    >
      {generateSkeletonItems(3).map((tag) => (
        <Box
          sx={{ maxWidth: '500px', display: 'flex', flexDirection: 'column', gap: '15px' }}
          key={tag.id}
        >
          <Skeleton
            variant={'rounded'}
            width={'100%'}
            height={160}
          />
          <Skeleton variant={'rounded'} />
          <Skeleton />
        </Box>
      ))}
    </Box>
  )
}

export default SupportProgramsSkeleton
