import AppInfiniteScroll from '@/components/app-infinite-scroll'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import useAxios from '@/hooks/use-axios'
import { GlobalSearchService } from '@/services/global-search-service'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import GlobalSearchItem from '@/containers/global-search/global-search-item'
import { LayoutRefContext } from '@/containers/layouts/client/client-layout/ClientLayout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { baseContainerSX, hideScroll } from '@/containers/constants'
import { responsiveGapV2 } from '@/pages/home-page/HomePage.styles'
import Searchbar from '@/containers/layouts/client/client-header/Searchbar'
import { useTagsFilter } from '@/hooks/use-tags-filter'
import { filterCategories, linkMap } from '@/pages/search-results-page/constant'
import { useClientLayoutRenderContext } from '@/context/client-layout-context'

const searchGridSx = {
  display: 'grid',
  gridTemplateColumns: { md: 'repeat(3, 1fr)', sm: 'repeat(2, 1fr)' },
  gap: '30px'
}
const SearchResultsPage = () => {
  const layoutRef = useContext(LayoutRefContext)
  const { setIsContactFormRender, resetDefaultLayout } = useClientLayoutRenderContext()
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const [searchItems, setSearchItems] = useState([])
  const [totalSearchItems, setTotalSearchItems] = useState(0)

  let [searchParam] = useSearchParams()
  const searchTerm = searchParam.get('q')

  useEffect(() => {
    setIsContactFormRender(false)

    return () => {
      resetDefaultLayout()
    }
  }, [resetDefaultLayout, setIsContactFormRender])

  const { fetchData: fetchSearchResults } = useAxios({
    service: GlobalSearchService.postGlobalSearch,
    handleError: true
  })

  const filterByCallback = useCallback(
    () =>
      Promise.resolve({
        data: filterCategories.map((c) => ({
          id: c,
          label: t(`globalSearchPage.searchCategory.${c}`),
          link: linkMap[c]
        }))
      }),
    [t]
  )

  const { filterBy, tagsItems, handleFilterChange } = useTagsFilter({ service: filterByCallback, toggleMode: true })

  const InfiniteScroll = useRef(null)
  const getSearchItems = useCallback(
    async ({ take, skip }) => {
      if (!searchTerm) {
        return []
      }
      const { items, count } = await fetchSearchResults({
        take,
        skip,
        lang: i18n.language,
        searchTerm,
        filtering: filterBy.map((e) => ({ field: 'category', value: e.id, operation: 'equal' }))
      })

      setSearchItems([...searchItems, ...items])
      setTotalSearchItems(count)
      return items
    },
    [fetchSearchResults, i18n.language, searchTerm, searchItems, filterBy]
  )

  const clearItems = () => {
    setTotalSearchItems(0)
    setSearchItems([])
  }

  useEffect(() => {
    setSearchItems([])
    InfiniteScroll.current.refreshData()
  }, [filterBy])

  useEffect(() => {
    clearItems()
    handleFilterChange(null)
    InfiniteScroll.current.refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, searchTerm])

  const setOpen = useCallback(() => {}, [])
  const getTagSx = (active) =>
    active
      ? {
          cursor: 'pointer',
          textDecoration: 'underline',
          color: 'accent.main',
          whiteSpace: 'nowrap',
          fontWeight: '600'
        }
      : {
          cursor: 'pointer',
          textDecoration: 'none',
          color: 'accent.light',
          whiteSpace: 'nowrap',
          fontWeight: '600',
          ':hover': {
            textDecoration: 'underline'
          }
        }

  return (
    <Box
      sx={{
        ...baseContainerSX,
        my: '60px',
        px: responsiveGapV2,
        width: '100%'
      }}
    >
      <Typography
        variant='h2'
        mb='30px'
      >
        {searchTerm
          ? t('globalSearchPage.results', { searchTerm, interpolation: { escapeValue: false } })
          : t('globalSearchPage.globalSearch')}
      </Typography>
      <Searchbar
        open
        autoFocus
        setOpen={setOpen}
        fullWidth
        bigSize
        searchTerm={searchTerm}
      />
      <Typography
        variant='subtitle1'
        mt='10px'
        mb='40px'
        sx={{ color: 'accent.light' }}
      >
        {t('globalSearchPage.found', { count: totalSearchItems })}
      </Typography>
      <Box sx={{ ...hideScroll, mb: '30px', display: 'flex', flexWrap: 'nowrap', gap: '30px' }}>
        <Typography
          sx={{ color: 'accent.main', ...getTagSx(filterBy.length === 0) }}
          variant='body2'
          onClick={() => handleFilterChange(null)}
        >
          {t('globalSearchPage.searchCategory.all')}
        </Typography>
        {tagsItems.map((item) => (
          <Typography
            key={item.id}
            sx={{ color: 'accent.main', ...getTagSx(filterBy.find((e) => e.id === item.id)) }}
            variant='body2'
            onClick={() => handleFilterChange(item)}
          >
            {item.label}
          </Typography>
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1, mb: responsiveGapV2, flex: 1, width: '100%' }}>
        <Box flexGrow='1'>
          <AppInfiniteScroll
            loadMore
            ref={InfiniteScroll}
            items={searchItems}
            parentRef={layoutRef}
            totalCount={totalSearchItems}
            fetchData={getSearchItems}
            clearItems={clearItems}
            loader={
              <Box sx={searchGridSx}>
                <GlobalSearchItem loading />
                <GlobalSearchItem loading />
                <GlobalSearchItem loading />
              </Box>
            }
            noItems={
              <Typography
                variant='h4'
                key='no-items'
              >
                {t('globalSearchPage.noItems')}
              </Typography>
            }
          >
            <Box sx={searchGridSx}>
              {searchItems.map((item, index) => (
                <GlobalSearchItem
                  key={`${item.category}-${item.parentId}`}
                  item={item}
                  onClick={() => navigate(`/${linkMap[item.category]}/${item.parentId}`)}
                />
              ))}
            </Box>
          </AppInfiniteScroll>
        </Box>
      </Box>
    </Box>
  )
}

export default SearchResultsPage
