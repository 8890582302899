import { Navigate, Route, Routes } from 'react-router-dom'

import AdminLoginPage from '@/pages/admin/admin-login-page'
import TokenGuard from '@/plugins/router/router-guards/TokenGuard'
import AdminUsersPage from '@/pages/admin/admin-users-page'
import AdminUsersEdit from '@/pages/admin/admin-users-edit'
import AdminProfilePage from '@/pages/admin/admin-profile-page'
import AdminChangePasswordPage from '@/pages/admin/admin-change-password-page'
import AdminNewsEdit from '@/pages/admin/admin-news-edit'
import AdminNewsPage from '@/pages/admin/admin-news-page'
import AdminSupportProgramsPage from '@/pages/admin/admin-support-programs-page'
import AdminSupportProgramsEdit from '@/pages/admin/admin-support-programs-edit'
import AdminEventsEdit from '@/pages/admin/admin-events-edit'
import AdminEventsPage from '@/pages/admin/admin-events-page'
import AdminInvestObjectPage from '@/pages/admin/admin-invest-object-page'
import AdminInvestObjectEdit from '@/pages/admin/admin-invest-object-edit'
import AdminTagsPage from '@/pages/admin/admin-tags/admin-tags-page'
import AdminCommunicationForms from '@/pages/admin/admin-communication-page'

import { adminRoutes } from '@/plugins/router/admin-router/routes'
import Box from '@mui/material/Box'
import RoleGuard, { role } from '@/plugins/router/router-guards/RoleGuard'
import AdminChangelogPage from '@/pages/admin/admin-changelog-page/AdminChangelogPage'
import AdminResetPage from '@/pages/admin/admin-reset-page/AdminResetPage'

const AdminPage = () => {
  return (
    <Routes>
      <Route
        path={adminRoutes.login.route}
        element={<AdminLoginPage />}
      />
      <Route
        path={adminRoutes.reset.route}
        element={<AdminResetPage />}
      />
      <Route
        path={''}
        element={<TokenGuard />}
      >
        <Route
          path={''}
          element={<Navigate to={adminRoutes.communicationForms.path} />}
        />
        <Route
          path={adminRoutes.notAvailable.route}
          element={<Box>Forbidden page</Box>}
        />
        <Route
          path={adminRoutes.users.route}
          element={
            <RoleGuard roles={[role.ADMIN]}>
              <AdminUsersPage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.createUser.route}
          element={
            <RoleGuard roles={[role.ADMIN]}>
              <AdminUsersEdit />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.profile.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.ANALYTICS, role.MARKETING]}>
              <AdminProfilePage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.changePassword.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.ANALYTICS, role.MARKETING]}>
              <AdminChangePasswordPage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.news.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminNewsPage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.createNews.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminNewsEdit />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.editNews.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminNewsEdit />
            </RoleGuard>
          }
        />

        <Route
          path={adminRoutes.events.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminEventsPage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.editEvent.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminEventsEdit />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.createEvent.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminEventsEdit />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.createSupportPrograms.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminSupportProgramsEdit />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.editSupportProgram.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminSupportProgramsEdit />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.supportPrograms.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminSupportProgramsPage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.investObjects.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.ANALYTICS]}>
              <AdminInvestObjectPage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.createInvestObject.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.ANALYTICS]}>
              <AdminInvestObjectEdit />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.editInvestObject.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.ANALYTICS]}>
              <AdminInvestObjectEdit />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.tags.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminTagsPage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.tagsEdit.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING]}>
              <AdminTagsPage />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.communicationForms.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING, role.ANALYTICS]}>
              <AdminCommunicationForms />
            </RoleGuard>
          }
        />
        <Route
          path={adminRoutes.changelog.route}
          element={
            <RoleGuard roles={[role.ADMIN, role.MARKETING, role.ANALYTICS]}>
              <AdminChangelogPage />
            </RoleGuard>
          }
        />
      </Route>
    </Routes>
  )
}

export default AdminPage
