import React from 'react'
import { Box, Typography, List, ListItem, IconButton, Divider, Zoom } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useBreakpoints from '@/hooks/use-breakpoints'
import InvestOfficeTeamMember from '@/pages/about-us-page/invest-office-team-member/InvestOfficeTeamMember'
import { teamMembers } from '@/pages/about-us-page/constants'
import { styles } from '@/pages/about-us-page/about-us-page.styles.js'

import aboutUsPageMapImage from '@/assets/images/about-us-page-map.png'
import linkedinSVG from '@/assets/icons/linkedin-blue.svg'
import YaroslavAlyokhinPhoto from '@/assets/images/Yaroslav-Alyokhin.jpg'
import { randomIntFromInterval } from '@/utils/skeleton'

const AboutUsPage = () => {
  const { t } = useTranslation()
  const { isTablet } = useBreakpoints()

  const linkedinYaroslavAlokhin = 'https://www.linkedin.com/in/yaroslav-alokhin-968a61165/'

  const TeamDescription = () => (
    <Box>
      <Typography
        variant='body1'
        sx={{ mb: { md: '30px', xs: '20px' }, fontWeight: 600 }}
      >
        {t('aboutUsPage.howDoWeHelpBusiness')}
      </Typography>
      <List sx={{ ...styles.bulletList }}>
        <ListItem>{t('aboutUsPage.uniquePlatform')}</ListItem>
        <ListItem>{t('aboutUsPage.investmentOpportunities')}</ListItem>
        <ListItem>{t('aboutUsPage.professionalConsultations')}</ListItem>
        <ListItem>{t('aboutUsPage.advocacyOfBusinessInterests')}</ListItem>
      </List>
      <Typography sx={{ mt: { md: '22px', xs: '12px' } }}>{t('aboutUsPage.teamDescription')}</Typography>
    </Box>
  )

  return (
    <>
      <Box sx={{ ...styles.container }}>
        <Zoom in>
          <Box
            sx={{
              maxWidth: { md: '686px', sm: '835px', xs: '500px' }
            }}
          >
            <Typography
              variant='h1'
              sx={{ mb: { md: '30px', xs: '20px' } }}
            >
              {t('aboutUsPage.investOfficeTitle')}
            </Typography>
            <Typography
              variant='body1'
              sx={{ mb: '20px' }}
            >
              {t('aboutUsPage.investOfficeDescription')}
            </Typography>
            <Typography
              variant='body1'
              sx={{ mb: { md: '30px', xs: '20px' } }}
            >
              {t('aboutUsPage.investOfficeGoal')}
            </Typography>
            <TeamDescription />
          </Box>
        </Zoom>
        {!isTablet && (
          <Zoom
            in
            style={{ transitionDelay: `${randomIntFromInterval(100, 200)}ms` }}
          >
            <Box
              component='img'
              src={aboutUsPageMapImage}
              loading='lazy'
              alt={t('aboutUsPage.title')}
              sx={{ ...styles.mapImage }}
            />
          </Zoom>
        )}
      </Box>
      <Box sx={{ ...styles.allTeamMembersContainer }}>
        <Typography
          variant='h1'
          sx={{ mb: { md: '50px', xs: '20px' } }}
        >
          {t('aboutUsPage.investOfficeTeam')}
        </Typography>
        <Box sx={{ ...styles.teamMemberContainer }}>
          <Box sx={{ position: 'relative' }}>
            <Box
              component='img'
              src={YaroslavAlyokhinPhoto}
              loading='lazy'
              alt={t('aboutUsPage.investOfficeTeamMembers.YaroslavAlyokhin')}
              sx={{ ...styles.memberImage }}
            />
            <IconButton
              href={linkedinYaroslavAlokhin}
              target='_blank'
              rel='noopener noreferrer'
              sx={{ ...styles.iconButton }}
            >
              <img
                alt='Taras Yeleyko Linkedin Link'
                src={linkedinSVG}
                height={54}
              />
            </IconButton>
          </Box>
          <Box sx={{ ml: { md: '50px', sm: '30px', xs: '0px' } }}>
            <Typography
              variant='h2'
              sx={{ mb: { md: '10px', xs: '20px' } }}
            >
              {t('aboutUsPage.investOfficeTeamMembers.YaroslavAlyokhin')}
            </Typography>
            <Typography sx={{ mb: { md: '22px', xs: '12px' } }}>
              {t('aboutUsPage.investOfficeTeamMembers.positionYaroslavAlyokhin')}
            </Typography>
            <Typography variant='body1'>{t('aboutUsPage.investOfficeTeamMembers.descriptionYaroslavAlyokhin')}</Typography>
          </Box>
        </Box>
        <Divider sx={{ my: { md: '50px', xs: '30px' } }} />
        <Box>
          <List sx={{ ...styles.memberList }}>
            {teamMembers(t).map((member) => (
              <InvestOfficeTeamMember
                key={member.fullName}
                fullName={member.fullName}
                photo={member.photo}
                position={member.position}
                linkedin={member.linkedinLink}
              />
            ))}
          </List>
        </Box>
      </Box>
    </>
  )
}

export default AboutUsPage
