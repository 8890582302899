import { useAppGridServices } from '@/components/app-grid/hooks/use-app-grid-services'
import { useCallback } from 'react'
import { useAppGrid } from '@/components/app-grid/AppGrid.context'

export const useAppGridActions = () => {
  const { setActionLoading } = useAppGrid()
  const { loadData } = useAppGridServices()

  const onActionClick = useCallback(
    async (action, options) => {
      try {
        setActionLoading(true)
        await action.onClick(options)
        if (!action.preventRefresh) {
          await loadData()
        }
      } finally {
        setActionLoading(false)
      }
    },
    [loadData, setActionLoading]
  )

  return { onActionClick }
}
