import i18next from 'i18next'
import { requiredField, minMaxLength } from '@/utils/validations/common'
import { styles } from '@/pages/admin/admin-profile-page/styles'

export const profileFormInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '**********',
  notification: false,
  role: ''
}

export const profileInputs = [
  {
    type: 'text',
    options: {
      name: 'email',
      label: i18next.t('common.email'),
      disabled: true,
      sx: styles.inputs.email
    }
  },
  {
    type: 'text',
    options: {
      name: 'firstName',
      label: i18next.t('common.name'),
      required: true,
      sx: styles.inputs.firstName
    }
  },
  {
    type: 'text',
    options: {
      name: 'lastName',
      label: i18next.t('common.surname'),
      sx: styles.inputs.lastName
    }
  },
  {
    type: 'text',
    options: {
      type: 'password',
      name: 'password',
      label: i18next.t('common.password'),
      disabled: true,
      sx: styles.inputs.password
    }
  },
  {
    type: 'text',
    options: {
      name: 'role',
      label: i18next.t('common.role'),
      disabled: true,
      sx: styles.inputs.role
    }
  },
  {
    type: 'checkbox',
    options: {
      name: 'notification',
      label: i18next.t('profile.accountNotifications'),
      sx: styles.inputs.notification
    }
  }
]

export const profileValidations = {
  firstName: [requiredField, minMaxLength(2, 50)],
  lastName: [minMaxLength(2, 50)]
}
