import i18next from 'i18next'

export const startDateMin = '2022-01-01T09:25:48.863Z'
export const endDateMax = '2050-11-30T22:00:00.000Z'

export const requiredRange = (value) => {
  if (!value) {
    return undefined
  }

  const err = {}

  if (Object.hasOwn(value, 'startDate')) {
    if (!value.startDate) {
      err.startDate = i18next.t('common.errorMessages.emptyField')
    }
  }

  return Object.values(err).filter((e) => !!e).length > 0 ? err : undefined
}


export const validMinYear = (year, messagePath = 'common.errorMessages.dateRange.pastYearNotAllowed' ) => date => {
  return new Date(date).getFullYear() < year
    ? i18next.t(messagePath, { year })
    : undefined
}

export const validMaxYear = (year, messagePath = 'common.errorMessages.dateRange.futureYearNotAllowed') => date => {
  return new Date(date).getFullYear() > year
    ? i18next.t(messagePath, { year })
    : undefined
}

export const validRange = (value) => {
  if (!value) {
    return undefined
  }

  let err = {}

  if (value.startDate) {
    err.startDate = validMinYear(new Date(startDateMin).getFullYear())(value.startDate)
    err.startDate = validMaxYear(new Date(endDateMax).getFullYear())(value.startDate) ?? err.startDate
  }

  if (value.endDate) {
    err.endDate = validMinYear(new Date(startDateMin).getFullYear())(value.endDate)
    err.endDate = validMaxYear(new Date(endDateMax).getFullYear())(value.endDate) ?? err.endDate
  }

  if (value.startDate && value.endDate) {
    const startDateLessTheEnd = value.startDate > value.endDate
    if (startDateLessTheEnd) {
      err = {
        startDate: i18next.t('common.errorMessages.dateRange.startLessThenEnd'),
        endDate: i18next.t('common.errorMessages.dateRange.startLessThenEnd')
      }
    }
  }

  return Object.values(err).filter((e) => !!e).length > 0 ? err : undefined
}
