import { createContext, useCallback, useContext, useState } from 'react'
import AppPopupDialog from '@/components/app-popup-dialog'

export const ModalContext = createContext({
  openModal: ({ content, props }) => {},
  closeModal: () => {}
})

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [externalProps, setExternalProps] = useState({})

  const openModal = useCallback(({ content, props }) => {
    setExternalProps(props)
    setModalContent(content)
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setExternalProps({})
    setModalContent('')
    setIsModalOpen(false)
  }, [])
  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalContent && (
        <AppPopupDialog
          externalProps={externalProps}
          content={modalContent}
          open={isModalOpen}
        />
      )}
    </ModalContext.Provider>
  )
}

export const useAppPopupDialog = () => {
  return useContext(ModalContext)
}
