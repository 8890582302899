import { footerMenuItems } from '@/containers/layouts/client/client-footer/constants'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import ListItemText from '@mui/material/ListItemText'
import useBreakpoints from '@/hooks/use-breakpoints'
import AppNavLink from '@/components/app-nav-link/AppNavLink'
import { useTranslation } from 'react-i18next'

const FooterMenuItems = () => {
  const { t } = useTranslation()
  const { isTablet } = useBreakpoints()

  const adaptiveStyles = {
    gridRowStart: isTablet && 2,
    gridRowEnd: isTablet && 2
  }

  return footerMenuItems(t).map((menuItem) => (
    <Box
      key={menuItem.label}
      sx={{ pr: '1rem', ...adaptiveStyles }}
    >
      <List
        component='ul'
        subheader={
          <ListSubheader
            component='div'
            disableGutters
            sx={{
              pb: '10px',
              fontSize: '20px',
              color: 'text.primary',
              fontWeight: 600,
              opacity: 0.5,
              lineHeight: '24px',
              position: 'static'
            }}
          >
            {menuItem.label}
          </ListSubheader>
        }
      >
        {menuItem.subItems.map((subItem) => {
          const tag = subItem.tag ? `?tag=${subItem.tag}` : ''
          return (
            <ListItem
              key={subItem.key}
              disablePadding
            >
              <ListItemButton
                to={`${clientRoutes[subItem.key].route}${tag}`}
                disableGutters
                component={AppNavLink}
              >
                <ListItemText
                  primary={subItem.label}
                  sx={{ span: { fontWeight: 600 }, '&:hover': { color: '#00782E' } }}
                />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Box>
  ))
}

export default FooterMenuItems
