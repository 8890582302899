import palette from '@/plugins/material-ui/palette'

const button = {
  styleOverrides: {
    root: {
      textTransform: 'none'
    },
    contained: {
      color: palette.basic.white,
      backgroundColor: palette.primary.main,
      '&:hover': {
        backgroundColor: palette.secondary.dark
      }
    },
    containedSecondary: {
      color: palette.basic.white,
      backgroundColor: palette.secondary.main,
      '&:hover': {
        backgroundColor: palette.secondary.dark
      }
    },
    sizeMedium: {
      padding: '13px 22px',
      borderRadius: '28px'
    },
    sizeLarge: {
      padding: '20px 57px',
      borderRadius: '40px'
    },
    sizeSmall: {
      padding: '8px 16px',
      borderRadius: '28px'
    }
  }
}

export default button
