import AppGrid from '@/components/app-grid'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import {
  adminCommunicationTableColumns,
  adminCommunicationTableSettings,
} from './constants'
import { useConfirm } from '@/hooks/use-confirm'
import i18next from 'i18next'
import { AppealService } from '@/services/communication-service'
import { exportToCsvFile } from '@/utils/csv-export-helper'

const AdminCommunicationTableProps = {
  filterBy: PropTypes.object,
  onTableRefresh: PropTypes.func
}
const AdminFormsTable = ({ filterBy, onTableRefresh }) => {
  const { confirm } = useConfirm()
  const { enqueueSnackbar } = useSnackbar()

  const loadAppeals = async (params) => {
    return await AppealService.grid(params)
  }

  const downloadAppeal = async (selected) => {
    const { data } = await AppealService.bulkExport({ ids: selected })
    const filename = `${i18next.t('adminCommunicationForms.contactFileName')}-${Date.now().toFixed()}`
    exportToCsvFile(data, filename)
    enqueueSnackbar( i18next.t('adminCommunicationForms.downloadSuccessMessage', { count: selected.length }), { variant: 'success' })
  }

  const deleteAppeal = async (selected) => {
    await confirm(i18next.t('dialog.delete_message')) && await AppealService.bulkDeletion({ ids: selected })
  }

  const tableService = { load: loadAppeals }

  const bulkActions = {
    download: {
      icon: <DownloadIcon />,
      label: i18next.t('adminCommunicationForms.download'),
      onClick: downloadAppeal
    },
    deletion: {
      icon: <DeleteIcon />,
      label: i18next.t('adminCommunicationForms.delete'),
      onClick: deleteAppeal
    }
  }

  const rowActions = {
    download: {
      icon: <DownloadIcon />,
      label: i18next.t('adminCommunicationForms.download'),
      onClick: downloadAppeal
    },
    deletion: {
      icon: <DeleteIcon />,
      label: i18next.t('adminCommunicationForms.delete'),
      onClick: deleteAppeal
    }
  }
  return (
    <AppGrid
      columns={adminCommunicationTableColumns}
      service={tableService}
      settings={adminCommunicationTableSettings}
      rowActions={rowActions}
      bulkActions={bulkActions}
      externalFilters={filterBy}
      onTableRefresh={onTableRefresh}
    />
  )
}

AdminFormsTable.propTypes = AdminCommunicationTableProps

export default AdminFormsTable
