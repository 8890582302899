export const URLs = {
  login: '/v1/auth/login',
  refresh: '/v1/auth/refresh',
  contact: '/v1/feedback-form',
  request: '/v1/feedback-form/request',
  news: {
    getNews: '/v1/news/search',
    getNewsTags: '/v1/tags/news',
    getNewsDetailsById: '/v1/news/:id',
    news_subscriptions: '/v1/news-subscriptions/subscribe'
  },
  supportPrograms: {
    getSupportPrograms: '/v1/support-programs/search',
    getSupportProgramTags: '/v1/tags/support-programs',
    getSupportProgramById: '/v1/support-programs/:id'
  },
  events: {
    getEvents: '/v1/events/search',
    getCountByMonths: '/v1/events/count-by-months',
    getEventById: '/v1/events/:id'
  },
  investObjects: {
    getMapList: '/v1/investment-objects/map/search',
    getMap: '/v1/investment-objects/map',
    getMapObj: '/v1/investment-objects/map/:id',
    getDetails: '/v1/investment-objects/:id',
    exportDetails: '/v1/investment-objects/export/:id',
    submit: '/v1/investment-objects'
  },
  admin: {
    users: {
      create: '/v1/users',
      patch: '/v1/users/:id',
      getUsers: '/v1/users/search',
      getUsersCounts: '/v1/users/count-per-role',
      getUserInfo: '/v1/users/me',
      putUserInfo: '/v1/users/me',
      postChangeUserPassword: '/v1/users/change-password',
      resetCode: '/v1/users/reset-password-code',
      resetEmail: '/v1/users/reset-password',
      bulk: {
        activate: '/v1/admin/users/bulk/activate',
        inActivate: '/v1/admin/users/bulk/deactivate'
      }
    },
    news: {
      search: '/v1/admin/news/search',
      bulk: {
        delete: '/v1/admin/news/bulk/delete',
        publish: '/v1/admin/news/bulk/publish'
      },
      countPerStatus: '/v1/admin/news/count-per-status',
      createNews: '/v1/admin/news',
      getNewsById: '/v1/admin/news/:id',
      putNewsById: '/v1/admin/news/:id'
    },
    supportPrograms: {
      search: '/v1/admin/support-programs/search',
      bulk: {
        delete: '/v1/admin/support-programs/bulk/delete',
        publish: '/v1/admin/support-programs/bulk/publish'
      },
      countPerStatus: '/v1/admin/support-programs/count-per-status',
      createProgram: '/v1/admin/support-programs',
      getProgramById: '/v1/admin/support-programs/:id',
      putProgramById: '/v1/admin/support-programs/:id'
    },
    events: {
      create: '/v1/admin/events',
      getById: '/v1/admin/events/:id',
      putById: '/v1/admin/events/:id',
      search: '/v1/admin/events/search',
      countPerStatus: '/v1/admin/events/count-per-status',
      bulk: {
        delete: '/v1/admin/events/bulk/delete',
        publish: '/v1/admin/events/bulk/publish'
      }
    },
    investObjects: {
      create: '/v1/admin/investment-objects',
      countPerStatus: '/v1/admin/investment-objects/count-per-status',
      search: '/v1/admin/investment-objects/search',
      bulk: {
        delete: '/v1/admin/investment-objects/bulk/delete'
      },
      getInvestObjectById: '/v1/admin/investment-objects/:id',
      putInvestObjectById: '/v1/admin/investment-objects/:id',
      sync: '/v1/admin/investment-objects/sync/run',
      getLastCompletedSync: '/v1/admin/investment-objects/sync/last-completed'
    },
    tags: {
      index: '/v1/admin/tags',
      putById: '/v1/admin/tags/:id',
      deleteById: '/v1/admin/tags/:id',
      usedById: '/v1/admin/tags/:id/usage'
    },
    changelog: {
      search: '/v1/admin/changelog',
      export: '/v1/admin/changelog/export'
    },
    appeals: {
      search: '/v1/admin/contacts/search',
      countPerStatus: '/v1/admin/contacts/count-per-type',
      export: '/v1/admin/contacts/export',
      bulk: {
        delete: '/v1/admin/contacts/bulk/delete',
        export: '/v1/admin/contacts/bulk/export'
      }
    }
  },
  file: {
    upload: '/v1/file/upload',
    delete: '/v1/file/:id'
  },
  globalSearch: {
    postGlobalSearch: '/v1/global-search/search'
  },
  videoBanner: {
    // Use YouTube video sources in appropriate format
    // https://www.youtube.com/embed/${videoId}?enablejsapi=1
    // changing the videoId(76MXVYMTZOI) is enough
    videoSRC: 'https://www.youtube.com/embed/DGWObPeaPhM?enablejsapi=1'
  }
}
