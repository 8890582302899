import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import MenuIcon from '@mui/icons-material/Menu'
import useBreakpoints from '@/hooks/use-breakpoints'
import { useAuth } from '@/context/auth-context'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { profile } from '@/containers/layouts/admin/admin-sidebar/constants'
import AppNavLink from '@/components/app-nav-link/AppNavLink'
import { Link } from 'react-router-dom'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import AppLogo from '@/components/app-logo'

const AdminHeaderProps = {
  handleMenuClick: PropTypes.func
}

const AdminHeader = ({ handleMenuClick }) => {
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()
  const { accessToken, logout } = useAuth()

  const showMenuButton = isDesktop ? 'none' : 'block'

  return (
    <AppBar
      position='fixed'
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      color='inherit'
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleMenuClick}
          sx={{ mr: 2, display: { sm: showMenuButton } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant='h6'
          noWrap
          component='div'
        >
          <Link
            to={clientRoutes.home.route}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <AppLogo height='41' />
          </Link>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {accessToken ? (
          <Box
            display='flex'
            gap='8px'
            alignItems='center'
          >
            <IconButton
              sx={{ color: 'common.green', ':hover, :focus': { backgroundColor: 'secondary.dark' } }}
              variant={'contained'}
              component={AppNavLink}
              to={clientRoutes.home.route}
              end={false}
            >
              <OpenInNewIcon />
            </IconButton>

            <IconButton
              sx={{ color: 'common.green', ':hover, :focus': { backgroundColor: 'secondary.dark' } }}
              variant={'contained'}
              component={AppNavLink}
              to={profile.link}
              end={false}
            >
              {profile.icon}
            </IconButton>
            <Button
              size='small'
              variant={'contained'}
              onClick={logout}
              disableElevation
            >
              {t('login.logout')}
            </Button>
          </Box>
        ) : null}
      </Toolbar>
    </AppBar>
  )
}

AdminHeader.propTypes = AdminHeaderProps

export default AdminHeader
