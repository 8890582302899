import { useMemo } from 'react'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useAppGridActions } from '@/components/app-grid/hooks/use-app-grid-actions'
import { useAppGrid } from '@/components/app-grid/AppGrid.context'
import { toLongDate, toShortDate } from '@/utils/date'

export const useAppGridColumns = () => {
  const { columns, rowActions, isActionLoading } = useAppGrid()
  const { onActionClick } = useAppGridActions()

  const columnsModel = useMemo(() => {
    const defaultColumn = { flex: 100, disableColumnMenu: true }
    const idColumn = {
      field: 'id',
      hide: true
    }

    const mappedColumns = columns.map((column) => {
      const rdyColumn = {
        valueGetter:
          {
            data: ({ value }) => (value ? toShortDate(value) : null),
            dateTime: ({ value }) => (value ? toLongDate(value) : null)
          }[column.type] ?? column.valueGetter,
        ...column
      }

      return {
        ...defaultColumn,
        ...rdyColumn
      }
    })

    if (Object.values(rowActions).length) {
      const actionColumn = {
        field: 'actions',
        type: 'actions',
        width: 52,
        getActions: (params) =>
          Object.values(rowActions).map((action) => (
            <GridActionsCellItem
              disabled={isActionLoading}
              key={action.label}
              icon={action.icon}
              label={action.label}
              onClick={() => {
                if (!action.fullValue) {
                  return onActionClick(action, [params.id])
                } else {
                  return onActionClick(action, [params.row])
                }
              }}
              showInMenu
            />
          ))
      }

      return [idColumn, ...mappedColumns, actionColumn]
    } else {
      return [idColumn, ...mappedColumns]
    }
  }, [columns, rowActions, isActionLoading, onActionClick])

  return {
    columnsModel
  }
}
