const typography = {
  fontFamily: ['Montserrat', 'sans-serif'].join(','),
  h1: {
    fontWeight: '600',
    fontSize: '48px'
  },
  h2: {
    fontWeight: '600',
    fontSize: '36px'
  },
  h3: {
    fontWeight: '600',
    fontSize: '30px'
  },
  h4: {
    fontWeight: '600',
    fontSize: '24px'
  },
  h5: {
    fontWeight: '600',
    fontSize: '16px'
  },
  h6: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px'
  },
  body1: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px'
  },
  body2: {
    fontWeight: '500',
    fontSize: '17px'
  },
  subtitle1: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px'
  },
  subtitle2: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px'
  },
  caption: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px'
  }
}

export default typography
