import OlenaHlinkaPhoto from '@/assets/images/Olena-Hlinka.jpg'
import AlexanderaAntipinaPhoto from '@/assets/images/Alexandera-Antipina.jpg'
import TatianaMayorovaPhoto from '@/assets/images/Tatiana-Mayorova.jpg'
import JuliaPlotkaPhoto from '@/assets/images/Julia-Plotka.jpg'
import StepanRihko from '@/assets/images/Stepan-Rishko.jpg'
import MariiaKurman from '@/assets/images/Mariia-Kurman.jpg'

export const teamMembers = (t) => [
  {
    fullName: t('aboutUsPage.investOfficeTeamMembers.JuliaPlotka'),
    position: t('aboutUsPage.investOfficeTeamMembers.positionJuliaPlotka'),
    photo: JuliaPlotkaPhoto
  },
  {
    fullName: t('aboutUsPage.investOfficeTeamMembers.OlenaHlinka'),
    position: t('aboutUsPage.investOfficeTeamMembers.positionOlenaHlinka'),
    photo: OlenaHlinkaPhoto,
    linkedinLink: 'https://www.linkedin.com/in/olena-glynka-63a5a9254/'
  },
  {
    fullName: t('aboutUsPage.investOfficeTeamMembers.AlexanderaAntipina'),
    position: t('aboutUsPage.investOfficeTeamMembers.positionAlexanderaAntipina'),
    photo: AlexanderaAntipinaPhoto,
  },
  {
    fullName: t('aboutUsPage.investOfficeTeamMembers.StepanRishko'),
    position: t('aboutUsPage.investOfficeTeamMembers.positionStepanRishko'),
    photo: StepanRihko,
    linkedinLink:
      'https://www.linkedin.com/in/%D1%81%D1%82%D0%B5%D0%BF%D0%B0%D0%BD-%D1%80%D1%96%D1%88%D0%BA%D0%BE-858602252/'
  },
  {
    fullName: t('aboutUsPage.investOfficeTeamMembers.TatianaMayorova'),
    position: t('aboutUsPage.investOfficeTeamMembers.positionTatianaMayorova'),
    photo: TatianaMayorovaPhoto,
    linkedinLink: 'https://www.linkedin.com/in/maiorovatetiana/'
  },
]
