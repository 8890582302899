import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useBreakpoints from '@/hooks/use-breakpoints'
import Box from '@mui/material/Box'
import { baseContainerSX } from '@/containers/constants'
import AppButtonGoTo from '@/components/app-button-go-to'
import { style } from '@/pages/home-page/HomePage.styles'

import InvestMobileSwiper from '@/containers/investment_opportunities/invest-mobile-swiper/InvestMobileSwiper'
import InvestDesktopSlider, {
  ArrowComponent
} from '@/containers/investment_opportunities/invest-desktop-slider/InvestDesktopSlider'
import { Skeleton, Typography } from '@mui/material'

const InvestmentOpportunities = ({ items, loading }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isDesktop, isLarge, isTablet, isMobile } = useBreakpoints()

  return (
    <Box sx={{ backgroundColor: ({ palette }) => palette.background.light }}>
      <Box
        sx={{
          ...baseContainerSX,
          px: { ...style.section.px },
          flexDirection: 'row',
          alignContent: 'space-between',
          width: '100%',
          pt: { md: '80px', sm: '30px', xs: '40px' },
          pb: '30px'
        }}
      >
        <Typography
          flex={1}
          variant='h1'
        >
          {t('clientNavigation.menuItems.investmentOpportunities')}
        </Typography>
        {isDesktop || isLarge ? (
          <Box>
            <ArrowComponent></ArrowComponent>
            <ArrowComponent reverse></ArrowComponent>
          </Box>
        ) : null}
      </Box>
      {loading ? (
        <Box
          display='flex'
          justifyContent='center'
          px={'40px'}
        >
          <Skeleton
            variant='rounded'
            width={'100%'}
            height={'400px'}
          />
        </Box>
      ) : null}
      {(isDesktop || isLarge) && !loading ? <InvestDesktopSlider items={items} /> : null}
      {(isTablet || isMobile) && !loading ? <InvestMobileSwiper items={items} /> : null}
      <Box
        sx={{
          ...baseContainerSX,
          px: { ...style.section.px },
          flexDirection: 'row',
          alignContent: 'flex-start',
          pt: { xl: '48px', md: '48px', sm: '30px', xs: '32px' },
          pb: { xl: '78px', md: '78px', sm: '30px', xs: '42px' }
        }}
      >
        <AppButtonGoTo
          onClick={() => navigate('/investment-opportunities-map')}
          title={t('startBusiness.investObjectLookAll')}
        />
      </Box>
    </Box>
  )
}

export default InvestmentOpportunities
