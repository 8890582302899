import palette from '@/plugins/material-ui/palette'

export const businessList = [
  {
    id: 1,
    title: 'financialPrograms',
    bgcolor: '#59BD2F',
    linkTo: '/support-programs'
  },
  {
    id: 2,
    title: 'investOpportunities',
    bgcolor: '#36A923',
    linkTo: '/investment-opportunities-map'
  },
  {
    id: 3,
    title: 'businessServices',
    bgcolor: '#009639',
    linkTo: '/business-offers'
  }
]

export const buttonStyles = {
  color: palette.basic.white,
  background: '#009639',
  transition: 'all .3s',
  '&:hover': {
    background: '#16782e',
    transition: 'all .3s'
  }
}
