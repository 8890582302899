import { Box, Modal, Typography } from '@mui/material'
import { baseContainerSX } from '@/containers/constants'
import { useTranslation } from 'react-i18next'
import ClearIcon from '@mui/icons-material/Clear'
import { containerPaddingSX, styles } from '@/components/app-modal/AppModal.styles'
import AppealForm from '@/containers/appeal-form-modal/AppealForm'

export const AppModal = ({
    open,
    handleClose,
    onHandleType,
    formInitialData,
    communicationType,
    visible,
    activeSubmit
  }) => {
  const { t } = useTranslation()
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={{
          ...baseContainerSX,
          ...containerPaddingSX
        }}
      >
        <ClearIcon
          sx={ styles.icon }
          onClick={ handleClose }
        />
        <Typography
          sx={ styles.title }
        >
          {t('modalForm.title')}
        </Typography>
        <AppealForm
          onHandleType={onHandleType}
          formInitialData={formInitialData}
          communicationType={communicationType}
          visible={visible}
          activeSubmit={activeSubmit}
          handleClose={handleClose}
        />
      </Box>
    </Modal>
  )
}

export default AppModal
