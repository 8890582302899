import { createContext, useContext } from 'react'
import { useLocalStorage } from '@/hooks/use-local-storage'

export const RefineContext = createContext({
  sortingModel: {},
  setSortingModel: () => {},
  filterBy: [],
  setFilterBy: () => {}
})

export const RefineProvider = ({
  children,
  defaultSorting = {
    field: 'name',
    sort: 'ASC'
  },
  prefix = ''
}) => {
  const [sortingModel, setSortingModel] = useLocalStorage(`${prefix}:sorting`, defaultSorting)

  const [filterBy, setFilterBy] = useLocalStorage(`${prefix}:filterBy`, [])

  return (
    <RefineContext.Provider value={{ sortingModel, setSortingModel, filterBy, setFilterBy }}>
      {children}
    </RefineContext.Provider>
  )
}

export const useRefine = () => {
  return useContext(RefineContext)
}
