import AdminPageHeader from '@/containers/admin-page-header'
import Box from '@mui/material/Box'
import { pageWrapperSX } from '@/pages/admin/constants'
import { useTranslation } from 'react-i18next'
import AdminChangeLogTable from '@/containers/admin-changelog/admin-changelog-table/AdminChangelogTable'
import { ChangelogService } from '@/services/changelog-service'
import { exportToCsvFile } from '@/utils/csv-export-helper'

const AdminChangelogPage = () => {
  const { t } = useTranslation()

  const changeExportToCSV = async () => {
    const { data } = await ChangelogService.exportCsv()
    const filename = `changelogs-${Date.now().toFixed()}`
    exportToCsvFile(data, filename)
  }

  return (
    <Box sx={{ ...pageWrapperSX }}>
      <AdminPageHeader
        title={t('changelog.title')}
        buttonLabel={t('changelog.downloadFile')}
        buttonProps={ { onClick: changeExportToCSV } }
      />
      <AdminChangeLogTable />
    </Box>
  )
}

export default AdminChangelogPage
