import { CssBaseline, ThemeProvider } from '@mui/material'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { theme } from '@/plugins/material-ui/theme'
import AppRouter from '@/plugins/router'
import { ModalProvider } from '@/context/app-popup-dialog-context'
import { SnackbarProvider } from 'notistack'

const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <ModalProvider>
            <CssBaseline />
            <AppRouter />
          </ModalProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
