import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Box from '@mui/material/Box'

import AdminPageHeader from '@/containers/admin-page-header'
import AdminInvestObjectForm from '@/containers/admin-invest-object/admin-invest-object-form'
import AppTabs from '@/components/app-tabs'
import { useMultiLang } from '@/hooks/admin-forms/use-multiLang'
import { InvestObjectService } from '@/services/invest-object-service'
import {
  investObjectFormGeneralInputs,
  investObjectFormInitialValues,
  investObjectFormMultiLangInputs,
  investObjectGeneralDraftValidations,
  investObjectGeneralValidations,
  investObjectMultiLangDraftValidations,
  investObjectMultiLangValidations
} from '@/pages/admin/admin-invest-object-edit/constants'

import { adminRoutes } from '@/plugins/router/admin-router/routes'
import useForm from '@/hooks/use-form'
import AppForm from '@/components/app-form/AppForm'
import useLeaveConfirm, { skipConfirmationNavState } from '@/hooks/use-leave-confirm'
import { useTabs } from '@/hooks/use-tabs'
import AppEditorSubmit from '@/containers/admin-editor-submit/AppEditorSubmit'
import { useUpdateValidations } from '@/pages/admin/admin-invest-object-edit/hooks/use-update-validations'
import { useStatusChange } from '@/hooks/admin-forms/use-status-change'
import { adminEditContainerSX, documentStatus } from '@/containers/constants'
import useAxiosWithErrorSnackbar from '@/hooks/use-axios-with-error-snackbar'

const AdminInvestObjectEdit = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const { setNeedConfirm } = useLeaveConfirm()

  const {
    isDirty: generalInputsDirty,
    formProps: generalFormProps,
    handleValidate: handleGeneralValidate,
    data: generalInputData,
    setValidations: setGeneralValidations,
    setMappedData: updateGlobalData
  } = useForm({
    inputs: investObjectFormGeneralInputs,
    initialValues: investObjectFormInitialValues.global,
    validations: investObjectGeneralValidations
  })

  const getFormComponent = useCallback(
    ({ lang, formProps }) => {
      return (
        <AdminInvestObjectForm
          lang={lang}
          multiInputs={investObjectFormMultiLangInputs}
          formPropsMulti={formProps}
          generalInputs={investObjectFormGeneralInputs}
          formPropsGeneral={generalFormProps}
        />
      )
    },
    [generalFormProps]
  )

  const updateInitialData = useCallback(
    (res) => {
      if (res) {
        updateGlobalData({
          state: res.state,
          domain: res.domain,
          code: res.code,
          note: res.note,
          imageIds: res.images || [],
          documentIds: res.documents || [],
          characteristicsType: res.characteristicsType,
          usageType: res.usageType,
          workersCount: res.workersCount !== null ? String(res.workersCount) : '',
          encumbrance: res.encumbrance,
          url: res.url,
          buildingNumber: res.buildingNumber,
          index: res.index !== null ? String(res.index) : '',
          latitude: res.latitude !== null ? String(res.latitude) : '',
          longitude: res.longitude !== null ? String(res.longitude) : '',
          cadastralNumberStatus: res.cadastralNumberStatus,
          cadastralNumber: res.cadastralNumber,
          plotArea: res.plotArea !== null ? String(res.plotArea) : '',
          ownershipType: res.ownershipType,
          landCategorySection: res.landCategorySection,
          landCategoryCategory: res.landCategoryCategory,
          landCategoryType: res.landCategoryType,
          landPlan: res.landPlan,
          landPlanFilesIds: res.landPlanFiles || [],
          realtyType: res.realtyType || [],
          realtyState: res.realtyState,
          realtyTotalAreaSqM: res.realtyTotalAreaSqM !== null ? String(res.realtyTotalAreaSqM) : '',
          realtyHasLandPlot: res.realtyHasLandPlot,
          powerSupply: res.powerSupply,
          powerSupplyKW: res.powerSupplyKW !== null ? String(res.powerSupplyKW) : '',
          nearestPowerSupplyKM: res.nearestPowerSupplyKM !== null ? String(res.nearestPowerSupplyKM) : '',
          gasSupply: res.gasSupply,
          nearestGasSupplyKM: res.nearestGasSupplyKM !== null ? String(res.nearestGasSupplyKM) : '',
          waterSupply: res.waterSupply,
          nearestWaterSupplyKM: res.nearestWaterSupplyKM !== null ? String(res.nearestWaterSupplyKM) : '',
          drainage: res.drainage,
          nearestDrainageKM: res.nearestDrainageKM !== null ? String(res.nearestDrainageKM) : '',
          railroad: res.railroad,
          internationalAndStateRoads: res.internationalAndStateRoads,
          borderCrossingPoints: res.borderCrossingPoints,
          contactsEmail: res.contactsEmail,
          contactsPhone: res.contactsPhone
        })
      } else {
        updateGlobalData(investObjectFormInitialValues.global)
      }
    },
    [updateGlobalData]
  )

  const formatData = useCallback(
    (data) => {
      const imageIds = generalInputData.imageIds.map(({ id: imageId }) => imageId)
      const documentIds = generalInputData.documentIds.map(({ id: fileId }) => fileId)
      const landPlanFilesIds = generalInputData.landPlanFilesIds.map(({ id: fileId }) => fileId)
      return {
        ...data,
        ...generalInputData,
        imageIds,
        documentIds,
        landPlanFilesIds,
        workersCount: Number(generalInputData.workersCount) || null,
        realtyTotalAreaSqM: Number(generalInputData.realtyTotalAreaSqM) || null,
        powerSupplyKW: Number(generalInputData.powerSupplyKW) || null,
        nearestPowerSupplyKM: Number(generalInputData.nearestPowerSupplyKM) || 0,
        nearestGasSupplyKM: Number(generalInputData.nearestGasSupplyKM) || 0,
        nearestWaterSupplyKM: Number(generalInputData.nearestWaterSupplyKM) || 0,
        nearestDrainageKM: Number(generalInputData.nearestDrainageKM) || 0,
        latitude: generalInputData.latitude || null,
        longitude: generalInputData.longitude || null,
        plotArea: Number(generalInputData.plotArea) || null,
        index: Number(generalInputData.index) || null,
        usageType: generalInputData.usageType || null,
        encumbrance: generalInputData.encumbrance || null,
        cadastralNumberStatus: generalInputData.cadastralNumberStatus || null,
        ownershipType: generalInputData.ownershipType || null,
        landCategorySection: generalInputData.landCategorySection || null,
        landCategoryCategory: generalInputData.landCategoryCategory || null,
        landCategoryType: generalInputData.landCategoryType || null,
        realtyState: generalInputData.realtyState || null,
        contactsEmail: generalInputData.contactsEmail,
        contactsPhone: generalInputData.contactsPhone
      }
    },
    [generalInputData]
  )

  const { fetchData: submitCreateRequest } = useAxiosWithErrorSnackbar({
    service: InvestObjectService.createInvestObject
  })
  const submitCreate = useCallback(
    (data) => {
      const formattedData = formatData(data)
      return submitCreateRequest(formattedData)
    },
    [formatData, submitCreateRequest]
  )

  const { fetchData: submitEditRequest } = useAxiosWithErrorSnackbar({
    service: InvestObjectService.putInvestObjectById
  })
  const submitEdit = useCallback(
    (data) => {
      const formattedData = formatData(data)
      return submitEditRequest({ id, data: formattedData })
    },
    [formatData, id, submitEditRequest]
  )

  const afterSubmit = useCallback(() => {
    navigate(adminRoutes.investObjects.path, { state: skipConfirmationNavState })
  }, [navigate])

  const setActiveTabIndex = (index) => {
    setTabIndex(index)
  }

  const { multiform, multilangTabs, onLanguageChange, handleSubmit, loading, isSaving, setMultiLangValidations } =
    useMultiLang({
      inputs: investObjectFormMultiLangInputs,
      globalInputs: investObjectFormGeneralInputs,
      getFormComponent,
      afterSubmit,
      setInitialData: updateInitialData,
      validations: investObjectMultiLangDraftValidations,
      publishValidation: investObjectMultiLangValidations,
      submitService: id ? submitEdit : submitCreate,
      initialData: investObjectFormInitialValues,
      getDataService: InvestObjectService.getInvestObjectById,
      additionalValidate: handleGeneralValidate,
      setActiveTabIndex,
      titleField: 'name',
      fallbackUrl: adminRoutes.investObjects.path
    })

  const { tabIndex, handleTabChange, setTabIndex } = useTabs({ tabs: multilangTabs, onTabChange: onLanguageChange })

  useStatusChange({
    setValidation: setGeneralValidations,
    draftValidation: investObjectGeneralDraftValidations,
    publishValidation: investObjectGeneralValidations,
    status: multiform.data[Object.keys(multiform.data)[tabIndex]].status
  })

  const activeForms = useMemo(() => {
    const arr = []
    if (multiform.data.en.status === documentStatus.PUBLISHED) {
      arr.push('en')
    }
    if (multiform.data.uk.status === documentStatus.PUBLISHED) {
      arr.push('uk')
    }
    return arr
  }, [multiform.data.en.status, multiform.data.uk.status])

  useUpdateValidations({
    activeForms,
    generalInputData,
    setGeneralValidations,
    setMultiLangValidations
  })

  const isDirty = useMemo(() => multiform.isDirty || generalInputsDirty, [multiform.isDirty, generalInputsDirty])

  useEffect(() => {
    setNeedConfirm(isDirty)
  }, [isDirty, setNeedConfirm])

  if (loading) {
    return <Box>Loading...</Box>
  }

  return (
    <Box sx={{ ...adminEditContainerSX }}>
      <AdminPageHeader
        title={id ? t('investObjects.edit') : t('investObjects.create')}
        styles={{ minHeight: 'auto' }}
      />
      <AppForm onFormSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', gap: '16px', flexGrow: 1, overflow: 'auto' }}>
          <AppTabs
            tabIndex={tabIndex}
            handleTabChange={handleTabChange}
            tabs={multilangTabs}
            onTabChange={onLanguageChange}
          />
        </Box>
        <AppEditorSubmit
          isSaving={isSaving}
          isDirty={isDirty}
        />
      </AppForm>
    </Box>
  )
}

export default AdminInvestObjectEdit
