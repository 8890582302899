import { Typography } from '@mui/material'

const AppSelectOptionLabel = ({ label }) => {
  return (
    <Typography
      sx={{ pt: '4px', pb: '2px', fontWeight: 600, color: 'grey.contrastText' }}
      variant={'body2'}
    >
      {label}
    </Typography>
  )
}

export default AppSelectOptionLabel
