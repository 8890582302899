const generalSectionInputStyles = {
  name: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: 1,
    root: {
      width: '545px',
      maxWidth: '100%',
      display: 'none',
    },

  },
  description: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: 2
  },
  images: {
    gridColumn: '1 / -1',
    gridRow: 3,
  },
  documents: {
    gridColumn: '1 / -1',
    gridRow: 4
  },
}

const characteristicsInputStyles = {
  characteristicsType: {
    gridColumn: '1 / -1',
    gridRow: 1,
  },
  usageType: {
    gridColumn: { md: '1 / 7', xs: '1 / -1' },
    gridRow: 2,
  },
  otherUsageType: {
    gridColumn: '1 / -1',
    gridRow: 3,
  },
  previousUsage: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: 4,
  },
  workersCount: {
    gridColumn: { sm: '1 / 5', xs: '1 / -1' },
    gridRow: 7,
  },
  encumbrance: {
    gridColumn: { md: '1 / 6', xs: '1 / -1' },
    gridRow: 8,
  },
  otherEncumbrance: {
    gridColumn: '1 / -1',
    gridRow: 9
  },
  url: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: 10
  },
}

const addressSectionInputStyles = {
  settlement: {
    gridColumn: { md: '1 / 4', xs: '1 / -1' },
    gridRow: 1
  },
  community: {
    gridColumn: { md: '4 / 7', xs: '1 / -1' },
    gridRow: { md: 1, xs: 2 },
  },
  district: {
    gridColumn: { md: '7 / 10', xs: '1 / -1' },
    gridRow: { md: 1, xs: 3 },
  },
  street: {
    gridColumn: { md: '1 / 4', sm: '1 / 5', xs: '1 / -1' },
    gridRow: { md: 2, sm: 4, xs: 5 },
  },
  buildingNumber: {
    gridColumn: { md: '4 / 6', sm: '5 / 8', xs: '1 / 6' },
    gridRow: { md: 2, sm: 4, xs: 6 },
  },
  index: {
    gridColumn: { md: '6 / 8', sm: '8 / -1', xs: '6 / -1' },
    gridRow: { md: 2, sm: 4, xs: 6 },
  },
  map: {
    gridColumn: '1 / -1',
    gridRow: { md: 3, sm: 5, xs: 7 },
  },
}

const landProtSectionInputStyles = {
  cadastralNumberOfPlot: {
    gridColumn: { md: '1 / 9', xs: '1 / -1' },
    gridRow: 1
  },
  cadastralNumber: {
    gridColumn: { md: '1 / 7', xs: '1 / -1' },
    gridRow: 2
  },
  plotArea: {
    gridColumn: { md: '1 / 3.5', sm: '1 / 4', xs: '1 / -1' },
    gridRow: 3
  },
  ownershipType: {
    gridColumn: { md: '3.5 / 7', sm: '4 / 7', xs: '1 / -1' },
    gridRow: { sm: 3, xs: 4 },
  },
  landCategorySection: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: 5
  },
  landCategoryCategory: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: { sm: 6, xs: 7 },
  },
  landCategoryType: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: { sm: 7, xs: 8 },
  },
  landPlan: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: { sm: 8, xs: 9 },
  },
  landPlanFilesIds: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: 10
  },
}

const realEstateSectionInputStyles = {
  realtyType: {
    gridColumn: '1 / -1',
    gridRow: 1,
    '& .MuiFormGroup-root': {
      display: 'grid',
      gridTemplateColumns: { md: 'repeat(3,1fr)', xs: '100%' },
    }
  },
  realtyState: {
    gridColumn: '1 / -1',
    gridRow: 2
  },
  realtyTotalAreaSqM: {
    gridColumn: '1 / -1',
    gridRow: 3
  },
  realtyHasLandPlot: {
    gridColumn: '1 / -1',
    gridRow: 4,
  },
}

const communicationsSectionInputStyles = {
  powerSupply: {
    gridColumn: '1 / -1',
    gridRow: 1
  },
  powerSupplyKW: {
    gridColumn: '1 / -1',
    gridRow: 2
  },
  nearestPowerSupplyKM: {
    gridColumn: '1 / -1',
    gridRow: 2
  },
  gasSupply: {
    gridColumn: '1 / -1',
    gridRow: 3
  },
  nearestGasSupplyKM: {
    gridColumn: '1 / -1',
    gridRow: 4
  },
  waterSupply: {
    gridColumn: '1 / -1',
    gridRow: 5
  },
  waterSupplyType: {
    gridColumn: '1 / -1',
    gridRow: 6
  },
  nearestWaterSupplyKM: {
    gridColumn: '1 / -1',
    gridRow: 6
  },
  drainage: {
    gridColumn: '1 / -1',
    gridRow: 8
  },
  drainageType: {
    gridColumn: '1 / -1',
    gridRow: 9
  },
  nearestDrainageKM: {
    gridColumn: '1 / -1',
    gridRow: 9
  },
  roadSurfaceType: {
    gridColumn: { md: '1 / 5', xs: '1 / -1' },
    gridRow: 2
  },
}

const transportConnectionsSectionInputStyles = {
  roadSurfaceType: {
    gridColumn: { md: '1 / 4', xs: '1 / -1' },
    gridRow: 1
  },
  railroad: {
    gridColumn: '1 / -1',
    gridRow: { sm: 2, xs: 3 },
  },
  internationalAndStateRoads: {
    gridColumn: '1 / -1',
    gridRow: { sm: 3, xs: 4 },
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    h4: {
      fontWeight: { sm: 600, xs: 400 },
    }
  },
  borderCrossingPoints: {
    marginTop: '30px',
    gridColumn: '1 / -1',
    gridRow: { sm: 4, xs: 5 },
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    h4: {
      fontWeight: { sm: 600, xs: 400 },
    }
  },
}

const contantPersonSectionInputStyles = {
  contactsFullName: {
    gridColumn: { md: '1 / 8', xs: '1 / -1' },
    gridRow: 1
  },
  contactsEmail: {
    gridColumn: { md: '1 / 6', xs: '1 / -1' },
    gridRow: 2
  },
  contactsPhone: {
    gridColumn: { md: '6 / -1', xs: '1 / -1' },
    gridRow: { md: 2, xs: 3 },
  }
}

export const styles = {
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gap: '6px',
    pr: '8px'
  },
  headers: {
    generalInformation: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    characteristic: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    address: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    realEstate: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    landPlot: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    communications: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    transportConnections: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    roads: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    railroads: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    internationalAndStateRoads: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    borderCrossingPoints: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    contactPerson: {
      gridColumn: '1 / -1',
      gridRow: 1
    }
  },
  inputs: {
    ...generalSectionInputStyles,
    ...characteristicsInputStyles,
    ...addressSectionInputStyles,
    ...landProtSectionInputStyles,
    ...realEstateSectionInputStyles,
    ...communicationsSectionInputStyles,
    ...transportConnectionsSectionInputStyles,
    ...contantPersonSectionInputStyles,
  }
}
