import AppFormHeader from '@/components/app-form-header'
import { useTranslation } from 'react-i18next'
import { styles } from '@/containers/admin-invest-object/admin-invest-object-form/admin-invest-object-form-styles'
import { MenuItem, TextField } from '@mui/material'
import {
  realtyStateOptions,
  realtyTypesOptions
} from '@/containers/admin-invest-object/admin-invest-object-form/real-estate-section/constants'
import AppRadioGroup from '@/components/app-radio-group'
import i18next from 'i18next'
import { yesNoOptions } from '@/constants/default'
import PropTypes from 'prop-types'

const RealEstateSectionProps = {
  formPropsGeneral: PropTypes.object
}

const RealEstateSection = ({ formPropsGeneral }) => {
  const { t } = useTranslation()

  const handleRadioChange = (e) => {
    const value = e.target.value === 'true'
    formPropsGeneral.handleChange('realtyHasLandPlot')({ target: { value } })
  }

  return (
    <>
      <AppFormHeader
        text={t('investObjects.headers.realEstate')}
        variant={'h4'}
        sx={styles.headers.realEstate}
      />
      <TextField
        select
        label={t('investObjects.labels.realtyTypes')}
        value={formPropsGeneral.data.realtyType}
        error={Boolean(formPropsGeneral.errors.realtyType)}
        helperText={formPropsGeneral.errors.realtyType || ' '}
        onChange={formPropsGeneral.handleChange('realtyType')}
        onBlur={formPropsGeneral.handleBlur.realtyType}
        sx={styles.inputs.realtyType}
        SelectProps={{ multiple: true }}
      >
        {realtyTypesOptions().map((option) => (
          <MenuItem
            key={option.text}
            value={option.value}
          >
            {option.text}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label={t('investObjects.labels.realtyStateRequired')}
        value={formPropsGeneral.data.realtyState}
        error={Boolean(formPropsGeneral.errors.realtyState)}
        helperText={formPropsGeneral.errors.realtyState || ' '}
        onChange={formPropsGeneral.handleChange('realtyState')}
        onBlur={formPropsGeneral.handleBlur.realtyState}
        sx={styles.inputs.realtyState}
      >
        {realtyStateOptions().map((option) => (
          <MenuItem
            key={option.text}
            value={option.value}
          >
            {option.text}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        value={formPropsGeneral.data.realtyTotalAreaSqM}
        error={Boolean(formPropsGeneral.errors.realtyTotalAreaSqM)}
        helperText={formPropsGeneral.errors.realtyTotalAreaSqM || ' '}
        onChange={formPropsGeneral.handleChange('realtyTotalAreaSqM')}
        onBlur={formPropsGeneral.handleBlur('realtyTotalAreaSqM')}
        sx={styles.inputs.realtyTotalAreaSqM}
        label={t('investObjects.labels.realtyTotalAreaSqM')}
      />
      <AppRadioGroup
        value={formPropsGeneral.data.realtyHasLandPlot}
        onChange={handleRadioChange}
        error={Boolean(formPropsGeneral.errors.realtyHasLandPlot)}
        helperText={formPropsGeneral.errors.realtyHasLandPlot}
        sx={styles.inputs.realtyHasLandPlot}
        options={yesNoOptions}
        label={i18next.t('investObjects.labels.realtyHasLandPlot')}
        row
      />
    </>
  )
}

RealEstateSection.propTypes = RealEstateSectionProps

export default RealEstateSection
