import { theme } from '@/plugins/material-ui/theme'

export const responsiveGap = { xl: theme.spacing(4), md: theme.spacing(4), sm: theme.spacing(3), xs: '15px' }
export const responsiveGapV2 = { xl: '45px', md: '24px', sm: '24px', xs: '15px' }

export const style = {
  container: {
    flexGrow: 1
  },
  section: {
    py: responsiveGap,
    px: { ...responsiveGapV2 }
  },
  fullWidthContainer: {
    minWidth: '100%'
  },
  appToGo: {
    borderTop: `1px solid ${theme.palette.grey.main}`,
    mx: '-' + responsiveGap.xs,
    px: responsiveGap.xs,
    pt: responsiveGap.xs
  }
}
