import { Box, Button, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Menu from '@mui/material/Menu'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { theme } from '@/plugins/material-ui/theme'
import { useSorting } from '@/containers/invest-object-list/invest-object-list-filters/hooks/useSorting'
import { paperProps, sortingKeys } from '@/containers/invest-object-list/invest-object-list-sorting/constant'
import useBreakpoints from '@/hooks/use-breakpoints'

export const SortingMenu = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { sorting, setSorting } = useSorting()
  const sortingLabel = sortingKeys(t)[sorting]

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Button
          sx={{
            p: '0',
            '&:hover, &:focus': {
              backgroundColor: 'transparent'
            }
          }}
          disableElevation
          endIcon={<ExpandMoreIcon />}
          aria-controls={open ? 'sorting-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Typography variant={'subtitle1'}>{sortingLabel}</Typography>
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='sorting-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {Object.entries(sortingKeys(t)).map(([key, value]) => (
          <MenuItem
            sx={{
              '&.Mui-selected': {
                color: 'primary.main',
                backgroundColor: 'transparent'
              }
            }}
            className={sorting === key ? 'Mui-selected' : ''}
            onClick={() => {
              handleClose()
              setSorting(key)
            }}
            key={key}
            title={value}
          >
            <Typography variant={'subtitle1'}>{value}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
const InvestObjectListSorting = () => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        gap: theme.spacing(1)
      }}
    >
      {!isMobile ? <Typography variant={'subtitle1'}>{t('sorting.label')}</Typography> : null}

      <SortingMenu />
    </Box>
  )
}

export default InvestObjectListSorting
