import { Box, Typography } from '@mui/material'
import { theme } from '@/plugins/material-ui/theme'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import facebookSVG from '@/assets/icons/facebook.svg'
import twitterSVG from '@/assets/icons/twitter.svg'
import linkedinSVG from '@/assets/icons/linkedin.svg'
import PropTypes from 'prop-types'
import { FACEBOOK, LINKEDIN, TWITTER } from '@/containers/layouts/client/client-footer/constants'

const AppShareButtonsProps = {
  label: PropTypes.string,
  url: PropTypes.string,
  quote: PropTypes.string,
  hashtags: PropTypes.array,
  sx: PropTypes.object
}

const AppShareButtons = ({ label, url, quote, hashtags, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: { sm: 'flex-end', xs: 'flex-start' },
        alignItems: 'center',
        placeSelf: 'flex-start',
        gap: theme.spacing(2),
        ...sx
      }}
    >
      <Typography
        component='strong'
        variant='body2'
        fontWeight='bold'
      >
        {label}
      </Typography>
      <FacebookShareButton
        url={url}
        quote={quote}
      >
        <img
          alt={FACEBOOK}
          src={facebookSVG}
          height={30}
        />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        quote={quote}
        hashtags={hashtags}
      >
        <img
          alt={TWITTER}
          src={twitterSVG}
          height={30}
        />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <img
          alt={LINKEDIN}
          src={linkedinSVG}
          height={30}
        />
      </LinkedinShareButton>
    </Box>
  )
}

AppShareButtons.propTypes = AppShareButtonsProps

export default AppShareButtons
