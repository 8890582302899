import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'
import { buildURL } from '@/utils/url-builder'

export const InvestObjectService = {
  grid: (params) => {
    return request.post(URLs.admin.investObjects.search, { ...params })
  },
  bulkDeletion: (params) => {
    return request.post(URLs.admin.investObjects.bulk.delete, { ...params })
  },
  countPerStatus: () => {
    return request.get(URLs.admin.investObjects.countPerStatus)
  },
  createInvestObject: (data) => {
    return request.post(URLs.admin.investObjects.create, { ...data })
  },
  getInvestObjectById: (id) => {
    return request.get(buildURL(URLs.admin.investObjects.getInvestObjectById, { id }))
  },
  putInvestObjectById: ({ id, data }) => {
    return request.put(buildURL(URLs.admin.investObjects.putInvestObjectById, { id }), data)
  },
  mapListGrid: (data) => {
    return request.post(URLs.investObjects.getMapList, { ...data })
  },
  mapGrid: (data) => {
    return request.post(URLs.investObjects.getMap, { ...data })
  },
  mapGridObject: ({ id, query }) => {
    return request.get(buildURL(URLs.investObjects.getMapObj, { id }, { ...query }))
  },
  getInvestObjectDetails: ({ id, query }) => {
    return request.get(buildURL(URLs.investObjects.getDetails, { id }, { ...query }))
  },
  sync: () => {
    return request.post(URLs.admin.investObjects.sync)
  },
  getLastCompletedSync: () => {
    return request.get(URLs.admin.investObjects.getLastCompletedSync)
  },
  exportInvestObjectDetails: ({ id, query }) => {
    return request.get(buildURL(URLs.investObjects.exportDetails, { id }, { ...query }), {
      responseType: 'blob'
    })
  },
  submitInvestObject: (data, recaptchaToken) => {
    return request.post(
      buildURL(URLs.investObjects.submit),
      { ...data },
      {
        headers: { 'x-recaptcha-value': recaptchaToken }
      }
    )
  }
}
