import { useFilterBy } from '@/containers/invest-object-list/invest-object-list-filters/hooks/useFilterBy'
import { useTranslation } from 'react-i18next'
import { characteristicsTypeOptions } from '@/pages/admin/admin-invest-object-edit/constants'
import useBreakpoints from '@/hooks/use-breakpoints'
import { characteristicsTypeFilter } from '@/containers/invest-object-list/invest-object-list-filters/constant'
import { Box, MenuItem, Typography } from '@mui/material'
import Chip from '@mui/material/Chip'
import AppFormControl from '@/containers/invest-object-list/invest-object-list-filters/app-form-control/AppFormControl'
import AppSelect from '@/containers/invest-object-list/invest-object-list-filters/app-select/AppSelect'
import AppSelectOptionLabel from '@/containers/invest-object-list/invest-object-list-filters/app-select-option-label/AppSelectOptionLabel'
import React, { useMemo } from 'react'
import ListSubheader from '@mui/material/ListSubheader'
import { filterChipSX } from '@/containers/invest-object-list/invest-object-list-filters/ivest-object-list-filter-characteristic/constants'

const CharacteristicTypeFilter = ({ mobileView = false }) => {
  const { getFilter, setFilter, clearAllFilters } = useFilterBy()
  const { t } = useTranslation()
  const lookUpLabel = t('investObjectMap.filter.lookUp')
  const labelAll = t('investObjectMap.filter.all')
  const options = characteristicsTypeOptions()

  const { isLarge } = useBreakpoints()

  const characteristicsTypeValue = getFilter(characteristicsTypeFilter)?.value ?? 'all'
  const selected = useMemo(() => characteristicsTypeValue !== 'all', [characteristicsTypeValue])
  const ChipView = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: mobileView ? 'space-around' : 'flex-start'
      }}
    >
      <Typography variant={'body1'}>{lookUpLabel}</Typography>

      <Chip
        variant={'outlined'}
        className={characteristicsTypeValue === 'all' ? 'active-chip' : ''}
        onClick={() => clearAllFilters()}
        color={'secondary'}
        key='all'
        label={labelAll}
        sx={filterChipSX}
      />
      {options.map(({ value, label }) => (
        <Chip
          variant={'outlined'}
          className={characteristicsTypeValue === value ? 'active-chip' : ''}
          color={'secondary'}
          onClick={() => {
            clearAllFilters()
            setFilter(characteristicsTypeFilter, value)
          }}
          key={value}
          label={label}
          sx={filterChipSX}
        />
      ))}
    </Box>
  )
  const SelectView = () => (
    <AppFormControl
      selected={selected}
      label={lookUpLabel}
    >
      <AppSelect
        value={characteristicsTypeValue}
        onChange={({ target: { value } }) => {
          clearAllFilters()
          if (value === 'all') {
            return
          }
          setFilter(characteristicsTypeFilter, value)
        }}
      >
        <ListSubheader>
          <AppSelectOptionLabel label={lookUpLabel} />
        </ListSubheader>
        <MenuItem
          key={'all'}
          value={'all'}
        >
          {labelAll}
        </MenuItem>
        {options.map((e) => (
          <MenuItem
            key={e.value}
            value={e.value}
          >
            {e.label}
          </MenuItem>
        ))}
      </AppSelect>
    </AppFormControl>
  )

  if (mobileView) {
    return (
      <Box sx={{ px: '12px', width: '100%' }}>
        <ChipView />
      </Box>
    )
  }
  return isLarge ? <ChipView /> : <SelectView />
}

export default CharacteristicTypeFilter
