import { generateSkeletonItems, randomIntFromInterval } from '@/utils/skeleton'
import Box from '@mui/material/Box'
import { Skeleton } from '@mui/material'

export const SkeletonTags = () => {
  return generateSkeletonItems(randomIntFromInterval(2, 3)).map((tag) => (
    <Box
      key={tag.id}
      sx={{ maxWidth: '100%', display: 'inline-flex', mr: '15px' }}
    >
      <Skeleton
        sx={{ display: 'flex-inline' }}
        variant={'rounded'}
        width={50}
        height={20}
      />
    </Box>
  ))
}
