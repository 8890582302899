import PropTypes from 'prop-types'

import { Chip, Tooltip } from '@mui/material'

const AppChipWithTooltipProps = {
  chipLabel: PropTypes.string,
  tooltipLabel: PropTypes.string,
  icon: PropTypes.element,
  onIconClick: PropTypes.func,
  sx: PropTypes.object,
  disabled: PropTypes.bool
}

const AppChipWithTooltip = ({ chipLabel, tooltipLabel, icon, onIconClick, onClick, sx = {}, disabled }) => {
  return (
    <Tooltip
      title={tooltipLabel}
      arrow
      onClick={onClick}
    >
      <Chip
        variant={'outlined'}
        color='secondary'
        sx={{ margin: '4px', ...sx }}
        deleteIcon={icon}
        label={chipLabel}
        onDelete={disabled ? undefined : onIconClick}
        disabled={disabled}
      />
    </Tooltip>
  )
}

AppChipWithTooltip.propTypes = AppChipWithTooltipProps

export default AppChipWithTooltip
