import { theme } from '@/plugins/material-ui/theme'
import { baseContainerSX } from '@/containers/constants'

const containerPaddingSX = { px: { md: theme.spacing(5.5), sm: theme.spacing(3), xs: theme.spacing(2) } }

export const styles = {
  image: {
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  container: {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      pb: theme.spacing(5)
    },
    [theme.breakpoints.up('xs')]: {
      pb: theme.spacing(3.5)
    },
    ...baseContainerSX,
    ...containerPaddingSX
  },
  header: {
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      lineHeight: 'initial',
      mt: theme.spacing(1.5),
      mb: theme.spacing(4)
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '32px',
      lineHeight: '39px',
      mt: theme.spacing(5),
      mb: theme.spacing(2.5)
    }
  },
  content: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: { md: 'row', xs: 'column' },
    mt: theme.spacing(4),
    gap: theme.spacing(5),
    otherEvents: {
      flex: '1',
      minWidth: '300px',
      maxWidth: { md: '300px', sm: 'auto' },
      height: 'fit-content',
      position: 'sticky',
      top: '10px'
    }
  }
}
