import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Box from '@mui/material/Box'

import AdminPageHeader from '@/containers/admin-page-header'
import AddNewsForm from '@/containers/admin-news/admin-add-news-form'
import AppTabs from '@/components/app-tabs'

import { NewsService } from '@/services/news-service'
import { adminRoutes } from '@/plugins/router/admin-router/routes'

import { useAttachedTags } from '@/hooks/admin-forms/use-attached-tags'
import { useMultiLang } from '@/hooks/admin-forms/use-multiLang'
import {
  createNewsForm,
  createNewsFormGeneralInputs,
  draftValidations,
  formInitialValues,
  generalValidationDraft,
  generalValidationPublish,
  publishValidations
} from '@/containers/admin-news/constants'
import AppForm from '@/components/app-form/AppForm'
import useLeaveConfirm, { skipConfirmationNavState } from '@/hooks/use-leave-confirm'
import AdminTagsSidebar from '@/containers/admin-tags/admin-tags-sidebar/AdminTagsSidebar'
import useForm from '@/hooks/use-form'
import { mapDataFromInputs } from '@/utils/form-helper'
import { useTabs } from '@/hooks/use-tabs'
import AppEditorSubmit from '@/containers/admin-editor-submit/AppEditorSubmit'
import { useStatusChange } from '@/hooks/admin-forms/use-status-change'
import { adminEditContainerSX } from '@/containers/constants'

const AdminNewsEdit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { setNeedConfirm } = useLeaveConfirm()

  const { isTagsDirty, clearTags, attachTag, unAttachTag, setInitial: setInitialTags, tags } = useAttachedTags()

  const {
    isDirty: generalInputsDirty,
    formProps: generalFormProps,
    handleValidate: handleGeneralValidate,
    data: generalInputData,
    setMappedData: updateGlobalData,
    setValidations: setGlobalValidation
  } = useForm({
    inputs: createNewsFormGeneralInputs,
    initialValues: formInitialValues.global,
    validations: generalValidationDraft
  })

  const getFormComponent = useCallback(
    ({ lang, formProps }) => {
      return (
        <AddNewsForm
          lang={lang}
          multiInputs={createNewsForm}
          formPropsMulti={formProps}
          generalInputs={createNewsFormGeneralInputs}
          formPropsGeneral={generalFormProps}
          tags={tags}
          onTagDelete={unAttachTag}
        />
      )
    },
    [generalFormProps, tags, unAttachTag]
  )

  const setInitialData = useCallback(
    (data) => {
      if (data) {
        setInitialTags(data?.tags || [])
        updateGlobalData({
          featureImage: data?.featureImage
        })
      } else {
        setInitialTags([])
        updateGlobalData(formInitialValues.global)
      }
    },
    [setInitialTags, updateGlobalData]
  )

  const createSubmitService = useCallback(
    (data) =>
      NewsService.createNews({
        ...data,
        featureImage: generalInputData?.featureImage?.id ?? null,
        tags: tags.map((tag) => tag.id)
      }),
    [generalInputData.featureImage, tags]
  )

  const editSubmitService = useCallback(
    (data) => {
      const editedData = {
        ...mapDataFromInputs(createNewsForm, data),
        featureImage: generalInputData.featureImage?.id,
        tags: tags.map((tag) => tag.id)
      }

      return NewsService.putNewsById(id, editedData)
    },
    [generalInputData.featureImage, id, tags]
  )

  const afterSubmit = useCallback(() => {
    clearTags()
    navigate(adminRoutes.news.path, { state: skipConfirmationNavState })
  }, [clearTags, navigate])

  const setActiveTabIndex = (index) => {
    setTabIndex(index)
  }

  const { multiform, multilangTabs, tabLanguage, onLanguageChange, handleSubmit, loading, isSaving } = useMultiLang({
    inputs: createNewsForm,
    getFormComponent,
    afterSubmit,
    validations: draftValidations,
    publishValidation: publishValidations,
    submitService: id ? editSubmitService : createSubmitService,
    initialData: formInitialValues,
    setInitialData,
    getDataService: NewsService.getNewsById,
    additionalValidate: handleGeneralValidate,
    setActiveTabIndex,
    fallbackUrl: adminRoutes.news.path
  })
  const { tabIndex, handleTabChange, setTabIndex } = useTabs({ tabs: multilangTabs, onTabChange: onLanguageChange })

  const isDirty = useMemo(
    () => isTagsDirty || multiform.isDirty || generalInputsDirty,
    [generalInputsDirty, isTagsDirty, multiform.isDirty]
  )

  useStatusChange({
    setValidation: setGlobalValidation,
    draftValidation: generalValidationDraft,
    publishValidation: generalValidationPublish,
    status: multiform.data[Object.keys(multiform.data)[tabIndex]].status
  })

  useEffect(() => {
    setNeedConfirm(isDirty)
  }, [isDirty, setNeedConfirm])

  if (loading) {
    return <Box>Loading...</Box>
  }

  return (
    <Box sx={{ ...adminEditContainerSX }}>
      <AdminPageHeader
        title={id ? t('news.editNews') : t('news.createNews')}
        styles={{ minHeight: 'auto' }}
      />
      <AppForm onFormSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', gap: '16px', flexGrow: 1, overflow: 'auto' }}>
          <AppTabs
            tabs={multilangTabs}
            tabIndex={tabIndex}
            handleTabChange={handleTabChange}
            styles={{ flexBasis: '70%' }}
          />
          <AdminTagsSidebar
            tags={tags}
            addTag={attachTag}
            chosenLanguage={tabLanguage}
          />
        </Box>
        <AppEditorSubmit
          isSaving={isSaving}
          isDirty={isDirty}
          afterDelete={afterSubmit}
          id={id}
          deleteService={NewsService.bulkDeletion}
        />
      </AppForm>
    </Box>
  )
}

export default AdminNewsEdit
