import { useCallback, useEffect, useState } from 'react'
import { isEqual } from '@/utils/common'
import { useAppDatePickerForm } from '@/components/app-date-picker/hooks/use-app-date-picker-form'
import { useAppEditorForm } from '@/components/app-editor/hooks/use-app-editor-form'
import { useAppImageUploaderForm } from '@/hooks/admin-forms/use-app-image-uploader-form'
import { useAppFilesUploaderForm } from '@/hooks/admin-forms/use-app-files-uploader-form'

export const useForm = ({ initialValues, validations: initialValidations = {}, inputs }) => {
  const [data, setData] = useState(initialValues)
  const [initialData, setInitialData] = useState(initialValues)
  const [isDirty, setDirty] = useState(false)
  const [errors, setErrors] = useState({})
  const [isTouched, setTouched] = useState({})
  const [validations, setValidations] = useState(initialValidations)

  const setMappedData = useCallback((newData) => {
    const fields = Object.keys(newData).map((key) => key)
    const mappedData = fields.reduce((acc, name) => {
      acc[name] = newData[name]

      return acc
    }, {})
    setData(mappedData)
    setInitialData(mappedData)
  }, [])

  useEffect(() => {
    setErrors({})
    setMappedData(initialValues)
  }, [setMappedData, initialValues])

  const validator = useCallback(
    (validationsArray, value) => {
      let validationError = ''
      validationsArray.some((validation) => {
        validationError = validation(value, data)
        return validationError
      })
      return validationError
    },
    [data]
  )

  const clearForm = useCallback(() => {
    setData({ ...initialData })
    setErrors({})
  }, [initialData]) // from event to value which this type consumes

  const dataGetter = (event) => {
    if (event.customType) {
      return {
        value: event.value,
        simplifiedValue: event.simplifiedValue ?? event.value
      }
    } else if (event.target) {
      if (event.target.type === 'checkbox') {
        return {
          value: event.target.checked,
          simplifiedValue: event.target.checked
        }
      } else {
        return {
          value: event.target.value,
          simplifiedValue: event.target.value
        }
      }
    }
  }

  const handleChange = useCallback(
    (key) =>
      (event, options = {}) => {
        const mappedData = dataGetter(event)
        setData((prevState) => ({
          ...prevState,
          [key]: mappedData.value
        }))
        if (!options.notValidate && isTouched[key] && validations[key]) {
          const error = validator(validations[key], mappedData.simplifiedValue)
          setErrors({
            ...errors,
            [key]: error
          })
        }
      },
    [errors, isTouched, validations, validator]
  )

  const handleBulkChange = (keys) => (events, options = {}) => {
    const newErrorData = {}
    const newFormData = {}

    keys.forEach((key, idx) => {
      const event = events[idx]
      const mappedData = dataGetter(event)
      newFormData[key] = mappedData.value

      if (!options.notValidate && isTouched[key] && validations[key]) {
        newErrorData[key] = validator(validations[key], mappedData.simplifiedValue)
      }
    })

    setData((prevState) => ({
      ...prevState,
      ...newFormData,
    }))

    setErrors((prevState) => ({
      ...prevState,
      ...newErrorData,
    }))
  }

  const handleBlur = (key) => (event) => {
    if (validations && validations[key]) {
      const mappedData = dataGetter(event)
      const error = validator(validations[key], mappedData.simplifiedValue)
      setErrors({
        ...errors,
        [key]: error
      })
    }
    setTouched({
      ...isTouched,
      [key]: true
    })
  }

  const handleBulkBlur = (keys) => {
    if (validations) {
      const foundErrors = keys
        .filter(key => validations[key])
        .reduce((acc, key) => {
          const value = data[key]
          acc[key] = validator(validations[key], value)
          return acc
        }, {})

      setErrors({
        ...errors,
        ...foundErrors,
      })
    }

    setTouched({
      ...isTouched,
      ...keys.reduce((acc, key) => { acc[key] = true; return acc }, {})
    })
  }

  const handleFieldValidation = useCallback((key, value) => {
    const error = validator(validations[key], value)

    const hasErrors =
      typeof error === 'object' ? Object.values(error).some((item) => Boolean(item)) : Boolean(error)

    if (hasErrors) {
      setErrors((prevState) => ({
        ...prevState,
        [key]: error
      }))
    }

    return hasErrors
  }, [validations, validator])

  const handleValidate = useCallback(() => {
    let isValid = true
    if (validations) {
      for (const key in validations) {
        const value = data[key]
        const hasErrors = handleFieldValidation(key, value)

        if (hasErrors) {
          isValid = false
        }
      }
    }
    return isValid
  }, [data, handleFieldValidation, validations])

  const areFieldsValid = (fields) => {
    const checkIfFieldValueIsValid = (key) => {
      const value = data[key]
      const error = validator(validations[key], value)
      return typeof error === 'object' ? Object.values(error).some((item) => Boolean(item)) : Boolean(error)
    }

    for (const field of fields) {
      if (validations[field]) {
        const hasErrors = checkIfFieldValueIsValid(field)

        if (hasErrors) {
          return false
        }
      }
    }

    return true
  }

  const areFieldsHaveAnyValidation = (fields) => {
    return fields.some((field) => validations[field] && validations[field].length)
  }

  const getProgress = (fields) => {
    return fields.reduce((acc, field) => {
      const fieldVal = data[field]
      const hasValue = typeof fieldVal === 'object' && fieldVal !== null ? fieldVal.length : fieldVal
      const isFalse = typeof fieldVal === 'boolean' && fieldVal === false

      return (hasValue || isFalse) ? ++acc : acc
    }, 0) / fields.length
  }

  const { formProps: datePickerFormProps } = useAppDatePickerForm({ handleBlur, handleChange })
  const { formProps: editorFormProps } = useAppEditorForm({ handleBlur, handleChange, setInitialData })
  const { formProps: imageFormProps } = useAppImageUploaderForm({ handleChange })
  const { formProps: filesFormProps } = useAppFilesUploaderForm({ handleChange })

  useEffect(() => {
    setDirty(!isEqual(data, initialData))
  }, [data, initialData])

  useEffect(() => {
    const errorTimeout = setTimeout(() => {
      if (Object.keys(errors).length) {
        setErrors({})
      }
    }, 10000)
    return () => clearTimeout(errorTimeout)
  }, [errors])

  return {
    data,
    isDirty,
    errors,
    handleValidate,
    handleFieldValidation,
    setMappedData,
    clearForm,
    setValidations,
    areFieldsValid,
    areFieldsHaveAnyValidation,
    getProgress,
    formProps: {
      handleChange,
      handleBulkChange,
      handleFieldValidation,
      handleBulkBlur,
      handleBlur,
      ...filesFormProps,
      ...datePickerFormProps,
      ...editorFormProps,
      ...imageFormProps,
      data,
      errors,
      inputs
    }
  }
}

export default useForm
