import { theme } from '@/plugins/material-ui/theme'

export const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    mt: theme.spacing(1),
    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.25))',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: '50%',
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) translateX(50%) rotate(45deg)',
      zIndex: 0
    }
  }
}
export const sortingKeys = (t) => ({
  'publishedDate:DESC': t('sorting.latest'),
  'publishedDate:ASC': t('sorting.oldest'),
  'plotArea:DESC': t('sorting.highestPlotArea'),
  'plotArea:ASC': t('sorting.lowestPlotArea'),
  'realtyTotalAreaSqM:DESC': t('sorting.highestRealtyTotal'),
  'realtyTotalAreaSqM:ASC': t('sorting.lowestRealtyTotal'),
})
