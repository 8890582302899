import React from 'react'
import { Box, Backdrop } from '@mui/material'
import { Link } from 'react-router-dom'
import AppLogo from '@/components/app-logo'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import HeaderToolbar from './HeaderToolbar'
import { responsiveGapV2 } from '@/pages/home-page/HomePage.styles'
import { baseContainerSX } from '@/containers/constants'
import useBreakpoints from '@/hooks/use-breakpoints'

const ClientHeader = ({ fullWidthHeader = false }) => {
  const [backdropOpen, setBackdropOpen] = React.useState(false)
  const { isMobile } = useBreakpoints()

  const toggleBackdrop = () => setBackdropOpen(!backdropOpen)

  return (
    <>
      <Backdrop
        sx={{ color: 'basic.white', zIndex: 1 }}
        open={backdropOpen}
        onClick={() => setBackdropOpen(false)}
      />

      <Box
        sx={{
          backgroundColor: 'basic.white',
          zIndex: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.grey.main}`,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%'
        }}
      >
        <Box
          maxWidth={fullWidthHeader ? false : 'xl'}
          sx={{
            ...baseContainerSX,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
            py: '20px',
            px: responsiveGapV2,
            height: !isMobile ? '90px' : '70px',
            position: 'relative'
          }}
          component='header'
        >
          <Link
            to={clientRoutes.home.route}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <AppLogo height='41' />
          </Link>

          <HeaderToolbar
            toggleBackdrop={toggleBackdrop}
            fullWidthHeader={fullWidthHeader}
          />
        </Box>
      </Box>
    </>
  )
}

export default ClientHeader
