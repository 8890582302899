import CircleIcon from '@mui/icons-material/Circle'
import palette from '@/plugins/material-ui/palette'

const withIcon = (offers) =>
  offers.map((offer) => ({
    ...offer,
    icon: <CircleIcon sx={{ width: '10px', height: '10px', mt: '5px', color: palette.primary.main }} />
  }))

export const content = (t) => [
  {
    title: t('businessOffers.help.title'),
    description: t('businessOffers.help.description'),
    offers: withIcon([
      {
        title: t('businessOffers.help.offers.grants')
      },
      {
        title: t('businessOffers.help.offers.grantsRequests')
      },
      {
        title: t('businessOffers.help.offers.grantsBudgets')
      },
      {
        title: t('businessOffers.help.offers.grantsLead')
      },
      {
        title: t('businessOffers.help.offers.grantsSummary')
      }
    ]),
    tag: 'financialPrograms'
  },
  {
    title: t('businessOffers.find.title'),
    description: t('businessOffers.find.description'),
    offers: withIcon([
      {
        title: t('businessOffers.find.offers.objs')
      },
      {
        title: t('businessOffers.find.offers.land')
      },
      {
        title: t('businessOffers.find.offers.docs')
      }
    ]),
    tag: 'investmentObjects'
  },
  {
    title: t('businessOffers.admin.title'),
    description: t('businessOffers.admin.description'),
    offers: withIcon([
      {
        title: t('businessOffers.admin.offers.bug')
      },
      {
        title: t('businessOffers.admin.offers.ur')
      }
    ]),
    tag: 'businessAdministration'
  },
  {
    title: t('businessOffers.export.title'),
    description: t('businessOffers.export.description'),
    offers: withIcon([
      {
        title: t('businessOffers.export.offers.analytic')
      },
      {
        title: t('businessOffers.export.offers.consult')
      },
      {
        title: t('businessOffers.export.offers.help')
      },
      {
        title: t('businessOffers.export.offers.consultGlobal')
      },
      {
        title: t('businessOffers.export.offers.cultural')
      }
    ]),
    tag: 'exportConsultations'
  }
]
