import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

import { FormGroup, TextField } from '@mui/material'
import AppInputs from '@/components/app-form/app-inputs'
import { styles } from '@/containers/admin-invest-object/admin-invest-object-form/admin-invest-object-form-styles'
import {
  characteristicsTypeOptions,
  characteristicType,
  investObjectFormHeaders
} from '@/pages/admin/admin-invest-object-edit/constants'
import AppFormHeader from '@/components/app-form-header'
import { useEffect } from 'react'
import LandPlotSection from '@/containers/admin-invest-object/admin-invest-object-form/land-plot-section'
import RealEstateSection from '@/containers/admin-invest-object/admin-invest-object-form/real-estate-section'
import AppRadioGroup from '@/components/app-radio-group'
import i18next from 'i18next'
import CommunicationsSection from '@/containers/admin-invest-object/admin-invest-object-form/communications-section'
import TransportConnectionSection from '@/containers/admin-invest-object/admin-invest-object-form/transport-connection-section'
import { FormStatusContext } from '@/containers/invest-object-form/context/formStatusContext'

const AdminInvestObjectFormProps = {
  multiInputs: PropTypes.array,
  formPropsMulti: PropTypes.object,
  generalInputs: PropTypes.array,
  formPropsGeneral: PropTypes.object,
  enabledForms: PropTypes.bool,
  onFormEnable: PropTypes.func,
  setGeneralValidations: PropTypes.func,
  setMultiLangValidation: PropTypes.func
}
const AdminInvestObjectForm = ({ multiInputs, formPropsMulti, generalInputs, formPropsGeneral }) => {
  const { t } = useTranslation()

  const clearLandSection = () => {
    const landSectionFields = [
      'cadastralNumberStatus',
      'cadastralNumber',
      'plotArea',
      'ownershipType',
      'landCategorySection',
      'landCategoryCategory',
      'landCategoryType'
    ]
    landSectionFields.forEach((field) => formPropsGeneral.handleChange(field)({ target: { value: '' } }))
    formPropsGeneral.handleChange('landPlan')({ target: { value: false } })
    formPropsGeneral.handleFileChange('landPlanFilesIds')([])
  }

  const clearRealEstateSection = () => {
    const realEstateFields = ['realtyState', 'realtyTotalAreaSqM']
    realEstateFields.forEach((field) => formPropsGeneral.handleChange(field)({ target: { value: '' } }))
    formPropsGeneral.handleChange('realtyHasLandPlot')({ target: { value: false } })
    formPropsGeneral.handleChange('realtyType')({ target: { value: [] } })
  }

  const handleCharacteristicsTypeChange = (e) => {
    formPropsGeneral.handleChange('characteristicsType')(e)
    if (e.target.value === characteristicType.land) {
      clearRealEstateSection()
    } else {
      clearLandSection()
    }
  }

  useEffect(() => {
    if (formPropsGeneral.data.usageType !== 'OTHER') {
      formPropsMulti.handleChange('otherUsageType')({ target: { value: '' } })
    }

    if (formPropsGeneral.data.encumbrance !== 'OTHER') {
      formPropsMulti.handleChange('otherEncumbrance')({ target: { value: '' } })
    }
    // with formPropsMulti in dependency array we end up with infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPropsGeneral.data.usageType, formPropsGeneral.data.encumbrance])

  return (
    <FormStatusContext.Provider value={formPropsMulti.data.status}>
      <FormGroup sx={{ flexGrow: 1, ...styles.form }}>
        {investObjectFormHeaders.map(({ text, sx, variant }) => (
          <AppFormHeader
            key={text}
            text={text}
            variant={variant}
            sx={sx}
          />
        ))}
        {formPropsGeneral.data.usageType === 'OTHER' && (
          <TextField
            value={formPropsMulti.data.otherUsageType}
            error={Boolean(formPropsMulti.errors.otherUsageType)}
            helperText={formPropsMulti.errors.otherUsageType || ' '}
            onChange={formPropsMulti.handleChange('otherUsageType')}
            onBlur={formPropsMulti.handleBlur('otherUsageType')}
            sx={styles.inputs.otherUsageType}
            placeholder={t('investObjects.labels.defineUsageType')}
          />
        )}
        {formPropsGeneral.data.encumbrance === 'OTHER' && (
          <TextField
            value={formPropsMulti.data.otherEncumbrance}
            error={Boolean(formPropsMulti.errors.otherEncumbrance)}
            helperText={formPropsMulti.errors.otherEncumbrance || ' '}
            onChange={formPropsMulti.handleChange('otherEncumbrance')}
            onBlur={formPropsMulti.handleBlur('otherEncumbrance')}
            sx={styles.inputs.otherEncumbrance}
            placeholder={t('investObjects.labels.defineEncumbrance')}
          />
        )}
        <AppRadioGroup
          value={formPropsGeneral.data.characteristicsType}
          onChange={handleCharacteristicsTypeChange}
          error={Boolean(formPropsGeneral.errors.characteristicsType)}
          helperText={formPropsGeneral.errors.characteristicsType}
          sx={styles.inputs.characteristicsType}
          options={characteristicsTypeOptions()}
          label={i18next.t('investObjects.labels.objectType')}
          row
        />
        {(formPropsGeneral.data.characteristicsType === characteristicType.land ||
          formPropsGeneral.data.realtyHasLandPlot) && <LandPlotSection formPropsGeneral={formPropsGeneral} />}
        {formPropsGeneral.data.characteristicsType === characteristicType.realEstate && (
          <RealEstateSection formPropsGeneral={formPropsGeneral} />
        )}
        <CommunicationsSection
          formPropsGeneral={formPropsGeneral}
          formPropsMulti={formPropsMulti}
        />
        <TransportConnectionSection formPropsGeneral={formPropsGeneral} />
        <AppInputs
          inputs={multiInputs}
          {...formPropsMulti}
        />
        <AppInputs
          inputs={generalInputs}
          {...formPropsGeneral}
        />
      </FormGroup>
    </FormStatusContext.Provider>
  )
}

AdminInvestObjectForm.propTypes = AdminInvestObjectFormProps

export default AdminInvestObjectForm
