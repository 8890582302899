import { useCallback, useEffect, useMemo, useState } from 'react'
import useForm from '@/hooks/use-form'
import { useAdminEdit } from '@/hooks/admin-forms/use-admin-edit'
import i18next from 'i18next'
import { requiredField } from '@/utils/validations/common'
import { useStatusChange } from '@/hooks/admin-forms/use-status-change'
import { statusValidation } from '@/utils/form-helper'

export const useMultiLang = ({
  inputs,
  initialData,
  setInitialData,
  validations,
  publishValidation,
  submitService,
  afterSubmit,
  getFormComponent,
  getDataService,
  fallbackUrl,
  additionalValidate,
  setActiveTabIndex,
  titleField = 'title'
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [tabLanguage, setTabLanguage] = useState('uk')

  const { initialValues, loading } = useAdminEdit({
    service: getDataService,
    initialData,
    setInitialData,
    fallbackUrl
  })

  const {
    data: ukData,
    isDirty: ukIsDirty,
    handleValidate: ukHandleValidate,
    clearForm: ukClearForm,
    formProps: ukFormProps,
    setValidations: ukSetValidations
  } = useForm({
    inputs,
    initialValues: initialValues.uk,
    validations
  })

  useStatusChange({
    setValidation: ukSetValidations,
    draftValidation: validations,
    publishValidation: publishValidation,
    status: ukData.status
  })

  const {
    data: enData,
    isDirty: enIsDirty,
    handleValidate: enHandleValidate,
    clearForm: enClearForm,
    formProps: enFormProps,
    setValidations: enSetValidations
  } = useForm({
    inputs,
    initialValues: initialValues.en,
    validations
  })

  useStatusChange({
    setValidation: enSetValidations,
    draftValidation: validations,
    publishValidation: publishValidation,
    status: enData.status
  })

  const multiform = useMemo(
    () => ({
      data: { uk: { ...ukData }, en: { ...enData } },
      isDirty: Boolean(ukIsDirty || enIsDirty)
    }),
    [enData, enIsDirty, ukData, ukIsDirty]
  )

  const onLanguageChange = (tab) => {
    setTabLanguage(tab)
  }

  const clearForms = useCallback(() => {
    ukClearForm()
    enClearForm()
  }, [enClearForm, ukClearForm])

  useEffect(() => {
    // we require at least title be required on some lang
    if (tabLanguage === 'uk') {
      ukSetValidations((e) => ({ ...e, [titleField]: [...e[titleField], requiredField] }))
    } else {
      const statusValidations = statusValidation(ukData.status, validations, publishValidation)
      ukSetValidations((e) => ({ ...e, [titleField]: [...(statusValidations[titleField] ?? [])] }))
    }
    if (tabLanguage === 'en') {
      enSetValidations((e) => ({ ...e, [titleField]: [...e[titleField], requiredField] }))
    } else {
      const statusValidations = statusValidation(enData.status, validations, publishValidation)
      enSetValidations((e) => ({ ...e, [titleField]: [...(statusValidations[titleField] ?? [])] }))
    }
  }, [
    enSetValidations,
    tabLanguage,
    titleField,
    ukSetValidations,
    validations,
    publishValidation,
    enData.status,
    ukData.status
  ])

  const handleSubmit = useCallback(async () => {
    const ukIsValid = ukHandleValidate()
    const enIsValid = enHandleValidate()
    const isAdditionalValid = additionalValidate ? additionalValidate() : true

    if (!ukIsValid) {
      setTabLanguage('uk')
      setActiveTabIndex(0)
      return
    }

    if (!enIsValid) {
      setTabLanguage('en')
      setActiveTabIndex(1)
      return
    }

    if (ukIsValid && enIsValid && isAdditionalValid) {
      try {
        setIsSaving(true)
        await submitService({ ...multiform.data })
        clearForms()
        afterSubmit && afterSubmit()
      } catch (e) {
        console.error(e)
      } finally {
        setIsSaving(false)
      }
    }
  }, [
    ukHandleValidate,
    enHandleValidate,
    additionalValidate,
    setActiveTabIndex,
    submitService,
    multiform.data,
    clearForms,
    afterSubmit
  ])

  const setMultiLangValidations = useMemo(
    () => ({
      en: enSetValidations,
      uk: ukSetValidations
    }),
    [enSetValidations, ukSetValidations]
  )

  const multilangTabs = useMemo(
    () => ({
      uk: {
        value: 'uk',
        label: i18next.t('common.labels.ukr'),
        component: getFormComponent({
          lang: 'uk',
          formProps: ukFormProps,
          setMultiLangValidation: ukSetValidations
        })
      },
      en: {
        value: 'en',
        label: i18next.t('common.labels.eng'),
        component: getFormComponent({
          lang: 'en',
          formProps: enFormProps,
          setMultiLangValidation: enSetValidations
        })
      }
    }),
    [enFormProps, enSetValidations, getFormComponent, ukFormProps, ukSetValidations]
  )

  return {
    isSaving,
    tabLanguage,
    onLanguageChange,
    multilangTabs,
    handleSubmit,
    clearForms,
    multiform,
    initialValues,
    loading,
    setMultiLangValidations
  }
}
