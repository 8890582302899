import ListItemButton from '@mui/material/ListItemButton'
import AppNavLink from '@/components/app-nav-link/AppNavLink'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'

const AdminSidebarListItemLinkProps = {
  item: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.elementType,
    forAll: PropTypes.bool
  }),
  subItem: PropTypes.bool,
  children: PropTypes.node
}

const AdminSidebarListItemLink = ({ item, subItem = false, children }) => {
  return (
    <ListItemButton
      sx={{ paddingLeft: subItem ? '24px' : '' }}
      component={AppNavLink}
      activeClassName={'Mui-selected'}
      to={item.link}
      end={!item.forAll}
    >
      <ListItemIcon sx={{ minWidth: 'max-content', marginRight: '8px' }}>{item.icon}</ListItemIcon>
      <ListItemText
        primary={item.label}
        sx={{ flexGrow: 0 }}
      />
      {children}
    </ListItemButton>
  )
}
AdminSidebarListItemLink.propType = AdminSidebarListItemLinkProps

export default AdminSidebarListItemLink
