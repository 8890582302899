import React from 'react'
import { Box, Fade, IconButton, Slide } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import MainNavMenu from '@/components/main-nav-menu'
import LanguageToggle from '@/components/app-language-toggle'
import searchIcon from '@/assets/icons/search.svg'
import { responsiveGapV2 } from '@/pages/home-page/HomePage.styles'

const MobileToolbar = ({ searchOpen, setSearchOpen }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const mobileToolbarRef = React.useRef(null)

  React.useEffect(() => {
    if (mobileToolbarRef?.current) {
      if (open) {
        document.documentElement.classList.add('hide-scroll')
      } else {
        document.documentElement.classList.remove('hide-scroll')
      }
    }

    return () => {
      document.documentElement.classList.remove('hide-scroll')
    }
  }, [open])

  const toggleMenu = () => setOpen(!open)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flex: 1,
          pl: 1,
          gap: '25px'
        }}
      >
        <Box sx={{ position: 'relative', width: '24px', height: '24px' }}>
          <Fade in={searchOpen}>
            <IconButton
              size='large'
              onClick={() => {
                setSearchOpen(false)
              }}
              title={t('clientNavigation.titles.closeMenu')}
              color='inherit'
              sx={{ position: 'absolute', left: '0', top: '0', padding: '0' }}
            >
              <CloseIcon />
            </IconButton>
          </Fade>
          <Fade in={!searchOpen}>
            <IconButton
              onClick={(ev) => {
                ev.stopPropagation()
                setOpen(false)
                setSearchOpen(true)
              }}
              title={t('clientNavigation.searchbar.searchButtonTitle')}
              size='large'
              sx={{ position: 'absolute', left: '0', top: '0', padding: '0' }}
            >
              <img
                src={searchIcon}
                width={24}
                height={24}
                alt='search'
              />
            </IconButton>
          </Fade>
        </Box>

        <Box sx={{ position: 'relative', width: '24px', height: '24px' }}>
          <Fade in={open}>
            <IconButton
              size='large'
              onClick={toggleMenu}
              title={t('clientNavigation.titles.closeMenu')}
              color='inherit'
              sx={{ position: 'absolute', left: '0', top: '0', padding: '0' }}
            >
              <CloseIcon />
            </IconButton>
          </Fade>
          <Fade in={!open}>
            <IconButton
              size='large'
              onClick={toggleMenu}
              title={t('clientNavigation.titles.openMenu')}
              color='inherit'
              sx={{ position: 'absolute', left: '0', top: '0', padding: '0' }}
            >
              <MenuIcon />
            </IconButton>
          </Fade>
        </Box>
      </Box>

      <Slide
        direction='up'
        in={open}
      >
        <Box
          ref={mobileToolbarRef}
          sx={{
            backgroundColor: 'basic.white',
            overflow: 'auto',
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            height: { sm: 'calc(100vh - 90px)', xs: 'calc(100vh - 70px)' },
            transform: 'translateY(1px)',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
        >
          <MainNavMenu
            mobile
            toggleMenu={toggleMenu}
          />

          <Box
            sx={{
              py: '22px',
              px: responsiveGapV2
            }}
          >
            <LanguageToggle toggleMenu={toggleMenu} />
          </Box>
        </Box>
      </Slide>
    </>
  )
}

export default MobileToolbar
