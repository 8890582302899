import AdminNewsTable from '@/containers/admin-news/admin-news-table'
import AdminPageHeader from '@/containers/admin-page-header'
import Box from '@mui/material/Box'
import AppTabs from '@/components/app-tabs'
import { setInitialFilter } from '@/components/app-grid/utils/app-grid-utils'
import useAxios from '@/hooks/use-axios'
import { NewsService } from '@/services/news-service'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import { useTranslation } from 'react-i18next'
import { documentStatus } from '@/containers/constants'
import { useTabs } from '@/hooks/use-tabs'
import { pageWrapperSX } from '@/pages/admin/constants'

const AdminNewsPage = () => {
  const [counts, setCounts] = useState({ allPosts: 0, posted: 0, draft: 0 })
  const { t } = useTranslation()
  const { fetchData } = useAxios({ service: NewsService.countPerStatus })

  const setCountPerStatus = useCallback(async () => {
    const { total, PUBLISHED, DRAFT } = await fetchData()
    setCounts({ allPosts: total, posted: PUBLISHED, draft: DRAFT })
  }, [fetchData])

  const newsListTabs = useMemo(
    () => ({
      allPosts: {
        value: 'allPosts',
        label: 'Всі пости',
        component: <AdminNewsTable onTableRefresh={setCountPerStatus} />
      },
      posted: {
        value: 'posted',
        label: 'Опубліковані',
        component: (
          <AdminNewsTable
            onTableRefresh={setCountPerStatus}
            filterBy={setInitialFilter('status', documentStatus.PUBLISHED)}
          />
        )
      },
      draft: {
        value: 'draft',
        label: 'Чорновик',
        component: (
          <AdminNewsTable
            onTableRefresh={setCountPerStatus}
            filterBy={setInitialFilter('status', documentStatus.DRAFT)}
          />
        )
      }
    }),
    [setCountPerStatus]
  )
  const { tabIndex, handleTabChange } = useTabs({ tabs: newsListTabs })

  return (
    <Box
      className='admin-news-page'
      sx={{ ...pageWrapperSX }}
    >
      <AdminPageHeader
        title={t('news.allNews')}
        buttonLabel={t('news.createNews')}
        onClick={setCountPerStatus}
        buttonProps={{ component: Link, to: adminRoutes.createNews.path }}
      />
      <AppTabs
        counts={counts}
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        tabs={newsListTabs}
        stayAlive={false}
      />
    </Box>
  )
}

export default AdminNewsPage
