import palette from '@/plugins/material-ui/palette'
import typography from '@/plugins/material-ui/typography'

const chip = {
  styleOverrides: {
    root: {
      borderRadius: '28px',
      border: '1px solid ' + palette.primary.main
    },
    label: {
      fontSize: '14px',
      fontWeight: 500
    },
    outlined: {
      height: '46px',
      paddingLeft: '22px',
      paddingRight: '22px',
      color: palette.text.primary,
      transition: 'all 0.2s linear',
      ...typography.body1,
      '&:hover': {
        backgroundColor: palette.primary.light + ' !important',
        color: '#FFFFFF'
      },
      '&.active-chip': {
        backgroundColor: palette.primary.main,
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: palette.primary.light,
          color: '#FFFFFF'
        }
      }
    },
    filled: {
      backgroundColor: palette.background.light,
      border: 0,
      borderRadius: 0,
      padding: '3px 12px',
      color: palette.text.primary,
      transition: 'all 0.2s linear',
      ...typography.body1,
      '&:hover': {
        backgroundColor: palette.primary.light,
        color: '#FFFFFF'
      },
      '&.active-chip': {
        backgroundColor: palette.primary.main,
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: palette.primary.light,
          color: '#FFFFFF'
        }
      }
    }
  }
}

export default chip
