import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PersonIcon from '@mui/icons-material/Person'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import AppGrid from '@/components/app-grid'

import {
  roleSelectionInputs,
  statusSelectionInputs,
  usersTableColumns,
  usersTableSettings
} from '@/containers/admin-users/admin-users-table/constants'
import { UsersService } from '@/services/users-service'
import { useAppPopupDialog } from '@/context/app-popup-dialog-context'
import AppFormConfirm from '@/components/app-confirm/app-form-cofirm/AppFormConfirm'
import useAxios from '@/hooks/use-axios'
import { useAuth } from '@/context/auth-context'
import { useSnackbar } from 'notistack'
import { useConfirm } from '@/hooks/use-confirm'
import { Box, Typography } from '@mui/material'

const AdminUsersTableProps = {
  filterBy: PropTypes.object,
  onTableRefresh: PropTypes.func
}

const AdminUsersTable = ({ filterBy, onTableRefresh }) => {
  const { t } = useTranslation()
  const { openModal, closeModal } = useAppPopupDialog()
  const { activeUserId } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { confirm } = useConfirm()
  const loadUsers = async (params) => {
    return await UsersService.grid(params)
  }

  const { confirm: openConfirmDialog } = useConfirm()

  const { fetchData: sendData } = useAxios({ service: UsersService.patchUser })
  const { fetchData: activate } = useAxios({ service: UsersService.bulkActivate })
  const { fetchData: deactivate } = useAxios({ service: UsersService.bulkInActivate })

  const tableService = { load: loadUsers }

  const roleSubmit = useCallback(
    async (patchedUser, action) => {
      const text_status = 'Чи дійсно Ви хочете змінити статус адміністратора?'
      const text_role = 'Чи дійсно Ви хочете змінити роль користувача на адміністратор'

      const content = (
        <Box>
          <Typography>{action === 'CHANGE_STATUS' ? text_status : text_role}</Typography>
          <Typography>{patchedUser.email}</Typography>
          <Typography>{patchedUser.firstName}</Typography>
        </Box>
      )

      if (patchedUser.role === 'ADMIN' && !(await openConfirmDialog('', content))) {
        return
      }
      return sendData(patchedUser)
    },
    [openConfirmDialog, sendData]
  )

  const confirmedAction = async (selected, service, message) => {
    const confirmed = await confirm(message, null)
    if (confirmed) {
      return await service({ ids: selected })
    }
  }

  const bulkActions = {
    activate: {
      icon: <PersonIcon />,
      label: t('users.activate'),
      onClick: (selected) => confirmedAction(selected, activate, t('dialog.activateMessage'))
    },
    inActivate: {
      icon: <PersonOffIcon />,
      label: t('users.inActivate'),
      onClick: (selected) => confirmedAction(selected, deactivate, t('dialog.inActivateMessage'))
    }
  }

  const rowActions = {
    changeStatus: {
      label: t('users.updateUserStatus'),
      fullValue: true,
      onClick: async ([user]) => {
        if (user.id === activeUserId) {
          return enqueueSnackbar(t('users.updateUserStatusNotAllowed'), { variant: 'info' })
        }
        openModal({
          content: (
            <AppFormConfirm
              inputs={statusSelectionInputs}
              text={t('users.updateUserStatus')}
              initialData={user}
              submit={(data) => roleSubmit(data, 'CHANGE_STATUS')}
              closeModal={closeModal}
            />
          )
        })
      }
    },
    changeRole: {
      label: t('users.updateRole'),
      fullValue: true,
      onClick: async ([user]) => {
        if (user.id === activeUserId) {
          return enqueueSnackbar(t('users.updateRoleNotAllowed'), { variant: 'info' })
        }
        openModal({
          content: (
            <AppFormConfirm
              inputs={roleSelectionInputs}
              text={t('users.updateRole')}
              initialData={user}
              submit={(data) => roleSubmit(data, 'CHANGE_ROLE')}
              closeModal={closeModal}
            />
          )
        })
      }
    }
  }

  return (
    <AppGrid
      columns={usersTableColumns}
      service={tableService}
      bulkActions={bulkActions}
      rowActions={rowActions}
      settings={usersTableSettings}
      externalFilters={filterBy}
      onTableRefresh={onTableRefresh}
    />
  )
}

AdminUsersTable.propTypes = AdminUsersTableProps

export default AdminUsersTable
