import { NavLink as BaseNavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const AppNavLinkProps = {
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object
}

const AppNavLink = forwardRef(({ activeClassName, activeStyle, ...props }, ref) => {
  return (
    <BaseNavLink
      ref={ref}
      {...props}
      className={({ isActive }) => [props.className, isActive ? activeClassName : null].filter(Boolean).join(' ')}
      style={({ isActive }) => ({
        ...props.style,
        ...(isActive ? activeStyle : null)
      })}
    />
  )
})

AppNavLink.displayName = 'AppNavLink'
AppNavLink.propTypes = AppNavLinkProps

export default AppNavLink
