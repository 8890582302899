import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { baseContainerSX } from '@/containers/constants'

import countersGrants from '@/assets/images/counters-grants.png'
import clientsGrants from '@/assets/images/clients-grants.png'
import moneyGrants from '@/assets/images/money-grants.png'
import useBreakpoints from '@/hooks/use-breakpoints'

const ProjectCounterBanner = () => {
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()

  const items = [
    {
      img: clientsGrants,
      count: '2000+ ',
      text: 'helpBusiness.counters.clients'
    },
    {
      img: countersGrants,
      count: '110',
      text: 'helpBusiness.counters.grants'
    },
    {
      img: moneyGrants,
      count: t('helpBusiness.counters.sumNum'),
      countPart: t('helpBusiness.counters.sumNumPart'),
      text: 'helpBusiness.counters.sum'
    }
  ]

  return (
    <Box
      sx={{
        backgroundColor: ({ palette }) => palette.primary.main,
        pt: { md: '110px', sm: '40px', xs: '40px' },
        pb: { md: '142px', sm: '30px', xs: '40px' },
        whiteSpace: 'pre-line'
      }}
    >
      <Box sx={{ ...baseContainerSX, alignItems: 'center' }}>
        <Typography
          flex={1}
          variant='h2'
          sx={{ mb: '40px', textAlign: 'center', px: '30px' }}
          color='#FFFFFF'
        >
          {t('helpBusiness.counters.title')}
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gap: { md: '90px', sm: '50px', xs: '30px' },
            gridTemplateColumns: { md: '1fr 1fr 1fr', sm: '1fr 1fr 1fr', xs: '1fr 1fr' },
            justifyItems: 'center'
          }}
        >
          {items.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: { md: '300px', sm: '195px', xs: '152px' },
                paddingLeft: isDesktop ? '25px' : '5px',
                paddingRight: isDesktop ? '25px' : '5px'
              }}
            >
              <img
                style={{
                  width: '80%',
                  objectFit: 'cover',
                  marginBottom: '20px'
                }}
                src={item.img}
                alt={item.text}
              />
              {
                <Typography
                  variant='h1'
                  color='#FFFFFF'
                  style={{
                    fontSize: isDesktop ? '35px' : '20px'
                  }}
                >
                  {item.count}
                </Typography>
              }

              <Typography
                variant='body1'
                color='#FFFFFF'
                sx={{ textAlign: 'center' }}
              >
                {t(item.text)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default ProjectCounterBanner
