import { Box, FormGroup, Grid, Typography, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import React, { useEffect, useMemo } from 'react'
import AppForm from '@/components/app-form'
import {
  communicationList,
  modalFormInputs,
  emailInputs, phoneInputs, contactFormValidations, contactFormValidations2
} from '@/containers/appeal-form-modal/constants'
import AppInputs from '@/components/app-form/app-inputs'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import useForm from '@/hooks/use-form'
import useAxios from '@/hooks/use-axios'
import { ContactService } from '@/services/contact-service'

export const AppealForm = ({ formInitialData, communicationType, onHandleType, visible, activeSubmit, handleClose }) => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { fetchData: contactRequest, loading } = useAxios({ service: ContactService.request })
  const {
    data,
    formProps: { inputs, ...rest },
    handleValidate,
    setValidations: setGeneralValidations,
  } = useForm({
    inputs: modalFormInputs(),
    initialValues: formInitialData,
    validations: contactFormValidations
  })
  useEffect(() => {
    if (communicationType === 'email') {
      setGeneralValidations(() => ({
        ...contactFormValidations,
      }))
    } else {
      setGeneralValidations(() => ({
        ...contactFormValidations2,
      }))
    }
  }, [communicationType, setGeneralValidations])
  const onSubmit = async () => {
    if (handleValidate()) {
      try {
        const {
          // eslint-disable-next-line no-shadow
          email,
          phone,
          description,
        } = data
        let sendField
        communicationType === 'email' ? sendField = { email } : sendField = { phone }
        const newData = {
          ...sendField,
          description,
          pageUrl: window.location.href
        }
        await contactRequest(newData)
        enqueueSnackbar(t('contactForm.successMessage'), { variant: 'success' })
        handleClose()
      } catch (error) {
        enqueueSnackbar(t(`error.${error?.response?.status}`), { variant: 'error' })
      }
    }
  }
  const communicationTypesItems = useMemo(() => {
    return communicationList.map((item) => (
      <Grid item key={item}>
        <Button
          variant={communicationType === item ? 'contained':  'outlined'} color={'success'} onClick={() => onHandleType(item)}
          sx={{ borderRadius: '28px', height: '34px', marginRight: '16px', padding: '4px 17px' }}
        >
          {t(`modalForm.${item}`)}
        </Button>
      </Grid>
    ))
  }, [onHandleType, communicationType, t])

  return (
    <AppForm onFormSubmit={onSubmit}>
      <FormGroup sx={{ marginTop: theme.spacing(2) }}>
        <AppInputs
          inputs={modalFormInputs()}
          {...rest}
        />
      </FormGroup>
      <Typography sx={{ my: '4px' }}>{t('modalForm.communication')}</Typography>
      <Box  variant="h6" sx={{ display: 'flex', borderRadius: '28px' }}>{ communicationTypesItems }</Box>
      <FormGroup sx={{ visibility: visible, flexGrow: 1, marginTop: theme.spacing(2) }}>
        <AppInputs
          inputs={communicationType === 'email' ? emailInputs : phoneInputs}
          {...rest}
        />
      </FormGroup>
      <Button
        variant={'contained'}
        type='submit'
        disabled={loading || !activeSubmit}
        sx={{ width: '100%', marginTop: '5px' }}
      >
        {t('forms.send')}
      </Button>
    </AppForm>
  )
}

export default AppealForm
