import AppGrid from '@/components/app-grid'
import PropTypes from 'prop-types'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { adminRoutes } from '@/plugins/router/admin-router/routes'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'
import { useConfirm } from '@/hooks/use-confirm'
import {
  investObjectTableColumns,
  investObjectTableSettings
} from '@/containers/admin-invest-object/admin-invest-object-table/constants'
import { InvestObjectService } from '@/services/invest-object-service'

const AdminInvestObjectTable = ({ filterBy, onTableRefresh }) => {
  const navigate = useNavigate()
  const { confirm } = useConfirm()

  const editObjects = async (id) => {
    navigate(adminRoutes.editInvestObject.path + `/${id}`)
  }

  const deleteObjects = async (selected) => {
    await confirm(i18next.t('dialog.delete_message')) && await InvestObjectService.bulkDeletion({ ids: selected })
  }

  const loadObjects = async (params) => {
    return await InvestObjectService.grid(params)
  }

  const tableService = { load: loadObjects }

  const bulkActions = {
    deletion: {
      icon: <DeleteIcon />,
      label: 'Видалити',
      onClick: deleteObjects
    }
  }

  const rowActions = {
    edit: {
      icon: <EditIcon />,
      label: 'Редагувати',
      onClick: editObjects
    },
    deletion: {
      icon: <DeleteIcon />,
      label: 'Видалити',
      onClick: deleteObjects
    },
  }

  return (
    <AppGrid
      columns={investObjectTableColumns}
      service={tableService}
      settings={investObjectTableSettings}
      rowActions={rowActions}
      bulkActions={bulkActions}
      externalFilters={filterBy}
      onTableRefresh={onTableRefresh}
    />
  )
}

AdminInvestObjectTable.propTypes = {
  filterBy: PropTypes.object,
  onTableRefresh: PropTypes.func
}

export default AdminInvestObjectTable
