import { Box } from '@mui/material'
import AppDatepicker from '@/components/app-date-picker'
import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { endDateMax, startDateMin } from '@/utils/validations/date-range'

const AppRangeDatepicker = ({
  value,
  labels = { start: '', end: '' },
  errors = {},
  required = {},
  helperTexts,
  onBlur,
  onChange,
  sx = {}
}) => {
  const handleChange = ({ startDate = value.startDate, endDate = value.endDate }) => {
    onChange({ startDate, endDate })
  }

  const handleBlur = (from) => (e) => {
    const toBlur = { [from]: e }
    onBlur(toBlur)
  }

  const formattedValue = useMemo(() => {
    return {
      startDate: value.startDate ? DateTime.fromISO(value.startDate) : null,
      endDate: value.endDate ? DateTime.fromISO(value.endDate) : null
    }
  }, [value.startDate, value.endDate])

  const maxAvailableStartDate = value.endDate ? formattedValue.endDate : endDateMax
  const minAvailableEndDate = value.startDate ? formattedValue.startDate : startDateMin

  return (
    <Box sx={{ display: 'flex', ...sx }} autoComplete="off">
      <AppDatepicker
        fullDate
        label={labels.start}
        required={required.start}
        value={formattedValue.startDate}
        maxValue={maxAvailableStartDate}
        minValue={startDateMin}
        onChange={(startDate) => handleChange({ startDate })}
        onBlur={handleBlur('startDate')}
        error={!!errors?.startDate}
        helperText={helperTexts?.startDate}
        sx={{ width: '100%' }}
      />
      <Box sx={{ mx: '5px', mt:'20px' }}>
        <div>-</div>
      </Box>
      <AppDatepicker
        fullDate
        required={required.end}
        label={labels.end}
        value={formattedValue.endDate}
        minValue={minAvailableEndDate}
        maxValue={endDateMax}
        onChange={(endDate) => handleChange({ endDate })}
        onBlur={handleBlur('endDate')}
        error={!!errors.endDate}
        helperText={helperTexts.endDate}
        disablePast
        sx={{ width: '100%' }}
      />
    </Box>
  )
}

export default AppRangeDatepicker
