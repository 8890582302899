import i18next from 'i18next'
import { styles } from '@/containers/admin-invest-object/admin-invest-object-form/admin-invest-object-form-styles'
import { email, minMaxLength, requiredField, ukrainianPhoneNumber, validRegExp } from '@/utils/validations/common'
import { objectCodeFormat } from '@/utils/validations/invest-object'
import { documentStatus, documentStatusField } from '@/containers/constants'
import {
  areaPattern,
  cadastralNumberPattern,
  latitudeLongtitudePattern,
  onlyDigits,
  postalIndexPattern,
  realtyTotalAreaSqMPattern
} from '@/constants/default'
import { transportSectionValidations } from '@/containers/admin-invest-object/admin-invest-object-form/transport-connection-section/constants'
import InvestObjectFormAddressSectionMap from '@/containers/invest-object-form/sections/address-section/partials/InvestObjectFormAddressSectionMap'

export const investObjectStateOptions = [
  { value: 'ACTIVE', text: i18next.t('investObjects.labels.stateOptions.active') },
  { value: 'ON_PROZORRO_SALE_AUCTION', text: i18next.t('investObjects.labels.stateOptions.onProzzoroSaleAuction') },
  { value: 'SOLD', text: i18next.t('investObjects.labels.stateOptions.sold') },
  { value: 'RENTED_OUT', text: i18next.t('investObjects.labels.stateOptions.rentedOut') },
  { value: 'ARCHIVED', text: i18next.t('investObjects.labels.stateOptions.archived') },
  { value: 'IRRELEVANT', text: i18next.t('investObjects.labels.stateOptions.irrelevant') }
]

export const objectMostSuitableForOptions = [
  { value: 'AGRO_INDUSTRIAL', text: i18next.t('investObjects.labels.suitableForOptions.agroIndustrial') },
  { value: 'CONSTRUCTION', text: i18next.t('investObjects.labels.suitableForOptions.construction') },
  {
    value: 'WOODWORKING_AND_FURNITURE',
    text: i18next.t('investObjects.labels.suitableForOptions.woodworkingAndFurniture')
  },
  { value: 'LIGHT_INDUSTRY', text: i18next.t('investObjects.labels.suitableForOptions.lightIndustry') },
  {
    value: 'MECHANICAL_ENGINEERING_AND_METALWORKING',
    text: i18next.t('investObjects.labels.suitableForOptions.mechanicalEngineeringAndMetalworking')
  },
  { value: 'FEC_AND_ELECTRIC_POWER', text: i18next.t('investObjects.labels.suitableForOptions.fecAndElectricPower') },
  { value: 'TRANSPORT_AND_LOGISTICS', text: i18next.t('investObjects.labels.suitableForOptions.transportAndLogistic') },
  { value: 'TOURISM', text: i18next.t('investObjects.labels.suitableForOptions.tourism') },
  { value: 'FOOD', text: i18next.t('investObjects.labels.suitableForOptions.food') },
  {
    value: 'CHEMICAL_AND_PETROCHEMICAL',
    text: i18next.t('investObjects.labels.suitableForOptions.chemicalAndPetrochemical')
  },
  {
    value: 'MEDICINE_AND_PHARMACEUTICALS',
    text: i18next.t('investObjects.labels.suitableForOptions.medicineAndPharmaceutical')
  },
  { value: 'IT', text: i18next.t('investObjects.labels.suitableForOptions.it') },
  { value: 'INDUSTRIAL_PARKS', text: i18next.t('investObjects.labels.suitableForOptions.industrialParks') },
  { value: 'OTHER', text: i18next.t('investObjects.labels.other') }
]

export const characteristicType = {
  land: 'LAND_PLOT',
  realEstate: 'REAL_ESTATE'
}
export const characteristicsTypeOptions = () => [
  { value: characteristicType.land, label: i18next.t('investObjects.labels.objectTypeOptions.landPlot') },
  { value: characteristicType.realEstate, label: i18next.t('investObjects.labels.objectTypeOptions.realEstate') }
]

export const usageType = {
  sale: 'SALE',
  rent: 'RENT',
  saleOrRent: 'SALE_OR_RENT',
  other: 'OTHER'
}
export const usageTypeOptions = () => [
  { value: 'SALE', text: i18next.t('investObjects.labels.usageTypeOptions.sale') },
  { value: 'RENT', text: i18next.t('investObjects.labels.usageTypeOptions.rent') },
  { value: 'SALE_OR_RENT', text: i18next.t('investObjects.labels.usageTypeOptions.saleOrRent') },
  { value: 'OTHER', text: i18next.t('investObjects.labels.other') }
]
export const portalUsageTypeOptions = () => [
  ...usageTypeOptions().filter(({ value }) => value !== usageType.sale),
  { value: 'SALE', text: i18next.t('investObjects.labels.usageTypeOptions.salePortal') }
]

export const encumbranceOptions = () => [
  { value: 'NONE', text: i18next.t('investObjects.labels.encumbranceOptions.none') },
  { value: 'ARREST', text: i18next.t('investObjects.labels.encumbranceOptions.arrest') },
  { value: 'MORTGAGE', text: i18next.t('investObjects.labels.encumbranceOptions.mortgage') },
  { value: 'OTHER', text: i18next.t('investObjects.labels.other') }
]

export const investObjectFormHeaders = [
  {
    text: i18next.t('investObjects.headers.generalInformation'),
    variant: 'h4',
    sx: styles.headers.generalInformation
  },
  {
    text: i18next.t('investObjects.headers.characteristic'),
    variant: 'h4',
    sx: styles.headers.characteristic
  },
  {
    text: i18next.t('investObjects.headers.address'),
    variant: 'h4',
    sx: styles.headers.address
  },
  {
    text: i18next.t('investObjects.headers.communications'),
    variant: 'h4',
    sx: styles.headers.communications
  },
  {
    text: i18next.t('investObjects.headers.transportConnections'),
    variant: 'h4',
    sx: styles.headers.transportConnections
  },
  {
    text: i18next.t('investObjects.labels.roads'),
    variant: 'h5',
    sx: styles.headers.roads
  },
  {
    text: i18next.t('investObjects.labels.railroads'),
    variant: 'h5',
    sx: styles.headers.railroads
  },
  {
    text: i18next.t('investObjects.labels.internationalAndStateRoads.title'),
    variant: 'h5',
    sx: styles.headers.internationalAndStateRoads
  },
  {
    text: i18next.t('investObjects.labels.borderCrossingPoints.title'),
    variant: 'h5',
    sx: styles.headers.borderCrossingPoints
  },
  {
    text: i18next.t('investObjects.headers.contactPerson'),
    variant: 'h4',
    sx: styles.headers.contactPerson
  }
]

export const investObjectFormGeneralInputs = [
  {
    type: 'select',
    options: {
      name: 'state',
      required: true,
      label: i18next.t('investObjects.labels.state'),
      sx: styles.inputs.state,
      SelectProps: {
        options: investObjectStateOptions
      }
    }
  },
  {
    type: 'select',
    options: {
      name: 'domain',
      label: i18next.t('investObjects.labels.objectSuitableFor'),
      sx: styles.inputs.domain,
      SelectProps: {
        multiple: true,
        options: objectMostSuitableForOptions
      }
    }
  },
  {
    type: 'text',
    options: {
      name: 'code',
      label: i18next.t('investObjects.labels.objectCode'),
      sx: styles.inputs.code
    }
  },
  {
    type: 'textEditor',
    options: {
      name: 'note',
      sx: styles.inputs.note,
      header: i18next.t('investObjects.labels.notes')
    }
  },
  {
    type: 'multipleImageUploader',
    options: {
      name: 'imageIds',
      sx: styles.inputs.images,
      header: i18next.t('investObjects.labels.images'),
      maxFilesCount: 15,
      maxFileSize: '5MB'
    }
  },
  {
    type: 'multipleFileUploader',
    options: {
      name: 'documentIds',
      sx: styles.inputs.documents,
      header: i18next.t('investObjects.labels.documents'),
      maxFilesCount: 10,
      maxFileSize: '10MB'
    }
  },
  {
    type: 'select',
    options: {
      name: 'usageType',
      label: i18next.t('investObjects.labels.usageType'),
      sx: styles.inputs.usageType,
      SelectProps: {
        options: usageTypeOptions()
      }
    }
  },
  {
    type: 'text',
    options: {
      name: 'workersCount',
      label: i18next.t('investObjects.labels.workersCount'),
      sx: styles.inputs.workersCount
    }
  },
  {
    type: 'select',
    options: {
      name: 'encumbrance',
      label: i18next.t('investObjects.labels.encumbranceRequired'),
      sx: styles.inputs.encumbrance,
      SelectProps: {
        options: encumbranceOptions()
      }
    }
  },
  {
    type: 'text',
    options: {
      name: 'url',
      label: i18next.t('investObjects.labels.virtualStorage'),
      sx: styles.inputs.url
    }
  },
  {
    type: 'text',
    options: {
      name: 'buildingNumber',
      label: i18next.t('investObjects.labels.buildingNumber'),
      sx: styles.inputs.buildingNumber
    }
  },
  {
    type: 'text',
    options: {
      name: 'index',
      label: i18next.t('investObjects.labels.index'),
      sx: styles.inputs.index
    }
  },
  {
    type: 'custom',
    component: InvestObjectFormAddressSectionMap,
    options: {
      name: 'map',
      sx: styles.inputs.map
    }
  },
  {
    type: 'text',
    options: {
      name: 'contactsEmail',
      label: i18next.t('investObjects.labels.email'),
      sx: styles.inputs.contactsEmail
    }
  },
  {
    type: 'maskInput',
    options: {
      name: 'contactsPhone',
      label: i18next.t('investObjects.labels.phoneNumber'),
      mask: '{+38}0000000000',
      sx: styles.inputs.contactsPhone
    }
  }
]

export const investObjectGeneralDraftValidations = {
  state: [],
  code: [objectCodeFormat],
  note: [minMaxLength(0, 1000)],
  characteristicsType: [],
  usageType: [],
  workersCount: [
    validRegExp({ regexp: onlyDigits(0, 10), errorMsg: i18next.t('investObjects.errors.workerCountFormat') })
  ],
  encumbrance: [],
  buildingNumber: [minMaxLength(0, 10)],
  index: [validRegExp({ regexp: postalIndexPattern, errorMsg: i18next.t('common.errorMessages.postalIndexError') })],
  latitude: [
    validRegExp({
      regexp: latitudeLongtitudePattern,
      errorMsg: i18next.t('common.errorMessages.latitudeAndLongitudeError')
    })
  ],
  longitude: [
    validRegExp({
      regexp: latitudeLongtitudePattern,
      errorMsg: i18next.t('common.errorMessages.latitudeAndLongitudeError')
    })
  ],
  internationalAndStateRoads: [transportSectionValidations],
  borderCrossingPoints: [transportSectionValidations],
  contactsPhone: [ukrainianPhoneNumber],
  contactsEmail: [email],
  realtyTotalAreaSqM: [
    validRegExp({
      regexp: realtyTotalAreaSqMPattern,
      errorMsg: i18next.t('investObjects.errors.realtyTotalAreaFormat')
    })
  ]
}

export const investObjectGeneralValidations = {
  state: [requiredField],
  code: [objectCodeFormat],
  note: [minMaxLength(0, 1000)],
  characteristicsType: [requiredField],

  usageType: [requiredField],
  workersCount: [
    validRegExp({ regexp: onlyDigits(0, 10), errorMsg: i18next.t('investObjects.errors.workerCountFormat') })
  ],
  encumbrance: [requiredField],
  buildingNumber: [minMaxLength(0, 10)],
  index: [validRegExp({ regexp: postalIndexPattern, errorMsg: i18next.t('common.errorMessages.postalIndexError') })],
  latitude: [
    requiredField,
    validRegExp({
      regexp: latitudeLongtitudePattern,
      errorMsg: i18next.t('common.errorMessages.latitudeAndLongitudeError')
    })
  ],
  longitude: [
    requiredField,
    validRegExp({
      regexp: latitudeLongtitudePattern,
      errorMsg: i18next.t('common.errorMessages.latitudeAndLongitudeError')
    })
  ],
  internationalAndStateRoads: [transportSectionValidations],
  borderCrossingPoints: [transportSectionValidations],
  contactsPhone: [ukrainianPhoneNumber, requiredField],
  contactsEmail: [email],
  cadastralNumber: [
    //additional validations for cadastralNumber are set in the AdminInvestObjectEdit component
    validRegExp({
      regexp: cadastralNumberPattern,
      errorMsg: i18next.t('investObjects.errors.cadastralNumberFormat')
    })
  ],
  plotArea: [validRegExp({ regexp: areaPattern, errorMsg: i18next.t('investObjects.errors.landPlotAreaFormat') })],
  realtyTotalAreaSqM: [
    validRegExp({
      regexp: realtyTotalAreaSqMPattern,
      errorMsg: i18next.t('investObjects.errors.realtyTotalAreaFormat')
    })
  ]
}

export const investObjectFormMultiLangInputs = [
  documentStatusField({ sx: styles.inputs.status }),
  {
    type: 'text',
    options: {
      name: 'name',
      label: i18next.t('investObjects.labels.objectName'),
      sx: styles.inputs.name
    }
  },
  {
    type: 'textEditor',
    options: {
      name: 'description',
      header: i18next.t('investObjects.labels.objectDescription'),
      sx: styles.inputs.description
    }
  },
  {
    type: 'text',
    options: {
      name: 'previousUsage',
      label: i18next.t('investObjects.labels.previousUsage'),
      placeholder: i18next.t('investObjects.labels.describePreviousUsage'),
      multiline: true,
      maxRows: 5,
      minRows: 5,
      sx: styles.inputs.previousUsage
    }
  },
  {
    type: 'text',
    options: {
      name: 'settlement',
      label: i18next.t('investObjects.labels.settlement'),
      sx: styles.inputs.settlement
    }
  },
  {
    type: 'text',
    options: {
      name: 'community',
      label: i18next.t('investObjects.labels.community'),
      sx: styles.inputs.community
    }
  },
  {
    type: 'text',
    options: {
      name: 'district',
      label: i18next.t('investObjects.labels.district'),
      sx: styles.inputs.district
    }
  },
  {
    type: 'text',
    options: {
      name: 'street',
      label: i18next.t('investObjects.labels.street'),
      sx: styles.inputs.street
    }
  },
  {
    type: 'text',
    options: {
      name: 'roadSurfaceType',
      label: i18next.t('investObjects.labels.roadSurfaceType'),
      sx: styles.inputs.roadSurfaceType
    }
  },
  {
    type: 'text',
    options: {
      name: 'contactsFullName',
      label: i18next.t('investObjects.labels.fullName'),
      sx: styles.inputs.contactsFullName
    }
  }
]

export const investObjectMultiLangDraftValidations = {
  name: [minMaxLength(0, 200)],
  description: [minMaxLength(0, 1500)],
  settlement: [minMaxLength(0, 100)],
  community: [minMaxLength(0, 100)],
  district: [minMaxLength(0, 100)],
  street: [minMaxLength(0, 100)],
  roadSurfaceType: [minMaxLength(0, 50)],
  previousUsage: [minMaxLength(0, 1000)]
  // otherUsageType and otherEncumbrance validations will be set on the level of AdminInvestObjectEdit component
}

export const investObjectMultiLangValidations = {
  name: [requiredField, minMaxLength(0, 200)],
  description: [minMaxLength(0, 1500)],
  settlement: [requiredField, minMaxLength(0, 100)],
  community: [requiredField, minMaxLength(0, 100)],
  district: [requiredField, minMaxLength(0, 100)],
  street: [minMaxLength(0, 100)],
  roadSurfaceType: [requiredField, minMaxLength(0, 50)],
  contactsFullName: [requiredField],
  previousUsage: [minMaxLength(0, 1000)]
  // otherUsageType and otherEncumbrance validations will be set on the level of AdminInvestObjectEdit component
}

export const investObjectFormInitialValues = {
  global: {
    state: investObjectStateOptions[0].value,
    domain: [],
    code: '',
    note: '',
    imageIds: [],
    documentIds: [],
    characteristicsType: characteristicType.land,
    usageType: '',
    workersCount: '',
    encumbrance: '',
    url: '',
    buildingNumber: '',
    index: '',
    latitude: '',
    longitude: '',
    cadastralNumberStatus: '',
    cadastralNumber: '',
    plotArea: '',
    ownershipType: '',
    landCategorySection: '',
    landCategoryCategory: '',
    landCategoryType: '',
    landPlan: false,
    landPlanFilesIds: [],
    realtyType: [],
    realtyState: '',
    realtyTotalAreaSqM: '',
    realtyHasLandPlot: false,
    powerSupply: false,
    powerSupplyKW: '',
    nearestPowerSupplyKM: '',
    gasSupply: false,
    nearestGasSupplyKM: '',
    waterSupply: false,
    nearestWaterSupplyKM: '',
    drainage: false,
    nearestDrainageKM: '',
    railroad: false,
    internationalAndStateRoads: [],
    borderCrossingPoints: [],
    contactsEmail: '',
    contactsPhone: ''
  },
  en: {
    status: documentStatus.DRAFT,
    name: '',
    description: '',
    otherUsageType: '',
    otherEncumbrance: '',
    previousUsage: '',
    settlement: '',
    community: '',
    district: '',
    street: '',
    waterSupplyType: '',
    drainageType: '',
    roadSurfaceType: '',
    contactsFullName: ''
  },
  uk: {
    status: documentStatus.DRAFT,
    name: '',
    description: '',
    otherUsageType: '',
    otherEncumbrance: '',
    previousUsage: '',
    settlement: '',
    community: '',
    district: '',
    street: '',
    waterSupplyType: '',
    drainageType: '',
    roadSurfaceType: '',
    contactsFullName: ''
  }
}
