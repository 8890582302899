import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import { theme } from '@/plugins/material-ui/theme'
import { useTranslation } from 'react-i18next'

const AppRadioGroup = ({ options, label, value, error, helperText, onChange, onBlur, row, sx }) => {
  const { t } = useTranslation()
  return (
    <FormControl
      error={error}
      sx={{ mb: theme.spacing(1), ...sx }}
    >
      <FormLabel id='radio-buttons-group-label'>{label}</FormLabel>
      <RadioGroup
        aria-labelledby='radio-buttons-group-label'
        row={row}
        value={value}
        name='radio-buttons-group'
        onChange={onChange}
        onBlur={onBlur}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={t(option.label)}
          />
        ))}
      </RadioGroup>
      <FormHelperText sx={{ height: '20px', ml: 0, mt: 0 }}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default AppRadioGroup
