import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'

export const AppealService = {
  grid: (params) => {
    return request.post(URLs.admin.appeals.search, { ...params })
  },
  countPerStatus: () => {
    return request.get(URLs.admin.appeals.countPerStatus)
  },
  export: () => {
    return request.get(URLs.admin.appeals.export)
  },
  bulkDeletion: (params) => {
    return request.post(URLs.admin.appeals.bulk.delete, { ...params })
  },
  bulkExport: (params) => {
    return request.post(URLs.admin.appeals.bulk.export, { ...params })
  }
}
