import { Box, Skeleton, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const componentProps = {
  icon: PropTypes.element,
  text: PropTypes.shape({ variant: PropTypes.string, value: PropTypes.string, color: PropTypes.string }),
  sx: PropTypes.object,
  loading: PropTypes.bool
}

const AppTextWithIcon = ({ icon, text, sx, loading }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      {loading ? <Skeleton variant={'rounded'} width={10} height={10} /> : icon}
      <Typography
        sx={{ ml: '16px' }}
        variant={text.variant}
        color={text.color}
      >
        {loading ? <Skeleton width={30} /> : text.value}
      </Typography>
    </Box>
  )
}

AppTextWithIcon.propType = componentProps

export default AppTextWithIcon
