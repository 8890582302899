import FileUploader from '@/components/file-uploader'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import palette from '@/plugins/material-ui/palette'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const AppDragAndDropProps = {
  maxFilesCount: PropTypes.number,
  value: PropTypes.array.isRequired,
  onValueChange: PropTypes.func.isRequired,
  accept: PropTypes.array.isRequired,
  maxFileSize: PropTypes.string
}

const AppDragAndDrop = ({ onValueChange, value, maxFilesCount, accept, maxFileSize }) => {
  const { t } = useTranslation()
  const uploaderRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [dragActive, setDragActive] = useState(false)

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploaderRef.current.handleFileChange({ target: { files: e.dataTransfer.files } })
    }
  }

  return (
    <FileUploader
      sx={{ justifyContent: 'start', width: '100%' }}
      accept={accept}
      onChangeFile={onValueChange}
      value={value}
      maxFilesCount={maxFilesCount}
      maxSize={maxFileSize}
      setLoading={setLoading}
      handleError={setError}
      ref={uploaderRef}
      returnFullFile
      multiple
    >
      <Box
        onDragEnter={handleDrag}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px dashed',
          borderColor: error ? palette.error.main : palette.primary.main,
          borderRadius: '5px',
          mr: '0',
          mb: '16px',
          py: '20px',
          px: '10px',
          backgroundColor: dragActive ? palette.grey.main : 'transparent',
          position: 'relative'
        }}
      >
        {dragActive && (
          <Box
            onDragLeave={handleDrag}
            onDrop={handleDrop}
            onDragOver={handleDrag}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0',
              right: '0',
              bottom: '0',
              left: '0'
            }}
          />
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant={'subtitle1'}
              color={error ? 'error' : 'primary'}
            >
            </Typography>
            <Button
              variant='contained'
            >
              <Typography
                onClick={() => uploaderRef.current.handleClick()}
                sx={{ color: '#ffffff', fontWeight: 500 }}
              >
                {accept.includes('.jpg') ? t('common.labels.addPhoto') : t('common.labels.addDocument')}
              </Typography>
            </Button>
            <Typography sx={{
              color: '#6D6C6C',
              fontWeight: 500,
              marginTop: '12px',
              textAlign: 'center',
              fontSize: { lg: '16px', xs: '14px' },
            }}
            >
              {error ? error : t('common.labels.dragAndDrop')}
            </Typography>
          </Box>
        )}
      </Box>
    </FileUploader>
  )
}

AppDragAndDrop.propTypes = AppDragAndDropProps

export default AppDragAndDrop
