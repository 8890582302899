import { Box } from '@mui/material'
import useBreakpoints from '@/hooks/use-breakpoints'
import { responsiveGap } from '@/pages/home-page/HomePage.styles'
import { generateSkeletonItems } from '@/utils/skeleton'
import { theme } from '@/plugins/material-ui/theme'
import InvestObjectListItem from '@/containers/invest-object-list/invest-object-list-item/InvestObjectListItem'

const InvestObjectSkeleton = () => {
  const { isMobile } = useBreakpoints()
  return (
    <Box
      key='items'
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: responsiveGap,
        py: theme.spacing(2)
      }}
    >
      {generateSkeletonItems(isMobile ? 1 : 2).map((item) => (
        <Box key={item.id}>
          <InvestObjectListItem
            vertical={isMobile}
            item={item}
            loading
          />
        </Box>
      ))}
    </Box>
  )
}

export default InvestObjectSkeleton
