export const characteristicType = {
  land: 'LAND_PLOT',
  realEstate: 'REAL_ESTATE'
}
export const characteristicsTypeOptions = (t) => [
  { value: characteristicType.land, label: t('investObjects.labels.objectTypeOptions.landPlot') },
  { value: characteristicType.realEstate, label: t('investObjects.labels.objectTypeOptions.realEstate') }
]

export const usageType = {
  sale: 'SALE',
  rent: 'RENT',
  saleOrRent: 'SALE_OR_RENT',
  other: 'OTHER'
}
export const usageTypeOptions = (t) => [
  { value: 'SALE', text: t('investObjects.labels.usageTypeOptions.sale') },
  { value: 'RENT', text: t('investObjects.labels.usageTypeOptions.rent') },
  { value: 'SALE_OR_RENT', text: t('investObjects.labels.usageTypeOptions.saleOrRent') },
  { value: 'OTHER', text: t('investObjects.labels.other') }
]

export const encumbranceOptions = (t) => [
  { value: 'NONE', text: t('investObjects.labels.encumbranceOptions.none') },
  { value: 'ARREST', text: t('investObjects.labels.encumbranceOptions.arrest') },
  { value: 'MORTGAGE', text: t('investObjects.labels.encumbranceOptions.mortgage') },
  { value: 'OTHER', text: t('investObjects.labels.other') }
]
