import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

const AdminPageHeaderProps = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  controlPane: PropTypes.element
}

const AdminPageHeader = ({ title, buttonLabel, buttonProps, onClick, styles, controlPane }) => {
  return (
    <Box sx={{ display: 'flex', minHeight: '100px', alignItems: 'center', ...styles }}>
      <Typography
        variant={'h2'}
        sx={{ mr: '24px' }}
      >
        {title}
      </Typography>
      {buttonLabel && (
        <Button
          variant={'contained'}
          onClick={onClick}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )}
      {controlPane}
    </Box>
  )
}

export default AdminPageHeader

AdminPageHeader.prototype = AdminPageHeaderProps
