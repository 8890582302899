import React, { useState } from 'react'
import { Box, Popover, Typography } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const AppFormPopOver = (props) => {
    const { val } = props
    const [pop, setPop] = useState(null)

    const handleClose = () => {
        setPop(null)
    }

    const open = Boolean(pop)
    const id = open ? 'simple-popover' : undefined

    const handleClick = (event) => {
        setPop(event.currentTarget)
    }
    
    return (
      <>
        <Box
          id={id}
          sx={{ display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'relative',
            height: '100%',
            maxWidth: { xl: 'inherit', xs: '100%' },
            overflow: { xl: 'visible', xs: 'hidden' },

          }}
          aria-haspopup="true"
          onMouseEnter={handleClick}
        >
          {val.slice(0, 21)}
          {' '}
          <MoreHorizIcon sx={{ position: { xl: 'relative', xs: 'absolute' },
            right:  { xl: '-10px', xs: 0 },
            backgroundColor: '#ffffff',  }}
          />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={pop}
          onClose={handleClose}
          top={388}
          anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
        >
          <Typography sx={{ p: 2 }} onMouseLeave={handleClose}>{val}</Typography>
        </Popover>
      </>
    )
}

export default AppFormPopOver
