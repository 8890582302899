import { Box } from '@mui/material'
import ClientNavFooter from '@/containers/layouts/client/client-footer/client-nav-footer'
import ClientBackFooter from '@/containers/layouts/client/client-footer/client-back-footer'
import { baseContainerSX } from '@/containers/constants'
import useBreakpoints from '@/hooks/use-breakpoints'

const ClientFooter = () => {
    const { isDesktop } = useBreakpoints()
    const breakPoints = isDesktop ? baseContainerSX : null

    const footerStyles = {
        ...breakPoints,
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'grey.main',
        px: { md: '44px', sm: '24px', xs: '16px' },
    }

  return (
    <>
      <Box sx={{ ...footerStyles }}>
        <ClientNavFooter />
      </Box>
      <Box sx={{ ...footerStyles, width: '100%' }}>
        <ClientBackFooter />
      </Box>
    </>
  )
}

export default ClientFooter
