import { documentStatus } from '@/containers/constants'

const systemLanguages = ['uk', 'en']

/**
 select only values from given data by the option name from the
 @inputs - array of inputs
 @data - data object with values

 example:

 mapDataFromInputs([{options: {name: title}}], {en: {title: 'EngTitle', createdAt: null}, uk: {title: 'UkTitle', createdAt: null})
 result :
 {
   uk: {title: 'UkTitle'}
   en: {title: 'EngTitle'}
 }
 */

export const mapDataFromInputs = (inputs, data) =>
  systemLanguages.reduce((langObj, lang) => {
    return {
      ...langObj,
      [lang]: inputs.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue.options.name]: data[lang][currentValue.options.name]
        }),
        {}
      )
    }
  }, {})

export const statusValidation = (status, draftValidation, publishValidation) => {
  if (status === documentStatus.DRAFT) {
    return draftValidation
  } else if (status === documentStatus.PUBLISHED) {
    return publishValidation
  } else {
    return {}
  }
}
