import { Route, Routes } from 'react-router-dom'

import { clientRoutes } from '@/plugins/router/clinet-router/routes'

import HomePage from '@/pages/home-page'
import NewsPage from '@/pages/news-page'
import AboutUsPage from '@/pages/about-us-page'
import SupportProgramPage from '@/pages/support-program-page'
import EventsPage from '@/pages/events-page'
import SearchResultsPage from '@/pages/search-results-page'
import EventDetailsPage from '@/pages/event-details-page'
import SupportProgramDetailPage from '@/pages/support-program-detail-page'
import NewsDetailPage from '@/pages/news-details-page/NewsDetailPage'
import InvestObjectMapPage from '@/pages/invest-object-map-page/InvestObjectMapPage'
import { RefineProvider } from '@/containers/invest-object-list/invest-object-list-filters/context/refine-context'
import InvestObjectDetailsPage from '@/pages/invest-object-details-page/InvestObjectDetailsPage'
import InvestObjectSubmitPage from '@/pages/invest-object-submit-page/InvestObjectSubmitPage'
import BusinessOffersPage from '@/pages/buissnes-offers/BusinessOffersPage'
import PartnersPage from '@/pages/partners-page/PartnersPage'
import NotFoundPage from '@/pages/not-found-page/NotFoundPage'

const ClientRouter = () => {
  return (
    <Routes>
      <Route
        path={clientRoutes.home.route}
        element={<HomePage />}
      />
      <Route
        path={clientRoutes.aboutUs.route}
        element={<AboutUsPage />}
      />
      <Route
        path={clientRoutes.news.route}
        element={<NewsPage />}
      />
      <Route
        path={clientRoutes.newsDetail.route}
        element={<NewsDetailPage />}
      />
      <Route
        path={clientRoutes.supportPrograms.route}
        element={<SupportProgramPage />}
      />
      <Route
        path={clientRoutes.eventCalendar.route}
        element={<EventsPage />}
      />
      <Route
        path={clientRoutes.event.route}
        element={<EventDetailsPage />}
      />
      <Route
        path={clientRoutes.businessOffers.route}
        element={<BusinessOffersPage />}
      />
      <Route
        path={clientRoutes.supportProgram.route}
        element={<SupportProgramDetailPage />}
      />
      <Route
        path={clientRoutes.searchResults.route}
        element={<SearchResultsPage />}
      />
      <Route
        path={clientRoutes.submitObject.route}
        element={<InvestObjectSubmitPage />}
      />
      <Route
        path={clientRoutes.investmentOpportunitiesMap.route}
        element={
          <RefineProvider
            prefix='investMapPage'
            defaultSorting={{
              field: 'publishedDate',
              sort: 'DESC'
            }}
          >
            <InvestObjectMapPage />
          </RefineProvider>
        }
      />
      <Route
        path={clientRoutes.investObjectDetailsPage.route}
        element={<InvestObjectDetailsPage />}
      />
      <Route
        path={clientRoutes.businessEnvironment.route}
        element={<PartnersPage />}
      />
      <Route
        path={clientRoutes.notFound.route}
        element={<NotFoundPage />}
      />
    </Routes>
  )
}

export default ClientRouter
