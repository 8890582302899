import { useCallback } from 'react'

export const useAppImageUploaderForm = ({ handleChange }) => {
  const handleImageChange = useCallback(
    (key) => (value) => {
      handleChange(key)({ customType: 'image', value })
    },
    [handleChange]
  )

  return {
    formProps: {
      handleImageChange
    }
  }
}
