import { Box, Card, CardContent, Divider, Link, Skeleton, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { toShortDate } from '@/utils/date'
import { Fragment } from 'react'
import { theme } from '@/plugins/material-ui/theme'
import AppNavLink from '@/components/app-nav-link/AppNavLink'
import { generateSkeletonItems, randomIntFromInterval } from '@/utils/skeleton'
import { truncateTextByLines } from '@/utils/common'

const AppRestFeedProps = {
  feedTitle: PropTypes.string,
  feeds: PropTypes.array,
  loading: PropTypes.bool,
  toPrefix: PropTypes.string,
  empty: PropTypes.element
}
const AppRestFeed = ({ feedTitle, feeds, loading, toPrefix, empty }) => {
  if (loading) {
    feeds = generateSkeletonItems(randomIntFromInterval(2, 3))
  }

  const feedFragment = ({ id, date, title }, feedIndex) => {
    const isLastFeedItem = feedIndex === feeds.length - 1
    return (
      <Fragment key={id}>
        <Link
          component={AppNavLink}
          to={`${toPrefix}/${id}`}
          underline='none'
        >
          <Box sx={{ maxWidth: '285px' }}>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              sx={{ mb: theme.spacing(1.2) }}
            >
              {loading ? <Skeleton /> : toShortDate(date)}
            </Typography>
            <Typography
              sx={{ fontWeight: 600, ...truncateTextByLines(5) }}
              variant='body2'
            >
              {loading ? <Skeleton /> : title}
            </Typography>
          </Box>
        </Link>
        <Divider sx={{ display: isLastFeedItem ? 'none' : { xs: 'none', md: 'block' } }} />
      </Fragment>
    )
  }

  return (
    <Card variant='outlined'>
      <CardContent sx={{ p: { md: theme.spacing(3.8), sm: theme.spacing(2.5), xs: theme.spacing(2.5) } }}>
        {feeds.length > 0 ? (
          <>
            <Typography
              variant='h4'
              sx={{ mb: theme.spacing(3.75) }}
            >
              {feedTitle}
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { md: '1fr', sm: '1fr 1fr', xs: '1fr' },
                gap: { xs: theme.spacing(4), sm: theme.spacing(2) }
              }}
            >
              {feeds.map(feedFragment)}
            </Box>
          </>
        ) : null}
        {feeds.length === 0 ? <Box>{empty}</Box> : null}
      </CardContent>
    </Card>
  )
}

AppRestFeed.propTypes = AppRestFeedProps

export default AppRestFeed
