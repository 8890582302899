import React from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { styles } from '@/pages/about-us-page/invest-office-team-member/invest-office-team-member.styles.js'
import linkedinSVG from '@/assets/icons/linkedin-blue.svg'
import InvestOfficeLogo from '@/assets/images/invest-office-logo.svg'

const InvestOfficeTeamMember = ({ fullName, photo, position, linkedin }) => {
  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={{ position: 'relative' }}>
        {photo ? (
          <Box
            component='img'
            src={photo}
            loading='lazy'
            alt={fullName}
            sx={{ ...styles.image }}
          />
        ) : (
          <Box
            component='img'
            src={InvestOfficeLogo}
            loading='lazy'
            alt='Invest Office Logo'
            sx={{ ...styles.image, objectFit: 'contain' }}
          />
        )}
        {linkedin && (
          <IconButton
            href={linkedin}
            target='_blank'
            rel='noopener noreferrer'
            sx={{ ...styles.iconButton }}
          >
            <img
              alt={`${fullName} Linkedin Link`}
              src={linkedinSVG}
              height={37}
            />
          </IconButton>
        )}
      </Box>

      <Typography
        variant='h5'
        sx={{ mb: '10px', textAlign: 'center' }}
      >
        {fullName}
      </Typography>
      <Typography
        variant='subtitle1'
        sx={{ textAlign: 'center' }}
      >
        {position}
      </Typography>
    </Box>
  )
}

export default InvestOfficeTeamMember
