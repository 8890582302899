import i18next from 'i18next'
import { minMaxLength, requiredField, requiredFile } from '@/utils/validations/common'
import { styles } from '@/containers/admin-news/admin-add-news-form/admin-news-form-styles'
import { backgroundImageUploadLabels } from '@/constants/default'
import { documentStatus, documentStatusField } from '@/containers/constants'
import { validMaxYear, validMinYear } from '@/utils/validations/date-range'

export const createNewsFormGeneralInputs = [
  {
    type: 'imageUploader',
    options: {
      name: 'featureImage',
      labels: backgroundImageUploadLabels,
      sx: styles.inputs.featureImage,
      previewSize: { width: '100%', height: 'auto', maxWidth: '500px' },
      maxSize: '5MB'
    }
  }
]

export const generalValidationDraft = {
  featureImage: []
}

export const generalValidationPublish = {
  featureImage: [requiredFile]
}

export const createNewsForm = [
  documentStatusField({
    sx: styles.inputs.status
  }),
  {
    type: 'text',
    options: {
      name: 'title',
      label: i18next.t('forms.addHeader'),
      sx: styles.inputs.title,
      required: true
    }
  },
  {
    type: 'textEditor',
    options: {
      name: 'text',
      sx: styles.inputs.text,
      header: i18next.t('news.addNewsText')
    }
  },
  {
    type: 'date',
    options: {
      name: 'publishedDate',
      label: 'Дата публікації',
      sx: styles.inputs.publishedDate,
      fullDate: true,
      required: true,
      readOnly: true,
      enabled: true
    }
  }
]

export const draftValidations = {
  title: [minMaxLength(0, 100)],
  text: [minMaxLength(0, 5000)],
  publishedDate: [
    validMinYear(new Date().getFullYear() - 1, 'news.error.pastYearNotAllowed'),
    validMaxYear(new Date().getFullYear() + 1, 'news.error.futureYearNotAllowed')
  ]
}

export const publishValidations = {
  title: [requiredField, ...draftValidations.title],
  text: [requiredField, ...draftValidations.text],
  publishedDate: [requiredField, ...draftValidations.publishedDate]
}

export const formInitialValues = {
  global: {
    featureImage: null
  },
  en: {
    title: '',
    status: documentStatus.DRAFT,
    text: '',
    publishedDate: new Date().toISOString()
  },
  uk: {
    title: '',
    status: documentStatus.DRAFT,
    text: '',
    publishedDate: new Date().toISOString()
  }
}
