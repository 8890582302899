import { useCallback, useEffect, useState } from 'react'
import { useClientLayoutRenderContext } from '@/context/client-layout-context'
import { NewsService } from '@/services/news-service'
import useAxios from '@/hooks/use-axios'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Typography } from '@mui/material'
import AppTextWithIcon from '@/components/app-text-with-icon'
import { toShortDate } from '@/utils/date'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { theme } from '@/plugins/material-ui/theme'
import Chip from '@mui/material/Chip'
import AppRestFeed from '@/components/app-rest-feed/AppRestFeed'
import AppButtonGoBackTo from '@/components/app-button-go-back-to/AppButtonGoBackTo'
import { newsDetails } from '@/pages/news-details-page/news-details-styles'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'
import { useNavigate, useParams } from 'react-router-dom'
import useBreakpoints from '@/hooks/use-breakpoints'
import AppShareButtons from '@/components/app-share-buttons/AppShareButtons'
import NewsItem from '@/containers/news-short-list/news-item'

const NewsDetailPage = () => {
  const [news, setNews] = useState({})
  const [restNews, setRestNews] = useState([])
  const { id } = useParams()
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile, isTablet } = useBreakpoints()
  const { fetchData, loading } = useAxios({
    service: NewsService.getNewsDetailsById,
    initialLoading: true
  })
  const { fetchData: fetchRestNews, loading: restNewsLoading } = useAxios({
    service: NewsService.getNews,
    initialLoading: true
  })
  const { setIsContactFormRender, resetDefaultLayout } = useClientLayoutRenderContext()

  useEffect(() => {
    setIsContactFormRender(false)

    return () => {
      resetDefaultLayout()
    }
  }, [resetDefaultLayout, setIsContactFormRender])

  const getRestNews = useCallback(async () => {
    const data = await fetchRestNews({ take: 3, skip: 0, exclude_ids: [Number(id)], lang: i18n.language })
    setRestNews(data.items.map(({ id: itemId, title, publishedDate: date }) => ({ id: itemId, title, date })))
  }, [fetchRestNews, i18n.language, id])

  useEffect(() => {
    getRestNews().catch()
  }, [getRestNews])

  const getNews = useCallback(async () => {
    const data = await fetchData({ id, query: { lang: i18n.language } })
    setNews(data)
  }, [fetchData, id, i18n.language])

  useEffect(() => {
    document.title = `Invest in Lviv - ${news.title}`
  }, [news.title])

  useEffect(() => {
    getNews().catch((res) => {
      if (res.response.status === 404) {
        navigate('/news')
      }
    })
  }, [getNews, navigate])

  const NewsContent = () => (
    <>
      <Typography
        variant='h1'
        sx={newsDetails.header}
      >
        {news.title}
      </Typography>
      <AppTextWithIcon
        sx={newsDetails.shortDate}
        text={{ value: toShortDate(news.publishedDate), variant: 'body1', color: 'textSecondary' }}
        icon={<CalendarTodayIcon sx={{ color: theme.palette.text.secondary }} />}
      />
      <Box sx={newsDetails.content}>
        <Box sx={newsDetails.content.inner}>
          <Box
            component='img'
            sx={{
              maxWidth: '955px',
              height: 'auto',
              width: '100%'
            }}
            src={`${news.featureImage}?w=248&fit=crop&auto=format`}
            srcSet={`${news.featureImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={news.title}
            loading='lazy'
          />
          <div
            style={{ maxWidth: '955px' }}
            dangerouslySetInnerHTML={{ __html: news.text }}
          />
          <Divider sx={{ mb: theme.spacing(3.8), mt: theme.spacing(13.2) }} />
          <Box sx={newsDetails.tagsContainer}>
            <Box sx={newsDetails.tags}>
              {news.tags.map(({ id: tagId, title: tagTitle }) => (
                <Chip
                  color={'secondary'}
                  key={tagId}
                  label={tagTitle}
                />
              ))}
            </Box>
            <AppShareButtons
              label={isMobile || isTablet ? t('common.share') : t('news.shareNews')}
              url={window.location.href}
              quote={document.title}
              hashtags={news.tags.map(({ title }) => title)}
              sx={newsDetails.share}
            />
          </Box>
        </Box>
        <Box sx={newsDetails.content.otherNews}>
          <AppRestFeed
            feedTitle={t('news.restNews')}
            feeds={restNews}
            loading={restNewsLoading}
            toPrefix={'/news'}
            empty={<Typography variant='subtitle2'>{t('news.noRestNews')}</Typography>}
          />
        </Box>
      </Box>
    </>
  )

  return (
    <Box sx={newsDetails.container}>
      <AppButtonGoBackTo
        to={clientRoutes.news.route}
        label={t('news.news')}
        sx={newsDetails.back}
      />
      {loading ? (
        <NewsItem
          loading
          vertical
        />
      ) : (
        <NewsContent />
      )}
    </Box>
  )
}

export default NewsDetailPage
