import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAxios from '@/hooks/use-axios'
import { NewsService } from '@/services/news-service'
import { Box, Chip, Divider, Typography } from '@mui/material'

import AppInfiniteScroll from '@/components/app-infinite-scroll'
import NewsPageSkeleton from './NewsPageSkeleton'
import NewsItem from '@/containers/news-short-list/news-item'
import { LayoutRefContext } from '@/containers/layouts/client/client-layout/ClientLayout'
import { baseContainerSX } from '@/containers/constants'

import useBreakpoints from '@/hooks/use-breakpoints'
import { useTagsFilter } from '@/hooks/use-tags-filter'

import { responsiveGap } from '@/pages/home-page/HomePage.styles'

const NewsPage = () => {
  const layoutRef = useContext(LayoutRefContext)
  const { i18n, t } = useTranslation()
  const { isDesktop, isTablet } = useBreakpoints()
  const [newsItems, setNewsItems] = useState([])
  const [totalNews, setTotalNews] = useState(0)

  const { fetchData: fetchNews } = useAxios({ service: NewsService.getNews, handleError: true })

  const { filterBy, tagsItems, handleFilterChange } = useTagsFilter({ service: NewsService.getNewsTags })

  const InfiniteScroll = useRef(null)

  const getNewsItems = useCallback(
    async ({ take, skip }) => {
      const { items, count } = await fetchNews({ take, skip, lang: i18n.language, tags: filterBy.map(({ id }) => id) })

      setNewsItems([...newsItems, ...items])
      setTotalNews(count)
      return items
    },
    [fetchNews, filterBy, i18n.language, newsItems]
  )

  const clearItems = () => {
    setTotalNews(0)
    setNewsItems([])
  }

  useEffect(() => {
    clearItems()
    InfiniteScroll.current.refreshData()
  }, [i18n.language, filterBy])

  const handleLanguageChanged = useCallback(() => {
    handleFilterChange(null)
  }, [handleFilterChange])

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged)
    return () => {
      i18n.off('languageChanged', handleLanguageChanged)
    }
  }, [i18n, handleLanguageChanged])

  return (
    <Box
      sx={{
        ...baseContainerSX,
        px: { md: '44px', sm: '24px', xs: '16px' },
        margin: 'auto',
        mt: '45px',
        flexGrow: 1,
        width: '100%'
      }}
    >
      <Typography
        variant='h1'
        sx={{ mb: '20px' }}
      >
        {t('newsPage.news')}
      </Typography>
      {Boolean(filterBy.length) && (
        <Typography
          sx={{ mb: '25px' }}
          variant={'body1'}
        >
          {t('newsPage.foundByTag', { count: totalNews })}
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Box sx={{ mr: { md: '50px' }, mb: responsiveGap, flexGrow: 1, position: 'relative', minHeight: '700px' }}>
          {!newsItems.length && <NewsPageSkeleton />}
          <AppInfiniteScroll
            ref={InfiniteScroll}
            items={newsItems}
            parentRef={layoutRef}
            totalCount={totalNews}
            fetchData={getNewsItems}
            clearItems={clearItems}
            loader={'...'}
            noItems={<div key='no-items'>{t('newsPage.noItems')}</div>}
          >
            <Box
              key='items'
              sx={{
                display: 'grid',
                gridTemplateColumns: isTablet ? '1fr 1fr' : '1fr',
                gap: '40px'
              }}
            >
              {newsItems.map((item, index) => (
                <Box key={item.id}>
                  <NewsItem
                    vertical={!isDesktop}
                    item={item}
                    url={`/news/${item.id}`}
                  />
                  {isDesktop && index !== newsItems.length - 1 && (
                    <Divider
                      variant='inset'
                      sx={{ mt: '40px', mx: 0 }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          </AppInfiniteScroll>
        </Box>

        {isDesktop && (
          <Box
            sx={{
              maxWidth: '300px',
              minWidth: '300px',
              maxHeight: '100vh',
              position: 'sticky',
              top: '10px',
              mb: '55px'
            }}
          >
            <Typography
              variant='body2'
              sx={{ mb: '20px' }}
            >
              {t('newsPage.popularTags')}
            </Typography>
            {tagsItems.map((item) => (
              <Chip
                className={filterBy.find((itemFilter) => itemFilter.id === item.id) ? 'active-chip' : ''}
                color={'secondary'}
                onClick={() => handleFilterChange(item)}
                sx={{ mr: '14px', mb: '14px' }}
                key={item.id}
                label={item.title}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default NewsPage
