import { useCallback } from 'react'
import { useAppGrid } from '@/components/app-grid/AppGrid.context'

export const useAppGridServices = () => {
  const {
    pageSize,
    currPage,
    filterModel,
    externalFilters,
    sortModel,
    service,
    setItems,
    setTotalRows,
    setLoading,
    onTableRefresh
  } = useAppGrid()

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const pagination = { skip: pageSize * currPage, take: pageSize }
      const filtering = []

      if (filterModel[0]) {
        filtering.push(filterModel[0])
      }
      if (externalFilters) {
        filtering.push(externalFilters)
      }

      const sorting = sortModel[0] ? { ...sortModel[0], sort: sortModel[0].sort.toUpperCase() } : {}

      const { data } = await service.load({ ...pagination, filtering, sorting })
      setItems(data.items)
      setTotalRows(data.count)
    } finally {
      setLoading(false)
      onTableRefresh()
    }
  }, [
    setLoading,
    pageSize,
    currPage,
    externalFilters,
    filterModel,
    sortModel,
    service,
    setItems,
    setTotalRows,
    onTableRefresh
  ])

  return {
    loadData
  }
}
