// FIXME: Use it for admin invest object create/edit form
import { useTranslation } from 'react-i18next'
import AppRadioGroup from '@/components/app-radio-group'
import { yesNoOptions } from '@/constants/default'
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  borders,
  roads
} from './constants'
import palette from '@/plugins/material-ui/palette'
import { generateGuid } from '@/utils/guid'
import SectionBase from '@/containers/invest-object-form/sections/common/SectionBase'

const TransportConnectionSection = ({ formPropsGeneral, styles }) => {
  const { t } = useTranslation()

  const handleRadioChange = (e) => {
    const value = e.target.value === 'true'
    formPropsGeneral.handleChange('railroad')({ target: { value } })
  }

  const handleChange = ({ field, index, e, sectionName }) => {
    const value = e.target.value
    const newValue = formPropsGeneral.data[sectionName]
    newValue[index] = { ...newValue[index], [field]: value }
    formPropsGeneral.handleChange(sectionName)({ customType: true, value: newValue })
  }

  const handleBlur = ({ field, index, e, sectionName }) => {
    const value = e.target.value
    const newValue = formPropsGeneral.data[sectionName]
    newValue[index] = { ...newValue[index], [field]: value }
    formPropsGeneral.handleBlur(sectionName)({ customType: true, value: newValue })
  }

  const handleAdd = (sectionName) => {
    const currentValue = formPropsGeneral.data[sectionName]
    formPropsGeneral.handleChange(sectionName)(
      {
        customType: true,
        value: [...currentValue, { name: '', distanceKM: '', id: generateGuid() }]
      },
      { notValidate: true }
    )
  }

  const handleDelete = ({ index: indexToDelete, sectionName }) => {
    const value = formPropsGeneral.data.internationalAndStateRoads.filter((_item, index) => index !== indexToDelete)

    formPropsGeneral.handleChange(sectionName)(
      {
        customType: true,
        value
      },
      { notValidate: true }
    )
  }

  const section = (sectionName) => (
    <>
      <Typography variant="h4" sx={styles.inputs[sectionName].title}>
        {t(`investObjects.labels.${sectionName}.title`)}
      </Typography>

      {formPropsGeneral.data[sectionName].map(({ name, distanceKM, id }, index) => {
        return (
          <Box
            sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}
            key={id}
          >
            <TextField
              value={name}
              id={'name' + id}
              error={Boolean(formPropsGeneral.errors[sectionName]?.['name' + id])}
              helperText={formPropsGeneral.errors[sectionName]?.['name' + id] || ' '}
              onChange={(e) =>
                handleChange({
                  field: 'name',
                  fieldId: 'name' + id,
                  index,
                  e,
                  sectionName
                })
              }
              onBlur={(e) =>
                handleBlur({
                  field: 'name',
                  fieldId: 'name' + id,
                  index,
                  e,
                  sectionName
                })
              }
              label={t(`investObjects.labels.${sectionName}.name`)}
              sx={{ flexBasis: { md: '60%', sm: '55%', xs: '100%' } }}
            />
            <TextField
              value={distanceKM}
              id={'distance' + id}
              error={Boolean(formPropsGeneral.errors[sectionName]?.['distance' + id])}
              helperText={formPropsGeneral.errors[sectionName]?.['distance' + id] || ' '}
              onChange={(e) => handleChange({ field: 'distanceKM', fieldId: 'distance' + id, index, e, sectionName })}
              onBlur={(e) =>
                handleBlur({
                  field: 'distanceKM',
                  fieldId: 'distance' + id,
                  index,
                  e,
                  sectionName
                })
              }
              label={t('investObjects.labels.distanceKM')}
              sx={{ flexBasis: { md: '30%', sm: '30%', xs: '60%' }, marginTop: { sm: 0, xs: '-10px' } }}
            />
            <DeleteIcon
              onClick={() => handleDelete({ index, sectionName })}
              sx={{
                mt: { sm: '35px', xs: '25px' },
                alignSelf: 'flex-start',
                color: palette.primary.main,
                cursor: 'pointer',
                '&:hover': { color: palette.primary.light }
              }}
            />
          </Box>
        )
      })}
      {formPropsGeneral.data[sectionName].length < 5 && (
        <Button
          sx={{ alignSelf: 'start', padding: 0 }}
          onClick={() => handleAdd(sectionName)}
        >
          <Box sx={{
              backgroundColor: '#009639',
              color: '#ffffff',
              borderRadius: '50%',
              width: '24px',
              height: '24px',
              fontSize: '25px',
              lineHeight: '25px',
              fontWeight: 600,
              marginRight: '12px',
            }}
          >
            +
          </Box>
          {t(`investObjects.labels.${sectionName}.add`)}
        </Button>
      )}
    </>
  )

  return (
    <>
      <Grid sx={styles.inputs.roadSurfaceType}>
        <Typography variant="h5">
          { t('investObjects.labels.roads') }
        </Typography>
        <TextField
          label={t('investObjects.labels.roadSurfaceType')}
          placeholder={t('investObjects.placeholder.roadSurfaceType')}
          value={formPropsGeneral.data.roadSurfaceType}
          error={Boolean(formPropsGeneral.errors.roadSurfaceType)}
          helperText={formPropsGeneral.errors.roadSurfaceType || ' '}
          onChange={formPropsGeneral.handleChange('roadSurfaceType')}
          onBlur={formPropsGeneral.handleBlur('roadSurfaceType')}
        />
      </Grid>
      <Grid sx={styles.inputs.railroad}>
        <Typography variant="h5">
          { t('investObjects.labels.railroads') }
        </Typography>
        <AppRadioGroup
          value={formPropsGeneral.data.railroad}
          onChange={handleRadioChange}
          error={Boolean(formPropsGeneral.errors.railroad)}
          helperText={formPropsGeneral.errors.railroad}
          options={yesNoOptions}
          label={t('investObjects.labels.railroad')}
          row
        />
      </Grid>
      <Box sx={styles.inputs.internationalAndStateRoads}>{section(roads)}</Box>
      <Box sx={styles.inputs.borderCrossingPoints}>{section(borders)}</Box>
    </>
  )
}

TransportConnectionSection.propTypes = SectionBase.propTypes

export default TransportConnectionSection
