import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import useAxios from '@/hooks/use-axios'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Typography, Skeleton } from '@mui/material'

import useBreakpoints from '@/hooks/use-breakpoints'
import { EventsService } from '@/services/events-service'
import { clientRoutes } from '@/plugins/router/clinet-router/routes'

import AppInfiniteScroll from '@/components/app-infinite-scroll'
import { baseContainerSX } from '@/containers/constants'
import { LayoutRefContext } from '@/containers/layouts/client/client-layout/ClientLayout'
import EventItem from '@/containers/recent-events-banner/event-item'
import EvenPageSkeleton from './EvenPageSkeleton'
import AppScrollableCalendar from '@/components/app-scrollable-calendar'

import { responsiveGap } from '@/pages/home-page/HomePage.styles'

const EventsPage = () => {
  const layoutRef = useContext(LayoutRefContext)
  const { i18n, t } = useTranslation()
  const { isDesktop, isTablet } = useBreakpoints()

  const [filterBy, setFilterBy] = useState({
    from: DateTime.now().startOf('month').toUTC().toISO(),
    to: DateTime.now().endOf('month').toUTC().toISO()
  })

  const [eventItems, setEventItems] = useState([])
  const [totalEvents, setTotalEvents] = useState(0)
  const [disabledMonths, setDisabledMonths] = useState(null)

  const { fetchData: fetchEvents } = useAxios({
    service: EventsService.getEvents,
    initialLoading: true,
    handleError: true
  })
  const { fetchData: fetchCountByMonth } = useAxios({ service: EventsService.getCountByMonths, handleError: true })

  const InfiniteScroll = useRef(null)

  const getEventItems = useCallback(
    async ({ take, skip }) => {
      const { items, count: total } = await fetchEvents({ take, skip, lang: i18n.language, ...filterBy })
      const counts = await fetchCountByMonth(i18n.language)

      setDisabledMonths(counts.filter(({ count }) => !Number(count)).map(({ date }) => date))
      setEventItems([...eventItems, ...items])
      setTotalEvents(total)
      return items
    },
    [fetchEvents, i18n.language, filterBy, fetchCountByMonth, eventItems]
  )

  const clearItems = () => {
    setTotalEvents(0)
    setEventItems([])
  }

  useEffect(() => {
    clearItems()
    InfiniteScroll.current.refreshData()
  }, [i18n.language, filterBy])

  return (
    <Box
      sx={{
        ...baseContainerSX,
        px: { md: '44px', sm: '24px', xs: '16px' },
        margin: 'auto',
        mt: '45px',
        flexGrow: 1,
        width: '100%'
      }}
    >
      <Typography
        variant='h1'
        sx={{ mb: '20px' }}
      >
        {t('events.eventsCalendar')}
      </Typography>
      {disabledMonths ? (
        <AppScrollableCalendar
          onChange={setFilterBy}
          disabledMonths={disabledMonths}
          sx={{ marginBottom: '30px' }}
        />
      ) : (
        <Skeleton />
      )}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Box sx={{ mb: responsiveGap, flexGrow: 1 }}>
          <AppInfiniteScroll
            ref={InfiniteScroll}
            items={eventItems}
            parentRef={layoutRef}
            totalCount={totalEvents}
            fetchData={getEventItems}
            clearItems={clearItems}
            loader={<EvenPageSkeleton />}
            noItems={
              <Typography
                variant='h4'
                key='no-items'
              >
                {t('events.noItems')}
              </Typography>
            }
          >
            <Box
              key='items'
              sx={{
                display: 'grid',
                gridTemplateColumns: isTablet ? '1fr 1fr' : '1fr',
                gap: '40px'
              }}
            >
              {eventItems.map((item) => (
                <Box key={item.id}>
                  <EventItem
                    vertical={!isDesktop}
                    url={`${clientRoutes.event.path}${item.id}`}
                    key={item.id}
                    item={item}
                  />
                  {isDesktop && (
                    <Divider
                      variant='inset'
                      sx={{ mt: '40px', mx: 0 }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          </AppInfiniteScroll>
        </Box>
      </Box>
    </Box>
  )
}

export default EventsPage
