import { useCallback, useMemo } from 'react'
import useForm from '@/hooks/use-form'
import { useUpdateValidations } from '@/pages/admin/admin-invest-object-edit/hooks/use-update-validations'
import { investObjectSectionMap, multiInputFieldNames } from '@/containers/invest-object-submit-form/constants'
import { documentStatus } from '@/containers/constants'
import { useTranslation } from 'react-i18next'

export const useInvestObjectSubmitForm = (
  {
    activeForms,
    initialValues,
    initialValidations,
  }) => {
  const {
    data: formData,
    isDirty,
    formProps,
    handleValidate,
    setValidations,
    areFieldsHaveAnyValidation,
    areFieldsValid,
    getProgress,
    setMappedData: updateFormData
  } = useForm({
    initialValues: initialValues,
    validations: initialValidations
  })

  const { t } = useTranslation()
  
  const setMultiLangValidations = useMemo(() => {
    return activeForms.reduce((acc, lang) => {
      acc[lang] = setValidations
      return acc
    }, {})
  }, [activeForms, setValidations])

  useUpdateValidations({
    generalInputData: formData,
    setGeneralValidations: setValidations,
    activeForms,
    setMultiLangValidations,
  })

  const handleSubmit = () => {
    const isValid = handleValidate()

    if (isValid) {
      return formatData(formData)
    }
  }

  const formatData = (data) => {
    const filteredData = { ...data }

    const imageIds = filteredData.imageIds.map(({ id: imageId }) => imageId)
    const documentIds = filteredData.documentIds.map(({ id: fileId }) => fileId)
    const landPlanFilesIds = filteredData.landPlanFilesIds.map(({ id: fileId }) => fileId)

    const moveLangData = () => {
      const langFieldsData = multiInputFieldNames.reduce((acc, field) => {
        acc[field] = filteredData[field]
        delete filteredData[field]
        return acc
      }, {})

      langFieldsData.status = documentStatus.DRAFT
      
      return langFieldsData
    }

    const multiLangData = activeForms.reduce((acc, lang) => {
      acc[lang] = moveLangData()
      return acc
    }, {})

    return {
      ...filteredData,
      ...multiLangData,
      imageIds,
      documentIds,
      landPlanFilesIds,
      workersCount: Number(filteredData.workersCount) || null,
      realtyTotalAreaSqM: Number(filteredData.realtyTotalAreaSqM) || null,
      powerSupplyKW: Number(filteredData.powerSupplyKW) || null,
      nearestPowerSupplyKM: Number(filteredData.nearestPowerSupplyKM) || null,
      nearestGasSupplyKM: Number(filteredData.nearestGasSupplyKM) || null,
      nearestWaterSupplyKM: Number(filteredData.nearestWaterSupplyKM) || null,
      nearestDrainageKM: Number(filteredData.nearestDrainageKM) || null,
      latitude: filteredData.latitude || null,
      longitude: filteredData.longitude || null,
      plotArea: Number(filteredData.plotArea) || null,
      index: Number(filteredData.index) || null,
      usageType: filteredData.usageType || null,
      encumbrance: filteredData.encumbrance || null,
      cadastralNumberStatus: filteredData.cadastralNumberStatus || null,
      ownershipType: filteredData.ownershipType || null,
      landCategorySection: filteredData.landCategorySection || null,
      landCategoryCategory: filteredData.landCategoryCategory || null,
      landCategoryType: filteredData.landCategoryType || null,
      realtyState: filteredData.realtyState || null,
      contactsEmail: filteredData.contactsEmail,
      contactsPhone: filteredData.contactsPhone
    }
  }

  const getVisibleSections = useCallback((sections) => {
    const isSectionVisible = (section) => {
      return !section.isVisible || section.isVisible({ formProps })
    }
    const isSectionValid = (section) => {
      return areFieldsHaveAnyValidation(section.validationFields) && areFieldsValid(section.validationFields)
    }

    return Object.keys(sections).reduce((acc, sectionKey) => {
      const section = sections[sectionKey]
      const isVisible = isSectionVisible(section)

      if (isVisible) {
        const isValid = isSectionValid(section)

        acc.push({
          ...section,
          isValid,
          key: sectionKey,
        })
      }

      return acc
    }, [])
  }, [areFieldsHaveAnyValidation, areFieldsValid, formProps])

  const visibleSectionsData = getVisibleSections(investObjectSectionMap(t))

  const isValid = useMemo(() => {
    return visibleSectionsData.every((section) => section.isValid)
  }, [visibleSectionsData])

  const progress = useMemo(() => {
    const formProgressFields = visibleSectionsData.reduce((acc, sectionData) => {
      const progressFields = sectionData.countableProgressFields({ data: formData }).filter(Boolean)
      return [ ...acc, ...progressFields ]
    }, [])

    return getProgress(formProgressFields)
  }, [formData, getProgress, visibleSectionsData])

  return {
    data: formData,
    isDirty,
    formProps,
    handleValidate,
    updateFormData,
    visibleSectionsData,
    isValid,
    handleSubmit,
    progress,
  }
}
