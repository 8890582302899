import i18next from 'i18next'
import { requiredField, validRegExp } from '@/utils/validations/common'
import { realtyTotalAreaSqMPattern } from '@/constants/default'

export const realEstateSectionValidations = {
  realtyType: [requiredField],
  realtyState: [requiredField],
  realtyTotalAreaSqM: [
    requiredField,
    validRegExp({ regexp: realtyTotalAreaSqMPattern, errorMsg: i18next.t('investObjects.errors.realtyTotalAreaFormat') })
  ]
}

export const realEstateSectionEmptyValidations = {
  realtyType: [],
  realtyState: [],
  realtyTotalAreaSqM: []
}

export const realtyTypesOptions = () => [
  { value: 'RESIDENTIAL', text: i18next.t('investObjects.labels.realtyTypesOptions.residential') },
  { value: 'HOTEL', text: i18next.t('investObjects.labels.realtyTypesOptions.hotel') },
  { value: 'OFFICE', text: i18next.t('investObjects.labels.realtyTypesOptions.office') },
  { value: 'TRADING', text: i18next.t('investObjects.labels.realtyTypesOptions.trading') },
  { value: 'CAR_PARK', text: i18next.t('investObjects.labels.realtyTypesOptions.carPark') },
  { value: 'INDUSTRIAL', text: i18next.t('investObjects.labels.realtyTypesOptions.industrial') },
  { value: 'WAREHOUSE', text: i18next.t('investObjects.labels.realtyTypesOptions.warehouse') },
  { value: 'OUTBUILDING', text: i18next.t('investObjects.labels.realtyTypesOptions.outbuilding') },
  { value: 'OTHER', text: i18next.t('investObjects.labels.realtyTypesOptions.other') }
]

export const realtyStateOptions = () => [
  { value: 'READY_TO_USE', text: i18next.t('investObjects.labels.realtyStateOptions.readyToUse') },
  { value: 'NEEDS_MINOR_REPAIRS', text: i18next.t('investObjects.labels.realtyStateOptions.needsMinorRepairs') },
  { value: 'NEED_MAJOR_REPAIRS', text: i18next.t('investObjects.labels.realtyStateOptions.needsMajorRepairs') },
  { value: 'UNUSABLE', text: i18next.t('investObjects.labels.realtyStateOptions.unusable') }
]
