export const styles = {
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    gap: '6px',
    pr: '8px'
  },
  headers: {
    generalInformation: {
      gridColumn: '1 / -1',
      gridRow: 1
    },
    characteristic: {
      gridColumn: '1 / -1',
      gridRow: 11
    },
    address: {
      gridColumn: '1 / -1',
      gridRow: 23
    },
    realEstate: {
      gridColumn: '1 / -1',
      gridRow: 30
    },
    landPlot: {
      gridColumn: '1 / -1',
      gridRow: 33
    },
    communications: {
      gridColumn: '1 / -1',
      gridRow: 41
    },
    transportConnections: {
      gridColumn: '1 / -1',
      gridRow: 46
    },
    roads: {
      gridColumn: '1 / -1',
      gridRow: 47
    },
    railroads: {
      gridColumn: '1 / -1',
      gridRow: 49
    },
    internationalAndStateRoads: {
      gridColumn: '1 / -1',
      gridRow: 51
    },
    borderCrossingPoints: {
      gridColumn: '1 / -1',
      gridRow: 58
    },
    contactPerson: {
      gridColumn: '1 / -1',
      gridRow: 65
    }
  },
  inputs: {
    status: {
      gridColumn: '1 / span 3',
      gridRow: 2,
      mt: '32px'
    },
    state: {
      gridColumn: '1 / span 3',
      gridRow: 3
    },
    code: {
      gridColumn: '1 / span 3',
      gridRow: 4
    },
    domain: {
      gridColumn: '1 / -1',
      gridRow: 5
    },
    note: {
      gridColumn: '4 / -1',
      gridRow: '2 / span 3',
      mb: '25px'
    },
    name: {
      gridColumn: '1 / -1',
      gridRow: 6
    },
    description: {
      gridColumn: '1 / -1',
      gridRow: 7
    },
    images: {
      gridColumn: '1 / -1',
      gridRow: 9
    },
    documents: {
      gridColumn: '1 / -1',
      gridRow: 10
    },
    characteristicsType: {
      gridColumn: '1 / -1',
      gridRow: 12
    },
    usageType: {
      gridColumn: '1 / -1',
      gridRow: 13
    },
    otherUsageType: {
      gridColumn: '1 / -1',
      gridRow: 14
    },
    previousUsage: {
      gridColumn: '1 / -1',
      gridRow: '15 / span 5'
    },
    workersCount: {
      gridColumn: '1 / 5',
      gridRow: 20
    },
    encumbrance: {
      gridColumn: '1 / -1',
      gridRow: 21
    },
    otherEncumbrance: {
      gridColumn: '1 / -1',
      gridRow: 22
    },
    url: {
      gridColumn: '5 / -1',
      gridRow: 20
    },
    settlement: {
      gridColumn: '1 / -1',
      gridRow: 24
    },
    community: {
      gridColumn: '1 / -1',
      gridRow: 25
    },
    district: {
      gridColumn: '1 / -1',
      gridRow: 26
    },
    street: {
      gridColumn: '1 / -1',
      gridRow: 27
    },
    buildingNumber: {
      gridColumn: '1 / 6',
      gridRow: 28
    },
    index: {
      gridColumn: '6 / -1',
      gridRow: 28
    },
    map: {
      gridColumn: '1 / 6',
      gridRow: 29
    },
    realtyType: {
      gridColumn: '1 / 6',
      gridRow: 31
    },
    realtyState: {
      gridColumn: '6 / -1',
      gridRow: 31
    },
    realtyTotalAreaSqM: {
      gridColumn: '1 / 6',
      gridRow: 32
    },
    realtyHasLandPlot: {
      gridColumn: '6 / -1',
      gridRow: 32,
      ml: '12px'
    },
    cadastralNumberOfPlot: {
      gridColumn: '1 / -1',
      gridRow: 34
    },
    cadastralNumber: {
      gridColumn: '1 / -1',
      gridRow: 35
    },
    plotArea: {
      gridColumn: '1 / 6',
      gridRow: 36
    },
    ownershipType: {
      gridColumn: '6 / -1',
      gridRow: 36
    },
    landCategorySection: {
      gridColumn: '1 / 6',
      gridRow: 37
    },
    landCategoryCategory: {
      gridColumn: '6 / -1',
      gridRow: 37
    },
    landCategoryType: {
      gridColumn: '1 / -1',
      gridRow: 38
    },
    landPlan: {
      gridColumn: '1 / -1',
      gridRow: 39
    },
    landPlanFilesIds: {
      gridColumn: '1 / -1',
      gridRow: 40
    },
    powerSupply: {
      gridColumn: '1 / 4',
      gridRow: 42
    },
    powerSupplyKW: {
      gridColumn: '4 / -1',
      gridRow: 42
    },
    nearestPowerSupplyKM: {
      gridColumn: '4 / -1',
      gridRow: 42
    },
    gasSupply: {
      gridColumn: '1 / 4',
      gridRow: 43
    },
    nearestGasSupplyKM: {
      gridColumn: '4 / -1',
      gridRow: 43
    },
    waterSupply: {
      gridColumn: '1 / 4',
      gridRow: 44
    },
    waterSupplyType: {
      gridColumn: '4 / -1',
      gridRow: 44
    },
    nearestWaterSupplyKM: {
      gridColumn: '4 / -1',
      gridRow: 44
    },
    drainage: {
      gridColumn: '1 / 4',
      gridRow: 45
    },
    drainageType: {
      gridColumn: '4 / -1',
      gridRow: 45
    },
    nearestDrainageKM: {
      gridColumn: '4 / -1',
      gridRow: 45
    },
    roadSurfaceType: {
      gridColumn: '1 / -1',
      gridRow: 48
    },
    railroad: {
      gridColumn: '1 / -1',
      gridRow: 50
    },
    internationalAndStateRoads: {
      gridColumn: '1 / -1',
      gridRow: '52 / span 5',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    borderCrossingPoints: {
      gridColumn: '1 / -1',
      gridRow: '59 / span 5',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    contactsFullName: {
      gridColumn: '1 / -1',
      gridRow: 66
    },
    contactsEmail: {
      gridColumn: '1 / 6',
      gridRow: 67
    },
    contactsPhone: {
      gridColumn: '6 / -1',
      gridRow: 67
    }
  }
}
