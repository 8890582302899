import { Box, Tab, Tabs } from '@mui/material'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const AppTabsProps = {
  tabs: PropTypes.object,
  counts: PropTypes.object,
  handleTabChange: PropTypes.func,
  styles: PropTypes.object,
  stayAlive: PropTypes.bool
}

const AppTabs = ({ tabs = {}, counts, tabIndex, handleTabChange, styles, stayAlive = true }) => {

  const tabsValues = useMemo(() => Object.values(tabs), [tabs])

  const tabsHeaders = useMemo(
    () =>
      tabsValues.map((tab, index) => (
        <Tab
          key={'header' + index}
          label={tab.label + (counts ? ` - ${counts[tab.value]}` : '')}
        />
      )),
    [counts, tabsValues]
  )

  const tabsComponents = useMemo(
    () =>
      tabsValues.map(({ component }, index) => {
        return stayAlive || index === tabIndex ? (
          <Box
            className='tabs-item'
            key={'component' + index}
            sx={{ display: index !== tabIndex ? 'none' : 'flex', flexGrow: 1, flexDirection: 'column' }}
          >
            {component}
          </Box>
        ) : (
          <div key={'component' + index}></div>
        )
      }),
    [stayAlive, tabIndex, tabsValues]
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, ...styles }}>
      <Tabs
        className='tabs-headers'
        value={tabIndex}
        onChange={handleTabChange}
      >
        {tabsHeaders}
      </Tabs>
      {tabsComponents}
    </Box>
  )
}

AppTabs.propTypes = AppTabsProps

export default AppTabs
