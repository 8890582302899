import { Box, Tab, Tabs } from '@mui/material'

import { DateTime, Interval } from 'luxon'
import React, { useMemo, useState, useEffect } from 'react'

import AppButtonGoTo from '@/components/app-button-go-to'
import useBreakpoints from '@/hooks/use-breakpoints'
import { styles } from '@/components/app-scrollable-calendar/AppScrollableCalendar.styles'
import { useTranslation } from 'react-i18next'

const componentProps = {}

const AppScrollableCalendar = ({ disabledMonths = [], sx, onChange }) => {
  const { isDesktop } = useBreakpoints()
  const { i18n } = useTranslation()

  const [tabValue, setTabValue] = useState(false)

  // eslint-disable-next-line func-style
  function* months(interval) {
    let cursor = interval.start.startOf('day')
    while (cursor < interval.end) {
      yield cursor
      cursor = cursor.plus({ month: 1 })
    }
  }

  const interval = useMemo(() => Interval.fromDateTimes(DateTime.local(), DateTime.local().plus({ month: 24 })), [])

  const calendarItems = useMemo(
    () =>
      Array.from(months(interval)).map((date) => ({
        value: date.toFormat('MM-yyyy'),
        range: { from: date.startOf('month').toUTC().toISO(), to: date.endOf('month').toUTC().toISO() },
        label: date.setLocale(i18n.language).monthLong,
        month: date.month,
        year: date.year
      })),
    [interval, i18n.language]
  )

  const tabs = calendarItems.reduce((arr, item) => {
    arr.push(item)
    if (item.month === 12) {
      const year = item.year + 1
      const yearItem = { value: year.toString(), label: year.toString(), isYear: true }
      arr.push(yearItem)
    }
    return arr
  }, [])

  const monthWithEvents = calendarItems
    .map((item) => item.value)
    .filter((data) => disabledMonths.indexOf(data) === -1)[0]

  useEffect(() => {
    setTabValue(monthWithEvents)
    const changeValue = calendarItems.find((item) => item.value === monthWithEvents)?.range
    onChange(changeValue)
  }, [calendarItems, onChange, monthWithEvents])

  const handleChange = (e, value) => {
    setTabValue(value)
    const changeValue = calendarItems.find((item) => item.value === value).range
    onChange(changeValue)
  }

  return (
    <Tabs
      value={tabValue}
      onChange={handleChange}
      variant='scrollable'
      scrollButtons={isDesktop}
      ScrollButtonComponent={(props) => (
        <AppButtonGoTo
          hideTitle
          sx={styles.leftButton}
          {...props}
          className={[props.className, { disabled: props.disabled }, props.direction]}
        />
      )}
      TabIndicatorProps={{ style: { display: 'none' } }}
      sx={{ position: 'relative', ...sx }}
    >
      {tabs.map(({ value, label, ...item }) => (
        <Tab
          value={value}
          label={label}
          key={value}
          disabled={item.isYear || disabledMonths.includes(value)}
          sx={styles.tab}
          component={
            item.isYear &&
            ((props) => (
              <Box
                {...props}
                sx={{ display: 'flex', flexDirection: 'row !important' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: (theme) => theme.palette.grey.lighter,
                    '&::before, &::after': {
                      display: 'block',
                      content: '""',
                      width: '28px',
                      height: '1px',
                      backgroundColor: (theme) => theme.palette.grey.main
                    },
                    '&::before': {
                      marginRight: '30px'
                    },
                    '&::after': {
                      marginLeft: '30px'
                    }
                  }}
                >
                  {label}
                </Box>
              </Box>
            ))
          }
        />
      ))}
    </Tabs>
  )
}

AppScrollableCalendar.propTypes = componentProps

export default AppScrollableCalendar
