import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'

export const ContactService = {
  contact: (params, recaptchaToken) => {
    return request.post(
      URLs.contact,
      { ...params },
      {
        headers: { 'x-recaptcha-value': recaptchaToken }
      }
    )
  },
  request: (params) => {
    return request.post(URLs.request, { ...params })
  }
}
