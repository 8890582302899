export const styles = {
  form: {
    display: 'grid',
    gap: '16px 6px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: 'repeat(6, auto)'
  },
  inputs: {
    status: {
      gridColumn: '5 / 7',
      gridRow: '1'
    },
    title: {
      gridColumn: '1 / 5',
      gridRow: '1'
    },
    target: {
      gridColumn: '1 / 4',
      gridRow: '2'
    },
    amount: {
      gridColumn: '4 / 7',
      gridRow: '2'
    },
    submissionTerm: {
      gridColumn: '1 / 4',
      gridRow: '3'
    },
    receiptTerm: {
      gridColumn: '4 / 7',
      gridRow: '3'
    },
    donorName: {
      gridColumn: '1 / 4',
      gridRow: '4'
    },
    donorLogo: {
      gridColumn: '4 / 7',
      gridRow: '4'
    },
    featureImage: {
      gridColumn: '1 / 4',
      gridRow: '5'
    },
    file: {
      gridColumn: '4 / 7',
      gridRow: '5'
    },
    textEditor: {
      gridColumn: '1 / 7',
      gridRow: '6'
    }
  }
}
