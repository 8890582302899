import { Link, Typography, Slide } from '@mui/material'
import useBreakpoints from '@/hooks/use-breakpoints'

const PartnerItem = ({ content }) => {
  const { isMobile } = useBreakpoints()
  return (
    <Slide
      direction={isMobile ? 'down' : 'right'}
      in
    >
      <Link
        underline='none'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          gap: '10px',
          padding: '20px',
          borderRadius: '4px',
          position: 'relative',
          '::after':{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            content: '""',
            boxShadow: '0px 24px 38px rgb(13 13 36 / 14%), 0px 9px 46px rgb(13 13 36 / 12%), 0px 11px 15px rgb(13 13 36 / 20%)',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out'
          },
          ':hover::after':{
            opacity: 1
          },
          img: {
            transition: 'filter 0.3s ease-in-out',
            filter: 'grayscale(1)'
          },
          ':hover img': {
            filter: 'grayscale(0)'
          }
        }}
        component='a'
        href={content.link}
        target='_blank'
        rel='noopener noreferrer'
      >
        {content.icon}
        <Typography
          variant='subtitle1'
          sx={{ fontSize: '17px', lineHeight: '20px', color: 'accent.main', fontWeight: '600' }}
        >
          {content.title}
        </Typography>
      </Link>
    </Slide>
  )
}

export default PartnerItem
