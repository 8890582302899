import { theme } from '@/plugins/material-ui/theme'
import circleDividerIcon from '@/assets/icons/circle-divider.svg'

export const styles = {
  item: {
    display: 'flex',
    width: 'auto',
    p: `0 0 ${theme.spacing(1.5)}`,
    '&:not(:last-child)': {
      mr: theme.spacing(2)
    },
    '&::before': {
      content: `url(${circleDividerIcon})`,
      pr: theme.spacing(2)
    }
  }
}
