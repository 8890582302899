/**
 * Determines if params are equal
 * @param x
 * @param y
 * @returns {boolean|boolean}
 */
export const isEqual = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every((key) => isEqual(x[key], y[key]))
    : x === y
}

/**
 * Creates an object copy
 *
 * @param object
 * @returns {any}
 */
export const jsonCopy = (object) => JSON.parse(JSON.stringify(object))

export const truncateTextByLines = (lines) => {
  return {
    wordBreak: 'break-word',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lines.toString()
  }
}

export const cutString = (str, len) => {
  if (str.length > len) {
    return str.substring(0, len)
  }

  return str
}
