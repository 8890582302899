import AdminFormsTable from '@/containers/admin-communication-forms'
import AdminPageHeader from '@/containers/admin-page-header'
import Box from '@mui/material/Box'
import AppTabs from '@/components/app-tabs'
import { setInitialFilter } from '@/components/app-grid/utils/app-grid-utils'
import useAxios from '@/hooks/use-axios'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTabs } from '@/hooks/use-tabs'
import { AppealService } from '@/services/communication-service'
import { appealTypes } from '@/containers/constants'
import { exportToCsvFile } from '@/utils/csv-export-helper'

const AdminCommunicationFormsPage = () => {
  const [counts, setCounts] = useState({ allPosts: 0, posted: 0, draft: 0 })
  const { t } = useTranslation()
  const { fetchData } = useAxios({ service: AppealService.countPerStatus })

  const setCountPerStatus = useCallback(async () => {
    const { total, FEEDBACK, NEWS_SUBSCRIPTION } = await fetchData()
    setCounts({ total: total, feedback: FEEDBACK, subscribe: NEWS_SUBSCRIPTION })
  }, [fetchData])

  const downloadAllContacts = async () => {
    const { data } = await AppealService.export()
    const filename = t('adminCommunicationForms.contactFileName')
    exportToCsvFile(data, filename)
  }

  const appealsListTabs = useMemo(
    () => ({
      total: {
        value: 'total',
        label: t('adminCommunicationForms.allContacts'),
        component: <AdminFormsTable onTableRefresh={setCountPerStatus} />
      },
      feedback: {
        value: 'feedback',
        label: t('adminCommunicationForms.appeal'),
        component: (
          <AdminFormsTable
            onTableRefresh={setCountPerStatus}
            filterBy={setInitialFilter('type', appealTypes.FEEDBACK)}
          />
        )
      },
      subscribe: {
        value: 'subscribe',
        label: t('adminCommunicationForms.subscribes'),
        component: (
          <AdminFormsTable
            onTableRefresh={setCountPerStatus}
            filterBy={setInitialFilter('type', appealTypes.NEWS_SUBSCRIPTION)}
          />
        )
      }
    }),
    [setCountPerStatus, t]
  )
  const { tabIndex, handleTabChange } = useTabs({ tabs: appealsListTabs })

  return (
    <Box
      className='admin-news-page'
      sx={{ mx: '32px', mb: '32px', display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <AdminPageHeader
        title={t('adminCommunicationForms.title')}
        buttonLabel={t('adminCommunicationForms.downloadFile')}
        onClick={downloadAllContacts}
      />
      <AppTabs
        counts={counts}
        tabIndex={tabIndex}
        handleTabChange={handleTabChange}
        tabs={appealsListTabs}
        stayAlive={false}
      />
    </Box>
  )
}

export default AdminCommunicationFormsPage
