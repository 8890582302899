import i18next from 'i18next'
import { theme } from '@/plugins/material-ui/theme'

export const baseContainerSX = {
  mx: 'auto',
  maxWidth: '1440px',
  display: 'flex',
  flexDirection: 'column'
}

export const adminEditContainerSX = {
  padding: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  flexGrow: 1
}

export const baseMb = {
  mb: { lg: theme.spacing(10), sm: theme.spacing(5), xs: theme.spacing(5) }
}

export const basePx = {
  px: { md: theme.spacing(5), sm: theme.spacing(2), xs: theme.spacing(1) }
}

export const documentStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED'
}

export const documentStatusOptions = [
  { value: documentStatus.PUBLISHED, text: i18next.t('forms.publishStatus') },
  { value: documentStatus.DRAFT, text: i18next.t('forms.draftStatus') }
]

export const documentStatusField = (options) => {
  return {
    type: 'select',
    options: {
      name: 'status',
      required: true,
      label: i18next.t('forms.status'),
      SelectProps: {
        options: documentStatusOptions
      },
      ...options
    }
  }
}

export const langTypeColumn = {
  type: 'singleSelect',
  valueOptions: [i18next.t('common.labels.ukr'), i18next.t('common.labels.eng')],
  valueFormatter: ({ value }) =>
    value?.map((e) => ({ en: i18next.t('common.labels.eng'), uk: i18next.t('common.labels.ukr') }[e])).join(', '),
  sortable: false
}
export const hideScroll = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none'
  },
  overflow: 'auto'
}

export const appealTypes = {
  FEEDBACK: 'FEEDBACK',
  NEWS_SUBSCRIPTION: 'NEWS_SUBSCRIPTION'
}
