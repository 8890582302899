import { useNavigate } from 'react-router-dom'
import { useScriptLoader } from '@/hooks/use-script-loader'
import Box from '@mui/material/Box'
import { IconButton } from '@mui/material'
import React from 'react'
import arrowIcon from '@/assets/icons/arrow.svg'
import InvestItem from '@/containers/investment_opportunities/invests-item/InvestItem'
import './gallery.css'

export const ArrowComponent = ({ reverse = false }) => {
  const sx = reverse ? { transform: 'scaleX(-1)' } : {}
  return (
    <IconButton className={reverse ? 'gallery-arrow-reverse' : 'gallery-arrow'}>
      <Box
        sx={{ ...sx }}
        component={'img'}
        alt={'left'}
        src={arrowIcon}
        height={55}
        width={55}
      />
    </IconButton>
  )
}

const InvestDesktopSlider = ({ items }) => {
  const navigate = useNavigate()

  useScriptLoader({ path: 'js/tween-max.min.js' })
  useScriptLoader({ path: 'js/jquery.min.js' })
  useScriptLoader({ path: 'js/gallery.js', loadAfterDeps: true, deps: items })

  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        className='filters hidden'
      >
        <defs>
          <filter id='blur'>
            <feGaussianBlur
              in='SourceGraphic'
              stdDeviation='0,0'
            />
          </filter>
        </defs>
      </svg>
      <div className='gallery'>
        <ul className='gallery-pictures'>
          {items.map((item, index) => (
            <Box
              onClick={(e) => {
                if (e.currentTarget.parentElement.classList.contains('dragging')) {
                  return
                }
                navigate('/investment-object/' + item.id)
              }}
              component='li'
              key={index}
              className={'gallery-picture'}
            >
              <InvestItem item={item} />
            </Box>
          ))}
        </ul>
        <div className='gallery-pagination'>
          {items.map((item) => (
            <button
              key={item.id}
              className='gallery-pagination-dot hidden'
            ></button>
          ))}
        </div>
      </div>
    </>
  )
}
export default InvestDesktopSlider
