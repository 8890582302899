import { request } from '@/utils/request'
import { URLs } from '@/constants/URLs'
import { buildURL } from '@/utils/url-builder'

export const FileService = {
  upload: (file) => {
    return request.post(URLs.file.upload, file)
  },
  delete: (id) => {
    return request.delete(buildURL(URLs.file.delete, { id }))
  }
}
