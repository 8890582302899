import AppTextWithIcon from '@/components/app-text-with-icon'
import { toShortDate } from '@/utils/date'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Chip, Skeleton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { truncateTextByLines } from '@/utils/common'
import useBreakpoints from '@/hooks/use-breakpoints'
import { SkeletonTags } from '@/containers/shared/skeleton/skeleton-tags/SkeletonTags'

const NewsItemBody = ({ item, isHovered, loading, dark = false }) => {
  const { isMobile } = useBreakpoints()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <AppTextWithIcon
        sx={{ mb: '20px' }}
        text={{
          value: toShortDate(item.publishedDate),
          variant: 'body1',
          color: dark ? ({ palette }) => palette.text.contrast : 'textSecondary'
        }}
        icon={
          <CalendarTodayIcon
            sx={(theme) => ({ color: dark ? theme.palette.text.contrast : theme.palette.text.secondary })}
          />
        }
        loading={loading}
      />
      <Typography
        data-testid={'title'}
        sx={{ mb: '16px', ...truncateTextByLines(isMobile ? 4 : 2) }}
        variant={'h4'}
        color={isHovered ? 'primary' : dark ? ({ palette }) => palette.basic.white : undefined}
      >
        {loading ? <Skeleton /> : item.title}
      </Typography>
      <Typography
        data-testid={'text'}
        component='div'
        sx={{
          mb: '20px',
          ...truncateTextByLines(isMobile ? 6 : 4)
        }}
        color={dark ? ({ palette }) => palette.text.contrast : 'textSecondary'}
        variant={'body1'}
      >
        {loading ? <Skeleton /> : item.text}
      </Typography>
      <Box>
        {(() => {
          if (loading) {
            return <SkeletonTags />
          }

          const tags = item.tags.slice(0, 2)

          return tags.map((tag) => (
            <Chip
              data-testid={'chip'}
              key={tag.title}
              sx={{ mr: '8px', mb: '8px',
                backgroundColor: dark ? '#6D6C6C' : '#F3F3F3',
                color: dark ? '#ffffff' : '#373A36'
              }}
              label={tag.title}
            />
          ))
        })()}
      </Box>
    </Box>
  )
}

export default NewsItemBody
