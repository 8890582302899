import { Badge, Box, Button, IconButton, styled } from '@mui/material'

import { characteristicType } from '@/pages/admin/admin-invest-object-edit/constants'
import React, { useMemo, useState } from 'react'
import { theme } from '@/plugins/material-ui/theme'
import AppFormControl from '@/containers/invest-object-list/invest-object-list-filters/app-form-control/AppFormControl'
import AppSelectRangeSlider from '@/containers/invest-object-list/invest-object-list-filters/app-select-range-slider/AppSelectRangeSlider'
import AppSelectMultiCheckbox from '@/containers/invest-object-list/invest-object-list-filters/app-select-multi-checkbox/AppSelectMultiCheckbox'
import { useTranslation } from 'react-i18next'
import useBreakpoints from '@/hooks/use-breakpoints'
import filterIcon from '@/assets/icons/filter.svg'
import { hideScroll } from '@/containers/constants'
import { useFilterBy } from '@/containers/invest-object-list/invest-object-list-filters/hooks/useFilterBy'
import {
  characteristicsTypeFilter,
  definedOwnershipTypeOptions,
  landCategorySectionFilter,
  ownershipTypeFilter,
  plotAreaFromFilter,
  plotAreaToFilter,
  realStateTypeCategorySectionFilter,
  realtyTotalAreaSqMFromFilter,
  realtyTotalAreaSqMToFilter,
  squareMaxArea,
  squareMinArea,
  usageTypeFilter,
  usageTypes
} from '@/containers/invest-object-list/invest-object-list-filters/constant'
import InvestObjectListFilterMobileBar from '@/containers/invest-object-list/invest-object-list-filters/InvestObjectListFilterMobileBar'
import Dialog from '@mui/material/Dialog'
import CharacteristicTypeFilter from '@/containers/invest-object-list/invest-object-list-filters/ivest-object-list-filter-characteristic/CharacteristicTypeFilter'
import { Transition } from '@/components/app-popup-dialog/constant'
import { usageType } from '@/pages/admin/admin-invest-object-edit/constants'
import { landCategorySectionOptions } from '@/containers/admin-invest-object/admin-invest-object-form/land-plot-section/constants'
import { realtyTypesOptions } from '@/containers/admin-invest-object/admin-invest-object-form/real-estate-section/constants'

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 18,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0'
  }
}))

const InvestObjectListFilters = () => {
  const { t } = useTranslation()
  const { isLarge } = useBreakpoints()

  const { getFilter, setFilter, clearFilter, filterBy, clearAllFilters } = useFilterBy()

  const isRealStateFilter = getFilter(characteristicsTypeFilter)?.value === characteristicType.realEstate
  const isLandFilter = getFilter(characteristicsTypeFilter)?.value === characteristicType.land
  const isAllFilter = !getFilter(characteristicsTypeFilter)?.value

  const usageTypeSelected = getFilter(usageTypeFilter)?.value.filter((e) => e !== usageType.saleOrRent) ?? []
  const landCategorySectionSelected = getFilter(landCategorySectionFilter)?.value ?? []
  const realStateTypeSectionSelected = getFilter(realStateTypeCategorySectionFilter)?.value ?? []
  const ownershipTypeSelected = getFilter(ownershipTypeFilter)?.value ?? []

  const plotAreaRangeFrom = getFilter(plotAreaFromFilter)?.value ?? squareMinArea
  const plotAreaRangeTo = getFilter(plotAreaToFilter)?.value ?? squareMaxArea

  const realtyTotalAreaSqMRangeFrom = getFilter(realtyTotalAreaSqMFromFilter)?.value ?? squareMinArea
  const realtyTotalAreaSqMRangeTo = getFilter(realtyTotalAreaSqMToFilter)?.value ?? squareMaxArea
  const filterCount = useMemo(() => filterBy.length, [filterBy.length])
  const plotRange = { from: plotAreaRangeFrom, to: plotAreaRangeTo }
  const realtyRange = { from: realtyTotalAreaSqMRangeFrom, to: realtyTotalAreaSqMRangeTo }

  const plotRangeIsSelected = useMemo(
    () => plotAreaRangeFrom !== squareMinArea || plotAreaRangeTo !== squareMaxArea,
    [plotAreaRangeFrom, plotAreaRangeTo]
  )
  const realtyRangeIsSelected = useMemo(
    () => realtyTotalAreaSqMRangeFrom !== squareMinArea || realtyTotalAreaSqMRangeTo !== squareMaxArea,
    [realtyTotalAreaSqMRangeFrom, realtyTotalAreaSqMRangeTo]
  )
  const [mobileView, setMobileView] = useState(false)
  const handleClickOpen = () => {
    setMobileView(true)
  }
  const handleClose = () => {
    setMobileView(false)
  }
  const getContent = () => (
    <>
      {isLarge ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2), mb: '16px' }}>
          <CharacteristicTypeFilter />
        </Box>
      ) : null}

      <Box
        sx={{
          display: 'flex',
          flexDirection: mobileView ? 'column' : 'row',
          gap: '8px',
          alignItems: 'center',
          my: '10px',
          flexWrap: 'nowrap',
          ...hideScroll,
          overflow: isLarge ? 'initial' : 'auto',
          minHeight: isLarge ? 'auto' : '50px'
        }}
      >
        {!isLarge && !mobileView ? (
          <IconButton onClick={handleClickOpen}>
            <StyledBadge
              badgeContent={filterCount}
              color='secondary'
            >
              <Box
                component={'img'}
                alt={t('investObjectMap.filter.lookUp')}
                src={filterIcon}
                height={20}
                width={20}
              />
            </StyledBadge>
          </IconButton>
        ) : null}
        {!isLarge ? (
          <CharacteristicTypeFilter
            isLarge={isLarge}
            mobileView={mobileView}
          />
        ) : null}

        {isAllFilter || isLandFilter ? (
          <AppFormControl
            selected={plotRangeIsSelected}
            mobileView={mobileView}
            minWidth='220px'
            label={t('investObjectMap.filter.squareHectares')}
          >
            <AppSelectRangeSlider
              mobileView={mobileView}
              min={squareMinArea}
              max={squareMaxArea}
              step={0.1}
              range={plotRange}
              changeFrom={(from) => setFilter(plotAreaFromFilter, from)}
              changeTo={(to) => setFilter(plotAreaToFilter, to)}
              placeholder={t('investObjectMap.filter.squareAny')}
            />
          </AppFormControl>
        ) : null}
        {isAllFilter || isRealStateFilter ? (
          <AppFormControl
            selected={realtyRangeIsSelected}
            mobileView={mobileView}
            minWidth='220px'
            label={t('investObjectMap.filter.squareMeters')}
          >
            <AppSelectRangeSlider
              mobileView={mobileView}
              min={squareMinArea}
              max={squareMaxArea}
              step={0.1}
              range={realtyRange}
              changeFrom={(from) => setFilter(realtyTotalAreaSqMFromFilter, from)}
              changeTo={(to) => setFilter(realtyTotalAreaSqMToFilter, to)}
              placeholder={t('investObjectMap.filter.squareAny')}
            />
          </AppFormControl>
        ) : null}

        {isLandFilter ? (
          <AppFormControl
            selected={landCategorySectionSelected.length > 0}
            mobileView={mobileView}
            label={t('investObjectMap.filter.landCategorySection')}
          >
            <AppSelectMultiCheckbox
              mobileView={mobileView}
              placeholder={t('investObjectMap.filter.allTypes')}
              options={landCategorySectionOptions()}
              minWidth={'340px'}
              selectedOptions={landCategorySectionSelected}
              changeOptions={(value) => setFilter(landCategorySectionFilter, value)}
              removeOptions={() => clearFilter(landCategorySectionFilter)}
            />
          </AppFormControl>
        ) : null}

        <AppFormControl
          mobileView={mobileView}
          selected={usageTypeSelected.length > 0}
          label={t('investObjectMap.filter.usageType')}
        >
          <AppSelectMultiCheckbox
            mobileView={mobileView}
            placeholder={t('investObjectMap.filter.allTypes')}
            options={usageTypes()}
            selectedOptions={usageTypeSelected}
            changeOptions={(value) => {
              return setFilter(usageTypeFilter, value.length > 0 ? [...value, usageType.saleOrRent] : value)
            }}
            removeOptions={() => clearFilter(usageTypeFilter)}
          />
        </AppFormControl>

        <AppFormControl
          selected={ownershipTypeSelected.length > 0}
          mobileView={mobileView}
          label={t('investObjectMap.filter.ownershipType')}
        >
          <AppSelectMultiCheckbox
            mobileView={mobileView}
            placeholder={t('investObjectMap.filter.allTypes')}
            options={definedOwnershipTypeOptions()}
            selectedOptions={ownershipTypeSelected}
            changeOptions={(value) => setFilter(ownershipTypeFilter, value)}
            removeOptions={() => clearFilter(ownershipTypeFilter)}
          />
        </AppFormControl>

        {isRealStateFilter ? (
          <AppFormControl
            selected={realStateTypeSectionSelected.length > 0}
            mobileView={mobileView}
            label={t('investObjectMap.filter.realStateType')}
          >
            <AppSelectMultiCheckbox
              mobileView={mobileView}
              placeholder={t('investObjectMap.filter.allTypes')}
              options={realtyTypesOptions()}
              minWidth={'280px'}
              selectedOptions={realStateTypeSectionSelected}
              changeOptions={(value) => setFilter(realStateTypeCategorySectionFilter, value)}
              removeOptions={() => clearFilter(realStateTypeCategorySectionFilter)}
            />
          </AppFormControl>
        ) : null}

        {mobileView ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '20px', px: '12px' }}>
            <Button
              disabled={filterBy.length === 0}
              onClick={() => {
                clearAllFilters()
                handleClose()
              }}
              variant={'outlined'}
            >
              {t('investObjectMap.filter.clearAllMobile')}
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={handleClose}
            >
              {t('investObjectMap.filter.apply')}
            </Button>
          </Box>
        ) : null}
      </Box>
    </>
  )

  if (mobileView) {
    return (
      <Dialog
        fullScreen
        open={mobileView}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <InvestObjectListFilterMobileBar handleClose={handleClose}>{getContent()}</InvestObjectListFilterMobileBar>
      </Dialog>
    )
  }
  return getContent()
}

export default InvestObjectListFilters
