export const styles = {
  form: {
    display: 'grid',
    gap: '16px 6px',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: 'repeat(2, auto) 1fr'
  },
  inputs: {
    title: {
      gridColumn: '1 / 5',
      gridRow: '1'
    },
    status: {
      gridColumn: '5 / 7',
      gridRow: '1'
    },
    featureImage: {
      gridColumn: '1 / 4',
      gridRow: '2'
    },
    publishedDate: {
      gridColumn: '4 / 7',
      gridRow: '2'
    },
    text: {
      gridColumn: 'span 6',
      gridRow: '3'
    }
  }
}
