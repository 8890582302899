import { useCallback, useState } from 'react'

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName)
      if (value) {
        return JSON.parse(value)
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (err) {
      return defaultValue
    }
  })
  const setValue = useCallback(
    (newValue) => {
      if (typeof newValue === 'function') {
        newValue = newValue(JSON.parse(window.localStorage.getItem(keyName)))
      }

      window.localStorage.setItem(keyName, JSON.stringify(newValue))
      setStoredValue(newValue)
    },
    [keyName]
  )

  const removeValue = useCallback(() => {
    window.localStorage.removeItem(keyName)
  }, [keyName])

  return [storedValue, setValue, removeValue]
}
