export const clientRoutes = {
  home: { label: 'home', route: '/' },
  news: { label: 'news', route: '/news' },
  searchResults: { label: 'searchResults', route: '/search-results' },
  newsDetail: { label: 'newsDetail', route: '/news/:id' },
  aboutRegion: { label: 'aboutRegion', route: '/about-region' },
  keyIndustries: { label: 'keyIndustries', route: '/key-industries' },
  businessEnvironment: { label: 'businessEnvironment', route: '/business-community' },
  investmentOpportunitiesMap: { label: 'investmentOpportunitiesMap', route: '/investment-opportunities-map' },
  investObjectDetailsPage: { label: 'investObjectDetails', route: '/investment-object/:id' },
  submitObject: { label: 'submitObject', route: '/submit-object' },
  eventCalendar: { label: 'eventCalendar', route: '/event-calendar' },
  event: { label: 'event', route: '/event-calendar/:id', path: '/event-calendar/' },
  businessOffers: { label: 'businessOffers', route: '/business-offers' },
  supportPrograms: { label: 'supportPrograms', route: '/support-programs' },
  supportProgram: { label: 'supportProgram', route: '/support-programs/:id' },
  exportConsulting: { label: 'exportConsulting', route: '/export-consulting' },
  aboutUs: { label: 'aboutUs', route: '/about-us' },
  loanPrograms: { label: 'loanPrograms', route: '/support-programs' },
  grants: { label: 'grants', route: '/support-programs' },
  irrevocableSupport: { label: 'irrevocableSupport', route: '/support-programs' },
  statePrograms: { label: 'statePrograms', route: '/support-programs' },
  financialPrograms: { label: 'financialPrograms', route: '/business-offers' },
  investmentObjects: { label: 'investmentObjects', route: '/business-offers' },
  businessAdministration: { label: 'businessAdministration', route: '/business-offers' },
  exportConsultations: { label: 'exportConsultations', route: '/business-offers' },
  notFound: { label: 'notFound', route: '*' }
}
