import React from 'react'
import { useReCaptcha } from '@/hooks/use-captcha'
import useAxios from '@/hooks/use-axios'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IconButton, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { email } from '@/utils/validations/common'
import useBreakpoints from '@/hooks/use-breakpoints'
import { useTranslation } from 'react-i18next'

import { NewsSubscription } from '@/services/news-service'
import { useSnackbar } from 'notistack'

const SubscribeBlock = () => {
  const { handleReCaptchaVerify } = useReCaptcha()
  const { enqueueSnackbar } = useSnackbar()

  const { t } = useTranslation()
  const { isTablet, isDesktop } = useBreakpoints()
  const [value, setValue] = React.useState('')
  const [error, setError] = React.useState('')
  const [sendStatus, setSendStatus] = React.useState('')
  const { fetchData, loading } = useAxios({ service: NewsSubscription.subscribe })

  const inputRef = React.useRef(null)

  const isInputEmpty = !value.length

  const adaptiveStyles = {
    gridColumnStart: isTablet && 1,
    gridColumnEnd: isTablet && 4,
    gridRowStart: isTablet && 3,
    gridRowEnd: isTablet && 4,
    borderTop: isTablet && '1px solid #D9D9D9',
    pt: isTablet && '1rem',
    display: isTablet ? 'flex' : 'block',
    justifyContent: isTablet && 'space-between'
  }

  const onChangeInputHandler = (inputValue) => {
    setValue(inputValue)
    const isValidEmail = email(inputValue)
    if (isValidEmail) {
      return setError(isValidEmail)
    }
    setError('')
  }

  const onSubmitHandler = async (ev) => {
    ev.preventDefault()
    try {
      const token = await handleReCaptchaVerify()
      token && (await fetchData({ email: value, pageUrl: window.location.href }, token))
      setSendStatus(t('footer.footer.successMessage'))
      setTimeout(() => {
        setSendStatus('')
      }, 5000)
      setValue('')
    } catch (e) {
      enqueueSnackbar(t('footer.footer.successError'), { variant: 'error' })
    }
  }

  return (
    <Box sx={{ ...adaptiveStyles }}>
      <Box sx={{ width: isTablet ? '315px' : '100%' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 600, pb: '1.5rem' }}>{t('footer.footer.subscribe')}</Typography>
        <Box sx={{ fontSize: '14px', fontWeight: 400, height: '51px', maxWidth: isDesktop ? '315px' : '100%' }}>
          {t('footer.footer.subscribeDescription')}
        </Box>
      </Box>
      {!sendStatus ? (
        <Box
          sx={{ display: 'flex', pt: '1rem', flex: 1 }}
          component='form'
          onSubmit={(ev) => onSubmitHandler(ev)}
        >
          <TextField
            error={!!error}
            helperText={error}
            inputRef={inputRef}
            value={value}
            onChange={(ev) => onChangeInputHandler(ev.target.value)}
            placeholder='E-mail'
            sx={{
              flex: 1,
              pt: '1rem',
              transition: 'max-width 300ms ease',
              '& .MuiOutlinedInput-root': {
                alignSelf: 'flex-end',
                borderRadius: '84px',
                height: '60px',
                width: '100%',
                fontSize: '17px',
                fontWeight: 500,
                transition: 'width 1s ease'
              }
            }}
          />
          <IconButton
            title={t('footer.footer.subscribe')}
            type='submit'
            disabled={isInputEmpty || error || loading}
            sx={{
              mt: '15px',
              ml: '0.9rem',
              width: '60px',
              height: '60px',
              backgroundColor: 'primary.main',
              '&:hover, &:focus': {
                backgroundColor: 'primary.light'
              },
              '&:disabled': {
                backgroundColor: 'accent.light'
              }
            }}
          >
            <SendIcon
              fontSize='medium'
              sx={{ color: 'basic.white' }}
            />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            py: '3rem',
            border: '1px solid transparent',
            color: 'primary.dark',
            fontSize: '18px',
            fontWeight: '500'
          }}
        >
          {sendStatus}
        </Box>
      )}
    </Box>
  )
}

export default SubscribeBlock
