import { createContext, useContext, useState } from 'react'
import { gridSettings } from '@/components/app-grid/constants/app-grid-settings'
import PropTypes from 'prop-types'

export const AppGridContext = createContext({})

const AppGridProviderProps = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  settings: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
  bulkActions: PropTypes.object,
  rowActions: PropTypes.object,
  externalFilters: PropTypes.object,
  onTableRefresh: PropTypes.func,
}

export const AppGridProvider = ({
  columns,
  settings,
  service,
  bulkActions,
  rowActions,
  externalFilters,
  onTableRefresh,
  children
}) => {
  const [items, setItems] = useState([])
  const [totalRows, setTotalRows] = useState(0)

  const [loading, setLoading] = useState(true)

  const [settingsModel] = useState({ ...settings, ...gridSettings })

  const [selectedRows, setSelectedRows] = useState([])

  const [currPage, setCurrPage] = useState(0)
  const [pageSize, setPageSize] = useState(settingsModel.rowsPerPageOptions[0])
  const [filterModel, setFilterModel] = useState({ items: [] })
  const [sortModel, setSortModel] = useState([])

  const [isActionLoading, setActionLoading] = useState(false)

  const provide = {
    items,
    totalRows,
    loading,
    settingsModel,
    selectedRows,
    currPage,
    pageSize,
    filterModel,
    sortModel,
    setItems,
    setTotalRows,
    setLoading,
    setSelectedRows,
    setCurrPage,
    setPageSize,
    setFilterModel,
    setSortModel,
    isActionLoading,
    setActionLoading,
    columns,
    service,
    bulkActions,
    rowActions,
    externalFilters,
    onTableRefresh
  }

  return <AppGridContext.Provider value={provide}>{children}</AppGridContext.Provider>
}

export const useAppGrid = () => {
  return useContext(AppGridContext)
}

AppGridProvider.propTypes = AppGridProviderProps
