import axios from 'axios'
import { URLs } from '@/constants/URLs'
import { DateTime } from 'luxon'
import parseJwt from '@/utils/jwt'
import i18next from 'i18next'

export const request = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_API_BASE_PATH
})

export const setupAuthBearer = () => {
  request.interceptors.request.use((config) => {
    const accessToken = JSON.parse(window.localStorage.getItem('accessToken'))

    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken
    }
    return config
  })
}

export const setupRefresh = async (refresh, logout) => {
  const token = JSON.parse(window.localStorage.getItem('accessToken'))
  const isExpiredAccessToken = token && DateTime.now().toSeconds() > parseJwt(token).exp

  if (isExpiredAccessToken) {
    const refreshToken = JSON.parse(window.localStorage.getItem('refreshToken'))
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_PATH}${URLs.refresh}`,
        { refreshToken },
        { headers: {} }
      )
      refresh(data)
    } catch (e) {
      logout()
    }
  }
}

export const setupCatchGlobalError = (setError) => {
  request.interceptors.response.use(undefined, (error) => {
    if (![401, 403].includes(error?.response?.status)) {
      setError(i18next.t(`error.${error?.response?.status}`))
    }
    return Promise.reject(error)
  })
}
