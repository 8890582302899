import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import AppContainerHeader from '@/components/app-container-header'

import NewsItem from '@/containers/news-short-list/news-item'
import useBreakpoints from '@/hooks/use-breakpoints'
import AppButtonGoTo from '@/components/app-button-go-to'
import { useNavigate } from 'react-router-dom'
import { baseContainerSX } from '@/containers/constants'
import { responsiveGap, style } from '@/pages/home-page/HomePage.styles'
import { generateSkeletonItems } from '@/utils/skeleton'

const componentProps = {
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
}

const NewsShortList = ({ items, loading }) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const { isDesktop, isTablet, isMobile } = useBreakpoints()

  const onHeaderButtonClick = () => {
    navigate('/news')
  }

  const newsItems = useMemo(() => {
    const responsiveItems = loading ? generateSkeletonItems(3) : isDesktop ? items : items.slice(0, 2)

    return responsiveItems.map((item) => (
      <NewsItem
        dark
        loading={loading}
        vertical
        url={`/news/${item.id}`}
        key={item.id}
        item={item}
      />
    ))
  }, [isDesktop, items, loading])

  const newsContainerSx = { gridTemplateColumns: isDesktop ? '1fr 1fr 1fr' : isTablet ? '1fr 1fr' : '1fr' }

  const goToNewsButtonProps = {
    onClick: onHeaderButtonClick,
    title: i18n.t('homePage.allNews'),
    color: ({ palette }) => palette.basic.white
  }

  return (
    <Box
      sx={{
        backgroundColor: ({ palette }) => palette.accent.main
      }}
      component='section'
      data-testid={'newsShortList'}
    >
      <Box sx={{ ...baseContainerSX, ...style.section }}>
        <AppContainerHeader
          sx={{ my: responsiveGap, color: ({ palette }) => palette.basic.white }}
          title={i18n.t('homePage.lastNews')}
          button={goToNewsButtonProps}
          showButton={!isMobile}
        />
        <Box sx={{ display: 'grid', columnGap: '30px', rowGap: '30px', ...newsContainerSx, mb: responsiveGap }}>
          {newsItems}
        </Box>
        {isMobile && (
          <Box sx={{ ...style.appToGo }}>
            <AppButtonGoTo {...goToNewsButtonProps} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

NewsShortList.propTypes = componentProps

export default NewsShortList
