import { createContext, useCallback, useContext, useMemo } from 'react'
import { useLocalStorage } from '@/hooks/use-local-storage'
import { useLocation, useNavigate } from 'react-router-dom'

import { adminRoutes } from '@/plugins/router/admin-router/routes'
import parseJwt from '@/utils/jwt'
import { useConfirm } from '@/hooks/use-confirm'

export const AuthContext = createContext({
  accessToken: '',
  refreshToken: '',
  login: (data) => {},
  logout: () => {},
  refresh: () => {},
  activeUserRole: '',
  activeUserId: '',
  tempPassword: false
})

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken, deleteToken] = useLocalStorage('accessToken', null)
  const user = useMemo(() => (accessToken ? parseJwt(accessToken) : {}), [accessToken])
  const activeUserRole = user.role
  const activeUserId = user.sub
  const tempPassword = user.passwordDeprecationDate
  const [refreshToken, setRefreshToken, deleteRefresh] = useLocalStorage('refreshToken', null)

  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const { confirm: openConfirmDialog } = useConfirm()

  const login = (data) => {
    setAccessToken(data.accessToken)
    setRefreshToken(data.refreshToken)
    let redirect = adminRoutes.communicationForms.path
    if (state?.redirectFrom) {
      redirect = state.redirectFrom
    }
    navigate(redirect, { replace: true })
  }

  const refresh = useCallback(
    (data) => {
      setAccessToken(data.accessToken)
      setRefreshToken(data.refreshToken)
    },
    [setAccessToken, setRefreshToken]
  )

  const logout = useCallback(async () => {
    const text = 'Ви дійсно хочете вийти?'

    if (await openConfirmDialog(text, '')) {
      setAccessToken(null)
      setRefreshToken(null)
      deleteToken()
      deleteRefresh()
      navigate(adminRoutes.login.path, { state: { redirectFrom: pathname } })
    }
  }, [deleteRefresh, deleteToken, navigate, openConfirmDialog, pathname, setAccessToken, setRefreshToken])

  return (
    <AuthContext.Provider
      value={{ accessToken, refreshToken, login, logout, refresh, activeUserRole, activeUserId, tempPassword }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
