import { Box, Divider } from '@mui/material'
import useBreakpoints from '@/hooks/use-breakpoints'
import { generateSkeletonItems } from '@/utils/skeleton'
import NewsItem from '@/containers/news-short-list/news-item'

const NewsPageSkeleton = () => {
  const { isDesktop, isTablet } = useBreakpoints()
  return (
    <Box
      key='items'
      sx={{
        display: 'grid',
        position: 'absolute',
        gridTemplateColumns: isTablet ? '1fr 1fr' : '1fr',
        gap: '40px'
      }}
    >
      {generateSkeletonItems(isDesktop ? 3 : 1).map((news) => (
        <Box key={news.id}>
          <NewsItem
            vertical={!isDesktop}
            loading
          />
          {isDesktop && (
            <Divider
              variant='inset'
              sx={{ mt: '40px', mx: 0 }}
            />
          )}
        </Box>
      ))}
    </Box>
  )
}

export default NewsPageSkeleton
