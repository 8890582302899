import PropTypes from 'prop-types'
import { useState } from 'react'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { useHideAndShow } from '@/hooks/use-hide-and-show'

const AppPassInputProps = {
  input: PropTypes.object,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool
}

const AppPassInput = ({ input, value, error, helperText, onChange, onBlur, disabled, sx = {} }) => {
  const [inputValue, setInputValue] = useState(value)

  const { hideInput, toggleHideInput } = useHideAndShow()

  const onChangeHandler = (event) => {
    setInputValue(event.target.value)
    onChange(event)
  }

  return (
    <TextField
      value={inputValue}
      error={error}
      helperText={helperText}
      onChange={onChangeHandler}
      onBlur={onBlur}
      disabled={disabled}
      InputProps={{
        ...input.options,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={toggleHideInput}
              edge='end'
            >
              {hideInput ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      required={input.options.required}
      label={input.options.label}
      type={hideInput ? 'password' : 'text'}
    />
  )
}

AppPassInput.propTypes = AppPassInputProps

export default AppPassInput
